<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">                        
                    <h3 class="card-title">{{ $t('label.questionario') }} {{questionnaire.name}}</h3>
                    <form> 
                        <div class="row">
                            <div class="col-md-12">
                                <v-server-table ref="table" :name="table" :url="questionUrl" :data="tableData" :columns="columns" :options="options">                    
                                    <div slot="actions" slot-scope="props">                                  
                                        <button type="button" class="btn view-btn p-0" @click="viewQuestion(props.row.id)" :title="$t('label.visualizar')"><i class="fa fa-eye"></i></button>
                                    </div>
                                </v-server-table>
                            </div>
                        </div>                        
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/questionnaire"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>                                  
                            </div>
                        </div>
                    </form>                            
                </div>
            </div>
        </div>

        <question-modal 
            :questionnaire="questionnaire"
            :modalMode="modalMode"
            :modalStatus="modalStatus"
            :question_id="question_id"
            @changeModal='changeModal'
            @refreshTable='refreshTable'>
        </question-modal>
    </div>
</template>

<script>
import swal from 'sweetalert'
import QuestionModal from './QuestionModal'

export default {
    components: {QuestionModal},
    data() {
        return {
            questionnaire: {
                id: 0,
                name: ''
            },
            questions: [],
            answers: [],
            endPoint: '/questionnaire/',

            columns: ['id', 'description', 'actions'],
            tableData: [],
            options: {
                headings: {
                    'id': 'N°',
                    'description': this.$t('label.pergunta'),
                    'actions': this.$t('label.acoes')
                },
                debounce: 1000,
                requestFunction: function (data) {
                    data.questionnaire_id = this.$route.params.id;

                    let self = this;
                    self.$store.dispatch("tooggleLoadModal"); 
                    return this.$http.get(this.url, {
                        params: data
                    }).then(data => {                        
                        self.$store.dispatch("tooggleLoadModal"); 
                        return data
                    }).catch(function (e) {
                        self.$store.dispatch("tooggleLoadModal"); 
                        this.dispatch('error', e);
                    }.bind(this));
                },
                texts: {
                    count: `${this.$t('label.mostrando')} {from} ${this.$t('label.de')} {to} ${this.$t('label.ate')} {count} ${this.$t('label.deRegistros')}`,
                    filter: false,
                    filterPlaceholder: this.$t('label.pesquisarAqui'),
                    limit: '',
                    noResults: this.$t('label.nenhumRegistroEncontrado'),
                    page: this.$t('label.pagina')+':'
                },
            },
            enableView: true,
            enableEdit: true,
            enableDelete: true,
            name: 'questionsTable',
            table: "questionsTable",
            question_id: 0,
            modalMode: '',
            modalStatus: false
        }
    },
    watch: {
        modalStatus: function () {
            const self = this;
            self.$refs.table.getData();
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        },
        questionUrl() {
            return this.$store.getters.apiUrl + '/question/';
        }
    },
    mounted: function () {
        const self = this;
        self.loadQuestionnaireView();
    },
    methods: {
        refreshTable() {
            this.$refs.table.getData();
        },
        loadQuestionnaireView() {
            const self = this;

            this.$http.get(self.url, {
                'questionnaire_id': this.$route.params.id
            })
            .then((response) => {
                self.questionnaire = response.data;
            }).catch((error) => {    
                self.$errorMessage(error);
            })

        },
        addQuestion() {
            this.modalMode = 'add';
            this.question_id = 0;
            this.modalStatus = true;

            this.$("#modalQuestion").modal('show');
        },
        viewQuestion(id) {
            this.modalMode = 'view';
            this.question_id = id;
            this.modalStatus = true;

            this.$("#modalQuestion").modal('show');
        },
        editQuestion(id) {
            this.modalMode = 'edit';
            this.question_id = id;
            this.modalStatus = true;

            this.$("#modalQuestion").modal('show');
        },
        destroy(props, row, index) {
            const self = this;

            swal({
                title: self.$t('label.desejaDeletarRegistro'),
                text: self.$t('label.essaOperacaoNaoPodeSerDesfeita'),
                type: "warning",
                // showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim, deletar!",
                showLoaderOnConfirm: true,
                dangerMode: true,
                buttons: {
                    catch: {
                        text: self.$t('label.nao'),
                        value: "cancel",
                        className: "btn-danger"
                    },
                    confirm: {
                        text: self.$t('label.sim'),
                        value: 'confirm'
                    }
                },
            })
            .then((value) => {
                if (value == 'confirm') {
                    this.$http.delete(this.$store.getters.apiUrl + '/question/' + row.id, {
                        'id': row.id
                    }).then(() => {
                        self.$refs.table.tableData.splice(index - 1, 1);
                        self.$message('success',  self.$t('label.sucesso'),  self.$t('label.registroExcluidoComSucesso'));
                    })
                }
            }).catch(error => {
                self.$errorMessage(error);
            });

        },
        changeModal(status) {
            this.modalStatus = status;
        }
    }
}
</script>
<script>
import VueChartJs from "vue-chartjs";
export default {
  extends: VueChartJs.Line,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {    
    return {
      data: {}
    };
  },  
  methods: {},
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
};
</script>
<template>
    <div class="card">
        <div class="card-header bg-info">
            <h4 class="text-white">Video</h4>
        </div>
        <div class="card-body">
            <form method="post" class="form-material" @submit.prevent>
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="nomeEmpresa">Nome do video</label>
                        <input type="text" class="form-control form-control-lines">
                    </div>

                    <div class="form-group col-md-4">
                        <label for="cnpj">CNPJ</label>
                        <input type="text" class="form-control form-control-lines">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="site">Site</label>
                        <input type="text" class="form-control">
                    </div>
                </div>

            <div class="row">
                    <div class="form-group col-md-6">
                        <label for="cidade">Cidade</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group col-md-6">
                    <label for="uf">Unidade Federal</label>
                    <select name="estados-brasil" class="form-control form-control-lines">
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    </select>
                </div>
            </div>

            <div class="row">
                    <div class="form-group col-md-12">
                        <label for="observações">Observações</label>
                        <textarea name="texto" class="form-control form-control-lines" rows="5"></textarea>
                    </div>
            </div>                     
                    <button @submit.prevent @click="salvar"  class="btn btn-info">Salvar</button>
                    <router-link class="btn btn-warning" to="/video">Voltar</router-link>
                </form>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            Video: {
                name: '',
                observation: ''
            },
            endPoint: '/video/'
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {
        salvar () {
            
            let self = this;

            // implementar o salvar            

            self.$http.post(self.url,{
                'name': self.VideoAdd.name,
                'observation': self.Video.observation,
                'customer_id': self.$store.getters.customerId
            }).then( () => {
                self.$message('success', 'Sucesso', 'Registro salvo com sucesso!');
                this.$router.push({
                    path: "/video/"
                });
                
            }).catch((error) => {
                
                self.$errorMessage(error)              
            })
            // tratativa de erro
        }        
    },
    components: {

    }
}
</script>

<style>

</style>

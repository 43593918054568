<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.cadastroDeTemplate') }}</h3>
          <hr />
          <ul
            class="nav nav-pills nav-justified filters mb-3"
            id="pills-tab-role-template"
            role="tablist"
          >
            <li class="nav-item" @click="activeTab = 'template'">
              <a
                class="nav-link active"
                id="pills-template-tab"
                data-toggle="pill"
                href="#template"
                role="tab"
                aria-controls="pills-template"
                aria-selected="true"
              >
                <i class="fa fa-file-text-o"></i>
                {{$t('label.template')}}
              </a>
            </li>
            <li class="nav-item" @click="activeTab = 'etapas'">
              <a
                class="nav-link"
                id="pills-etapas-tab"
                data-toggle="pill"
                href="#etapas"
                role="tab"
                aria-controls="pills-etapas"
                aria-selected="true"
              >
                <i class="fa fa-list-ol"></i>
                {{$t('label.etapas')}}
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div
              :class="['tab-pane fade show', {'active': activeTab == 'template'}]"
              id="template"
              role="tabpanel"
              aria-labelledby="pills-template-tab"
            >
              <form method="post" @submit.prevent>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cep" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-building"></i>
                        </div>
                        <input type="text" class="form-control" v-model="role.name" autofocus />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cep" class="control-label mb-1">{{ $t('label.cargo') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-tag"></i>
                        </div>
                        <select id="role_id" v-model="role.role_id" class="form-control">
                          <option value>{{ $t('label.selecione') }}</option>
                          <option
                            v-for="role in roles"
                            :value="role.id"
                            :key="role.id"
                          >{{role.name}} {{ role.company_id ? ' - ' + role.company.name : '' }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cep" class="control-label mb-1">{{ $t('label.descricao') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-file-text-o"></i>
                        </div>
                        <textarea class="form-control" v-model="role.description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cep" class="control-label mb-1">{{ $t('label.requisitos') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-file-text-o"></i>
                        </div>
                        <textarea class="form-control" v-model="role.requirements"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cep"
                        class="control-label mb-1"
                      >{{ $t('label.competenciasDesejadas') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-star"></i>
                        </div>
                        <textarea type="text" class="form-control" v-model="role.differential"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cep"
                        class="control-label mb-1"
                      >{{ $t('label.competenciasComportamentais') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-star"></i>
                        </div>
                        <textarea type="text" class="form-control" v-model="role.behavioral_skills"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="formacao" class="control-label mb-1">{{ $t('label.formacao') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-university"></i>
                        </div>
                        <textarea
                          type="text"
                          v-model="role.formation"
                          id="formacao"
                          class="form-control"
                          placeholder
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cursosAdicionais"
                        class="control-label mb-1"
                      >{{ $t('label.cursosAdicionais') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-university"></i>
                        </div>
                        <textarea
                          type="text"
                          v-model="role.additional_courses"
                          id="cursosAdicionais"
                          class="form-control"
                          placeholder
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="horarioTrabalho"
                        class="control-label mb-1"
                      >{{ $t('label.horarioTrabalho') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input
                          type="text"
                          v-model="role.work_schedule"
                          id="horarioTrabalho"
                          class="form-control"
                          placeholder
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cargaHoraria"
                        class="control-label mb-1"
                      >{{ $t('label.cargaHoraria') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input
                          type="text"
                          v-model="role.workload"
                          id="cargaHoraria"
                          class="form-control"
                          placeholder
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="workplace"
                        class="control-label mb-1"
                      >{{ $t('label.localTrabalho') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-map-marker"></i>
                        </div>
                        <input
                          type="text"
                          v-model="role.workplace"
                          id="workplace"
                          class="form-control"
                          placeholder
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <h3 class="card-title mt-3">{{ $t('label.beneficios') }}</h3>

                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table color-table human-flow-table">
                        <thead v-if="roleBenefits.length > 0">
                          <tr>
                            <td class="no-pt-pb-bt">{{ $t('label.beneficio') }}</td>
                            <td class="no-pt-pb-bt">{{ $t('label.descricao') }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(benefit, key) in roleBenefits" :key="key">
                            <td>
                              <div class="input-group">
                                <div class="input-group-addon">
                                  <i class="fa fa-pencil"></i>
                                </div>
                                <input type="text" v-model="benefit.name" class="form-control" />
                              </div>
                            </td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-addon">
                                  <i class="fa fa-pencil"></i>
                                </div>
                                <input type="text" v-model="benefit.value" class="form-control" />
                                <div class="input-group-btn">
                                  <a
                                    class="btn trash-btn ml-3 color-white"
                                    @click="roleBenefits.splice(key,1)"
                                  >
                                    <i class="fa fa-trash-o"></i>
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tr>
                          <td colspan="3">
                            <a
                              class="btn include-btn p-0 color-white"
                              @submit.prevent
                              @click="addBenefit"
                            >
                              <i class="fa fa-plus"></i>
                              {{ $t('label.incluirBeneficios') }}
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              :class="['tab-pane fade show', {'active': activeTab == 'etapas'}]"
              id="etapas"
              role="tabpanel"
              aria-labelledby="pills-etapas-tab"
            >
              <div class="row">
                <div class="col-md-12 mb-4">
                  <div class="input-group">
                    <input
                      type="text"
                      id="adicionarCampo"
                      name="adicionarCampo"
                      :placeholder="$t('label.adicionarCampo')"
                      class="form-control"
                      v-model="stage.name"
                    />
                    <div class="input-group-btn">
                      <button class="btn form-btn ml-3" @click="addStage">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="rolesStages.length == 0">
                <div class="col-md-12 text-center">
                  <p class="text-muted">{{ $t('label.nenhumaEtapaCadastradaCadastreEtapas') }}</p>
                </div>
              </div>

              <ul class="sortable">
                <draggable v-model="rolesStages" @change="reorder" :list="rolesStages">
                  <li v-for="(stage,index) in rolesStages" :key="index">
                    <i class="fa fa-arrows"></i>

                    <span :id="'stage' + stage.id">
                      <label
                        v-if="!editing_stage || index != editing_index"
                      >{{ index + 1 }} - {{ stage.name }}</label>
                      <input
                        @keyup.enter="saveStage(stage)"
                        @keyup.esc="cancelUpdate"
                        class="bd-r-3"
                        type="text"
                        v-if="editing_stage && index == editing_index"
                        v-model="stage.name"
                      />
                    </span>

                    <div class="float-right" v-if="!readonly">
                      <button class="btn view-btn p-0" @click="loadModalConfig(stage, index)">
                        <i class="fa fa-cog"></i>
                      </button>

                      <button
                        class="btn edit-btn p-0"
                        v-if="!editing_stage || index != editing_index"
                        @click="updateStage(index)"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>

                      <button
                        class="btn edit-btn p-0"
                        v-if="editing_stage && index == editing_index"
                        @click="saveStage(stage)"
                      >
                        <i class="fa fa-check"></i>
                      </button>

                      <button
                        :disabled="editing_stage"
                        class="btn delete-btn p-0"
                        @click="removeStage(index)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </li>
                </draggable>
              </ul>
              <div class="row">
                <div class="col-md-12">
                  <p class="small text-muted">
                    {{ $t('label.arrasteSolteOrderDesejada') }}
                    <b>{{ $t('label.salvarOrdenacao') }}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <router-link class="btn include-btn p-1 ml-3 float-right" to="/roleTemplate">
                <i class="fa fa-reply"></i>
                {{ $t('label.voltar') }}
              </router-link>
              <button
                @submit.prevent
                @click="save"
                id="save-btn"
                class="btn include-btn p-1 ml-3 float-right"
              >
                <i class="fa fa-floppy-o"></i>
                {{ $t('label.salvar') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalConfig"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('label.configuracaoEtapa') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 form-group">
                <label>{{ $t('label.tipo') }}</label>
                <select name="type" v-model="stage_modal.type" class="form-control">
                  <option value="1">{{ $t('label.comum') }}</option>
                  <option value="2">{{ $t('label.teste') }}</option>
                  <option value="3">{{ $t('label.entrevista') }}</option>
                </select>
              </div>

              <div class="col-md-12 form-group">
                <label>{{ $t('label.perguntas') }}</label>
                <select
                  v-model="stage_modal.questionnaire_id"
                  :disabled="stage_modal.type != 2"
                  class="form-control"
                >
                  <option value disabled>{{ $t('label.selecione') }}...</option>
                  <option
                    v-for="question in questions"
                    :value="question.id"
                    :key="question.id"
                  >{{question.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn include-btn p-1 ml-3 float-right"
              @click="saveModalConfig"
            >
              <i class="fa fa-floppy-o"></i>
              {{ $t('label.salvar') }}
            </button>
            <button
              type="button"
              class="btn include-btn p-1 ml-3 float-right"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
              {{ $t('label.fechar') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "template",
      rolesStages: [],
      editing_stage_obj: {},
      stage_modal: {},
      stage_modal_index: "",
      questions: [],
      stage: {
        name: ""
      },
      readonly: false,
      editing_stage: false,
      editing_index: "",
      role: {
        name: "",
        requirements: "",
        differential: "",
        customer_id: "",
        contract_type: "",
        role_id: "",
        description: "",
        benefits: [],
        stages: []
      },
      roles: [],
      roleBenefits: [],
      endPoint: "/roleTemplate/"
    };
  },
  mounted: function() {
    this.getRoles();
    this.loadQuestions();
  },
  computed: {
    customerId() {
      return this.$store.state.customer.id;
    },
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    loadQuestions() {
      let self = this;
      self.$http
        .get(this.$store.getters.apiUrl + "/questionnaire", {
          params: {
            paginated: false
          }
        })
        .then(response => {
          self.questions = response.data.data;
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    },
    saveStage(stage) {
      if (stage.name == "") {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          self.$t("label.descricaoNaoInformada")
        );
        return;
      }

      this.editing_stage = false;
      this.editing_index = "";
      this.editing_stage_obj = {};
    },
    updateStage(index) {
      this.editing_stage = true;
      this.editing_index = index;
      this.editing_stage_obj = Object.assign(
        {},
        this.rolesStages[this.editing_index]
      );
    },
    cancelUpdate() {
      if (this.rolesStages.length > 0 && this.editing_stage) {
        this.rolesStages[this.editing_index] = this.editing_stage_obj;
      }

      this.editing_stage = false;
      this.editing_index = "";
      this.editing_stage_obj = {};
    },
    reorder() {
      for (let j in this.rolesStages) {
        this.rolesStages[j].order = parseInt(j) + 1;
      }
    },
    addStage() {
      if (this.stage.name == "") {
        this.message(
          "warning",
          this.$t("label.atencao"),
          this.$t("label.descricaoNaoInformada")
        );
        return;
      }

      this.cancelUpdate();

      this.rolesStages.push({
        id: 0,
        name: this.stage.name,
        order: this.rolesStages.length + 1
      });

      this.stage.name = "";
    },
    removeStage(index) {
      this.rolesStages.splice(index, 1);
    },
    getRoles() {
      const self = this;
      const api = self.$store.getters.apiUrl + "/role";

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(api, {
          params: {
            paginated: false,
            with: ["company"]
          }
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          this.roles = response.data.data;
        })
        .catch(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAoCliente")
          );
        });
    },
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");

      self.role.stages = this.rolesStages;
      self.role.benefits = this.roleBenefits;
      this.$http
        .post(self.url, self.role)
        .then(function() {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );

          self.$router.push({
            path: self.endPoint
          });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response.data;

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
    addBenefit() {
      this.roleBenefits.push({
        name: "",
        value: ""
      });
    },
    loadModalConfig(stage, index) {
      this.stage_modal = Object.assign({}, stage);
      this.stage_modal_index = index;
      this.$("#modalConfig").modal("show");
    },
    saveModalConfig() {
      let self = this;

      if (self.stage_modal.type != 2) {
        delete self.stage_modal.questionnaire_id;
      }

      this.rolesStages[this.stage_modal_index] = Object.assign(
        {},
        this.stage_modal
      );
      this.stage_modal = {};
      this.stage_modal_index = "";
      this.$("#modalConfig").modal("hide");
    }
  }
};
</script>

<style>
</style>

var RoleTemplateAdd = Vue.component("role-template-add", {
    
    template: 
    `
        
    `
})

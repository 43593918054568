<template>
    <div>   
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">        
                    <h3 class="card-title">{{ $t('label.visualizacaoCadastroEmpresas') }}</h3>                    
                    <form>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.logo') }}*</label>
                                    <div class="logo-image mb-3">                                        
                                        <img :src="companies.photo" :alt="$t('label.logo')" class="logo-companies">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="nome" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                                <input readonly='true' type="text" class="form-control" v-model="companies.name" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="sobre" class="control-label mb-1">{{ $t('label.sobreAEmpresa') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>                                                
                                                <textarea readonly='true' class="form-control" v-model="companies.about" name="sobre" id="sobre" rows="5" placeholder="" disabled=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <h3 class="card-title mt-3">{{ $t('label.informacoesEmpresa') }}</h3>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="cep" class="control-label mb-1">{{ $t('label.cep') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="companies.cep" placeholder="">
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="endereco" class="control-label mb-1">{{ $t('label.endereco') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="companies.address" placeholder="">
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="complemento" class="control-label mb-1">{{ $t('label.complemento') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                        <input readonly='true' type="text" placeholder="" class="form-control" v-model="companies.complement">
                                    </div>
                                </div>
                            </div>                                                                      
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="numero" class="control-label mb-1">{{ $t('label.numero') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                        <input readonly='true' type="text" class="form-control" v-model="companies.number" placeholder="">
                                    </div>
                                </div>
                            </div>                                  
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="bairro" class="control-label mb-1">{{ $t('label.bairro') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="companies.neighborhood" placeholder="">
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="cidade" class="control-label mb-1">{{ $t('label.cidade') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="companies.city" placeholder="">
                                    </div>
                                </div>
                            </div>                                                       
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="cidade" class="control-label mb-1">{{ $t('label.estado') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="companies.state" placeholder="">
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="cidade" class="control-label mb-1">{{ $t('label.codigoERP') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="companies.erp_code" placeholder="">
                                    </div>
                                </div>
                            </div>                                                             
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/companies"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                            </div>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {      
            companies: {
                id: 0,
                name: '',
                about: '',
                photo: '',
                address: '',
                state: '',
                city: '',
                complement: '',
                neighborhood: '',
                number: '',
                cep: '',
                erp_code: '',
            },
            endPoint: '/companies/'
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    mounted: function () {
        this.loadCompanies();
    },
    methods: {
        loadCompanies() {
            let self = this;
            
            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.get(this.url + this.$route.params.id)
                .then((response) => {
                    self.$store.dispatch("tooggleLoadModal"); 
                    self.companies = response.data;
                })
                .catch((error) => {    
                    self.$store.dispatch("tooggleLoadModal"); 
                    self.$errorMessage(error);
                })
        }
    }
}   
</script>

<style>

</style>
<template>
    <div>
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="text-white">Empresas Restritas</h4>                    
            </div>
            <div class="card-body">                                                        
                <form class="form-material" @submit.prevent>

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label><span class="required">*</span> Nome</label>
                                <input type="text" class="form-control" v-model="restricted.name" placeholder="Nome da empresa">
                            </div>
                        </div>                            
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label><span class="required">*</span> Observação</label>
                                <textarea class="form-control" v-model="restricted.observation" rows="3" placeholder="Observação"></textarea>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-5">
                                <button @submit.prevent @click="salvar"  class="btn btn-info">Salvar</button>
                                <router-link class="btn btn-warning" to="/restricted">Voltar</router-link>
                            </div>
                            <div class="col-md-6">
                                <b><span class="required">*</span> Campos Obrigatórios</b>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {  
            // this.$route.params.id          
            restricted: {
                id: 0,
                name: '',
                observation: ''
            },
            endPoint: "/restricted/"            
        }
    },
    computed: {
        url () {            
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    mounted: function() {
        this.loadRestricted()
    },
    methods: {
        loadRestricted () {
            let self = this
            this.$http.get(this.url + this.$route.params.id)
            .then( (response) => {
                self.restricted.id = response.data.id
                self.restricted.name = response.data.name
                self.restricted.observation = response.data.observation
            })
        },
        salvar () {
            let self = this
            self.$http.put(self.url + this.$route.params.id,{
                'name': self.restricted.name,
                'observation': self.restricted.observation
            }).then( () => {
                self.$message('success', 'Sucesso', 'Registro atualizado com sucesso!');                
            }).catch((error) => {
                
                self.$errorMessage(error)
                
            })
        }
    },
    components: {
        
    }
}
</script>
<template>
  <div
    class="modal fade"
    id="dashRecruiterFilter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('label.filtros') }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <label>{{ $t('label.de') }}</label>
              <input
                type="date"
                class="form-control"
                v-model="filter.from"
                @blur="updateFilter(false)"
              />
            </div>
            <div class="col-md-6">
              <label>{{ $t('label.ate') }}</label>
              <input
                type="date"
                class="form-control"
                v-model="filter.to"
                @blur="updateFilter(false)"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn include-btn p-1 ml-3 float-right"
            data-dismiss="modal"
            aria-label="Close"
            @click="updateFilter(true)"
          >
            <i class="fa fa-times"></i>
            {{ $t('label.fechar') }}
          </button>
          <!-- <button type="button" class="btn btn-primary" @click="updateFilter(true)"> Salvar</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      filter: {
        from: "",
        to: ""
      }
    };
  },
  methods: {
    updateFilter: function() {
      
      // if (
      //   !/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(this.filter.from) ||
      //   !/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(this.filter.to) ||
      //   this.filter.from.trim() == "" ||
      //   this.filter.to.trim() == ""
      // ) {
      //   //Verifying if dates are valid

      //   if (closing) {
      //     message("error", "Erro", "Filtro de data não informado corretamente");
      //   }

      //   return;
      // }
      this.$emit("updateFilter", this.filter);
    }
  },
  components: {}
};
</script>

<template>
  <div :key="disableds" class="drag-container flex-one minus-margin-10">
    <ul class="drag-list animated flipInY show">
      <div
        v-for="(stage, index) in stages"
        class="drag-column drag bg-flat-gray-2 min-100-height"
        :key="stage.name"
        :index="index"
      >
        <!-- <slot name="stage-header" :stage="stage-header"></slot> -->
        <h6 class="float-left mb-2">{{ stage.name }}</h6>
        <slot name="stage" :stage="stage"></slot>
        <div class="clear"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group float-left mb-0">
              <div class="input-group">
                <div class="form-check-inline form-check">
                  <!-- <label :for="'marcarTodos' + stage.id" class="form-check-label mb-0">
                    <input
                      type="checkbox"
                      :disabled="countCandidates(stage.id)"
                      :checked="stage.selectedAll"
                      v-model="stage.selectedAll"
                      @change="setSelectAll(stage)"
                      :id="'marcarTodos' + stage.id"
                      :name="'marcarTodos' + stage.id"
                      value="option1"
                      class="form-check-input"
                    />
                    <span class="checkmark"></span>
                    {{ $t('label.marcarTodosCandidatos') }}
                  </label>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="drag-options"></div>
        <div class="drag-inner-list" ref="list" :data-status="stage.name">
          <div
            class="drag-item no-margin"
            v-for="block in getBlocks(stage.name)"
            :data-block-id="block.id"
            :key="block.id"
          >
            <slot :name="block.id">
              <strong>{{ block.status }}</strong>
              <div>{{ block.id }}</div>
            </slot>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import dragula from "dragula";

export default {
  extends: "kanban-board",
  data: () => {
    return {
      Job: {
        role_name: "",
        company: {
          name: "",
          photo: ""
        },
        cost_center: {
          cost_center: ""
        },
        recruiter: {
          name: ""
        }
      },
      isMoving: false
    };
  },
  props: {
    stages: {},
    blocks: {},
    disableMove: {}
  },
  // props: ["stages", "blocks", "onlyMyJobs"],
  computed: {
    localBlocks() {
      return this.blocks;
    },
    disableds() {
      return this.disableMove;
    }
  },
  filters: {
    toBrazilianDate: function(date) {
      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];
      return dt;
    }
  },
  methods: {
    getBlocks(status) {
      return this.localBlocks.filter(block => block.status === status);
    },
    countCandidates(status) {
      return this.blocks.filter(candidate => candidate.status_id == status)
        .length == 0
        ? true
        : false;
    },
    countCandidatesStages(status) {
      return this.blocks.filter(
        candidate => candidate.status_id == status && candidate.selected == true
      ).length == 0
        ? true
        : false;
    },
    getStages(stage_id) {
      return this.stages.filter(item => item.id != stage_id);
    },
    setSelectAll(stage_id) {
      this.$emit("setSelectAll", stage_id);
    },
    changeStatus(origin, final) {
      this.$emit("changeStatus", origin, final);
    }
  },
  mounted() {
    dragula(this.$refs.list, {
      accepts: function() {
        return true; // elements can be dropped in any of the `containers` by default
      },
      moves: function() {
        return false; // elements can be dropped in any of the `containers` by default
      }
    })
      .on("drag", el => {
        el.classList.add("is-moving");
        this.isMoving = true;
      })
      .on("drop", (block, list) => {
        let index = 0;

        if (list != undefined) {
          for (index = 0; index < list.children.length; index += 1) {
            if (list.children[index].classList.contains("is-moving")) break;
          }

          this.$emit(
            "update-block",
            block.dataset.blockId,
            list.dataset.status,
            index
          );
        }
      })
      .on("dragend", el => {
        el.classList.remove("is-moving");

        this.isMoving = false;

        window.setTimeout(() => {
          el.classList.add("is-moved");
          window.setTimeout(() => {
            el.classList.remove("is-moved");
          }, 600);
        }, 100);
      });
  }
};
</script>

<style>
</style>
<template>
    <div>    
        <div class="row">           
            <div class="col-md-12">
                <div class="card height-95-perc">
                    <div class="card-body">
                        <div class="card-text row">
                            <div class="col-md-4 text-center margin-auto">
                                    <img alt="img" class="img img-responsive height-50-px" :src="job.company.photo" >
                                    
                            </div>
                            <div class="col-md-8 text-center">
                                <p>Detalhes das vagas</p>
                                
                                    <h5>{{ job.company_name }}</h5>
                                
                                <p>
                                    <small>
                                        <b>Vaga: {{ job.role_name }}</b>
                                    </small>
                                </p>
                                                                    
                                <small><b>Oferta:</b> {{ job.position_salary }}</small>
                                <br>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="white-box">
                            <h3 class="box-title">Candidatos</h3>
                            <div class="table-responsive" id="candidatesTable">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <td><h5>Foto</h5></td>
                                            <td><h5>Nome</h5></td>
                                            <td><h5>Origem</h5></td>
                                            <td><h5>Estado</h5></td>
                                            <td><h5>Cidade</h5></td>
                                            <!--<td><h5>Maior Aptidão</h5></td>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="candidate in candidates" :key="candidate.id">
                                            <td>
                                                <img :src="candidate.candidate.image" alt="..." class="img-circle" width="65px" height="65px">
                                            </td>
                                            <td>{{ candidate.candidate.fullName }}</td>
                                            <td>{{ candidate.platform.name }}</td>
                                            <td>{{ candidate.candidate.state }}</td>
                                            <td>{{ candidate.candidate.city }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-warning text-white" @click="voltar()">Voltar</button>
                        </div>
                    </div>
                </div>
            
            <div class="right-sidebar">
                <div class="slimscrollright">
                    <div class="rpanel-title"> Service Panel
                        <span>
                            <i class="ti-close right-side-toggle"></i>
                        </span>
                    </div>
                    <div class="r-panel-body">
                        <ul id="themecolors" class="m-t-20">
                            <li>
                                <b>With Light sidebar</b>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-default" class="default-theme working">1</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-green" class="green-theme">2</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-red" class="red-theme">3</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-blue" class="blue-theme">4</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-purple" class="purple-theme">5</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-megna" class="megna-theme">6</a>
                            </li>
                            <li class="d-block m-t-30">
                                <b>With Dark sidebar</b>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-default-dark" class="default-dark-theme ">7</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-green-dark" class="green-dark-theme">8</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-red-dark" class="red-dark-theme">9</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-blue-dark" class="blue-dark-theme">10</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-purple-dark" class="purple-dark-theme">11</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" data-skin="skin-megna-dark" class="megna-dark-theme ">12</a>
                            </li>
                        </ul>
                        <ul class="m-t-20 chatonline">
                            <li>
                                <b>Chat option</b>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/1.jpg" alt="user-img" class="img-circle">
                                    <span>Varun Dhavan
                                        <small class="text-success">online</small>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/2.jpg" alt="user-img" class="img-circle">
                                    <span>Genelia Deshmukh
                                        <small class="text-warning">Away</small>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/3.jpg" alt="user-img" class="img-circle">
                                    <span>Ritesh Deshmukh
                                        <small class="text-danger">Busy</small>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/4.jpg" alt="user-img" class="img-circle">
                                    <span>Arijit Sinh
                                        <small class="text-muted">Offline</small>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/5.jpg" alt="user-img" class="img-circle">
                                    <span>Govinda Star
                                        <small class="text-success">online</small>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/6.jpg" alt="user-img" class="img-circle">
                                    <span>John Abraham
                                        <small class="text-success">online</small>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/7.jpg" alt="user-img" class="img-circle">
                                    <span>Hritik Roshan
                                        <small class="text-success">online</small>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="images/users/8.jpg" alt="user-img" class="img-circle">
                                    <span>Pwandeep rajan
                                        <small class="text-success">online</small>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                
            </div>
            
        </div>
        
    </div> 
</template>

<script>
export default {
    data: () => {
        return {
            job: {
                company: {
                    name: '',
                    photo: ''
                }
            },
            candidates: {
                
            }
        }
    },
    methods: {
        getJob: function () {
            const self = this;
            const api = self.$store.state.apiUrl + '/job/' + self.$route.params.id;

            self.$http.get(api,{
                params: {
                    with: ['company']
                }
            })
            .then((response) => {
                self.job = response.data;

                self.getCandidates();
            })
            .catch(() => {
                self.$message('error', null, 'Ocorreu um erro ao buscar as Informações do Emprego');
            });
        },
        getCandidates: function () {
            const self = this;
            const api = self.$store.state.apiUrl + '/jobCandidate?job_id=' + self.$route.params.id + '&with[]=platform';

            self.$http.get(api,{
                params: {
                    with:['candidate']
                }
            })
            .then((response) => {
                self.candidates = response.data.data;
            })
            .catch(() => {
                self.$message('error', null, 'Ocorreu um erro ao buscar as Informações dos Candidatos');
            });
        },

        voltar() {
            this.$router.go(-1);
        }
    },
    mounted: function () {
        this.getJob();
        
    },
    components: {}
}
</script>

<style>

</style>


<template>
    <ol class="breadcrumb text-right">
        <li class="active" v-if="breadcrumb.path != '/'">
            <router-link to="/"> {{ $t('route.dashboard') }}</router-link>
        </li>
        
        <li class="active">
            <router-link to="breadcrumb.path" class="text-black">{{ $t(breadcrumb.name) }}</router-link>
        </li>
    </ol>      
</template>

<script>
export default {
    data: () => {
        return {
        }
    },
    methods: {

    },
    components: {

    },
    computed: {
        breadcrumb: function () {
            return this.$store.getters.breadcrumb;
        }
    }
}
</script>

<template>
  <div>
    <div class="col-xl-12" v-if="!loading">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.AprovadoresDaVaga') }}</h3>

          <div class="row">
            <div class="col-sm-12" v-if="!isApprover && !alreadyApproved">
              <div class="alert alert-danger">{{ $t('label.voceNaoEAprovadorDessaVaga') }}</div>
            </div>
          </div>

          <div v-for="(approver, i) in jobRequisition.approvers" :key="approver.id">
            <div class="row">
              <div v-if="jobRequisition.status_id == 9" class="col-md-12">
                <div class="alert alert-info">
                  <span class="badge badge-pill badge-info">
                    {{$t('label.vagaReprovadaPor')}} {{ approver.approver.name }} {{$t('label.em')}}
                    {{ new Date(jobRequisition.approved_at).toLocaleDateString() + " - " + new Date(jobRequisition.approved_at).toLocaleTimeString() }}
                  </span>
                  {{$t('label.observacao')}}: {{ jobRequisition.approver_observation }}.
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="name" class="control-label mb-1">{{$t('label.aprovador')}} {{ i+1 }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-globe"></i>
                    </div>
                    <input
                      type="text"
                      v-model="approver.user.name"
                      id="name"
                      class="form-control"
                      placeholder
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="name" class="control-label mb-1">{{$t('label.status')}}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-globe"></i>
                    </div>
                    <input
                      type="text"
                      v-model="approver.status"
                      id="name"
                      class="form-control"
                      placeholder
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name" class="control-label mb-1">{{$t('label.observacao')}}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-globe"></i>
                    </div>
                    <textarea
                      name
                      class="form-control"
                      v-model="observation"
                      rows="3"
                      v-if="approver.approver_id == $store.getters.user.id && approver.status == 'Aguardando'"
                    ></textarea>
                    <textarea
                      name
                      class="form-control"
                      v-model="approver.observation"
                      rows="3"
                      v-else
                      readonly
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <label>{{ $t('label.justificativa') }}</label>
              <textarea
                class="form-control"
                v-model="jobRequisition.reason_job_observation"
                disabled
              />
            </div>
          </div>

          <h3 class="card-title mt-3">{{ $t('label.detalhesDaVaga') }}</h3>

          <div class="row">
            <div class="col-md-12 form-group">
              <label for="empresa" class="control-label mb-1">{{$t('label.tituloDaVaga')}}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-globe"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.role_name"
                  id="empresa"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label for="empresa" class="control-label mb-1">{{$t('label.empresa')}}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-globe"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.company.name"
                  id="empresa"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{$t('label.estabelecimento')}}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-building-o"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.branch.name"
                  id="estabelecimento"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{$t('label.prazo')}}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-clock-o"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.end_date"
                  id="end_date"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 form-group">
              <label class="control-label mb-1">{{ $t('label.centroCusto') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-money"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.cost_center"
                  id="cost_center"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <!-- <div class="col-md-4 form-group">
                            <label class="control-label mb-1">{{ $t('label.cargo') }}</label>
                            <div class="input-group">
                                <div class="input-group-addon"><i class="fa fa-tags"></i></div>
                                <input type="text" v-model="jobRequisition.role_template.role.name" id="role_template" class="form-control">
                            </div>
            </div>-->
            <div class="col-md-6 form-group">
              <label class="control-label mb-1">{{ $t('label.numeroVagas') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-plus-square-o"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.quantity"
                  id="quantity"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{ $t('label.solicitante') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-user"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.requester"
                  id="requester"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{ $t('label.departamento') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-pencil"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.department"
                  id="department"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{ $t('label.tipoVaga') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file"></i>
                </div>
                <input
                  type="text"
                  v-model="jobRequisition.position_type"
                  id="position_type"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div>

          <h3 class="card-title mt-3">{{ $t('label.informacoesCargo') }}</h3>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label
                  for="role_description"
                  class="control-label mb-1"
                >{{ $t('label.descricao') }}*</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file-text-o"></i>
                  </div>
                  <textarea
                    class="form-control"
                    v-model="jobRequisition.role_description"
                    id="role_description"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  for="role_requirements"
                  class="control-label mb-1"
                >{{ $t('label.requisitos') }}*</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file-text-o"></i>
                  </div>
                  <textarea
                    class="form-control"
                    v-model="jobRequisition.role_requirements"
                    id="role_requirements"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label
                  for="differential"
                  class="control-label mb-1"
                >{{ $t('label.competenciasDesejadas') }}*</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file-text-o"></i>
                  </div>
                  <textarea
                    class="form-control"
                    id="differential"
                    v-model="jobRequisition.differential"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  for="observation"
                  class="control-label mb-1"
                >{{ $t('label.informacaoComplementar') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file-text-o"></i>
                  </div>
                  <textarea
                    class="form-control"
                    v-model="jobRequisition.observation"
                    id="observation"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <h3 class="card-title mt-3">{{ $t('label.beneficios') }}</h3>

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table color-table human-flow-table">
                  <thead v-if="jobRequisition.benefits.length > 0">
                    <tr>
                      <td class="no-pt-pb-bt">{{ $t('label.beneficio') }}</td>
                      <td class="no-pt-pb-bt">{{ $t('label.descricao') }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(benefit, key) in jobRequisition.benefits" :key="key">
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">
                            <i class="fa fa-pencil"></i>
                          </div>
                          <input
                            type="text"
                            v-model="benefit.description"
                            class="form-control"
                            readonly
                          />
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">
                            <i class="fa fa-pencil"></i>
                          </div>
                          <input type="text" v-model="benefit.value" class="form-control" readonly />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h3 class="card-title mt-3">{{ $t('label.complementos') }}</h3>

          <div class="row" v-if="jobRequisition && jobRequisition.approvers[1]">
            <div class="col-md-6">
              <div class="form-group">
                <label for="position_salary" class="control-label mb-1">{{ $t('label.salario') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-money"></i>
                  </div>
                  <input
                    class="form-control"
                    type="text"
                    id="position_salary"
                    v-if="jobRequisition.approvers[1].approver_id == $store.getters.user.id"
                    v-model="jobRequisition.position_salary"
                    v-money="money"
                  />
                  <input
                    class="form-control"
                    type="text"
                    id="position_salary"
                    v-else
                    v-model="jobRequisition.position_salary"
                    v-money="money"
                    disabled
                  />
                </div>
              </div>
            </div>

            <!-- <div class="col-md-3">
                            <div class="form-group">
                                <label for="show_salary" class="control-label mb-1">{{ $t('label.exibirSalario') }}</label>
                                <div class="input-group">
                                    <label class="switch switch-default switch-primary mt-2 mb-0"><input type="checkbox" class="switch-input" id="show_salary" v-model="jobRequisition.show_salary" disabled> <span class="switch-label border-gray"></span> <span class="switch-handle border-gray"></span></label>                                        
                                </div>
                            </div>
            </div>-->

            <!-- <div class="col-md-3">
                            <div class="form-group">
                                <label for="to_combine" class="control-label mb-1">{{ $t('label.acombinar') }}</label>
                                <div class="input-group">
                                    <label class="switch switch-default switch-primary mt-2 mb-0"><input type="checkbox" class="switch-input" id="to_combine" v-model="jobRequisition.to_combine" disabled> <span class="switch-label border-gray"></span> <span class="switch-handle border-gray"></span></label>                                        
                                </div>
                            </div>
            </div>-->
          </div>

          <div class="row">
            <!-- <div class="col-md-6">
                        <div class="form-group">
                                <label for="tipoAnuncio" class="control-label mb-1">{{ $t('label.tipoDeAnuncio') }}*</label>
                                <div class="input-group">
                                    <div class="form-check-inline form-check mt-2">
                                        <label for="tipoAnuncio1" class="form-check-label mr-3">
                                            <input type="radio" id="tipoAnuncio1" name="tiposAnuncio" value="interno" class="form-check-input" v-model='jobRequisition.publication_type' disabled>
                                            <span class="radiomark"></span>{{ $t('label.interno') }}
                                        </label>
                                        <label for="tipoAnuncio2" class="form-check-label mr-3">
                                            <input type="radio" id="tipoAnuncio2" name="tiposAnuncio" value="externo" class="form-check-input" v-model='jobRequisition.publication_type' disabled>
                                            <span class="radiomark"></span>{{ $t('label.externo') }}
                                        </label>
                                        <label for="tipoAnuncio3" class="form-check-label">
                                            <input type="radio" id="tipoAnuncio3" name="tiposAnuncio" value="ambos" class="form-check-input" v-model='jobRequisition.publication_type' disabled>
                                            <span class="radiomark"></span>{{ $t('label.ambos') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
            </div>-->

            <div class="col-md-12">
              <div class="form-group">
                <label
                  for="regimeContratacao"
                  class="control-label mb-1"
                >{{ $t('label.regimeContratacao') }}</label>
                <div class="input-group">
                  <div class="form-check-inline form-check mt-2">
                    <label for="regimeContratacao1" class="form-check-label mr-3">
                      <input
                        type="checkbox"
                        id="regimeContratacao1"
                        name="regimeContratacao1"
                        value="clt"
                        v-model="jobRequisition.clt"
                        class="form-check-input"
                        disabled
                      />
                      <span class="checkmark"></span>
                      {{ $t('label.clt') }}
                    </label>
                    <label for="regimeContratacao2" class="form-check-label mr-3">
                      <input
                        type="checkbox"
                        id="regimeContratacao2"
                        name="regimeContratacao2"
                        value="pj"
                        v-model="jobRequisition.pj"
                        class="form-check-input"
                        disabled
                      />
                      <span class="checkmark"></span>
                      {{ $t('label.terceirizado') }}
                    </label>
                    <label for="regimeContratacao4" class="form-check-label mr-3">
                      <input
                        type="checkbox"
                        id="regimeContratacao4"
                        name="regimeContratacao4"
                        value="temporary"
                        v-model="jobRequisition.temporary"
                        class="form-check-input"
                        disabled
                      />
                      <span class="checkmark"></span>
                      {{ $t('label.temporario') }}
                    </label>
                    <label for="regimeContratacao6" class="form-check-label mr-3">
                      <input
                        type="checkbox"
                        id="regimeContratacao6"
                        name="regimeContratacao6"
                        value="trainee"
                        v-model="jobRequisition.trainee"
                        class="form-check-input"
                        disabled
                      />
                      <span class="checkmark"></span>
                      {{ $t('label.trainee') }}
                    </label>
                    <label for="regimeContratacao3" class="form-check-label mr-3">
                      <input
                        type="checkbox"
                        id="regimeContratacao3"
                        name="regimeContratacao3"
                        value="intern"
                        v-model="jobRequisition.intern"
                        class="form-check-input"
                        disabled
                      />
                      <span class="checkmark"></span>
                      {{ $t('label.estagiario') }}
                    </label>
                    <label for="regimeContratacao7" class="form-check-label">
                      <input
                        type="checkbox"
                        id="regimeContratacao7"
                        name="regimeContratacao7"
                        value="apprentice"
                        v-model="jobRequisition.apprentice"
                        class="form-check-input"
                        disabled
                      />
                      <span class="checkmark"></span>
                      {{ $t('label.apprentice') }}
                    </label>
                    <!-- <label for="regimeContratacao5" class="form-check-label mr-3">
                                            <input type="checkbox" id="regimeContratacao5" name="regimeContratacao5" value="freelancer" v-model='jobRequisition.freelancer' class="form-check-input" disabled>
                                            <span class="checkmark"></span>{{ $t('label.freelancer') }}
                    </label>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-requisition">
      <div class="background-buttons" v-if="jobRequisition.status_id == 8">
        <button type="submit" @click="save" class="btn include-btn mt-0">
          <i class="fa fa-check width-21-px"></i>
          {{$t('label.salvar')}}
        </button>
        <button
          type="submit"
          @submit.prevent
          @click="aprovar"
          class="btn select-btn mt-0"
          :disabled="!isApprover"
        >
          <i class="fa fa-check width-21-px"></i>
          {{$t('label.Aprovar')}}
        </button>
        <button
          type="submit"
          @submit.prevent
          @click="print"
          class="btn pub-btn mt-0"
          :disabled="!isApprover"
        >
          <i class="fa fa-print width-21-px"></i>
          {{$t('label.imprimir')}}
        </button>
        <button
          type="submit"
          @submit.prevent
          @click="reprovar"
          class="btn delete-btn mt-0"
          :disabled="!isApprover"
        >
          <i class="fa fa-ban width-21-px"></i>
          {{$t('label.Reprovar')}}
        </button>
        <button class="btn default-btn mt-0" @click="voltar">
          <i class="fa fa-close width-23-px"></i>
          {{$t('label.voltar')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      // this.$route.params.id
      job: {
        company: {
          name: ""
        },
        branch: {
          name: ""
        }
      },
      endPoint: "/job/",
      post_endPoint: "/approveJob",
      postPlatforms: [],
      alreadyApproved: false,
      jobRequisition: {
        clt: "",
        pj: "",
        trainee: "",
        intern: "",
        apprentice: "",
        temporary: "",

        cost_center_id: 0,
        role_template: {
          name: "",
          role: {
            name: ""
          }
        },
        approver: {
          name: ""
        },
        company: {
          name: ""
        },
        branch: {
          name: ""
        },
        customer: {
          name: ""
        },
        approvers: []
      },
      approver: {
        id: 0,
        name: ""
      },
      approverInTime: {
        id: 0,
        name: ""
      },
      approval: {
        observation: "",
        status: false
      },
      loading: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      aprovador: "",
      observation: ""
    };
  },
  computed: {
    url: function() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    post_url: function() {
      return this.$store.getters.apiUrl + this.post_endPoint;
    },
    isApprover: function() {
      return this.aprovador == this.$store.getters.user.id;
    }
  },
  mounted: function() {
    this.loadJob();
  },
  methods: {
    startModal: function() {
      $("#modalJob").modal({
        show: true
      });
    },
    save: function() {
      const self = this;
      let id = self.$route.params.id;

      let fd = self.makeFormData();
      if (!fd) return;

      self.$store.dispatch("tooggleLoadModal");

      self.$http
        .put(self.$store.getters.apiUrl + "/job/" + id, fd)
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
          self.$router.push({ path: "/jobRoles" });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    makeFormData: function() {
      const self = this;

      let fd = {};

      fd.role_name = self.jobRequisition.role_name;
      fd.quantity = self.jobRequisition.quantity;
      fd.role_description = self.jobRequisition.role_description;
      fd.role_requirements = self.jobRequisition.role_requirements;
      fd.differential = self.jobRequisition.differential;
      fd.observation = self.jobRequisition.observation;
      fd.clt = self.jobRequisition.clt;
      fd.pj = self.jobRequisition.pj;
      fd.intern = self.jobRequisition.intern;
      fd.temporary = self.jobRequisition.temporary;
      fd.freelancer = self.jobRequisition.freelancer;
      fd.trainee = self.jobRequisition.trainee;
      fd.apprentice = self.jobRequisition.apprentice;

      return fd;
    },
    loadCostCenter() {
      let self = this;

      self.$http
        .get(
          this.$store.getters.apiUrl +
            "/cost-centers/" +
            self.jobRequisition.cost_center_id,
          {
            params: {
              with: ["user"]
            }
          }
        )
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");

          self.approver = response.data.user;

          self.loading = false;
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    getApprover() {
      let self = this;

      self.$http
        .get(
          this.$store.getters.apiUrl + "/getApprover/" + self.jobRequisition.id,
          {
            params: {
              with: ["user"]
            }
          }
        )
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");

          self.approverInTime = response.data;

          self.loading = false;
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
          self.loading = false;
        });
    },
    loadJob() {
      let self = this;
      self.loading = true;
      let id = this.$route.params.id;
      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .get(this.url + id, {
          params: {
            with: [
              "roleTemplate.role",
              "benefits",
              "approver",
              "customer",
              "branch",
              "company",
              "approvers"
            ]
          }
        })
        .then(response => {
          self.jobRequisition = response.data;

          for (var i = 0; i < self.jobRequisition.approvers.length; i++) {
            if (this.jobRequisition.approvers[i].status == "Aprovado") {
              if(this.$store.getters.user.id == this.jobRequisition.approvers[i].approver_id){
                this.alreadyApproved = true;
              }
            }
            if (this.jobRequisition.approvers[i].status == "Aguardando") {
              if(this.$store.getters.user.id == this.jobRequisition.approvers[i].approver_id){
                this.aprovador = this.jobRequisition.approvers[i].approver_id;
              }
            }
          }
        })
        .then(() => {
          self.loadCostCenter();
        })
        .then(() => {
          setTimeout(function() {
            Array.prototype.slice.call(document.querySelectorAll(".js-switch"));
            self.$(".js-switch").each(function() {
              new Switchery(self.$(this)[0], self.$(this).data());
            });
          }, 1000);
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    aprovar() {
      this.doApproval(true);
    },
    reprovar() {
      this.doApproval(false);
    },
    doApproval(_status) {
      var self = this;
      // var fluig = self.jobRequisition.fluig_proccess_number;

      if (self.$store.getters.mode == "dev") {
        self.$store.dispatch("tooggleLoadModal");
        self.$http
          .post(self.post_url, {
            jobId: self.jobRequisition.id,
            status: _status,
            observation: self.observation,
            approver_id: this.$store.getters.user.id,
            salary: this.jobRequisition.position_salary,
            clt: this.jobRequisition.clt,
            pj: this.jobRequisition.pj,
            temporary: this.jobRequisition.temporary,
            trainee: this.jobRequisition.trainee,
            intern: this.jobRequisition.intern,
            apprentice: this.jobRequisition.apprentice
          })
          .then(() => {
            self.$store.dispatch("tooggleLoadModal");
            self.$message("success", "Sucesso", "Registro salvo com sucesso!");

            this.$router.go(-1);
          })
          .catch(() => {
            self.$store.dispatch("tooggleLoadModal");
          });
      } else {
        /*
                self.$http.post('/bpm/api/v1/requests/' + fluig + '/move', {
                    "movementSequence": 0,
                    "assignee": "",
                    "targetState": 0,
                    "targetAssignee": "",
                    "subProcessTargetState": 0,
                    "comment": "aprovado via HUMANFLOW",
                    "asManager": true,
                    "formFields": {
                        "aprovacao": _status ? 'on' : 'off',
                        "observacao": self.approval.observation,
                        "formMode": 'api'
                    }
                })
                    .then(response => {
                */
        self.$store.dispatch("tooggleLoadModal");
        self.$http
          .post(self.post_url, {
            jobId: self.jobRequisition.id,
            status: _status,
            observation: self.observation,
            approver_id: this.$store.getters.user.id,
            salary: this.jobRequisition.position_salary,
            clt: this.jobRequisition.clt,
            pj: this.jobRequisition.pj,
            temporary: this.jobRequisition.temporary,
            trainee: this.jobRequisition.trainee,
            intern: this.jobRequisition.intern,
            apprentice: this.jobRequisition.apprentice
          })
          .then(() => {
            self.$store.dispatch("tooggleLoadModal");
            self.$message("success", "Sucesso", "Registro salvo com sucesso!");

            // this.$router.push({
            //     path: "/jobRoles/approval/"
            // });
            self.$router.go(-1);
          })
          .catch(() => {
            self.$store.dispatch("tooggleLoadModal");
          });
        /*
                    })
                    */

        /*
                    .catch(response => {
                        if (response.code = 'BPMInvalidWorkflowProcessException') {
                            self.$http.post(self.post_url, {
                                jobId: self.jobRequisition.id,
                                status: _status,
                                observation: self.approval.observation,
                                approver_id: self.approver.id
                            }).then(() => {
                                message('success', 'Sucesso', 'Registro salvo com sucesso!');

                                this.$router.go(-1);
                            })
                        }
                    });

                    */
      }
    },
    voltar() {
      this.$router.go(-1);
    },

    print () {
      const self = this;
      let id = this.$route.params.id;
      let api = self.$store.getters.apiUrl+"/job/pdf/"+id;

      self.$store.dispatch("tooggleLoadModal");
      
      self.$http.get(api, {responseType: 'blob'})
      .then(function(response) {
        self.$store.dispatch("tooggleLoadModal");
        let fileURL = URL.createObjectURL(response.data);
        window.open(fileURL);
      })
    }
  },
  components: {}
};
</script>

<style>
</style>
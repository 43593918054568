<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.requisitarVaga') }}</h3>

          <form method="post" class="form-material" @submit.prevent>
            <div class="row mt-5">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="budgeted" class="control-label mb-1">{{ $t('label.budgeted') }}</label>
                  <div class="input-group">
                    <label class="switch switch-default switch-primary mt-2 mb-0">
                      <input type="checkbox" class="switch-input" v-model="jobRequisition.budgeted" />
                      <span class="switch-label border-gray"></span>
                      <span class="switch-handle border-gray"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="empresa" class="control-label mb-1">{{ $t('label.empresa') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-globe"></i>
                    </div>
                    <select
                      name="company_id"
                      v-model="jobRequisition.company_id"
                      class="form-control"
                      @change="getBranches"
                    >
                      <option value selected="selected">{{ $t('label.selecione') }}...</option>
                      <option
                        v-for="company in companies"
                        :value="company.id"
                        :key="company.id"
                      >{{company.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="estabelecimentos"
                    class="control-label mb-1"
                  >{{ $t('label.estabelecimentos') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-building-o"></i>
                    </div>
                    <select
                      name="branch_id"
                      v-model="jobRequisition.branch_id"
                      class="form-control"
                    >
                      <option
                        v-if="branches.length == 0"
                        value="0"
                        selected="selected"
                      >{{ $t('label.selecioneEmpresa') }}...</option>
                      <option
                        v-if="branches.length > 0"
                        value
                        selected="selected"
                      >{{ $t('label.selecione') }}...</option>
                      <option
                        v-for="branch in branches"
                        :value="branch.id"
                        :key="branch.id"
                      >{{branch.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label
                    for="centrosCusto"
                    class="control-label mb-1"
                  >{{ $t('label.centroCusto') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-money"></i>
                    </div>
                    <select
                      name="centroCusto"
                      v-model="jobRequisition.cost_center_id"
                      class="form-control"
                    >
                      <option value selected="selected">{{ $t('label.selecione') }}...</option>
                      <option
                        v-for="cost_center in cost_centers"
                        :value="cost_center.id"
                        :key="cost_center.id"
                      >{{ cost_center.cost_center }} - {{ cost_center.name }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label
                    for="roleTemplate"
                    class="control-label mb-1"
                  >{{ $t('label.templateCargo') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-tags"></i>
                    </div>
                    <select
                      name="role_template"
                      v-model="jobRequisition.role_template_id"
                      class="form-control"
                      @change="changeRole"
                    >
                      <option value selected="selected">{{ $t('label.selecione') }}...</option>
                      <option
                        v-for="role_template in role_templates"
                        :value="role_template.id"
                        :key="role_template.id"
                      >{{role_template.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="numVagas" class="control-label mb-1">{{ $t('label.numeroVagas') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-plus-square-o"></i>
                    </div>
                    <input
                      type="number"
                      min="1"
                      v-model="jobRequisition.qtdVagas"
                      class="form-control"
                      placeholder
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="solicitante" class="control-label mb-1">{{ $t('label.solicitante') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-user"></i>
                    </div>
                    <input
                      type="text"
                      v-model="jobRequisition.solicitante"
                      id="solicitante"
                      class="form-control"
                      placeholder
                      readonly
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label for="titulo" class="control-label mb-1">{{ $t('label.tituloDaVaga') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-pencil"></i>
                    </div>
                    <input
                      class="form-control"
                      v-model="jobRequisition.titulo"
                      placeholder
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label
                    for="horarioTrabalho"
                    class="control-label mb-1"
                  >{{ $t('label.horarioTrabalho') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-clock-o"></i>
                    </div>

                    <select
                      name="company_id"
                      v-model="jobRequisition.work_schedule"
                      class="form-control"
                    >
                      <option value selected="selected">{{ $t('label.selecione') }}...</option>
                      <option
                        v-for="work_schedule in work_schedules"
                        :value="work_schedule.title"
                        :key="work_schedule.id"
                      >{{work_schedule.title}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="workload" class="control-label mb-1">{{ $t('label.cargaHoraria') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <input
                      type="text"
                      v-model="jobRequisition.workload"
                      id="workload"
                      class="form-control"
                      placeholder
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="workplace" class="control-label mb-1">{{ $t('label.localTrabalho') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <input
                      type="text"
                      v-model="jobRequisition.workplace"
                      id="workplace"
                      class="form-control"
                      placeholder
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h3 class="card-title">{{ $t('label.motivo') }}</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="input-group">
                      <div
                        class="form-check-inline form-check mt-2"
                        id="reason-job-requisition-container"
                      >
                        <!-- <label for="tipoVaga1" class="form-check-label mr-3">
                                                    <input type="radio" class="form-check-input" name="tipoVaga" v-model="jobRequisition.tipoVaga" id="tipoVaga1" value="nova">
                                                    <span class="radiomark"></span>{{ $t('label.nova') }}
                        </label>-->
                        <label for="tipoVaga3" class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="tipoVaga"
                            v-model="jobRequisition.tipoVaga"
                            id="tipoVaga3"
                            value="aumento de quadro"
                          />
                          <span class="radiomark"></span>
                          {{ $t('label.aumentoDeQuadro') }}
                        </label>
                        <!-- <label for="tipoVaga2" class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="tipoVaga" v-model="jobRequisition.tipoVaga" id="tipoVaga2" value="reposicao">
                                                    <span class="radiomark"></span>{{ $t('label.reposicao') }}
                        </label>-->
                        <label for="tipoVaga4" class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="tipoVaga"
                            v-model="jobRequisition.tipoVaga"
                            id="tipoVaga4"
                            value="substituição por demissão"
                          />
                          <span class="radiomark"></span>
                          {{ $t('label.substituicaoPorDemissao') }}
                        </label>
                        <label for="tipoVaga5" class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="tipoVaga"
                            v-model="jobRequisition.tipoVaga"
                            id="tipoVaga5"
                            value="substituição por alteração"
                          />
                          <span class="radiomark"></span>
                          {{ $t('label.substituicaoPorAlteracao') }}
                        </label>
                        <label for="tipoVaga6" class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="tipoVaga"
                            v-model="jobRequisition.tipoVaga"
                            id="tipoVaga6"
                            value="substituição por temporária"
                          />
                          <span class="radiomark"></span>
                          {{ $t('label.substituicaoTemporaria') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <label>{{ $t('label.justificativa') }}</label>
                    <textarea class="form-control" v-model="jobRequisition.reason_job_observation" />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <h3 class="card-title">{{ $t('label.regimeContratacao') }}</h3>
                <div class="col-md-12">
                  <div class="input-group">
                    <div class="form-check-inline form-check mt-2">
                      <label for="regimeContratacao1" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao1"
                          name="regimeContratacao1"
                          value="clt"
                          v-model="jobRequisition.clt"
                          class="form-check-input"
                          :disabled="disableRegime"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.clt') }}
                      </label>
                      <label for="regimeContratacao2" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao2"
                          name="regimeContratacao2"
                          value="pj"
                          v-model="jobRequisition.pj"
                          class="form-check-input"
                          :disabled="disableRegime"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.terceirizado') }}
                      </label>
                      <label for="regimeContratacao4" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao4"
                          name="regimeContratacao4"
                          value="temporary"
                          v-model="jobRequisition.temporary"
                          class="form-check-input"
                          :disabled="disableRegime"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.temporario') }}
                      </label>
                      <label for="regimeContratacao6" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao6"
                          name="regimeContratacao6"
                          value="trainee"
                          v-model="jobRequisition.trainee"
                          class="form-check-input"
                          disabled
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.trainee') }}
                      </label>
                      <label for="regimeContratacao3" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao3"
                          name="regimeContratacao3"
                          value="intern"
                          v-model="jobRequisition.intern"
                          class="form-check-input"
                          disabled
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.estagiario') }}
                      </label>
                      <!-- <label for="regimeContratacao5" class="form-check-label mr-3">
                                                    <input type="checkbox" id="regimeContratacao5" name="regimeContratacao5" value="freelancer" v-model='jobRequisition.freelancer' class="form-check-input">
                                                    <span class="checkmark"></span>{{ $t('label.freelancer') }}
                      </label>-->
                      <label for="regimeContratacao7" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao7"
                          name="regimeContratacao7"
                          value="apprentice"
                          v-model="jobRequisition.apprentice"
                          class="form-check-input"
                          disabled
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.apprentice') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h3 class="card-title mt-3">{{ $t('label.informacoesCargo') }}</h3>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="descricao"
                    class="control-label mb-1"
                  >{{ $t('label.principaisAtribuicoes') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      class="form-control"
                      v-model="jobRequisition.descricaoVaga"
                      id="description"
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="requisitos" class="control-label mb-1">{{ $t('label.requisitos') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      class="form-control"
                      v-model="jobRequisition.requisitosVaga"
                      id="requirements"
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="diferencial"
                    class="control-label mb-1"
                  >{{ $t('label.competenciasDesejadas') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      class="form-control"
                      id="diferencial"
                      v-model="jobRequisition.diferencial"
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="role_behavioral_skills"
                    class="control-label mb-1"
                  >{{ $t('label.competenciasComportamentais') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      class="form-control"
                      id="role_behavioral_skills"
                      v-model="jobRequisition.role_behavioral_skills"
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="formacao" class="control-label mb-1">{{ $t('label.formacao') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-university"></i>
                    </div>
                    <textarea
                      type="text"
                      v-model="jobRequisition.formation"
                      id="formacao"
                      class="form-control"
                      placeholder
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="cursosAdicionais"
                    class="control-label mb-1"
                  >{{ $t('label.cursosAdicionais') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-university"></i>
                    </div>
                    <textarea
                      type="text"
                      v-model="jobRequisition.additional_courses"
                      id="cursosAdicionais"
                      class="form-control"
                      placeholder
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="observacoes"
                    class="control-label mb-1"
                  >{{ $t('label.informacaoComplementar') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      class="form-control"
                      v-model="jobRequisition.observacoesSolicitante"
                      id="observacoesSolicitante"
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <h3 class="card-title mt-3">{{ $t('label.beneficios') }}</h3>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table color-table human-flow-table">
                    <thead v-if="jobRequisition.role_benefits.length > 0">
                      <tr>
                        <td class="no-pt-pb-bt">{{ $t('label.beneficio') }}</td>
                        <td class="no-pt-pb-bt">{{ $t('label.descricao') }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(benefit, key) in jobRequisition.role_benefits" :key="key">
                        <td>
                          <div class="input-group">
                            <!-- <div class="input-group-addon">
                                                            <i class="fa fa-pencil"></i>
                            </div>-->
                            <input
                              type="text"
                              v-model="benefit.beneficio"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <!-- <div class="input-group-addon">
                                                            <i class="fa fa-pencil"></i>
                            </div>-->
                            <input
                              type="text"
                              v-model="benefit.valor"
                              class="form-control"
                              disabled
                            />
                            <!-- <div class="input-group-btn">
                                                            <a class="btn trash-btn ml-3 color-white" @click="delBenefits( key )" >
                                                                <i class="fa  fa-trash-o "></i>
                                                            </a>
                            </div>-->
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <!-- <tr>
                                            <td colspan=3>
                                                <a class="btn include-btn p-0 color-white" @submit.prevent @click="addBenefits" >
                                                    <i class="fa fa-plus"></i>
                                                    {{ $t('label.incluirBeneficios') }}
                                                </a>
                                            </td>
                    </tr>-->
                  </table>
                </div>
              </div>
            </div>

            <h3 class="card-title mt-3">{{ $t('label.extras') }}</h3>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table color-table human-flow-table">
                    <thead v-if="jobRequisition.role_extras.length > 0">
                      <tr>
                        <td class="no-pt-pb-bt">{{ $t('label.nome') }}</td>
                        <td class="no-pt-pb-bt">{{ $t('label.descricao') }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(extra, key) in jobRequisition.role_extras" :key="key">
                        <td>
                          <div class="input-group">
                            <input type="text" v-model="extra.extra" class="form-control" disabled />
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <input type="text" v-model="extra.valor" class="form-control" disabled />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <h3 class="card-title mt-3">{{ $t('label.complementos') }}</h3>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="salario" class="control-label mb-1">{{ $t('label.salario') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-money"></i>
                    </div>
                    <input
                      class="form-control"
                      type="text"
                      id="salario"
                      v-model="jobRequisition.salario"
                      :placeholder="$t('label.digiteAqui')"
                      v-money="money"
                      maxlength="50"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <router-link class="btn include-btn p-1 ml-3 float-right" to="/">
                  <i class="fa fa-times width-23-px"></i>
                  {{ $t('label.cancelar') }}
                </router-link>

                <button @click="save" class="btn include-btn p-1 ml-3 float-right">
                  <i class="fa fa-check width-21-px"></i>
                  {{ $t('label.iniciar') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companies: {},
      work_schedules: {},
      branches: [],
      cost_centers: {},
      role_templates: {},
      disableRegime: true,
      jobRequisition: {
        server_url: "",
        estabelecimento: "",
        company_id: 0,
        nomeEmpresa: "",
        branch_id: 0,
        cost_center_id: "",
        centroCusto: "",
        role_template_id: "",
        qtdVagas: "",
        solicitante: "",
        solicitante_id: "",
        titulo: "",
        tipoVaga: "",
        descricaoVaga: "",
        requisitosVaga: "",
        role_behavioral_skills: "",
        diferencial: "",
        observacoesSolicitante: "",
        role_benefits: [],
        role_extras: [],
        salario: "",
        work_schedule: "",
        formation: "",

        reason_job_observation: "",
        budgeted: ""
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      endPoint: "/newJobRequisition"
    };
  },
  computed: {
    // user() {
    //     this.jobRequisition.solicitante = this.$store.state.user ? this.$store.state.user.name : "";
    //     this.jobRequisition.solicitante_id = this.$store.state.user ? this.$store.state.user.id : 0;
    //     return this.$store.state.user;
    // },
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
    // disableRegime() {
    //   console.log("entrou na computed");
    //   if (
    //     this.jobRequisition.clt ||
    //     this.jobRequisition.pj ||
    //     this.jobRequisition.intern ||
    //     this.jobRequisition.apprentice ||
    //     this.jobRequisition.temporary
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  },
  mounted: function() {
    this.jobRequisition.solicitante = this.$store.getters.user.name;
    this.jobRequisition.solicitante_id = this.$store.getters.user.id;

    this.getCustomer();
    this.getWorkload();
  },
  methods: {
    getBranches() {
      let self = this;

      let company_id = self.jobRequisition.company_id;
      const api = self.$store.getters.apiUrl + "/branchs";

      self.branches = [];
      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(api, {
          params: {
            paginated: false,
            company_id: company_id
          }
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.branches = response.data.data;
        })
        .catch(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAEmpresa")
          );
        });
    },
    changeRole() {
      var roleId = event.target.value;

      let roleTemplate = this.role_templates.filter(role => {
        return role.id == roleId;
      })[0];

      this.jobRequisition.requisitosVaga = roleTemplate.requirements;
      this.jobRequisition.descricaoVaga = roleTemplate.description;
      this.jobRequisition.diferencial = roleTemplate.differential;
      this.jobRequisition.role_behavioral_skills =
        roleTemplate.behavioral_skills;
      this.jobRequisition.titulo = roleTemplate.name;

      this.jobRequisition.clt = roleTemplate.clt;
      this.jobRequisition.pj = roleTemplate.pj;
      this.jobRequisition.intern = roleTemplate.intern;
      this.jobRequisition.temporary = roleTemplate.temporary;
      this.jobRequisition.freelancer = roleTemplate.freelancer;
      this.jobRequisition.trainee = roleTemplate.trainee;
      this.jobRequisition.apprentice = roleTemplate.apprentice;

      if (
        this.jobRequisition.clt ||
        this.jobRequisition.pj ||
        this.jobRequisition.intern ||
        this.jobRequisition.apprentice ||
        this.jobRequisition.temporary
      ) {
        this.disableRegime = true;
      } else {
        this.disableRegime = false;
      }

      this.jobRequisition.work_schedule = roleTemplate.work_schedule;
      this.jobRequisition.formation = roleTemplate.formation;
      this.jobRequisition.additional_courses = roleTemplate.additional_courses;
      this.jobRequisition.workload = roleTemplate.workload;
      this.jobRequisition.workplace = roleTemplate.workplace;

      this.jobRequisition.salario = roleTemplate.role
        ? roleTemplate.role.salary
        : roleTemplate.role;

      var beneficios = roleTemplate.benefits.map(benefit => {
        return {
          beneficio: benefit.name,
          valor: benefit.value
        };
      });

      var extras = roleTemplate.extras.map(extras => {
        return {
          extra: extras.name,
          valor: extras.value
        };
      });

      this.jobRequisition.role_benefits = beneficios;
      this.jobRequisition.role_extras = extras;
    },
    getCustomer() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(
          self.$store.getters.apiUrl +
            "/customer?id=" +
            self.$store.getters.customerId,
          {
            params: {
              with: [
                "companies",
                "cost_centers",
                "cost_centers.user",
                "roleTemplates",
                "roleTemplates.role",
                "roleTemplates.benefits",
                "roleTemplates.extras"
              ]
            }
          }
        )
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.companies = response.data.data[0].companies;
          // self.branches       =  response.data.data[0].branches;
          self.cost_centers = response.data.data[0].cost_centers;
          self.role_templates = response.data.data[0].role_templates;
        })
        .catch(() => {
          self.$store.dispatch("tooggleLoadModal");
        });
    },
    getWorkload() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(self.$store.getters.apiUrl + "/workload", {
          params: {
            with: []
          }
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.work_schedules = response.data.data;
        })
        .catch(() => {
          self.$store.dispatch("tooggleLoadModal");
        });
    },
    delBenefits: function(index) {
      const self = this;
      self.jobRequisition.role_benefits.splice(index, 1);
    },
    addBenefits: function() {
      const self = this;
      self.jobRequisition.role_benefits.push({
        beneficio: "",
        valor: ""
      });
    },
    getApprover: function() {
      return this.cost_centers.filter(cost => {
        return cost.id == this.jobRequisition.cost_center_id;
      })[0].user.user_code;
    },
    save: function() {
      const self = this;

      self.jobRequisition.server_url =
        window.location.origin +
        "/portal/p/" +
        self.$store.getters.user.tenantCode.id +
        "/HumanFlow";

      let nomeEmpresa = self.companies.filter(company => {
        return company.id == self.jobRequisition.company_id;
      })[0];

      if (nomeEmpresa == undefined) {
        self.$message(
          "error",
          self.$t("label.ocorreuUmErro"),
          self.$t("label.empresaObrigatoria")
        );
        return false;
      }

      self.jobRequisition.nomeEmpresa = nomeEmpresa.name;

      let estabelecimento = self.branches.filter(branch => {
        return branch.id == self.jobRequisition.branch_id;
      })[0];

      if (estabelecimento == undefined) {
        self.$message(
          "error",
          self.$t("label.ocorreuUmErro"),
          self.$t("label.estabelecimentoObrigatorio")
        );
        return false;
      }

      self.jobRequisition.estabelecimento = estabelecimento.name;

      let centroCusto = self.cost_centers.filter(cost => {
        return cost.id == self.jobRequisition.cost_center_id;
      })[0];

      if (centroCusto == undefined) {
        self.$message(
          "error",
          self.$t("label.ocorreuUmErro"),
          self.$t("label.centroCustoObrigatorio")
        );
        return false;
      }

      self.jobRequisition.centroCusto = centroCusto.name;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .post(self.url, {
          jobRequisition: self.jobRequisition,
          customer_id: self.$store.getters.customerId
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
          this.$router.push({ path: "/jobRoles/approval" });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error, "");
        });
    }
  }
};
</script>

<style scoped>
#reason-job-requisition-container {
  width: 100%;
}

#reason-job-requisition-container label {
  width: 33%;
}
</style>

<template>
  <div>
    <div v-if="$store.getters.user.isRecruiter">
      <div
        class="col-sm-12"
        v-if="analytics.approval > 0 && ($store.getters.user ? !$store.getters.user.isRecruiter : false)"
      >
        <div class="alert alert-info alert-dismissible fade show" role="alert">
          <span class="badge badge-pill badge-info">{{ $t('label.vagas') }}</span>
          {{ $t('label.voceTem') }} {{analytics.approval}} {{ $t('label.vagasEmAprovacao') }}.
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div
        class="col-sm-12"
        v-if="analytics.disapproval > 0 && ($store.getters.user ? !$store.getters.user.isRecruiter : false)"
      >
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <span class="badge badge-pill badge-warning">{{ $t('label.vagas') }}</span>
          {{ $t('label.voceTem') }} {{analytics.disapproval}} {{ $t('label.vagasReprovadas') }}.
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <!-- Card de Aprovação -->
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2">
        <div class="card text-dark-blue bg-flat-red">
          <div class="card-header p-2 bg-red">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h4 class="card-title mb-0 text-light-gray">{{ $t('label.solicitadas') }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body pb-0 pt-2 text-center">
            <h3 class="mb-3 text-center text-light-gray">
              <span class="count">
                <loading-spinner :loading="loading"></loading-spinner>
                <label v-if="!loading">{{analytics.approval}}</label>
              </span>
              <span v-if="!loading" style="text-transform: lowercase">&nbsp;{{ $t('label.vagas') }}</span>
            </h3>
            <div id="chartApr" class="chart-wrapper px-0 chart-dash hidden" height="80">
              <!-- <canvas id="widgetChartApr" v-show="graphicsAnalytics.approval"></canvas> -->
              <small-chart
                :chartData="graphicsAnalytics.approval.chartData"
                :options="graphicsAnalytics.options"
                v-if="graphicsAnalytics.approval.enable"
                :height="250"
              ></small-chart>
              <span
                class="mb-3 text-light-gray"
                v-if="!graphicsAnalytics.approval.enable"
              >Dados não encontrados!</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Card de Aprovação -->

      <!-- Card de JobDescription -->
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2">
        <div class="card text-dark-blue bg-flat-orange">
          <div class="card-header p-2 bg-orange">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h4 class="card-title mb-0">{{ $t('label.aprovadas') }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body pb-0 pt-2">
            <h3 class="mb-3 text-center">
              <span class="count">
                <loading-spinner :loading="loading"></loading-spinner>
                <label v-if="!loading">{{analytics.jobDescription}}</label>
              </span>
              <span v-if="!loading" style="text-transform: lowercase">&nbsp;{{ $t('label.vagas') }}</span>
            </h3>
            <div id="chartDes" class="chart-wrapper px-0 chart-dash hidden" height="80">
              <canvas id="widgetChartDes" v-show="graphicsAnalytics.jobDescription"></canvas>
              <span class="mb-3" v-show="!graphicsAnalytics.jobDescription">Dados não encontrados!</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Card de JobDescription -->

      <!-- Card de Publicação -->
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2">
        <div class="card text-dark-blue bg-flat-yellow">
          <div class="card-header p-2 bg-yellow">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h4 class="card-title mb-0">{{ $t('label.publicadas') }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body pb-0 pt-2">
            <h3 class="mb-3 text-center">
              <span class="count">
                <loading-spinner :loading="loading"></loading-spinner>
                <label v-if="!loading">{{analytics.publication}}</label>
              </span>
              <span v-if="!loading" style="text-transform: lowercase">&nbsp;{{ $t('label.vagas') }}</span>
            </h3>
            <div id="chartPub" class="chart-wrapper px-0 chart-dash hidden" height="80">
              <canvas id="widgetChartPub" v-show="graphicsAnalytics.publication"></canvas>
              <span class="mb-3" v-show="!graphicsAnalytics.publication">Dados não encontrados!</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Card de Publicação -->

      <!-- Card de Triagem -->
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2">
        <div class="card text-dark-blue bg-flat-blue">
          <div class="card-header p-2 bg-blue">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h4 class="card-title mb-0 text-light-gray">{{ $t('label.triagem') }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body pb-0 pt-2">
            <h3 class="mb-3 text-center text-light-gray">
              <span class="count">
                <loading-spinner :loading="loading"></loading-spinner>
                <label v-if="!loading">{{analytics.recruitment}}</label>
              </span>
              <span v-if="!loading" style="text-transform: lowercase">&nbsp;{{ $t('label.vagas') }}</span>
            </h3>
            <div id="chartTri" class="chart-wrapper px-0 chart-dash hidden" height="80">
              <canvas id="widgetChartTri" v-show="graphicsAnalytics.recruitment"></canvas>
              <span
                class="mb-3 text-light-gray"
                v-show="!graphicsAnalytics.recruitment"
              >Dados não encontrados!</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Card de Triagem -->

      <!-- Card de Seleção -->
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2">
        <div class="card text-dark-blue bg-flat-cian">
          <div class="card-header p-2 bg-cian">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h4 class="card-title mb-0">{{ $t('label.selecao') }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body pb-0 pt-2">
            <h3 class="mb-3 text-center">
              <span class="count">
                <loading-spinner :loading="loading"></loading-spinner>
                <label v-if="!loading">{{analytics.selection}}</label>
              </span>
              <span v-if="!loading" style="text-transform: lowercase">&nbsp;{{ $t('label.vagas') }}</span>
            </h3>
            <div id="chartSel" class="chart-wrapper px-0 chart-dash hidden" height="80">
              <canvas id="widgetChartSel" v-show="graphicsAnalytics.selection"></canvas>
              <span class="mb-3" v-show="!graphicsAnalytics.selection">Dados não encontrados!</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Card de Seleção -->

      <!-- Card de Concluídas -->
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2">
        <div class="card text-dark-blue bg-flat-green">
          <div class="card-header p-2 bg-green">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h4 class="card-title mb-0 text-light-gray">{{ $t('label.concluidas') }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body pb-0 pt-2">
            <h3 class="mb-3 text-center text-light-gray">
              <span class="count">
                <loading-spinner :loading="loading"></loading-spinner>
                <label v-if="!loading">{{analytics.completed}}</label>
              </span>
              <span v-if="!loading" style="text-transform: lowercase">&nbsp;{{ $t('label.vagas') }}</span>
            </h3>
            <div id="chartCon" class="chart-wrapper px-0 chart-dash hidden" height="80">
              <!-- <canvas id="widgetChartCon" v-show="graphicsAnalytics.completed"></canvas> -->
              <small-chart
                :chartData="graphicsAnalytics.completed.chartData"
                :options="graphicsAnalytics.options"
                v-if="graphicsAnalytics.completed.enable"
                :height="250"
              ></small-chart>
              <span
                class="mb-3 text-light-gray"
                v-if="!graphicsAnalytics.completed.enable"
              >Dados não encontrados!</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Card de Concluídas -->

      <div class="clear"></div>

      <!-- Gráfico de contratações -->
      <div class="col-xl-6 col-lg-12">
        <div class="card text-dark-blue bg-flat-gray">
          <div class="card-header pt-2 pb-2 pl-3 pr-3 mb-1">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title mb-0">
                  {{ $t('label.contratacoes') }}
                  <a
                    class="btn dark-btn pull-right"
                    @click="showModal"
                  >
                    <i class="fa fa-filter"></i>
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-wrapper">
              <chartFinishedJobs :filter="filter"></chartFinishedJobs>
            </div>
          </div>
        </div>
      </div>
      <!-- Gráfico de contratações -->

      <!-- Tabela Dados -->
      <div class="col-xl-6 col-lg-12">
        <div class="card text-dark-blue bg-flat-gray">
          <div class="card-header pt-2 pb-2 pl-3 pr-3 mb-1">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title mb-0">{{ $t('label.vagasSemRecrutador') }}</h4>
                <div class="small text-muted">{{ $t('label.selecione') }}</div>
              </div>
              <!--/.col-->
            </div>
            <!--/.row-->
          </div>
          <div class="card-body">
            <v-server-table
              ref="table"
              name="jobTable"
              :url="url"
              :columns="columns"
              :options="options"
            >
              <template slot="recruiter" slot-scope="props">
                <button
                  class="btn takeover-btn p-0"
                  @click="assignRecruiter(props.row)"
                  v-if="enableAssign(props)"
                >
                  <i class="fa fa-thumbs-o-up"></i>
                  {{ getRecruiter(props.row) }}
                </button>

                <span v-else class="badge font-14">{{ getRecruiter(props.row) }}</span>
              </template>
            </v-server-table>
          </div>
        </div>
      </div>
      <!-- Tabela Dados -->

      <div class="clear"></div>

      <dash-recruiter-filter @updateFilter="updateFilter"></dash-recruiter-filter>

      <assign-recruiter :job="job" @recruiterAssigned="recruiterAssigned"></assign-recruiter>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12">teste</div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../LoadingSpinner";
import ChartFinishedJobs from "./ChartFinishedJobs";
import DashRecruiterFilter from "./DashRecruiterFilter";
import AssignRecruiter from "./AssignRecruiter";
import SmallChart from "./SmallChart";
export default {
  data() {
    let self = this;
    return {
      graphicsAnalytics: {
        options: {
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          responsive: true,
          tooltips: {
            enabled: true,
            displayColors: false
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "transparent",
                  zeroLineColor: "transparent"
                },
                ticks: {
                  fontSize: 2,
                  fontColor: "transparent"
                }
              }
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  display: false
                }
              }
            ]
          },
          title: {
            display: false
          },
          elements: {
            line: {
              tension: 0.00001,
              borderWidth: 1
            },
            point: {
              radius: 4,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        },
        approval: {
          chartData: {
            labels: [],
            type: "line",
            datasets: [
              {
                data: [],
                label: "Vagas",
                backgroundColor: "rgba(255,255,255,0)",
                borderColor: "rgba(249,249,249,1)"
              }
            ]
          },
          enable: false
        },
        completed: {
          chartData: {
            labels: [],
            type: "line",
            datasets: [
              {
                data: [],
                label: "Vagas",
                backgroundColor: "rgba(255,255,255,0)",
                borderColor: "rgba(249,249,249,1)"
              }
            ]
          },
          enable: false
        }
      },
      loading: false,
      filter: {
        from: "",
        to: ""
      },
      job: {},
      endPoint: "/job/",
      analytics: {
        completed: 0,
        selection: 0,
        recruitment: 0,
        publication: 0,
        jobDescription: 0,
        approval: 0,
        disapproval: 0
      },
      jobs: [],
      columns: ["role_name", "quantity", "recruiter"],
      tableData: [],
      orderField: "created_at",
      options: {
        debounce: 1000,
        sortable: ["role_name", "quantity"],
        pagination: {
          chunk: 10,
          dropdown: false,
          nav: "scroll"
        },
        texts: {
          count: `${self.$t("label.mostrando")} {from} ${self.$t(
            "label.de"
          )} {to} ${self.$t("label.ate")} {count} ${self.$t(
            "label.deRegistros"
          )}`,
          filter: false,
          filterPlaceholder: self.$t("label.pesquisarAqui"),
          limit: self.$t("label.registros") + ":",
          noResults: self.$t("label.nenhumRegistroEncontrado"),
          page: self.$t("label.pagina") + ":"
        },
        perPage: 5,
        perPageValues: [5, 10, 25, 50, 100],
        headings: {
          role_name: self.$t("label.titulo"),
          quantity: self.$t("label.vagas"),
          recruiter: self.$t("label.recrutador")
        },
        requestFunction: function(data) {
          let requestData = {};

          requestData.params = data;
          requestData.params.with = ["recruiter"];
          requestData.params.whereNot = ["status,2", "status,4", "status,5"];

          requestData.params.withEmpty = "recruiter";

          return this.$http.get(this.url, requestData).catch(
            function(e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        }
      }
    };
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    urlAnalytics() {
      return (
        this.$store.getters.apiUrl +
        "/job/analytics/" +
        this.$store.getters.customerId
      );
    },
    urlGraphicsAnalytics() {
      return (
        this.$store.getters.apiUrl +
        "/job/graphics-analytics/" +
        this.$store.getters.customerId
      );
    }
  },
  filters: {
    toBrazilianDate: function(date) {
      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];

      return dt;
    }
  },
  methods: {
    getRecruiter(row, isSpan) {
      let self = this;
      if (row.recruiter.name == "") {
        if (isSpan) {
          return self.$t("label.semRecrutador");
        }

        return self.$t("label.definir");
      }

      return row.recruiter.name;
    },
    showChart(elem) {
      if (this.$("#" + elem).hasClass("hidden")) {
        this.$("#" + elem).removeClass("hidden");
      } else {
        this.$("#" + elem).addClass("hidden");
      }
    },

    enableAssign(props) {
      return (
        props.row.recruiter != undefined &&
        props.row.recruiter.name == "" &&
        this.$store.getters.user.isRecruiter
      );
    },
    assignRecruiter: function(row) {
      this.job = row;

      this.$("#assignRecruiterModal").modal("show");
    },
    getAnalytics: function() {
      let self = this;

      this.loading = true;
      this.$http
        .get(this.urlAnalytics)
        .then(response => {
          this.analytics = response.data;
          this.loading = false;
        })
        .then(() => {
          self.$(".count").each(function() {
            self
              .$(this)
              .prop("Counter", 0)
              .animate(
                {
                  Counter: self.$(this).text()
                },
                {
                  duration: 1500,
                  easing: "swing",
                  step: function(now) {
                    self.$(this).text(Math.ceil(now));
                  }
                }
              );
          });
        });
    },
    showModal: function() {
      this.$("#dashRecruiterFilter").modal({
        backdrop: "static",
        keyboard: false
      });
    },
    updateFilter: function(filter) {
      this.filter = filter;
    },
    recruiterAssigned: function(job) {
      this.job.recruiter_id = job.recruiter_id;
      this.job.recruiter = job.recruiter;
    },

    loadFilteredJobList: function(
      aFilterCheckBox,
      oDateOpenFilter,
      oDateExpFilter,
      cSearchParam,
      cSearchParamRequester,
      cSearchParamDepartment,
      changePage = false,
      orderField,
      orderDirection
    ) {
      const self = this;
      self.changeLoading(true);

      let params = {};

      if (orderDirection) {
        params = {
          status: self.actualStatus,
          role_name: cSearchParam,
          requester: cSearchParamRequester,
          department: cSearchParamDepartment,
          limit: 16,
          with: ["candidates", "recruiter"],
          orWhere: ["status"],
          orderByAsc: orderField
        };
      } else {
        params = {
          status: self.actualStatus,
          role_name: cSearchParam,
          requester: cSearchParamRequester,
          department: cSearchParamDepartment,
          limit: 16,
          with: ["candidates", "recruiter"],
          orWhere: ["status"],
          orderByDesc: orderField
        };
      }

      if (changePage) {
        params.page = this.currentPage;
      }
    },

    loadJobList: function() {
      const self = this;

      self.changeLoading(true);

      let params = {
        with: ["candidates", "approver", "recruiter"],
        limit: 16,
        orWhere: ["status"],
        orderByDesc: "created_at"
      };

      if (self.onlyApprover) {
        params.status = 8; // pendente de aprovação
        params.approver_id = this.$store.getters.user.id;
        delete params.recruiter_id;
      }

      self.$http
        .get(self.url, {
          params: params
        })
        .then(response => {
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1;
          self.numberOfPages = response.data.pages;

          self.changeLoading(false);
        });
    },
    changeLoading: function(value) {
      this.loading = value;
    },
    loadJob(id) {
      const self = this;
      self.$http.get(this.url + id).then(response => {
        self.job.id = response.data.id;
        self.job.status = response.data.status;
        self.job.profile = response.data.profile;
        self.job.market_info = response.data.market_info;
        self.job.desired_age = response.data.desired_age;
        self.job.desired_sex = response.data.desired_sex;
        self.job.internal_candidate = response.data.internal_candidate;
        self.job.initial_date = response.data.initial_date;
        self.job.end_date = response.data.end_date;
        self.job.business_segment = response.data.business_segment;
        self.job.complements = response.data.complements;
        self.job.company_name = response.data.company_name;
        self.job.role_name = response.data.role_name;
        self.job.role_description = response.data.role_description;
        self.job.role_requirements = response.data.role_requirements;
        self.job.overreach_info = response.data.overreach_info;
        self.job.position_salary = response.data.position_salary;
        self.job.position_type = response.data.position_type;
        self.job.contract_type = response.data.contract_type;
      });
    },
    //loadFilteredJobListByTabs
    // changePage = false
    clickJobDescription: function(iStatus, changePage = false) {
      this.changeLoading(true);
      const self = this;

      let params = {
        status: iStatus,
        limit: 16,
        with: ["candidates", "recruiter"],
        orWhere: ["status"],
        orderByAsc: this.orderField
      };

      if (changePage) {
        params.page = this.currentPage;
      }

      self.$http
        .get(self.url, {
          params
        })
        .then(response => {
          self.actualStatus = iStatus;
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1;
          self.numberOfPages = response.data.pages;

          self.changeLoading(false);
        })
        .catch(function() {
          self.$errorMessage(
            "Não foi possível buscar as informações referente ao Status selecionado"
          );
        });
    },
    makeGraphiqAnalytic: function(
      element /*, backgroundColor, borderColor, data*/
    ) {
      var ctx = document.getElementById(element);

      if (ctx == null) {
        return;
      }

      /*
      new Chart(ctx, {
        type: 'line',
        data: {
            labels: data ? data.months : [],
            type: 'line',
            datasets: [{
                data: data ? data.data : [],
                label: 'Vagas',
                backgroundColor: backgroundColor,
                borderColor: borderColor,
            }, ]
        },
        options: {
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            responsive: true,
            tooltips: {
                enabled: true,
                displayColors: false,
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        color: 'transparent',
                        zeroLineColor: 'transparent'
                    },
                    ticks: {
                        fontSize: 2,
                        fontColor: 'transparent'
                    }
                }],
                yAxes: [{
                    display: false,
                    ticks: {
                        display: false
                    }
                }]
            },
            title: {
                display: false
            },
            elements: {
                line: {
                    tension: 0.00001,
                    borderWidth: 1
                },
                point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4
                }
            }
        }
      });
      */
    },
    getDataGraphicsAnalytics: function() {
      let self = this;
      this.$http
        .get(this.urlGraphicsAnalytics)
        .then(response => {
          let data = response.data;
          self.graphicsAnalytics.approval.chartData.labels =
            data.approval.months;
          self.graphicsAnalytics.approval.chartData.datasets[0].data =
            data.approval.data;

          self.graphicsAnalytics.completed.chartData.labels =
            data.completed.months;
          self.graphicsAnalytics.completed.chartData.datasets[0].data =
            data.completed.data;
          // this.makeGraphiqAnalytic('widgetChartApr', 'rgba(255,255,255,0)', 'rgba(249,249,249,1)', data.approval);
          // this.makeGraphiqAnalytic('widgetChartDes', 'rgba(255,255,255,0)', 'rgba(11,18,51,1)', data.jobDescription);
          // this.makeGraphiqAnalytic('widgetChartPub', 'rgba(255,255,255,0)', 'rgba(11,18,51,1)', data.publication);
          // this.makeGraphiqAnalytic('widgetChartTri', 'rgba(255,255,255,0)', 'rgba(249,249,249,1)', data.recruitment);
          // this.makeGraphiqAnalytic('widgetChartSel', 'rgba(255,255,255,0)', 'rgba(11,18,51,1)', data.selection);
          // this.makeGraphiqAnalytic('widgetChartCon', 'rgba(255,255,255,0)', 'rgba(249,249,249,1)', data.completed);
        })
        .then(() => {
          self.graphicsAnalytics.approval.enable = true;
          self.graphicsAnalytics.completed.enable = true;
        });
    }
  },
  created: function() {
    this.$store.dispatch("disableLoadModal");
    this.getAnalytics();
    this.getDataGraphicsAnalytics();

    if (!this.$store.getters.user.isRecruiter) {
      this.$router.push("/jobMyRoles");
    }
  },
  destroyed() {
    this.$store.commit("updateContextButtons", []);
  },
  components: {
    LoadingSpinner,
    ChartFinishedJobs,
    DashRecruiterFilter,
    AssignRecruiter,
    SmallChart
  },
  props: {}
};
</script>

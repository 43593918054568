<template>
  <div id="humanflowApp" class="hidden">
    <loading-human-flow-transparent
      v-if="!$store.getters.activated || $store.getters.getLoadModal"
      :loading="true"
    ></loading-human-flow-transparent>

    <div :class="$store.getters.getLoadModal ? 'loading-blur' : ''" class="adjust-frame-principal">
      
      <left-navbar></left-navbar>

      <div id="right-panel" class="right-panel" v-if="$store.getters.activated">
        <header id="header" class="header">
          <div class="header-menu">
            <div class="col-sm-5">
              <a id="menuToggle" class="menutoggle pull-left">
                <i class="fa fa fa-tasks"></i>
              </a>
            </div>
            <div class="col-sm-7">
              <button
                v-if="$store.getters.mode == 'web' && $store.getters.user.id != ''"
                v-on:click="logout"
                class="btn btn-link header-button"
              >
                <i class="fa fa-sign-out"></i>
                {{ $t('label.sair') }}
              </button>
              <img
                v-if="$store.getters.user.id != ''&& $store.getters.user.image"
                :src="$store.getters.user.image"
                class="header-avatar"
              />
              <p class="header-name">{{ $store.getters.user.name }}</p>
              <div class="container-button-lang">
                <a href="#" v-on:click="setLanguage('es')">
                  <img
                    :src="$store.getters.imgEs"
                    :alt="$t('label.idiomaEspanhol')"
                    :title="$t('label.idiomaEspanhol')"
                    :class="'button-lang ' + ($i18n.locale == 'es' ? 'active' : '')"
                  />
                </a>
                <a href="#" v-on:click="setLanguage('en')">
                  <img
                    :src="$store.getters.imgEn"
                    :alt="$t('label.idiomaIngles')"
                    :title="$t('label.idiomaIngles')"
                    :class="'button-lang ' + ($i18n.locale == 'en' ? 'active' : '')"
                  />
                </a>
                <a href="#" v-on:click="setLanguage('ptBR')">
                  <img
                    :src="$store.getters.imgBr"
                    :alt="$t('label.idiomaPortugues')"
                    :title="$t('label.idiomaPortugues')"
                    :class="'button-lang ' + ($i18n.locale == 'ptBR' ? 'active' : '')"
                  />
                </a>
              </div>
            </div>
          </div>
        </header>

        <div class="breadcrumbs">
          <div class="col-sm-4">
            <div class="page-header float-left">
              <div class="page-title">
                <h1>{{ $t($router.name) }}</h1>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="page-header float-right">
              <div class="page-title">
                <breadcrumb></breadcrumb>
              </div>
            </div>
          </div>
        </div>

        <div class="content mt-3">
          <router-view :key="$route.fullPath + $i18n.locale"></router-view>
        </div>

        <div class="copyright">
          <p class="left small">
            {{ $t('label.copyright') }} &copy; 2015 -
            <span id="year">{{ new Date().getFullYear() }}</span>
            . {{ $t('label.todosDireitos') }}
          </p>
          <p class="right small">
            {{ $t('label.desenvolvidoPor') }}
            <a
              href="https://www.innovareti.com.br/"
              target="_blank"
            >
              <img :src="$store.getters.imagePath + 'logo-innovare.png'" alt="logo-innovare" />
            </a>
            V1.1.0
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import LeftNavbar from './components/Layout/LeftNavbar'
import loadingHumanFlowTransparent from './components/LoadingHumanFlowTransparent'
import breadcrumb from './components/Layout/Breadcrumb'

export default {
  name: "app",
  components: {
    loadingHumanFlowTransparent, breadcrumb, LeftNavbar
  },
  data () {
    return {}
  },
  computed: {
    
  },
  methods: {
    logout(){
      this.$store.dispatch('logout', this)
    },
    setLanguage(lang) {
      this.$store.dispatch('changeLang', lang)
    },
    fullScreen() {
      if (!document.fullscreenElement &&    // alternative standard method
        !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {  // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    }    
  },
  mounted() {
    $(function($) {
      $('[data-toggle="tooltip"]').tooltip({
        placement: "bottom"
      });
    });
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<template>
  <div class="card bg-flat-gray animated flipInY">
    <div class="card-body">
      <img :src="platform.image" class="publish-pic float-left mr-3" />
      <div class="float-left mt-3">
        <h6>{{ platform.name }}</h6>
        <a
          target="_blank"
          class="small text-muted"
          :href="'http://'+platform.url"
        >{{ platform.url }}</a>
      </div>
    </div>
    <div class="card-footer">
      <template v-if="platform.isPosted">
        <a
          v-if="job.public_link == ''"
          :disabled="platform.isPosted"
          class="btn edit-btn btn-block mt-1 mb-1 text-white"
        >
          <i class="fa fa-check"></i>
          {{$t('label.publicado')}}
        </a>
        <a
          v-if="job.public_link != ''"
          target="_blank"
          :href="job.public_link"
          class="btn edit-btn btn-block mt-1 mb-1 text-white"
        >
          <i class="fa fa-check"></i>
          {{$t('label.publicado')}}
        </a>
      </template>
      <template v-else>
        <a @click="postPosition" class="btn edit-btn btn-block mt-1 mb-1 text-white">
          <i class="fa fa-upload"></i>
          {{$t('label.publicarVaga')}}
        </a>
      </template>
    </div>    
  </div>
</template>

<script>
export default {
  props: ["plat_form", "job"],
  data: () => {
    return {};
  },
  computed: {
    platform: function() {
      const self = this;
      let platform = {};
      let isPosted = false;

      isPosted = this.job.platforms.filter(function(p) {
        return p.platform_id == self.plat_form.id;
      });

      platform = this.plat_form;
      platform.isPosted = isPosted.length > 0 ? true : false;
      return platform;
    }
  },
  mounted: function() {},
  methods: {
    
    postPosition: function() {
      this.platform.isPosted = true;
      this.platform.teste = "vizs";
      this.$emit("completeJobInformation", this.platform);
    }
  },
  components: {}
};
</script>


<template>
  <div>
    <center>
      <p v-if="loading">
        <i class="mb-2 fa fa-spinner fa-pulse"></i>
      </p>
    </center>
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  props: ["loading"],
  computed: {},
  mounted: function() {},
  watch: {},
  methods: {
    activate: function() {}
  },
  components: {}
};
</script>
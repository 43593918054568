<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">HumanFlow</h3>

          <div class="row mt-3">
            <div class="col-md-12">
              <h4>{{ $t('label.bemVindoAoHumanFlow') }}</h4>
              <p>{{ $t('label.paraIniciaraAtivacaoPreenchaoFormularioAbaixo') }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>{{ $t('label.empresa') }}</label>
              <input type="text" class="form-control" v-model="name" />
            </div>
            <div class="col-md-4">
              <label>{{ $t('label.cnpj') }}</label>
              <input
                type="text"
                class="form-control"
                id="cnpj"
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
              />
            </div>
            <div class="col-md-4">
              <label>{{ $t('label.email') }}</label>
              <input type="email" class="form-control" id="email" v-model="email" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 text-right">
              <button class="btn init-btn" @click="activate">{{ $t('label.iniciarAtivacao') }}</button>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-warning no-margin">
                {{ $t('label.aposIniciaraAtivacaoEmBreveVoceReceberaUmEmailComAsSeguintesInformacoes') }}:
                <ul class="pl-35-px">
                  <li>{{ $t('label.tokenParaInstalacao') }}</li>
                  <li>{{ $t('label.quantidadedeRecrutadores') }}</li>
                  <li>{{ $t('label.quantidadedeGestores') }}</li>
                </ul>
                {{ $t('label.essaConfiguracaoeRealizadaComBaseNaSuaPropostaComercial') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      message: "",
      token: "",
      errorMsg: "",
      customer: {},
      name: "",
      cnpj: "",
      email: "",
      image: ""
    };
  },
  computed: {
    active: function() {
      return this.$store.getters.activated;
    },
    uuid: function() {
      return this.$store.getters.customer.uuid;
    },
    tenant: function() {
      return this.$store.getters.customer.tennantCode;
    }
  },
  created: function() {
    this.$store.dispatch("disableLoadModal");
  },
  mounted: function() {

    let self = this

    if (typeof WCMAPI == "undefined") {
      WCMAPI = {
        isAdmin() {
          return true;
        },
        getImageLogo() {
          return "false";
        }
      };
    } else {
      WCMAPI.isAdmin = function() {
        return self.$store.getters.user.fluigAdmin
      }
    }

    if (WCMAPI != undefined && WCMAPI.isAdmin() == false) {
      self.$router.push("/notAdmin");
    }

    this.$("#cnpj").mask("99.999.999/9999-99");

    this.name = this.$store.getters.customer.name;
    this.cnpj = this.$store.getters.customer.cnpj;
    this.email = this.$store.getters.user.email;

    window.setTimeout(function() {
      self.getFluigImage();
    }, 1000);
  },
  watch: {},
  methods: {
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          if (xhr.status == 404) {
            callback("");
          } else {
            callback(reader.result);
          }
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    getFluigImage() {
      let self = this;
      const tennantCode = self.$store.getters.user.tenantCode;

      if (WCMAPI.getImageLogo() != "false") {
        this.toDataUrl(
          "/portal/api/servlet/image/" + tennantCode + "/custom/logo_image.png",
          function(data) {
            self.image = data;
          }
        );
      } else {
        self.toDataUrl(self.$store.getters.logo, function(data) {
          self.image = data;
        });
      }
    },
    activate: function() {
      let self = this;
      let api = self.$store.getters.apiUrl + "/customer/activate";
      self.cnpj = self.$("#cnpj").val();
      self.email = self.$("#email").val();

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(api, {
          uuid: self.uuid,
          tenant: WCMAPI.tenantCode || 1,
          name: self.name,
          cnpj: self.cnpj,
          email: self.email,
          tenant_code: WCMAPI.tenantCode || 1,
          server_url: WCMAPI.serverURL || "",
          version: WCMAPI.version || "",
          image: self.image
        })
        .then( () => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.ativacaoIniciadaEmBreveVoceReceberaoTokenPorEmail")
          );
          self.message = self.$t(
            "label.ativacaoIniciadaComSucessoEmBreveVoceReceberaoTokenDeInstalacaoPorEmail"
          );
          self.$store.commit("checkActivation", self.$store);
        })
        .catch(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage("", response.response.data);
        });
    }
  },
  components: {}
};
</script>

<style>
</style>



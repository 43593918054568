<template>
    <div>   
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">        
                    <h3 class="card-title">{{ $t('label.visualizacaoCadastroEstabelecimento') }}</h3>                    
                    <form>

                        <div class="row">

                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-building"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="branchs.name" placeholder="">
                                    </div>
                                </div>
                            </div> 

                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name" class="control-label mb-1">{{ $t('label.empresa') }}*</label>
                                    <div class="input-group" v-if="branchs.company">
                                        <div class="input-group-addon"><i class="fa fa-building"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="branchs.company.name" placeholder="">
                                    </div>
                                </div>
                            </div> 

                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="name" class="control-label mb-1">{{ $t('label.codigoERP') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-list-alt"></i></div>                                        
                                        <input readonly='true' type="text" class="form-control" v-model="branchs.erp_code" placeholder="">
                                    </div>
                                </div>
                            </div> 


                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="observation" class="control-label mb-1">{{ $t('label.observacao') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>                                        
                                        <textarea readonly="true" rows="5" class="form-control" v-model="branchs.observation" placeholder="" ></textarea>
                                    </div>
                                </div>
                            </div>                           

                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/branchs"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                            </div>
                        </div>                        
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {  
            branchs: {
                id: 0,
                name: '',
                observation: '',
                erp_code: '',
                company: '',
            },
            endPoint: '/branchs/'
        }
    },
    computed: {
        url () {            
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    mounted: function() {
        this.loadBranchs();
    },
    methods: {
        loadBranchs () {
            let self = this;
            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.get(this.url + this.$route.params.id, {
                params: {
                    with: ['company']
                }
            })
            .then( (response) => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.branchs = response.data;            
            }).catch((error) => {   
                self.$store.dispatch("tooggleLoadModal");  
                self.$errorMessage(error);
            })
        }
    }
}
</script>

<style>

</style>

<template>
    <div>
        <div class="card">
            <div class="card-body">
                <section id="wrapper" class="error-page">
                    <div class="error-box">
                        <div class="error-body text-center">
                            <h1 class="title-error-404">404</h1>
                            <h3 class="text-uppercase">Página não encontrada!</h3>
                            <p class="text-muted m-t-30 m-b-30">CLIQUE NO LINK ABAIXO PARA RETORNAR À PÁGINA INICIAL</p>
                            <router-link class="btn btn-primary m-t-md btn-rounded waves-effect waves-light m-b-40 text-white" :to="'/'">
                                Página Inicial <i class="fa fa-home"></i>
                            </router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {}
    },
    computed: {
    }
}
</script>
<template>
    <div class="modal fade" id="modalQuestion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{modalLabel}} {{ $t('label.pergunta') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent class="form-material">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="nome" class="control-label mb-1">{{ $t('label.pergunta') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-question"></i></div>
                                        <input type="text" name="question" id="question" class="form-control" v-model="question.description"
                                            :readonly="modalMode=='view'" autofocus>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="80%">{{ $t('label.alternativa') }}</th>
                                                <th width="30%">{{ $t('label.alternativaCorreta') }}</th>
                                                <th v-if="modalMode !='view'">*</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tbAnswers">
                                            <tr v-for="answer in question.answers" :key="answer.id">
                                                <td width="70%">
                                                    <div class="input-group">
                                                        <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>
                                                        <input type="text" class="form-control" v-model="answer.description"
                                                            :readonly="modalMode=='view'" placeholder="">
                                                    </div>
                                                </td>
                                                <td width="40%">
                                                    <label for="regimeContratacao2" class="form-check-label mr-3">
                                                        <div class="input-group">
                                                            <label class="switch switch-default switch-primary mt-2 mb-0"><input type="checkbox" class="switch-input" id="exibir" v-model="answer.right_answer" :disabled="modalMode=='view'"> <span class="switch-label border-gray"></span> <span class="switch-handle border-gray"></span></label>                                        
                                                        </div>
                                                    </label>
                                                </td>
                                                <td v-if="modalMode !='view'">
                                                    <div class="input-group">
                                                        <button class="btn delete-btn mt-1 p-0" @click="deleteQuestion(answer)"><i
                                                                class="fa fa-trash"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="flex-one">
                                <button v-if="modalMode!='view'" @click="addAnswer" class="btn add-btn p-0 mb-3 float-right margin-15-px"><i
                                        class="fa fa-plus"></i> {{ $t('label.adicionarAlternativa') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn include-btn p-1 ml-3 float-right" @click="save" v-if="modalMode != 'view' "><i
                            class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>
                    <button type="button" class="btn include-btn p-1 ml-3 float-right" data-dismiss="modal" @click="clean"
                        aria-label="Close"><i class="fa fa-times"></i> {{ $t('label.fechar') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['questionnaire', 'modalMode', 'modalStatus', 'question_id'],
    data() {
        return {
            count: 0,
            questionnaire_id: 0,
            question: {
                id: 0,
                description: '',
                answers: []
            },
            endPoint: '/question/'
        };
    },
    watch: {
        modalStatus: function (newStatus) {
            this.clean();
            if (newStatus == true) {
                this.startModal();
            }
        }
    },
    computed: {
        getCount() {
            return this.count;
        },
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        },
        answerUrl() {
            return this.$store.getters.apiUrl + '/answer/'
        },
        modalLabel() {
            if (this.modalMode == 'add') {
                return this.$t('label.nova')
            } else if (this.modalMode == 'edit') {
                return this.$t('label.editar')
            } else if (this.modalMode == 'view') {
                return this.$t('label.visualizar')
            }
            return '';
        }
    },
    methods: {
        startModal() {
            if (this.modalMode == 'add') {
                this.clean()
            } else if (this.modalMode == 'view') {
                this.getQuestion()

            } else if (this.modalMode == 'edit') {
                this.getQuestion()
            }
        },
        getQuestion() {
            const self = this;

            self.$store.dispatch("tooggleLoadModal");
            self.$http.get(this.answerUrl + this.question_id, {
                params: {
                  with: [
                      "candidateAnswer"
                  ]
                }
              })
                .then((response) => {
                    self.$store.dispatch("tooggleLoadModal");
                    this.question.description = response.data[0].description;
                    this.question.id = response.data[0].id;
                    this.question.answers = response.data[0].answers;
                    this.count = this.question.answers.length;
                })
                .catch(error => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                })
        }, clean() {
            this.question.description = '';
            this.question.answers = []; 
            this.$emit('changeModal', false);
        }, addAnswer() {
            const self = this; 
            self.count++;
            self.question.answers.push({ 'id': self.count, 'description': '', 'right_answer': false });
        },  
        deleteQuestion(answer) {

            let self = this
            if (this.question.answers.length < 2) {
                self.$message('info', self.$t('label.atencao'), self.$t('label.perguntaPrecisaTerNoMinimo1Alternativa'));
                return;
            }

            let answerObject = this.question.answers.filter(function(ans) { return ans.id === answer.id; })[0]; 

            if (this.question_id != 0) {
                if (answerObject.candidate_answer.length > 0) {
                    self.$message('info', self.$t('label.atencao'), self.$t('label.NãoEPossivelDeletarAAlternativaJaFoiUsadaResposta'));
                    return;
                }
            }

            let index = this.question.answers.indexOf(answerObject, 0);
            this.question.answers.splice(index, 1);
            this.$emit('changeModal', false);
        }, 
        save() {
            const self = this; 
            if (self.modalMode == 'add') {
                
                self.$store.dispatch("tooggleLoadModal");
                this.$http.post(self.url, {
                    'questionnaire_id': self.questionnaire.id, 'questionnaire_question_type_id': 1,
                    'description': self.question.description, 'answers': self.question.answers

                }).then(() => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.registroSalvoComSucesso'));
                })
                .then(() => {
                    self.clean();
                    $("#modalQuestion").modal('hide');
                    self.$emit('refreshTable');
                })
                .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                })
            } else {
                self.$store.dispatch("tooggleLoadModal");
                self.$http.put(self.url + self.question.id, {
                    'id': self.question.id,
                    'questionnaire_id': self.questionnaire.id,
                    'questionnaire_question_type_id': 1,
                    'description': self.question.description,
                    'answers': self.question.answers
                })
                .then(() => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.registroAtualizadoComSucesso'));
                }).then(() => {
                    self.clean();
                    $("#modalQuestion").modal('hide');
                    self.$emit('refreshTable');
                })
                .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                })
            }
        }
    }
}
</script>

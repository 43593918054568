<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">            
                    <h3 class="card-title">{{ $t('label.cadastroUsuario') }}</h3>
                    <form class="form-material" @submit.prevent>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                <input type="text" class="form-control" v-model="user.name" max="191" autofocus>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.email') }}*</label>                                        
                                <input type="email" class="form-control" v-model="user.email">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.password') }}*</label>                                        
                                <input type="password" class="form-control" v-model="user.password">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.tipo') }}*</label>    
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-tags"></i></div>
                                    <select name="role_template" v-model="user.user_type_id" class="form-control">
                                        <option value="" selected="selected">{{ $t('label.selecione') }}...</option>
                                        <option value="2">{{ $t('label.recrutador') }}</option>
                                        <option value="3">{{ $t('label.normal') }}</option>
                                    </select>
                                </div>
                            </div>                              
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/users"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                                <button @submit.prevent @click="save" id="save-btn" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>                                                                        
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {
                name: '',
            },
            endPoint: '/users/',
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {
        save() {
            const self = this;

            self.$store.dispatch("tooggleLoadModal");
            this.$http.post(self.url, self.user)
            .then(function(){
                self.$store.dispatch("tooggleLoadModal");
                self.$message('success', self.$t('label.sucesso'), self.$t('label.registroSalvoComSucesso'));
                self.$router.push({path: "/users/"});
            })
            .catch((error) => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
            });                
        },
    }
}
</script>
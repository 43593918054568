<template>
    <div class="modal fade defineRecruiterModal" :id="'defineRecruiterModal' + job.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">{{ $t('label.definirRecrutador') }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">

                <select name="recruiter_id" :id="'recruiter_id' + job.id" class="form-control" v-model="selectedUser">
                    <option value="">{{ $t('label.selecione') }}...</option>
                    <option v-for="user in users" :key="user.id" :value="user.id">{{user.name}}</option>
                </select>

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn include-btn p-1 ml-3 float-right" @click="assignJob"><i
                                class="fa fa-floppy-o"></i> {{ $t('label.definir') }}</button>
                  <button type="button" class="btn include-btn p-1 ml-3 float-right" data-dismiss="modal" 
                      aria-label="Close"><i class="fa fa-times"></i> {{ $t('label.fechar') }}</button>
              </div>
          </div>
      </div>
    </div>  
</template>

<script>
export default {
  data: () => {
    return {
      users: [],
      selectedUser: ''
    };
  },
  props: ["job", "active"],
  methods: {
    closeModal() {
      let self = this
      $("#defineRecruiterModal" + self.job.id).modal("hide");
    },
    eventListJobs() {
      this.$parent.$emit("eventListJobs");
    },
    assignJob: function() {
      let self = this;

      self.$http
        .put(self.$store.getters.apiUrl + "/job/assignRecruiter", {
          recruiter_id: $("#recruiter_id" + self.job.id).val(),
          id: self.job.id
        })
        .then(() => {
          this.eventListJobs();
        })
        .catch( () => {});

      $("#defineRecruiterModal" + self.job.id).modal("hide");
    },
    openModal() {
      let self = this;
      let api = self.$store.getters.apiUrl + "/customer/getUserRecruiter";

      self.$http
        .post(api, {
          customer_id: self.$store.getters.customerId
        })
        .then(response => {
          self.users = response.data.data;
        })
        .then(() => {
          $("#defineRecruiterModal" + self.job.id).modal("show");
        })
        .catch((error) => {
          self.$errorMessage(error)
        });
    }
  },
  watch: {
    active: function(newState) {
      if (newState) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  },
  components: {}
}
</script>

<style>

</style>
<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ role.name }}</h3>
                    <role-stage :roleTemplateId="role.id" :role="role.name"></role-stage>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <router-link class="btn include-btn p-1 ml-3 float-right" to="/roleTemplate"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RoleStage from '../RoleStages/RoleStage'

export default {
    components: {RoleStage},
    data() {
        return {
            role: {
                id: 0,
                role_id: 0,
                name: '',
                description: '',
                requirements: '',
                differential: '',
                type: '',
                clt: '',
                pj: '',
                intern: '',
                temporary: '',
                freelancer: '',
                trainee: '',
                customer_id: '',
                contract_type: '',
                role: {
                    name: ''
                }
            },
            rolesStages: [],
            // roleBenefits: [],
            endPoint: "/roleTemplate/"
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        }
    },
    mounted: function () {
        this.getLoadRole();
        // this.getLoadBenefits();
    },
    methods: {
        // getLoadBenefits() {
        //     const self = this;
        //     const api = self.$store.getters.apiUrl + '/roleBenefit';

        //     self.$store.dispatch("tooggleLoadModal"); 
        //     this.$http.get(api, {
        //         params: {
        //             'role_template_id': this.$route.params.id
        //         }
        //     })
        //     .then((response) => {
        //         self.$store.dispatch("tooggleLoadModal"); 
        //         this.roleBenefits = response.data.data;
        //     })
        //     .catch((error) => {
        //         self.$store.dispatch("tooggleLoadModal"); 
        //         errorMessage(error);
        //     })
        // },
        // updateBenefits(data) {
        //     this.loadBenefits();
        // },
        getLoadRole() {
            const self = this;

            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.get(self.url, {
                params: {
                    with: ['role'],
                    paginated: false
                }
            })
            .then((response) => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.role = response.data
            })
            .catch((error) => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.errorMessage(error);
            })
        },
        viewStage() {
            this.$router.push({
                path: this.endPoint + this.role.id + '/roleStage/'
            });
        }
    }
}
</script>

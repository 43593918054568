<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t("label.cadastroLicencas") }}</h3>
          <form class="form-material" @submit.prevent autocomplete="off">
            <div class="row" v-if="user.id != 0">
              <div class="col-md-4 form-group">
                <label class="control-label mb-1"
                  >{{ $t("label.nome") }}*</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="user.name"
                  max="191"
                  autofocus
                />
              </div>
              <div class="col-md-4 form-group">
                <label class="control-label mb-1"
                  >{{ $t("label.email") }}*</label
                >
                <input type="email" class="form-control" v-model="user.email" />
              </div>
              <div class="col-md-4 form-group">
                <label class="control-label mb-1"
                  >{{ $t("label.tipo") }}*</label
                >
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-tags"></i>
                  </div>
                  <select
                    name="role_template"
                    v-model="user.user_type_id"
                    class="form-control"
                  >
                    <option value="" selected="selected">
                      {{ $t("label.selecione") }}...
                    </option>
                    <option value="2">{{ $t("label.recrutador") }}</option>
                    <option value="1">{{ $t("label.requisitante") }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="control-label mb-1">Matricula*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="user.register"
                />
              </div>
              <div class="col-md-4 form-group">
                <label class="control-label mb-1"
                  >{{ $t("label.password") }}*</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-model="user.password"
                />
              </div>
              <div class="col-md-4 form-group">
                <label class="control-label mb-1"
                  >{{ $t("label.passwordConfirm") }}*</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-model="user.password_confirmation"
                />
              </div>
            </div>
            <div class="col-md-4 form-group form-check-inline form-check">
              <label for="admin" class="form-check-label mr-3">
                <input
                  :disabled="!this.$store.getters.user.isManager"
                  type="checkbox"
                  id="admin"
                  v-model="user.is_manager"
                  name="admin"
                  value="option1"
                  class="form-check-input"
                />
                <span class="checkmark"></span>{{ $t("label.admin") }}
              </label>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <router-link
                  class="btn include-btn p-1 ml-3 float-right"
                  to="/accounts"
                  ><i class="fa fa-reply"></i>
                  {{ $t("label.voltar") }}</router-link
                >
                <button
                  @submit.prevent
                  @click="save"
                  id="save-btn"
                  class="btn include-btn p-1 ml-3 float-right"
                >
                  <i class="fa fa-floppy-o"></i> {{ $t("label.salvar") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        id: 0,
      },
      endPoint: "/users/",
    };
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
    },
  },
  mounted: function () {
    this.loadUser();
  },
  methods: {
    loadUser() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(this.url)
        .then((response) => {
          self.$store.dispatch("tooggleLoadModal");
          self.user = response.data;
        })
        .catch((error) => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .put(self.url, self.user)
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroAtualizadoComSucesso")
          );
          self.$router.push({ path: "/accounts/" });
        })
        .catch((error) => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
  },
};
</script>

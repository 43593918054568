<template>
    <div class="card">
        <div class="card-header bg-info">
            <h4 class="text-white">Blacklist</h4>
        </div>
        <div class="card-body">
            <form method="post" class="form-material" @submit.prevent>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="nomeBlacklist"><span class="required">*</span> Nome Completo</label>
                        <input type="text" class="form-control form-control-lines" v-model="blacklist.name">
                    </div>
                </div>
                <div class="row">
                    
                    <div class="form-group col-md-4">
                        <label for="email"><span class="required">*</span> E-mail</label>
                        <input type="email" class="form-control form-control-lines" v-model="blacklist.email">
                    </div>
                    <div class="form-group col-md-8">
                        <label for="motivo"><span class="required">*</span> Motivo</label>
                        <input type="text" class="form-control form-control-lines" v-model="blacklist.motive">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="observações"><span class="required">*</span> Observações</label>
                        <textarea name="texto" class="form-control form-control-lines" rows="5" v-model="blacklist.observation"></textarea>
                    </div>
                </div>  
                
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-5">
                            <button @submit.prevent @click="salvar"  class="btn btn-info">Salvar</button>
                            <router-link class="btn btn-warning" to="/blacklist">Voltar</router-link>
                        </div>

                        <div class="col-md-5">
                            <b><span class="required">*</span> Campos Obrigatórios</b>
                        </div>
                    </div>
                   
                </div>
                    
                </form>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            blacklist: {
                name: '',
                observation: '',
                motive: '',
                email: ''
            },
            endPoint: '/blacklist/'
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {
        salvar() {

            let self = this;

            self.$http.post(self.url, {
                'name': self.blacklist.name,
                'observation': self.blacklist.observation,
                'customer_id': self.$store.getters.customerId,
                'motive': self.blacklist.motive,
                'email': self.blacklist.email
            }).then(() => {
                self.$message('success', 'Sucesso', 'Registro salvo com sucesso!');
                
                this.$router.push({
                    path: "/blacklist/"
                });
                
            }).catch((error) => {

                self.$errorMessage(error)
            })
            // tratativa de erro
        }
    },
    components: {

    }
}
</script>

<template>
  <div>
    <job-detail :job_id="$route.params.id" :jobObj="job"></job-detail>
    <div class="col-xl-12" v-if="!loading">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">
            {{ $t('label.publicarVaga') }} -
            <small>{{ job.role_name }}</small>

            <div class="float-right">
              <button
                class="btn edit-btn"
                type="button"
                style="margin-left: 10px;"
                @click="startModal"
              >{{ $t('label.verificarDetalhes') }}</button>
            </div>

            <div class="float-right">
              <button
                class="btn edit-btn"
                type="button"
                @click="openJobDetailModal"
              >{{ $t('label.detalhesDaVaga') }}</button>
            </div>
          </h3>

          <div class="row">
            <div class="col-md-12">
              <loading-human-flow :loading="loading"></loading-human-flow>
            </div>

            <div class="col-md-6 border-divider">
              <h5 class="text-left">{{ $t('label.configuraçoesDePublicacao') }}</h5>

              <!-- <div class="row mt-4">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exibir" class="control-label mb-1">{{ $t('label.exibirSalario') }}</label>
                                            <div class="input-group">
                                                <label class="switch switch-default switch-primary mt-2 mb-0"><input type="checkbox" class="switch-input" id="exibir" v-model="job.show_salary"> <span class="switch-label border-gray"></span> <span class="switch-handle border-gray"></span></label>                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="combinar" class="control-label mb-1">{{ $t('label.acombinar') }}</label>
                                            <div class="input-group">
                                                <label class="switch switch-default switch-primary mt-2 mb-0"><input type="checkbox" class="switch-input" id="combinar" v-model="job.to_combine"> <span class="switch-label border-gray"></span> <span class="switch-handle border-gray"></span></label>                                        
                                            </div>
                                        </div>
                                    </div>
              </div>-->

              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group mt-3">
                    <label>{{ $t('label.salario') }}</label>
                    <br />
                    <div class="btn-group btn-block" role="group" id="salaryConfig">
                      <button
                        @click="setSalaryConfig('show_salary')"
                        type="button"
                        :class="['btn col-md-6', {'btn-primary btn-hf-blue': job.show_salary == true}, {'btn-secondary': job.show_salary == false}]"
                      >{{ $t('label.exibirValor') }}</button>
                      <button
                        @click="setSalaryConfig('to_combine')"
                        type="button"
                        :class="['btn col-md-6', {'btn-primary btn-hf-blue': job.to_combine == true}, {'btn-secondary': job.to_combine == false}]"
                      >{{ $t('label.aCombinar') }}</button>
                      <button
                        @click="setSalaryConfig('not_informed')"
                        type="button"
                        :class="['btn col-md-6', {'btn-primary btn-hf-blue': job.not_informed == true}, {'btn-secondary': job.not_informed == false}]"
                      >{{ $t('label.naoInformado') }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mt-3">
                    <label>{{ $t('label.valor') }}</label>
                    <div class="input-group">
                      <div class="input-group-addon">
                        <i class="fa fa-money"></i>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="job.salary"
                        :readonly="!job.show_salary"
                        v-money="money"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label
                      for="regimeContratacao"
                      class="control-label mb-1"
                    >{{ $t('label.regimeContratacao') }}</label>
                    <div class="input-group">
                      <div class="form-check-inline form-check mt-2">
                        <label for="regimeContratacao1" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao1"
                            name="regimeContratacao1"
                            value="clt"
                            v-model="job.clt"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.clt') }}
                        </label>
                        <label for="regimeContratacao2" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao2"
                            name="regimeContratacao2"
                            value="outsourced"
                            v-model="job.outsourced"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.terceirizado') }}
                        </label>
                        <label for="regimeContratacao3" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao3"
                            name="regimeContratacao3"
                            value="intern"
                            v-model="job.intern"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.estagiario') }}
                        </label>
                        <label for="regimeContratacao4" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao4"
                            name="regimeContratacao4"
                            value="temporary"
                            v-model="job.temporary"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.temporario') }}
                        </label>
                        <label for="regimeContratacao5" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao5"
                            name="regimeContratacao5"
                            value="apprentice"
                            v-model="job.apprentice"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.aprendiz') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="job.questionnaire_id == null">
                <div class="col-md-12">
                  <label class="control-label mb-1">{{ $t('label.selecioneQuestionario') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <select class="form-control" v-model="questionnaire_ids">
                      <option>Selecione...</option>
                      <option
                        v-for="questionnaire in questionnaires"
                        :key="questionnaire.id"
                        :value="questionnaire.id"
                      >{{questionnaire.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <br />
              <div class="row justify-content-center" v-if="job.questionnaire_id == null">
                <div class="col-md-12">
                  <button
                    class="btn edit-btn"
                    type="button"
                    style="margin-left: 10px;"
                    @click="save"
                  >{{ $t('label.salvar') }}</button>
                </div>
              </div>
              <div class="row justify-content-center" v-else>
                <div class="col-md-12">
                  <label>Questionário já vinculado.</label>
                </div>
              </div>

              <!-- <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="tipoAnuncio" class="control-label mb-1">{{ $t('label.tipoDeAnuncio') }}*</label>
                                            <div class="input-group">
                                                <div class="form-check-inline form-check mt-2">
                                                    <label for="tipoAnuncio1" class="form-check-label mr-3">
                                                        <input type="radio" id="tipoAnuncio1" name="tiposAnuncio" value="interno" class="form-check-input" v-model='job.publication_type'>
                                                        <span class="radiomark"></span>{{ $t('label.interno') }}
                                                    </label>
                                                    <label for="tipoAnuncio2" class="form-check-label mr-3">
                                                        <input type="radio" id="tipoAnuncio2" name="tiposAnuncio" value="externo" class="form-check-input" v-model='job.publication_type'>
                                                        <span class="radiomark"></span>{{ $t('label.externo') }}
                                                    </label>
                                                    <label for="tipoAnuncio3" class="form-check-label">
                                                        <input type="radio" id="tipoAnuncio3" name="tiposAnuncio" value="ambos" class="form-check-input" v-model='job.publication_type'>
                                                        <span class="radiomark"></span>{{ $t('label.ambos') }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
              </div>-->

              <hr />

              <h5 class="text-left">{{ $t('label.publicarNosSites') }}</h5>
              <p
                class="small text-muted text-left"
              >{{ $t('label.publiqueAsVagasNosSitesPredefinidos') }}</p>
              <div class="row">
                <template v-for="(platform, key) in platforms">
                  <div class="col-md-6" :key="key">
                    <job-platform
                      :plat_form="platform"
                      :job="job"
                      :republish="true"
                      @completeJobInformation="postPosition"
                    ></job-platform>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-md-6">
              <h5 class="text-left">{{ $t('label.compartilharNasRedes') }}</h5>
              <p
                class="small text-muted text-left"
              >{{ $t('label.compartilheAsVagasNasRedesCadastradas') }}</p>
              <div class="row" v-if="job.status_id == 2">
                <div class="col-sm-12">
                  <div class="alert alert-danger">{{ $t('label.publiqueAVagaNoSitePrimeiro') }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card bg-flat-gray animated flipInY">
                    <div class="card-body">
                      <img
                        :src="$store.getters.imagePath + 'social/logo-linkedin.png'"
                        class="publish-pic float-left mr-3"
                      />
                      <div class="float-left mt-3">
                        <h6>Linkedin</h6>
                        <!-- <a href="#" target="_blank" class="small text-muted">Innovare TI</a> -->
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        @click="shareLinkedin()"
                        class="btn pub-btn btn-block mt-1 mb-1 disabled"
                        type="button"
                        :disabled="job.status_id == 2"
                      >
                        <i class="fa fa-share-square-o"></i>
                        {{ $t('label.compartilhar') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card bg-flat-gray animated flipInY">
                    <div class="card-body">
                      <img
                        :src="$store.getters.imagePath + 'social/logo-facebook.png'"
                        class="publish-pic float-left mr-3"
                      />
                      <div class="float-left mt-3">
                        <h6>Facebook</h6>
                        <!-- <a href="#" target="_blank" class="small text-muted">Innovare TI</a> -->
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        @click="shareFacebook()"
                        class="btn pub-btn btn-block mt-1 mb-1 disabled"
                        type="button"
                        :disabled="job.status_id == 2"
                      >
                        <i class="fa fa-share-square-o"></i>
                        {{ $t('label.compartilhar') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card bg-flat-gray animated flipInY">
                    <div class="card-body">
                      <img
                        :src="$store.getters.imagePath + 'social/logo-twitter.png'"
                        class="publish-pic float-left mr-3"
                      />
                      <div class="float-left mt-3">
                        <h6>Twitter</h6>
                        <!-- <a href="#" target="_blank" class="small text-muted">@innovareti</a> -->
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        @click="shareTwitter()"
                        class="btn pub-btn btn-block mt-1 mb-1 disabled"
                        type="button"
                        :disabled="job.status_id == 2"
                      >
                        <i class="fa fa-share-square-o"></i>
                        {{ $t('label.compartilhar') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <template v-for='(platform, key) in platforms'>
                            <div class ='col-md-3'>
                                <job-platform 
                                    :plat_form="platform"
                                    :job="job"
                                    @completeJobInformation="postPosition"></job-platform>
                            </div>
            </template>-->
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <a
                href="#"
                @click="() => $router.go(-1)"
                class="btn include-btn p-1 ml-3 float-right"
              >
                <i class="fa fa-reply"></i>
                {{ $t('label.voltar') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="modalJob"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalJobLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalJobLabel">Verificar informações</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label>Título da vaga</label>
                <input type="text" v-model="newPublish.role_name" class="form-control" />
              </div>
              <div class="col-md-12">
                <label>Requisitos</label>
                <textarea v-model="newPublish.role_requirements" class="form-control" />
              </div>
              <div class="col-md-12">
                <label>Diferenciais</label>
                <textarea v-model="newPublish.differential" class="form-control" />
              </div>
              <div class="col-md-12">
                <label>Formação Acadêmica</label>
                <textarea v-model="newPublish.formation" class="form-control" />
              </div>
              <div class="col-md-12">
                <label>Cursos Adicionais</label>
                <textarea v-model="newPublish.additional_courses" class="form-control" />
              </div>

              <div class="col-md-12">
                <label>Local de Trabalho</label>
                <textarea v-model="newPublish.workplace" class="form-control" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">Salvar Modificações</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobDetail from "../JobRecruitment/JobDetail";
import LoadingHumanFlow from "../LoadingHumanFlow";
import JobPlatform from "../JobPostSelection/JobPlatform";

export default {
  components: { JobDetail, LoadingHumanFlow, JobPlatform },
  data: () => {
    return {
      // this.$route.params.id
      questionnaires: [],
      questionnaire_ids: "",

      loading: false,
      newPublish: {
        role_name: ""
      },
      Job: {
        questionnaire_ids: ""
      },
      job: {
        profile: "",
        market_info: "",
        prazo: "",
        desired_age: "",
        desired_sex: "",
        internal_candidate: "",
        initial_date: "",
        business_segment: "",
        complements: "",
        company_name: "",
        company_location: "",
        end_date: "",
        requester: "",
        role_name: "",
        quantity: "",
        role_description: "",
        position_new_renew: "",
        role_requirements: "",
        differential: "",
        observations: "",
        role_benefits: [],
        position_salary: "",
        position_type: "",
        show_salary: "",
        to_combine: "",
        not_informed: "",
        clt: "",
        outsourced: "",
        intern: "",
        temporary: "",
        apprentice: "",
        internal_start_date: "",
        internal_end_date: "",
        publication_type: "",

        branch: {
          name: ""
        },
        cost_center: {
          name: ""
        },
        company: {
          photo: ""
        },
        approver: {
          name: ""
        },
        platforms: []
      },
      endPoint: "/platform/",
      post_endPoint: "/job/publication",
      otherPost: "/releaseJob",
      platforms: [],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: true /* doesn't work with directive */
      }
    };
  },
  computed: {
    url: function() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    post_url: function() {
      return this.$store.getters.apiUrl + this.post_endPoint;
    },
    otherPostUrl: function() {
      return this.$store.getters.apiUrl + this.otherPost;
    },
    urlJob: function() {
      return this.$store.getters.apiUrl + "/job/" + this.$route.params.id;
    },
    jobsUrlFind: function() {
      return (
        "https://modern.humanflow.com.br/jobs/find/" + this.$route.params.id
      );
    }
  },
  mounted: function() {
    this.loadPlatforms();
    this.loadJob();
    this.loadQuestionnaires();
  },
  methods: {
    save: function() {
      const self = this;
      // let job_id = self.$route.params.id ? self.$route.params.id : '';
      let Job = self.job;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(self.otherPostUrl, {
          jobId: Job.id,
          prazo: Job.end_date_raw,
          perfil_candidato: Job.profile,
          informacoes_mercado: Job.market_info,
          candidato: Job.internal_candidate,
          prazoInicial: Job.internal_start_date,
          prazoFinal: Job.internal_end_date,
          segmentos: Job.business_segment,
          observacoes: Job.complements,
          role_description: Job.role_description,
          role_requirements: Job.role_requirements,
          differential: Job.differential,
          questionnaire_id: this.questionnaire_ids
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );

          this.loadJob();
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    startModal: function() {
      // this.newPublish.role_name = this.job.role_name;
      // this.newPublish.role_requirements = this.job.role_requirements;
      // this.newPublish.role_description = this.job.role_description;

      $("#modalJob").modal({
        show: true
      });
    },
    shareFacebook() {
      const self = this;
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" + self.jobsUrlFind,
        "popup",
        "width=600,height=500"
      );
    },
    shareTwitter() {
      const self = this;
      window.open(
        "https://twitter.com/share?text=Humanflow - Vagas&url=" +
          self.jobsUrlFind,
        "popup",
        "width=600,height=500"
      );
    },
    shareLinkedin() {
      const self = this;
      window.open(
        "https://www.linkedin.com/sharing/share-offsite/?url=" +
          self.jobsUrlFind,
        "popup",
        "width=600,height=500"
      );
    },
    setSalaryConfig(config) {
      const self = this;

      if (self.job[config]) return;

      self.job.show_salary = false;
      self.job.to_combine = false;
      self.job.not_informed = false;

      self.job[config] = true;

      // self.$http.put(this.$store.getters.apiUrl + '/job/' + self.job.id, {
      //     show_salary: self.job.show_salary,
      //     to_combine: self.job.to_combine
      // }).then(() => {
      //     self.$message('success', 'Sucesso', self.$t('Configuração salarial salva'))
      // }).catch(() => {
      //     self.$message('error', 'Erro', self.$t('Erro ao salvar configuração salarial'))
      // })
    },
    openJobDetailModal() {
      this.$("#jobDetailModal").modal("show");
    },
    loadRecruiterView: function() {
      this.$router.push({
        path: this.endPoint + "/view/role/" + this.$route.params.id
      });
    },
    loadJob() {
      const self = this;

      self.$http
        .get(this.urlJob, {
          params: {
            with: [
              "platforms",
              "company",
              "approver",
              "cost_center",
              "benefits"
            ]
          }
        })
        .then(response => {
          this.job = response.data;

          this.newPublish.role_name = this.job.role_name;
          this.newPublish.role_requirements = this.job.role_requirements;
          this.newPublish.differential = this.job.differential;
          this.newPublish.formation = this.job.formation;
          this.newPublish.work_schedule = this.job.work_schedule;
          this.newPublish.additional_courses = this.job.additional_courses;
          this.newPublish.workplace = this.job.workplace;

          // self.job.id = response.data.id;
          // self.job.status = response.data.status;
          // self.job.profile = response.data.profile;
          // self.job.market_info = response.data.market_info;
          // self.job.desired_age = response.data.desired_age;
          // self.job.desired_sex = response.data.desired_sex;
          // self.job.internal_candidate = response.data.internal_candidate;
          // self.job.inicial_date = response.data.inicial_date;
          // self.job.end_date = response.data.end_date;
          // self.job.business_segment = response.data.business_segment;
          // self.job.complements = response.data.complements;
          // self.job.company_name = response.data.company_name;
          // self.job.role_name = response.data.role_name;
          // self.job.role_description = response.data.role_description;
          // self.job.role_requirements = response.data.role_requirements;
          // self.job.overreach_info = response.data.overreach_info;
          // self.job.position_salary = response.data.position_salary;
          // self.job.position_type = response.data.position_type;
          // self.job.contract_type = response.data.contract_type;
          // self.job.platforms = response.data.platforms;
        });
    },
    loadPlatforms: function() {
      this.loading = true;

      const self = this;
      self.$store.dispatch("tooggleLoadModal");

      self.$http
        .get(self.url)
        .then(response => {
          this.platforms = response.data.data;
          this.loading = false;
          self.$store.dispatch("tooggleLoadModal");
        })
        .catch(function() {
          self.$message(
            "error",
            self.$t("label.erro"),
            self.$t("label.erroCarregarPlataformas")
          );
        });
    },
    loadQuestionnaires() {
      const self = this;
      const api = self.$store.getters.apiUrl + "/questionnaire?paginated=false";

      self.$http
        .get(api)
        .then(response => {
          let questionnaires = response.data.data;
          self.questionnaires = questionnaires;
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });

      return "ok";
    },
    postPosition: function(platform) {
      const self = this;
      
      if (! this.newPublish.role_name) {
        self.$message(
          "error",
          self.$t("label.verificarDetalhes"),
          self.$t("label.tituloObrigatoria")
        );
        return false;
      }
      if (! this.newPublish.role_requirements) {
        self.$message(
          "error",
          self.$t("label.verificarDetalhes"),
          self.$t("label.requirementsObrigatoria")
        );
        return false;
      }
      if (! this.newPublish.differential) {
        self.$message(
          "error",
          self.$t("label.verificarDetalhes"),
          self.$t("label.differentialObrigatoria")
        );
        return false;
      }
      if (! this.newPublish.formation) {
        self.$message(
          "error",
          self.$t("label.verificarDetalhes"),
          self.$t("label.formationObrigatoria")
        );
        return false;
      }
      if (! this.newPublish.workplace) {
        self.$message(
          "error",
          self.$t("label.verificarDetalhes"),
          self.$t("label.workplaceObrigatoria")
        );
        return false;
      }

      let publishConfig = {
        show_salary: self.job.show_salary,
        to_combine: self.job.to_combine,
        not_informed: self.job.not_informed,
        salary: self.job.salary,
        clt: self.job.clt,
        outsourced: self.job.outsourced,
        intern: self.job.intern,
        temporary: self.job.temporary,
        apprentice: self.job.apprentice,
        date_sequence: self.job.date_sequence,
        sequence: self.job.sequence,
        formation: self.job.formation,
        workload: self.job.workload,
        additional_courses: self.job.additional_courses,
        role_behavioral_skills: self.job.role_behavioral_skills,
        work_schedule: self.job.work_schedule,
        workplace: self.job.workplace,
        questionnaire_id: self.job.questionnaire_id
      };

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(this.post_url, {
          job_id: self.job.id,
          platform_id: platform.id,
          newPublish: self.newPublish,
          publishConfig: publishConfig
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.loadPlatforms();
          self.loadJob();
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.vagaPostadaComSucesso")
          );
        })
        .catch(function(error) {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    }
  }
};
</script>

<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-12">
                <ul class="nav nav-pills nav-justified filters" id="pills-tab-candidatos" role="tablist">
                    <li class="nav-item" @click="onDisplayNew">
                        <a class="nav-link active show" id="pills-aplicados-tab" data-toggle="pill" href="#aplicados" role="tab" aria-controls="pills-aplicados" aria-selected="true"><i class="fa fa-arrow-right"></i>
                            {{$t('label.available_candidates')}} <span>({{ totalPending }})</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tab-content" id="filters2TabContent">
                    <div class="tab-pane fade show active" id="aplicados" role="tabpanel" aria-labelledby="pills-aplicados-tab">
                        <div v-if="getCandidates.length == 0">
                            <div class="alert alert-info">
                                {{$t('label.naoHaCandidatosAplicados')}}
                            </div>
                        </div>

                        <div v-if="getCandidates.length > 0">

                            <div class="row">
                                <div class="col-md-12">
                                    <loading-human-flow :loading='loadingPending'></loading-human-flow>
                                </div>
                                <template v-if="!loadingPending">
                                    <template v-for="candidate in getCandidates">
                                        <candidate-list-item :key="candidate.id" :candidate='candidate' :select="selectPending" @selected='selected' @notSelected='notSelected' @showModal='showModal' :type='type.candidate'></candidate-list-item>
                                    </template>
                                </template>                
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <nav aria-label="Page navigation example mr-10">
                                        <ul class="pagination pull-right">
                                            <li class="page-item">        
                                                <button class="page-link" @click="previousPagePending">{{$t('label.anterior')}}</button>
                                            </li>
                                            <template v-for="page in getPagesPending">
                                                <li class="page-item" :class=" {'active': currentPagePending == page.pageNumber} " :key="page.pageNumber">
                                                    <button class="page-link" @click="changePagePending(page.pageNumber)">{{page.pageNumber}}</button>
                                                </li>
                                            </template>   
                                            <li class="page-item">
                                                <button class="page-link" @click="nextPagePending">{{$t('label.proximo')}}</button>
                                            </li>                                         
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import CandidateListItem from './CandidateListItemTalentBank'
import LoadingHumanFlow from '../LoadingHumanFlow'

export default {
    components: {CandidateListItem, LoadingHumanFlow},
    props: [
        'candidate_list',
        'selectedCandidate',
        'deletedCandidate',

        'selectPending',
        'selectSelected',
        'selectDeleted',

        'pagesPending',
        'pagesSelected',
        'pagesDeleted',
        'pagesBlackList',

        'currentPagePending',
        
        
        'currentPageBlackList',

        'loadingPending',
        
        'loadingDeleted',

        'totalPending',

        
        'currentPageSelected',
        'totalSelected',
        'loadingSelected',
        
        'currentPageDeleted',
        'totalDeleted',
        

    ],
    data: () => {
        return {
            // this.$route.params.id
            checked_candidateList: [],
            checked_selectedList: [],
            checked_deletedList: [],
            type: {
                candidate: 'candidate',
                selected: 'selected',
                deleted: 'deleted'
            }
        }
    },
    computed: {
        getPagesPending() {
            const self = this;
            let pages = [];
            let numberOfPages = this.pagesPending;
            let currentPage = this.currentPagePending;

            if (numberOfPages > 5 && currentPage > 1) {
                for (let i = currentPage; i <= currentPage + 4; i++) {
                    if(i > self.pagesPending) break;
                    pages.push({
                        pageNumber: i
                    });
                }
            } else if (numberOfPages < 5) {
                for (let i = 0; i < numberOfPages; i++) {
                    pages.push({
                        pageNumber: i + 1
                    });
                }
            } else {
                for (let i = 0; i < 5; i++) {
                    pages.push({
                        pageNumber: i + 1
                    });
                }
            }
            return pages;
        },
        getPagesSelected() {
            let numberOfPages = this.pagesSelected;
            console.log(numberOfPages)
            let currentPage = this.currentPageSelected;
            let pages = [];

            if (numberOfPages > 5 && currentPage > 1) {

                for (let i = currentPage; i <= currentPage + 4; i++) {
                    pages.push({
                        pageNumber: i
                    });
                }
            } else if (numberOfPages < 5) {
                for (let i = 0; i < numberOfPages; i++) {
                    pages.push({
                        pageNumber: i + 1
                    });

                }
            } else {

                for (let i = 0; i < 5; i++) {
                    pages.push({
                        pageNumber: i + 1
                    });
                }
            }
            return pages;
        },
        getPagesDeleted() {
            let pages = [];
            let numberOfPages = this.pagesDeleted;
            let currentPage = this.currentPageDeleted;

            if (numberOfPages > 5 && currentPage > 1) {
                for (let i = currentPage; i <= currentPage + 4; i++) {
                    pages.push({
                        pageNumber: i
                    });
                }
            } else if (numberOfPages < 5) {
                for (let i = 0; i < numberOfPages; i++) {
                    pages.push({
                        pageNumber: i + 1
                    });
                }
            } else {
                for (let i = 0; i < 5; i++) {
                    pages.push({
                        pageNumber: i + 1
                    });
                }
            }
            return pages;
        },
        getCandidates: function () {
            return this.candidate_list;
        },

        deletedCandidate_list: function () {
            return this.deletedCandidate;
        },

        selectedCandidate_list: function () {
            return this.selectedCandidate;
        },

        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {
        changePagePending(page) {
            this.$emit('changePagePending', page);
        },
        previousPagePending() {
            this.$emit('previousPagePending');
        },
        nextPagePending() {
            this.$emit('nextPagePending');
        },
        changePageSelected(page) {
            this.$emit('changePageSelected', page);
        },
        previousPageSelected() {
            this.$emit('previousPageSelected');
        },
        changePageDeleted(page) {
            this.$emit('changePageDeleted', page);
        },
        previousPageDeleted() {
            this.$emit('previousPageDeleted');
        },
        nextPageDeleted() {
            this.$emit('nextPageDeleted');
        },
        nextPageSelected() {
            this.$emit('nextPageSelected');
        },
        selected(id, type) {
            this.$emit('selectCandidate', id, type);
        },
        notSelected(id, type) {
            this.$emit('notSelectCandidate', id, type);
        },
        onDisplayNew() {
            this.$emit('onDisplayNew');
        },
        onDisplaySelected() {
            this.$emit('onDisplaySelected');
        },
        onDisplayOut() {
            this.$emit('onDisplayOut');
        },
        showModal(candidate) {
            this.$emit('showModal', candidate);
        }
    },    
    mounted() {
        $(document).on("click", "#pills-tab-candidatos > li", function() { 
            $("#marcarTodos").prop('checked', false)
            $("#filters2TabContent > div.tab-pane.fade").removeClass('active').removeClass('show')
            $($(this).find("a").attr("href")).addClass('active').addClass('show')
        })
    },
}
</script>

<style>

</style>

<template>
  <div class="alert alert-danger">
    Usuário não autorizado a acessar o Humanflow, por favor entre em contato com o adminstrador da plataforma, ou envie um e-mail para
    <a href="mailto:fluig@innovareti.com.br">fluig@innovareti.com.br</a> que tentaremos lhe ajudar.
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  methods: {},
  components: {}
};
</script>

<style>
</style>


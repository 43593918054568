<template>
    <div>
        <job-detail :job_id="job_id" :jobObj="jobObj"></job-detail>
        <job-recruitment-candidate-detail 
            :candidate="jobCandidate" 
            :iterations='iterations' 
            @sendMessage="sendMessage" 
            @newiteration="newiteration">
            </job-recruitment-candidate-detail>            
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ $t('label.triagem') }}</h3>
                    <div class="row">                                 
                        <filter-candidates 
                            :objectivesOptions="objectivesOptions" 
                            :areasOptions="areasOptions" 
                            :graduationsOptions="graduationsOptions"
                            :habilitiesOptions="habilitiesOptions" 
                            :languagesOptions="languagesOptions"
                            :experienceCompaniesOptions="experienceCompaniesOptions"
                            :experiencePositionsOptions="experiencePositionsOptions"
                            @filterCandidates="filterCandidates"
                            :key="'filter-candidates-' + currentId"
                            >
                        </filter-candidates>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="float-right mb-4">

                                        <button class="btn edit-btn" @click="loadRecruitment"><i class="fa fa-refresh"></i></button>

                                        <button class="btn edit-btn" type="button" data-toggle="collapse" data-target-id="#informacoes" aria-expanded="false" aria-controls="informacoes">{{ $t('label.informacoes') }}</button>

                                        <button class="btn edit-btn" type="button" @click="openJobDetailModal">{{ $t('label.detalhesDaVaga') }}</button>

                                        <button type="button" class="btn edit-btn" @click="openLink" v-if="jobObj.public_link != ''">{{ $t('label.linkPublico') }}</button>

                                        <button 
                                        v-if=" $store.getters.user.isRecruiter"
                                        class="btn select-btn color-white" type="button" :disabled="checkedItens.length <= 0 && totalSelected <= 0" @click=" $router.push('/selection/view/' + job_id); changeStatus() ">{{ $t('label.selecao') }} <i class="fa fa-forward"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="informacoes">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{$t('label.tituloDaVaga')}}</th>
                                            <th scope="col">{{$t('label.vagasDisponiveis')}}</th>
                                            <th scope="col">{{$t('label.NomeDaEmpresa')}}</th>
                                            <th scope="col">{{$t('label.questionario')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="jobObj">
                                        <tr v-if="jobObj.role_name != ''">
                                            <th scope="row">{{ jobObj.role_name }}</th>
                                            <td>{{ jobObj.quantity }} {{$t('label.vaga')}}{s}</td>
                                            <td>{{ jobObj.company ? jobObj.company.name : '' }}</td>
                                            <td>{{ jobObj.questionnaire_id ? questionnaire.name : 'Não Possui' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-12 text-center">
                                    <span class="title-triagem"><i class="fa fa-user"></i> {{ $t('label.candidatos') }} <span>({{ totalPending + totalSelected + totalDeleted }})</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group float-left mr-3">
                                        <div class="input-group">
                                            <div class="form-check-inline form-check">
                                                <label for="marcarTodos" class="form-check-label mr-3">
                                                    <input type="checkbox" id="marcarTodos" v-model="checkalldisplayed" name="marcarTodos" value="option1" class="form-check-input">
                                                    <span class="checkmark"></span>{{ $t('label.marcarTodosCandidatos') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown float-left">
                                        <button
                                        v-if=" $store.getters.user.isRecruiter" 
                                        class="btn drop-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ $t('label.moverPara') }}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item font-14 cursor-pointer" @click="actionChoosen('pending')"><i class="fa fa-arrow-right"></i> {{ $t('label.aplicados') }}</a>
                                            <a class="dropdown-item font-14 cursor-pointer" @click="actionChoosen('selected')"><i class="fa fa-check"></i> {{ $t('label.selecionados') }}</a>
                                            <a class="dropdown-item font-14 cursor-pointer" @click="actionChoosen('out')"><i class="fa fa-times"></i> {{ $t('label.desclassificados') }}</a>
                                        </div>                                           
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <candidate-list
                                        :candidate_list="candidatesList" 
                                        :selectedCandidate="selectedCandidatesList" 
                                        :deletedCandidate="deletedCandidatesList"
                                        :selectPending='checkalldisplayed && displayedId =="candidate"' 
                                        :selectDeleted="checkalldisplayed && displayedId =='out'"
                                        :selectSelected="(checkalldisplayed && displayedId =='selected')" 
                                        @notSelectCandidate="notSelectCandidate"
                                        @selectCandidate="selectCandidate" 
                                        @onDisplayNew="onDisplayNew" 
                                        @onDisplaySelected='onDisplaySelected'
                                        @onDisplayOut="onDisplayOut" 
                                        @showModal="showModal" 
                                        :pagesPending="pagesPending" 
                                        :currentPagePending="currentPagePending"
                                        :totalPending="totalPending" 
                                        @changePagePending="changePagePending" 
                                        @previousPagePending="previousPagePending"
                                        @nextPagePending="nextPagePending" 
                                        :loadingPending="loadingPending"
                                        :pagesSelected="pagesSelected" 
                                        :currentPageSelected="currentPageSelected"
                                        :totalSelected="totalSelected" 
                                        @changePageSelected="changePageSelected"    
                                        @previousPageSelected="previousPageSelected"
                                        @nextPageSelected="nextPageSelected" 
                                        :loadingSelected="loadingSelected"
                                        :pagesDeleted="pagesDeleted" 
                                        :currentPageDeleted="currentPageDeleted"
                                        :totalDeleted="totalDeleted" 
                                        @changePageDeleted="changePageDeleted" 
                                        @previousPageDeleted="previousPageDeleted"
                                        @nextPageDeleted="nextPageDeleted" 
                                        :loadingDeleted="loadingDeleted">
                                    </candidate-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn include-btn p-1 ml-3 float-right" @click="() => $router.go(-1)" >  <i class="fa fa-times width-23-px"></i>{{ $t('label.voltar') }}</button>
                    </div>                            
                </div>
            </div>

        </div>
    </div>        
    
</template>

<script>
import JobDetail from '../JobRecruitment/JobDetail'
import JobRecruitmentCandidateDetail from '../JobRecruitment/JobRecruitmentCandidateDetail'
import FilterCandidates from '../Filters/FilterCandidates'
import CandidateList from '../Candidates/CandidateList'

export default {
    components: {JobDetail, JobRecruitmentCandidateDetail, FilterCandidates, CandidateList},
    props: [],
    data: () => {
        return {
            currentId: new Date(),
            loadingPending: false,
            loadingBlacklist: false,
            loadingOfflimits: false,
            loadingSimultaneos: false,
            loadingSelected: false,
            loadingDeleted: false,

            pagesPending: 0,
            pagesBlacklist: 0,
            pagesOfflimits: 0,
            pagesSimultaneos: 0,
            pagesSelected: 0,
            pagesDeleted: 0,

            currentPagePending: 0,
            currentPageBlacklist: 0,
            currentPageOfflimits: 0,
            currentPageSimultaneos: 0,
            currentPageSelected: 0,
            currentPageDeleted: 0,

            totalPending: 0,
            totalBlacklist: 0,
            totalOfflimits: 0,
            totalSimultaneos: 0,
            totalSelected: 0,
            totalDeleted: 0,

            pendingParams: {},
            selectedParams: {},
            deletedParams: {},
            questionnaire: {},

            blacklist: '',
            selectedCandidates: '',

            jobObj: {
                company: {
                    about: '',
                    name: ''
                },
                approver: {
                    id: 0,
                    name: '',
                },
                cost_center: {
                    department_name: ''
                },
                approved_at: '',
                approver_observation: '',
                role_name: ''
            },
            job_id: '',
            endPoint: {
                candidate: "/jobCandidate/",
                jobRoles: "/job/",
                iterationEndPoint: '/jobCandidateStageIteration/',
                messageEndPoint: '/message/',
                filterEndPoint: '/filters/',
                questionnaires: '/questionnaire/'
            },
            candidatesList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            blacklistList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            offLimitsList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            simultaneousList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            selectedCandidatesList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            deletedCandidatesList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            checkedItens: [],
            checkalldisplayed: false,
            displayedLength: 0,
            displayedId: 'candidate',
            staredChoosen: '',
            objectivesOptions: [""],
            areasOptions: [""],
            graduationsOptions: [""],
            habilitiesOptions: [""],
            languagesOptions: [""],
            experienceCompaniesOptions: [""],
            experiencePositionsOptions: [""],
            jobCandidate: {
                candidate: {
                    fullName: ''
                }
            },
            iterations: [],
            type: {
                blacklist: 'blacklist',
                offlimits: 'offlimits',
                simultaneous: 'simultaneos'
            }
        }
    },
    computed: {
        url_JOB() {
            return this.$store.getters.apiUrl + this.endPoint.jobRoles
        },
        url_CANDIDATE() {
            return this.$store.getters.apiUrl + this.endPoint.candidate
        },
        url_iterationS() {
            return this.$store.getters.apiUrl + this.endPoint.iterationEndPoint
        },
        url_MESSAGE() {
            return this.$store.getters.apiUrl + this.endPoint.messageEndPoint
        },
        url_FILTERS() {
            return this.$store.getters.apiUrl + '/job/' + this.$route.params.id + this.endPoint.filterEndPoint
        }
    },
    mounted: function () {

        this.forceTabToggle()
        $("#humanflowApp").on('click', "button[data-toggle='collapse']", function() {
            
            let elem = $(this).attr('data-target-id')
            if ($(elem).hasClass('show')) { 
                $(elem).removeClass('show');
            }
            else {
                $(elem).addClass('show');
            }
        });
        

        this.loadRecruitment()
    },
    methods: {
        loadRecruitment () {

            this.currentId = new Date();
            this.$('.carousel').carousel();

            this.pendingParams = {
                'job_id': this.$route.params.id,
                'status': 1,
                'with': [
                    'stages',
                    'platform',
                    'candidate',
                    'candidate.skills',
                    'candidate.courses',
                    'candidate.habilities',
                    'candidate.languages',
                    'candidate.graduations',
                    'candidate.experiences',
                    'candidate.networks',
                    'cost_center'
                ]
            };

            this.selectedParams = {
                'job_id': this.$route.params.id,
                'status': 2,
                'with': [
                    'stages',
                    'platform',
                    'candidate',
                    'candidate.skills',
                    'candidate.courses',
                    'candidate.habilities',
                    'candidate.languages',
                    'candidate.graduations',
                    'candidate.experiences',
                    'candidate.networks',
                    'cost_center'
                    
                ]
            };

            this.deletedParams = {
                'job_id': this.$route.params.id,
                'status': 3,
                'with': [
                    'stages',
                    'platform',
                    'candidate',
                    'candidate.skills',
                    'candidate.courses',
                    'candidate.habilities',
                    'candidate.languages',
                    'candidate.graduations',
                    'candidate.experiences',
                    'candidate.networks',
                    'cost_center'
                ]
            };

            this.blacklistParams = {
                'job_id': this.$route.params.id,
                'status': 4,
                'with': [
                    'stages',
                    'platform',
                    'candidate',
                    'candidate.skills',
                    'candidate.courses',
                    'candidate.habilities',
                    'candidate.languages',
                    'candidate.graduations',
                    'candidate.experiences',
                    'candidate.networks',
                    'cost_center'
                ]
            };

            this.offlimitsParams = {
                'job_id': this.$route.params.id,
                'status': 5,
                'with': [
                    'stages',
                    'platform',
                    'candidate',
                    'candidate.skills',
                    'candidate.courses',
                    'candidate.habilities',
                    'candidate.languages',
                    'candidate.graduations',
                    'candidate.experiences',
                    'candidate.networks',
                    'cost_center'
                ]
            };

            this.simultaneosParams = {
                'job_id': this.$route.params.id,
                'status': 6,
                'with': [
                    'stages',
                    'platform',
                    'candidate',
                    'candidate.skills',
                    'candidate.courses',
                    'candidate.habilities',
                    'candidate.languages',
                    'candidate.graduations',
                    'candidate.experiences',
                    'candidate.networks',
                    'cost_center'
                ]
            };

            this.loadJob();
            this.loadCandidatesPending();
            // this.loadCandidatesBlacklist();
            // this.loadCandidatesOfflimits();
            // this.loadCandidatesSimultaneos();
            this.loadCandidatesSelected();
            this.loadCandidatesDeleted();
        },
        forceTabToggle() {
            $(".nav-item .nav-link").click(function() {
              $(".nav-item .nav-link").each(function() {
                $(this).removeClass("active show");
              });
              $(this).addClass("active show");
            });
        },
        actionChoosen: function (action) {

            let self = this

            if (this.checkedItens.length !== 0) {
                switch (action) {
                    case 'selected':
                        this.onMovetoSelected();
                        break;
                    case 'out':
                        this.onMovetoOut();
                        break;
                    case 'blacklist':
                        this.onMoveToBlackList();
                        break;
                    case 'offlimits':
                        this.onMoveToOffLimits();
                        break;
                    case 'pending':
                        this.onMoveToPending();
                        break;
                }
            } else {
                self.$message('warning', self.$t('label.mensagem'), self.$t('label.selecioneAoMenosUmRegistro')+".");
            }

        },
        openLink() {
            window.open(this.jobObj.public_link);
        },
        changePagePending(page) {
            this.currentPagePending = page
            this.pendingParams.page = this.currentPagePending
            this.loadCandidatesPending()
        },
        previousPagePending() {

            let self = this
            if (this.currentPagePending != 1) {
                this.currentPagePending = this.currentPagePending - 1
                this.pendingParams.page = this.currentPagePending
                this.loadCandidatesPending()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.primeiraPagina'));
            }
        },
        nextPagePending() {
            let self = this
            if (this.currentPagePending < this.pagesPending) {

                this.currentPagePending = this.currentPagePending + 1
                this.pendingParams.page = this.currentPagePending
                this.loadCandidatesPending()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.ultimaPagina'));
            }
        },
        changePageSelected(page) {
            this.currentPageSelected = page
            this.selectedParams.page = this.currentPageSelected
            this.loadCandidatesSelected()
        },
        previousPageSelected() {
            let self = this
            if (this.currentPageSelected != 1) {
                this.currentPageSelected = this.currentPageSelected - 1
                this.selectedParams.page = this.currentPageSelected
                this.loadCandidatesSelected()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.primeiraPagina'));
            }
        },
        nextPageSelected() {
            let self = this
            if (this.currentPageSelected < this.pagesSelected) {
                this.currentPageSelected = this.currentPageSelected + 1
                this.selectedParams.page = this.currentPageSelected
                this.loadCandidatesSelected()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.ultimaPagina'));
            }
        },
        changePageDeleted(page) {
            this.currentPageDeleted = page
            this.deletedParams.page = this.currentPageDeleted
            this.loadCandidatesDeleted()
        },
        previousPageDeleted() {
            let self = this
            if (this.currentPageDeleted != 1) {
                this.currentPageDeleted = this.currentPageDeleted - 1
                this.deletedParams.page = this.currentPageDeleted
                this.loadCandidatesDeleted()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.primeiraPagina'));
            }
        },
        nextPageDeleted() {
            let self = this
            if (this.currentPageDeleted < this.pagesDeleted) {
                this.currentPageDeleted = this.currentPageDeleted + 1
                this.deletedParams.page = this.currentPageDeleted
                this.loadCandidatesDeleted()
            } else {
                self.message('error', self.$t('label.erro'), self.$t('label.ultimaPagina'));
            }
        },
        changePageBlacklist(page) {
            this.currentPageBlacklist = page
            this.blacklistParams.page = this.currentPageBlacklist
            this.loadCandidatesBlacklist()
        },
        previousPageBlacklist() {
            let self = this
            if (this.currentPageBlacklist != 1) {

                this.currentPageBlacklist = this.currentPageBlacklist - 1
                this.blacklistParams.page = this.currentPageBlacklist
                this.loadCandidatesBlacklist()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.primeiraPagina'));
            }
        },
        nextPageBlacklist() {
            let self = this
            if (this.currentPageBlacklist < this.pagesBlacklist) {

                this.currentPageBlacklist = this.currentPageBlacklist + 1
                this.blacklistParams.page = this.currentPageBlacklist
                this.loadCandidatesBlacklist()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.ultimaPagina'));
            }
        },
        changePageOfflimits(page) {
            this.currentPageOfflimits = page
            this.offlimitsParams.page = this.currentPageOfflimits
            this.loadCandidatesOfflimits()
        },
        previousPageOfflimits() {
            let self = this
            if (this.currentPageOfflimits != 1) {

                this.currentPageOfflimits = this.currentPageOfflimits - 1
                this.offlimitsParams.page = this.currentPageOfflimits
                this.loadCandidatesOfflimits()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.primeiraPagina'));
            }
        },
        nextPageOfflimits() {
            let self = this
            if (this.currentPageOfflimits < this.pagesOfflimits) {

                this.currentPageOfflimits = this.currentPageOfflimits + 1
                this.offlimitsParams.page = this.currentPageOfflimits
                this.loadCandidatesOfflimits()
            } else {
                self.message('error', self.$t('label.erro'), self.$t('label.ultimaPagina'));
            }
        },
        changePageSimultaneos(page) {
            this.currentPageSimultaneos = page
            this.simultaneosParams.page = this.currentPageSimultaneos
            this.loadCandidatesSimultaneos()
        },
        previousPageSimultaneos() {
            let self = this
            if (this.currentPageSimultaneos != 1) {

                this.currentPageSimultaneos = this.currentPageSimultaneos - 1
                this.simultaneosParams.page = this.currentPageSimultaneos
                this.loadCandidatesSimultaneos()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.primeiraPagina'));
            }
        },
        nextPageSimultaneos() {
            let self = this
            if (this.currentPageSimultaneos < this.pagesSimultaneos) {

                this.currentPageSimultaneos = this.currentPageSimultaneos + 1
                this.simultaneosParams.page = this.currentPageSimultaneos
                this.loadCandidatesSimultaneos()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.ultimaPagina'));
            }
        },
        showSelection() {
            let self = this
            if (this.selectedCandidatesList.length > 0) {
                this.$router.push({
                    path: '/selection/view/' + this.job_id
                });
            } else {
                self.$message('warning', self.$t('label.alerta'), self.$t('label.selecioneAoMenosUmCandidatoParaProsseguir'));
            }
        },
        selectCandidate(id, type) {
            this.checkedItens.push({
                'id': id,
                'type': type
            });
        },
        showModal: function (candidate) {
            this.jobCandidate = candidate;
            this.$("#jobRecruitmentCandidateDetail").modal('show');
        },
        notSelectCandidate(id) {
            this.checkedItens.splice(this.checkedItens.findIndex(item => item.id == id), 1);
        },
        normalizeArraySelected(status) {
            this.updateCandidatesStatus(this.checkedItens, status);
            this.checkalldisplayed = false;
            this.checkedItens = [];
        },
        onMovetoSelected() {
            this.normalizeArraySelected(2, "Selecionados", this.selectedCandidatesList);
            this.displayedId = 'selected';
            this.$("#pills-selecionados-tab").click()
        },
        onMovetoOut() {
            this.normalizeArraySelected(3, "Desclassificados", this.deletedCandidatesList);
            this.displayedId = 'out';
            this.$("#pills-desclassificados-tab").click()
        },
        onMoveToBlackList() {
            this.normalizeArraySelected(4, "Blacklist", this.blacklistList);
        },
        onMoveToOffLimits() {
            this.normalizeArraySelected(5, "Offlimits", this.offLimitsList);
        },
        onMoveToPending() {
            this.normalizeArraySelected(1, "Pendentes", this.candidatesList);
            this.displayedId = 'candidate';
            this.$("#pills-aplicados-tab").click()
        },
        onDisplayNew() {
            // this.loadPendingCandidates();
            this.displayedLength = this.candidatesList.length;
            this.displayedId = 'candidate';
            this.checkalldisplayed = false;
        },
        onDisplaySelected() {
            // this.loadSelectedCandidates();
            this.displayedLength = this.selectedCandidatesList.length;
            this.displayedId = 'selected';
            this.checkalldisplayed = false;
        },
        onDisplayOut() {
            this.displayedLength = this.deletedCandidatesList.length;
            this.displayedId = 'out';
            this.checkalldisplayed = false;
        },
        onDisplayBlackList() {
            // this.loadBlackListCandidates();
            this.displayedLength = this.blacklist.length;
            this.displayedId = 'blacklist';
            this.checkalldisplayed = false;
        },
        onDisplayOff() {
            this.displayedLength = this.offLimitsList.length;
            this.displayedId = 'offlimits';
            this.checkalldisplayed = false;
        },
        onDisplaySimult() {
            this.displayedLength = this.simultaneousList.length;
            this.displayedId = 'simultaneous';
            this.checkalldisplayed = false;
        },

        loadJob() {
            let self = this;

            self.job_id = self.$route.params.id; 
            self.$http.get(self.url_JOB + self.job_id, {
                params: {
                    'with': ['company', 'approver', "cost_center", 'benefits']
                }
            })
                .then(response => { 
                    self.jobObj = response.data;
                })
                .then(() =>{
                    self.loadQuestionnaire(self.jobObj.questionnaire_id)
                })
                .catch((error) => { 
                    let errors = (error.response) ? error.response.data :  self.$t('label.ocorreuUmErroAoCarregarInformacoesVaga');

                    if (typeof errors === 'object') {
                        let html = '<ul class="errors">';

                        self.$.each(errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });

                        html += '</ul>';

                        errors = html;
                    }

                    self.$message('error', null, errors);
                });
        },

        updateCandidatesStatus(candidates, status) {
            const self = this;
            self.job_id = self.$route.params.id;

            self.$store.dispatch("tooggleLoadModal");
            self.$http
                .post(self.url_CANDIDATE + 'update/selected', {
                    status: status,
                    candidates: candidates
                })
                .then( () => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$message('success', self.$t('label.atualizadoComSucesso'), self.$t('label.candidatosAtualizadosComSucesso'));
                })
                .then(() => {
                    self.loadAll();
                })
                .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                });
        },
        loadAll() {
            this.loadCandidatesPending();
            this.loadCandidatesSelected();
            this.loadCandidatesDeleted();
        },
        loadCandidatesBlacklist() {
            const self = this;

            self.loadingBlacklist = true;
            self.job_id = self.$route.params.id;

            self.$http
                .get(self.url_CANDIDATE, {
                    params: self.blacklistParams
                })
                .then((response) => {
                    self.blacklistList = response.data.data;

                    self.loadingBlacklist = false;

                    self.pagesBlacklist = response.data.pages;
                    self.currentPageBlacklist = response.data.page + 1;
                    self.totalBlacklist = response.data.count;
                })
                .catch(() => {
                    self.$errorMessage(self.$t('label.ocorreuUmErroCarregarInformacoesCandidatos'));
                });
        },
        loadCandidatesOfflimits() {
            const self = this;

            self.loadingOfflimits = true;
            self.job_id = self.$route.params.id;

            self.$http.get(self.url_CANDIDATE, {
                params: self.offlimitsParams
            })
                .then((response) => {
                    let data = response.data.data;

                    self.offLimitsList = data;

                    self.loadingOfflimits = false;

                    self.pagesOfflimits = response.data.pages
                    self.currentPageOfflimits = response.data.page + 1
                    self.totalOfflimits = response.data.count

                    // self.onDisplayNew();
                })
                .catch((error) => {
                    let errors = (error.response) ? error.response.data : self.$t('label.ocorreuUmErroCarregarInformacoesCandidatos');

                    if (typeof errors === 'object') {
                        let html = '<ul class="errors">';

                        self.$.each(errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });

                        html += '</ul>';

                        errors = html;
                    }

                    self.$message('error', null, errors);
                });
        },
        loadCandidatesSimultaneos() {
            const self = this;

            self.loadingSimultaneos = true;
            self.job_id = self.$route.params.id;

            self.$http.get(self.url_CANDIDATE, {
                params: self.simultaneosParams
            })
                .then((response) => {
                    self.simultaneousList = response.data.data;

                    self.loadingSimultaneos = false;

                    self.pagesSimultaneos = response.data.pages;
                    self.currentPageSimultaneos = response.data.page + 1;
                    self.totalSimultaneos = response.data.count;
                })
                .catch((error) => {
                    let errors = (error.response) ? error.response.data :  self.$t('label.ocorreuUmErroCarregarInformacoesCandidatos');

                    if (typeof errors === 'object') {
                        let html = '<ul class="errors">';

                        self.$.each(errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });

                        html += '</ul>';

                        errors = html;
                    }

                    self.$message('error', null, errors);
                });
        },
        loadCandidatesDeleted() {
            const self = this;

            self.loadingDeleted = true;
            self.job_id = self.$route.params.id;

            self.$http.get(self.url_CANDIDATE, {
                params: self.deletedParams
            })
                .then((response) => {
                    let data = response.data.data;

                    self.deletedCandidatesList = data;

                    self.loadingDeleted = false;

                    self.pagesDeleted = response.data.pages
                    self.currentPageDeleted = response.data.page + 1
                    self.totalDeleted = response.data.count

                })
                .catch((error) => {
                    let errors = (error.response) ? error.response.data :  self.$t('label.ocorreuUmErroCarregarInformacoesCandidatos');

                    if (typeof errors === 'object') {
                        let html = '<ul class="errors">';

                        self.$.each(errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });

                        html += '</ul>';

                        errors = html;
                    }

                    self.$message('error', null, errors);
                });
        },
        loadCandidatesSelected() {

            this.loadingSelected = true

            const self = this;

            self.job_id = self.$route.params.id;

            self.$http.get(self.url_CANDIDATE, {
                params: self.selectedParams
            })
                .then((response) => {
                    let data = response.data.data;

                    self.selectedCandidatesList = data;

                    self.loadingSelected = false;

                    self.pagesSelected = response.data.pages
                    self.currentPageSelected = response.data.page + 1
                    self.totalSelected = response.data.count

                })
                .catch((error) => {
                    let errors = (error.response) ? error.response.data :  self.$t('label.ocorreuUmErroCarregarInformacoesCandidatos');

                    if (typeof errors === 'object') {
                        let html = '<ul class="errors">';

                        self.$.each(errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });

                        html += '</ul>';

                        errors = html;
                    }

                    self.$message('error', null, errors);
                });
        },
        loadCandidatesPending() {
            const self = this;
            self.changeloadingPending(true);

            self.job_id = self.$route.params.id;

            let params = {};
            params = {
                ...self.pendingParams, ...{
                    'job_id': self.job_id,
                    'status': 1,
                    'page': self.pendingParams.page,
                    'with': [
                        'stages',
                        'platform',
                        'candidate',
                        'candidate.skills',
                        'candidate.courses',
                        'candidate.habilities',
                        'candidate.languages',
                        'candidate.graduations',
                        'candidate.experiences',
                        'candidate.networks'
                    ]

                }
            };

            self.$http
                .get(self.$store.getters.apiUrl + '/jobCandidate/applieds', {
                    'params': params
                })
                .then((response) => {
                    self.candidatesList = response.data.data;
                    self.changeloadingPending(false);
                    self.pagesPending = response.data.last_page;
                    self.currentPagePending = response.data.current_page;
                    self.totalPending = response.data.total
                })
                .catch((error) => {
                    self.$errorMessage(error);
                });
        },
        loadSelectedCandidates() {
            const self = this;
            self.changeloadingSelected(true);

            self.job_id = self.$route.params.id;
            self.params = {
                'job_id': self.job_id,
                'status': 2,
                'page': self.selectedParams.page
            };

            self.$http
                .get(self.url_CANDIDATE, {
                    'params': self.params
                })
                .then((response) => {
                    self.selectedCandidatesList = response.data.data;
                    self.changeloadingSelected(false);
                    self.pagesSelected = response.data.pages;
                    self.currentPageSelected = response.data.page + 1;

                    self.totalSelected = response.data.count;
                })
                .catch((error) => {
                    self.$errorMessage(error);
                });
        },

        loadDeletedCandidates() {
            const self = this;
            self.changeloadingDeleted(true);

            self.job_id = self.$route.params.id;
            self.params = {
                'job_id': self.job_id,
                'status': 3,
                'page': self.deletedParams.page
            }

            self.$http.get(self.url_CANDIDATE, {
                'params': self.params
            })
                .then((response) => {

                    let data = response.data.data;

                    self.deletedCandidates = data;
                    self.changeloadingDeleted(false);
                    self.pagesDeleted = response.data.pages
                    self.currentPageDeleted = response.data.page + 1
                    self.totalDeleted = response.data.count
                })
                .catch((error) => {
                    let errors = (error.response) ? error.response.data :  self.$t('label.ocorreuUmErroCarregarInformacoesCandidatosDesclassificados');

                    if (typeof errors === 'object') {

                        self.$http
                            .get(self.url_CANDIDATE, {
                                'params': self.params
                            })
                            .then((response) => {
                                self.deletedCandidates = response.data.data;
                                self.changeloadingDeleted(false);
                                self.pagesDeleted = response.data.pages;
                                self.currentPageDeleted = response.data.page + 1;
                                self.totalDeleted = response.data.count;
                            })
                            .catch((error) => {
                                self.$errorMessage(error);
                            });
                    }
                })
        },
        loadBlackListCandidates() {
            const self = this;
            self.changeloadingBlackList(true);

            self.job_id = self.$route.params.id;
            self.params = {
                'job_id': self.job_id,
                'status': 4,
                'page': self.blackListParams.page,
                'with': [
                    'stages',
                    'platform',
                    'candidate',
                    'candidate.skills',
                    'candidate.courses',
                    'candidate.habilities',
                    'candidate.languages',
                    'candidate.graduations',
                    'candidate.experiences',
                    'candidate.networks'
                ]
            };

            self.$http
                .get(self.url_CANDIDATE, {
                    'params': self.params
                })
                .then((response) => {
                    self.candidatesList = response.data.data;
                    self.changeloadingBlackList(false);
                    self.pagesBlackList = response.data.pages;
                    self.currentPageBlackList = response.data.page + 1;
                })
                .catch((error) => {
                    self.$errorMessage(error);
                });
        },
        changeloadingPending: function (value) {
            this.loadingPending = value;
        },
        changeloadingSelected: function (value) {
            this.loadingSelected = value;
        },
        changeloadingDeleted: function (value) {
            this.loadingDeleted = value;
        },
        changeloadingBlackList: function (value) {
            this.loadingBlackList = value;
        },
        sendMessage: function (header, txt, id) {
            const self = this;
            self.$http
                .post(self.url_MESSAGE +
                    "role/" + self.job_id +
                    "/candidate/" + id, {
                        header: header,
                        text: txt
                    })
                .then( () => {
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.mensagemEnviadaComSucesso'));
                })
                .catch((error) => {
                    self.$errorMessage(error)
                });
        },
        newiteration: function (txt, id) {
            let self = this;

            self.$http
                .post(self.url_iterationS, {
                    'job_candidate_id': id,
                    'content': txt
                })
                .then((response) => {
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.ok'));
                    self.iterations.splice(0, 0, response.data.data);
                })
                .catch((error) => {
                    self.$errorMessage(error);
                });
        },
        filterCandidates(params) {
            this.pendingParams = JSON.parse('{"' + decodeURI(params.replace(/&/g, "\",\"").replace(/=/g, "\":\"")) + '"}');
            this.currentPagePending = 1;
            this.pendingParams.page = this.currentPagePending;
            this.loadCandidatesPending();
        },
        openJobDetailModal() {
            this.$('#jobDetailModal').modal('show');
        },
        goBack() {
            this.$router.push('/jobRoles')
        },
        changeStatus() {
            const self = this;
            const api = self.$store.getters.apiUrl + '/job/' + self.$route.params.id;
             self.$http
                .put(api,{
                    'status': 3, 
                })
                .then( () => {
                    if(self.$t){
                        self.$message('success', self.$t('label.sucesso'), self.$t('label.sucesso'));
                    }
                })
                .catch((error) => {
                    self.$errorMessage(error)
                });
        },
        loadQuestionnaire(id) {
            const self = this
            let api = self.$store.getters.apiUrl + self.endPoint.questionnaires
   
            if(id){
                api += id   
            }
            self.$http.get(api)
            .then((response)=>{
                self.questionnaire = response.data
            })
            .catch((error) =>{
                self.$errorMessage(error)
            });

        }
    },
    filters: {
        normalizeStatus: function (status) {
            let self = this
            switch (status) {
                case 'finished':
                    status = self.$t('label.finalizado');
                    break;
                case 'inedition':
                    status = self.$t('label.emEdicao');
                    break;
                case 'inprogress':
                    status = self.$t('label.emRecrutamento');
                    break;
                case 'canceled':
                    status = self.$t('label.cancelado');
                    break;
            }

            return status;
        }
    }
}
</script>

<style scoped>

.btn {
    margin-right: 5px;
}

</style>
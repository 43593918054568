<template>
  <div>
    <div class="col-xl-12">
        <div class="card text-dark-blue bg-flat-gray">
            <div class="card-body">
                <h3 class="card-title">{{title}}</h3>
                <form>
                    <div class="row">
                        <div class="col-md-8">
                            <filter-jobs-text
                                @onSearchText="onSearchText" 
                                :onlyApprover="onlyApprover">
                            </filter-jobs-text>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-12">                        
                        <loading-human-flow :loading='loading'></loading-human-flow>
                    </div>
                </div>
                <div class="row" v-if="!loading">
                    <template v-if="jobRoles.length == 0">
                        <div class="col-md-12" v-if="!loading">
                            <div class="text-center">
                                <span><strong>{{ $t('label.nenhumResultadoComFiltroInformado') }}</strong></span>
                            </div>
                        </div>
                    </template>
                    <template v-else v-for='job in jobRoles'>                        
                        <job-roles-list :jobsObj="job" :onlyMyJobs="omj" :onlyApprover="onlyApprover" @eventListJobs="loadJobList" :key="job.id" ></job-roles-list>                          
                    </template>
                </div>
                <div class="row" v-show="pages.length > 1">
                    <div class="col-md-12">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination pull-right">
                                <li class="page-item" v-show="currentPage > 1"><button class="page-link" @click="changePage(1)"><i class="fa fa-backward"></i></button></li>
                                <li class="page-item" v-show="currentPage > 1" title="Página anterior"><button class="page-link" @click="previousPage"><i class="fa fa-step-backward"></i></button></li>                
                                <template v-for="page in pages">
                                    <li class="page-item" :class=" {'active': currentPage == page.pageNumber} " :key="page.pageNumber">
                                    <button class="page-link" @click="changePage(page.pageNumber)">{{page.pageNumber}}</button>
                                    </li>
                                </template>              
                                <li class="page-item" v-show="currentPage < pages.length" title="Próxima página"><button class="page-link" @click="nextPage"><i class="fa fa-step-forward"></i></button></li>
                                <li class="page-item" v-show="currentPage < pages.length"><button class="page-link" @click="changePage(pages.length)"><i class="fa fa-forward"></i></button></li>  
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>  
</template>

<script>
import FilterJobsText from '../Filters/FilterJobsText'
import LoadingHumanFlow from '../LoadingHumanFlow'
import JobRolesList from '../JobRoles/JobRolesList'

export default {
  data: () => {
    return {
      // this.$route.params.id
      loading: false,
      omj: true,
      jobRoles: [],
      jobBenefits: [],
      job: {},
      endPoint: "/job",
      searchString: '',
      onlyApprover: true,
      currentPage: 0,
      numberOfPages: 0,
      orderField: 'created_at',
      orderDirection: true
    };
  },
  computed: {
    url: function () {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    title: function () {
      return this.onlyApprover ?  this.$t('label.vagasPendentesAprovacao') : this.$t('label.vagas');
    },
    pages: function () {

      let pages = []

      if (this.numberOfPages > 5 && this.currentPage > 1) {

        for (let i = this.currentPage - 1; i <= this.currentPage + 4; i++) {

          if (i <= this.numberOfPages) {

            pages.push({
              pageNumber: i
            });
          }

        }

      } else if (this.numberOfPages > 5) {

        for (let i = 0; i < 5; i++) {

          pages.push({
            pageNumber: i + 1
          });

        }
      } else {
        for (let i = 0; i < this.numberOfPages; i++) {

          pages.push({
            pageNumber: i + 1
          });

        }
      }

      return pages;
    }
  },
  mounted: function () {

    let self = this

    this.$forceUpdate();

    this.onlyApprover = self.$route.meta.onlyApprover

    // this.loadFilteredJobListByTabs(8);
    this.loadJobList();

  },
  methods: {
    orderJobs: function () {
      this.orderDirection = !this.orderDirection;
      this.loadFilteredJobList(this.searchString, false, this.orderField, this.orderDirection)
    },
    previousPage() {
      this.currentPage = this.currentPage - 1
      this.loadFilteredJobList(this.searchString, true)
    },
    nextPage() {
      this.currentPage = this.currentPage + 1
      this.loadFilteredJobList(this.searchString, true)
    },
    changePage: function (page) {
      this.currentPage = page
      this.loadFilteredJobList(this.searchString, true)
    },

    onSearchText(textQueryString) {
      this.searchString = textQueryString
      this.loadFilteredJobList(textQueryString);
    },

    loadFilteredJobList: function (cSearchParam, changePage = false, orderField/*, orderDirection*/) {

      this.changeLoading(true);
      let self = this;

      var params = {
        'role_name': cSearchParam,
        'limit': 16,
        // 'recruiter_id': self.$store.getters.user.id,
        'with': ['candidates', 'recruiter'],
        'status': [8],
        'orderByDesc': orderField
      }

      if (changePage) {
        params.page = this.currentPage
      }

      self.$http
        .get(self.url, {
          params
        })
        .then(response => {
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1
          self.numberOfPages = response.data.pages

          self.changeLoading(false);
        })
        .catch(function (error) {
          self.$errorMessage(error)
        });
    },

    loadJobList: function () {
      let self = this;

      this.changeLoading(true);

      let params = {
        // 'recruiter_id': this.$store.getters.user.id,            
        'with': ['candidates', 'approver', 'recruiter'],
        'limit': 16,
        'status': [8],
        'approver_id': self.$store.getters.user.id
      }

      this.$http
        .get(self.url, {
          params: params
        })
        .then(response => {

          this.jobRoles = response.data.data;
          this.currentPage = response.data.page + 1
          this.numberOfPages = response.data.pages

          this.changeLoading(false);
        })
        .catch(function (error) {
          self.$errorMessage(error)
        });
    },
    changeLoading: function (value) {
      this.loading = value;
    },
    loadJob(id) {
      let self = this;
      self.$http.get(this.url + id).then(response => {
        self.job.id = response.data.id;
        self.job.status = response.data.status;
        self.job.profile = response.data.profile;
        self.job.market_info = response.data.market_info;
        self.job.desired_age = response.data.desired_age;
        self.job.desired_sex = response.data.desired_sex;
        self.job.internal_candidate = response.data.internal_candidate;
        self.job.initial_date = response.data.initial_date;
        self.job.end_date = response.data.end_date;
        self.job.business_segment = response.data.business_segment;
        self.job.complements = response.data.complements;
        self.job.company_name = response.data.company_name;
        self.job.role_name = response.data.role_name;
        self.job.role_description = response.data.role_description;
        self.job.role_requirements = response.data.role_requirements;
        self.job.overreach_info = response.data.overreach_info;
        self.job.position_salary = response.data.position_salary;
        self.job.position_type = response.data.position_type;
        self.job.contract_type = response.data.contract_type;
      });
    },

    loadFilteredJobListByTabs: function (iStatus, changePage = false) {

      this.changeLoading(true);
      let self = this;

      var params = {
        // 'status': iStatus,
        'limit': 16,
        'recruiter_id': self.$store.getters.user.id,
        'with': ['candidates', 'recruiter'],
        'status': [8],
        'orderByDesc': this.orderField
      };

      if (changePage) {
        params.page = this.currentPage
      }

      self.$http
        .get(self.url, {
          params
        })
        .then(response => {
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1
          self.numberOfPages = response.data.pages

          self.changeLoading(false);
        })
        .catch(function (error) {
          self.$errorMessage(error)
        });
    },

  },
  components: {FilterJobsText, LoadingHumanFlow, JobRolesList}
}
</script>
import Vue from 'vue'
import App from './App.vue'
import $ from 'jquery'
import axios from 'axios'
import router from './router'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
// import mainStore from './store'
import { ServerTable } from 'vue-tables-2';
import swal from 'sweetalert';
// import i18n from './i18n'
import VueI18n from 'vue-i18n'
import money from 'v-money'
import VueToastr from '@deveodk/vue-toastr'


import CKEditor from '@ckeditor/ckeditor5-vue';

Vue.use(CKEditor);


import 'selectize/dist/js/selectize.min.js'
import 'selectize/dist/css/selectize.css'
import 'selectize/dist/css/selectize.bootstrap3.css'

import env from './config/env'

Vue.use(VueToastr);


router.beforeEach((to, from, next) => {

  if (to.path.substr(0, 12) == '/login-email') {
    next('/login-email')
    return false;
  } else {

    // alert(to.path)

    if (!store.getters.activated && to.path != '/activation' && to.path != '/license' && to.path != "/notAdmin" && to.path != "/notAllowed" && to.path != "/login") {
      next(false);
      // alert('1');
    } else if (!store.getters.installed && to.path != '/installation' && to.path != '/activation' && to.path != '/license' && to.path != "/notAdmin" && to.path != "/notAllowed" && to.path != "/login") {
      next(false);
      // alert('2');
    } else {
      // alert('3');
      if (to.meta.recruiter && store.getters.user.isRecruiter) {
        next();
      } else if (to.meta.user) {
        next();
      } else if (to.meta.user) {
        next();
      } else {

        if (to.path == "/notAllowed") {
          next();
        }
        else {

          this.$message('error', i18n.t('label.ocorreuUmErro'), i18n.t('label.voceNaoTemPermissaoDeAcesso'));
        }
      }
    }
    // alert('4');
  }

});

router.afterEach((to) => {
  store.commit("updateBreadCrumbs", {
    name: to.name,
    path: to.fullPath
  })

  $(".nav-item .nav-link").click(function () {
    $(".nav-item .nav-link").each(function () {
      $(this).removeClass("active show");
    });
    $(this).addClass("active show");
  });

});

require('./custom')

window.$ = $
// window.jQuery = jQuery
window.$ = window.jQuery = $

//moment
// import '../node_modules/moment/moment'
// window.moment = require('moment');

/*css import*/
require("./assets/css/stylish-tooltip.css")
import 'bootstrap';
import './assets/css/custom-bootstrap.css';
import 'font-awesome/css/font-awesome.min.css'
require("./assets/plugins/switchery/dist/switchery.css")
require("./assets/plugins/switchery/dist/switchery.js")
require("./assets/css/tab-page.css")
require('./assets/css/style.css')
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'
import vueKanban from 'vue-kanban'

require('./assets/css/kanban.css')

import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.use(vueKanban)
//expose axios to global object
window.axios = axios;
window.$http = axios;
Vue.prototype.$http = axios;
Vue.use(money, { precision: 2 })
// window.axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token')


Vue.prototype.$ = $

Vue.config.productionTip = false

Vue.prototype.$message = function (type, titulo, mensagem, close) {

  this.$toastr('add', {
    title: titulo,
    msg: mensagem,
    clickClose: true,
    timeout: close,
    position: 'toast-top-right',
    type: type
  })

}

Vue.prototype.$errorMessage = function (error, customMessage) {

  if (customMessage == null || customMessage == undefined) {
    customMessage = null;
  }

  var errors = (error.response) ? error.response.data : customMessage;

  if (typeof errors === 'object') {
    var html = '<ul class="errors">';

    $.each(errors, function (key, value) {
      html += '<li>' + value[0] + '</li>';
    });

    html += '</ul>';

    errors = html;
  }

  if (errors != null && error != '') {
    this.$message('error', customMessage, errors, 5000);
  }
}

const translation = {
  // count: `${$t('label.mostrando')} {from} ${$t('label.de')} {to} ${$t('label.ate')} {count} ${$t('label.deRegistros')}`,
  // filter: false,
  // filterPlaceholder: $t('label.pesquisarAqui'),
  // limit: $t('label.registros')+':',
  // noResults: $t('label.nenhumRegistroEncontrado'),
  // page: $t('label.pagina')+':'
};

const options = {
  requestFunction: function (data) {

    data.customer_id = store.getters.customer.id;

    return this.$http.get(this.url, {
      params: data
    }).catch(function (e) {
      this.dispatch('error', e);
    }.bind(this));
  },
  texts: translation,
  pagination: {
    chunk: 15,
    dropdown: false,
    nav: 'fixed'
  },
  perPage: 10,
  perPageValues: [10, 25, 50, 100]
};

Vue.use(VueI18n);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(ServerTable, options, false, 'bootstrap3', 'footerPagination');

const store = new Vuex.Store({
  state: {
    setjobsUrl: '',
    errorLogin: '',
    apiUrl: env.api,
    frontUrl: env.frontUrl,
    customer_id: 0,
    mode: 'dev',
    customer: {
      name: '',
      cnpj: ''
    },
    user: {
      image: '',
      name: '',
      code: '',
      login: '',
      id: 0,
      tenantId: '',
      tenantCode: ''
    },
    imagePath: '/images/',
    logoFull: '/logo_full.png',
    logo: '/logo.png',
    imgEs: '/lang/es.png',
    imgEn: '/lang/en.png',
    imgBr: '/lang/br.png',
    // logoLight: '/humanflowwidget/resources/images/logo-light-text.png',
    // logoInnovare: '/humanflowwidget/resources/images/logo-innovare.png',
    // logoFluig: '/portal/resources/images/logo.png',
    applicationRoutes: [
      {
        name: 'home',
        path: '/',
        label: 'route.dashboard',
        class: 'menu-icon fa fa-dashboard',
        children: [],
        show: true,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'vagas',
        path: '/jobRoles',
        label: 'route.vagas',
        class: 'menu-icon fa fa-briefcase',
        children: [],
        show: true,
        permission: {
          user: false, recruiter: true
        }
      },
      {
        name: 'vagas_internas',
        path: '/InternalVacancies',
        label: 'route.vagasInternas',
        class: 'menu-icon fa fa-suitcase',
        children: [],
        show: true,
        permission: {
          user: false, recruiter: true
        }

      },
      {
        name: '',
        path: '/talent_bank',
        label: 'route.talent_bank',
        class: 'menu-icon fa fa-suitcase',
        children: [],
        show: true,
        permission: {
          user: false, recruiter: true
        }

      },
      {
        name: '',
        path: '/cv_bank',
        label: 'route.cv_bank',
        class: 'menu-icon fa fa-suitcase',
        children: [],
        show: true,
        permission: {
          user: false, recruiter: true
        }

      },
      {
        name: 'aprovações',
        path: '/jobRoles/approval',
        label: 'route.aprovacoes',
        class: 'menu-icon fa fa-check',
        children: [],
        show: true,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'reprovações',
        path: '/jobRoles/disapproval',
        label: 'route.reprovacoes',
        class: 'menu-icon fa fa-ban',
        children: [],
        show: true,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'requisitar',
        path: '/jobRequisition',
        label: 'route.requisitarVaga',
        class: 'menu-icon fa fa-pencil-square-o',
        children: [],
        show: true,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'requisitar',
        path: '/jobMyRoles',
        label: 'route.minhasVagas',
        class: 'menu-icon fa fa-briefcase',
        children: [],
        show: true,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'requisitar',
        path: '/jobMyApproval',
        label: 'route.minhasVagasGerenciadas',
        class: 'menu-icon fa fa-briefcase',
        children: [],
        show: true,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'requisitar',
        path: '/profile',
        label: 'route.meuPerfil',
        class: 'menu-icon fa fa-user',
        children: [],
        show: true,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'account',
        path: '/accounts',
        label: 'route.licencas',
        class: 'menu-icon fa fa-users',
        children: [],
        show: true,
        permission: {
          isManager: true
        }
      },
      {
        name: 'geral',
        path: '',
        label: 'route.geral',
        class: 'menu-icon fa fa-cog',
        children: [
          {
            name: 'empresa',
            path: '/companies',
            label: 'route.empresas',
            class: 'fa fa-globe',
            children: [],
            show: true
          },
          {
            name: 'Estabelecimento',
            path: '/branchs',
            label: 'route.estabelecimentos',
            class: 'fa fa-building-o',
            children: [],
            show: true
          },
          {
            name: 'centro_custos',
            path: '/cost-centers',
            label: 'route.centroDeCusto',
            class: 'fa fa-money',
            children: [],
            show: true
          },
          {
            name: 'workload',
            path: '/workload',
            label: 'route.workload',
            class: 'fa fa-clock-o',
            children: [],
            show: true
          },
          {
            name: 'portal',
            path: '/portal',
            label: 'route.portal',
            class: 'fa fa-globe',
            children: [],
            show: true
          },
          {
            name: 'email_templates',
            path: '/email_templates',
            label: 'route.email_templates',
            class: 'fa fa-globe',
            children: [],
            show: true
          },
          // {
          //   name: 'Recrutadores',
          //   path: '/recruiters-manager',
          //   label: 'route.recrutadores',
          //   class: 'fa fa-bullseye',
          //   children: [],
          //   show: true
          // },
          // {
          //   name: 'Usuarios',
          //   path: '/users-manager',
          //   label: 'route.gestoresDeArea',
          //   class: 'fa fa-crosshairs',
          //   children: [],
          //   show: true,
          // },
          // {
          //   name: 'Integracoes',
          //   path: '/integrations',
          //   label: 'route.integracoes',
          //   class: 'fa fa-plug',
          //   children: [],
          //   show: true,
          // },
          {
            name: 'Usuarios',
            path: '/users',
            label: 'route.usuarios',
            class: 'fa fa-users',
            children: [],
            show: true,
            onlyWeb: true,
          }
        ],
        show: true,
        permission: {
          user: false, recruiter: true
        }
      },
      {
        name: 'selection',
        path: '',
        label: 'route.selecao',
        class: 'menu-icon fa fa-users',
        children: [
          {
            name: 'questions',
            path: '/questionnaire',
            label: 'route.questionario',
            class: 'fa fa-file-text-o',
            children: [],
            show: true
          }
        ],
        show: true,
        permission: {
          user: false, recruiter: true
        }
      },
      {
        name: 'vagas_sub',
        path: '',
        label: 'route.vagas',
        class: 'menu-icon fa fa-tasks',
        children: [
          {
            name: 'cargos',
            path: '/role',
            label: 'route.cargos',
            class: 'fa fa-tag',
            children: [],
            show: true
          },
          {
            name: 'templatecargos',
            path: '/roleTemplate',
            label: 'route.templateDeCargos',
            class: 'fa fa-tags',
            children: [],
            show: true
          }
        ],
        show: true,
        permission: {
          user: false, recruiter: true
        }
      },
      //,
      // {
      //     name: 'atracao',
      //     path: '',
      //     label: 'Atração',

      //     children: [
      //         {
      //             name: 'empresaRestrita',
      //             path: '/restricted',
      //             label: 'Empresas Restritas',
      //         },
      //         {
      //             name: 'blacklist',
      //             path: '/blacklist',
      //             label: 'Blacklist',
      //         },                            
      //     ]

      // }            

    ],

    installationRoutes: [
      {
        name: 'dashboard',
        path: '/installation',
        label: 'Dashboard',
        class: 'fa fa-play',
        children: [],
        show: false,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'Admin',
        path: '/notAdmin',
        label: 'Não Admin',
        class: '',
        children: [],
        show: false,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'Fluig',
        path: '/activation',
        label: 'Fluig',
        class: 'fa fa-exclamation',
        children: [],
        show: false,
        permission: {
          user: true, recruiter: true
        }
      },
      {
        name: 'License',
        path: '/license',
        label: 'Licença',
        class: 'fa fa-exclamation',
        children: [],
        show: false,
        permission: {
          user: true, recruiter: true
        }
      },

    ],

    breadcrumb: {},
    contextButtons: [],
    activated: false,
    installed: false,
    loadModal: false
  },
  mutations: {
    setErrorLogin(state, error) {
      state.errorLogin = error;
    },
    changeLang(state, lang) {
      state.user.lang_app = lang;
      i18n.locale = lang;
    },
    initilizeApplication(state, store) {
      store.commit('checkLicense', store);
    },
    checkLicense(state, store) {
      // let slot = 3150

      store.commit('checkActivation', store);
    },
    checkActivation(state, store) {
      const api = state.apiUrl + '/customer/is-actived';

      if (state.mode == 'dev') {
        state.customer.uuid = "123";
        state.customer.tennantCode = "innovare";

        window.$http.post(api, {
          tennantCode: state.customer.tennantCode,
          uuid: state.customer.uuid
        })
          .then(response => {
            if (!response.data) {
              state.activated = true
              router.push('/activation');
            } else {
              if (response.data.active) {
                state.customer = response.data
                state.activated = true
                store.commit('checkInstallation', store)
              } else {
                state.activated = true
                router.push('/activation');
              }
            }
          });
      } else {

        window.$http.post(api, {
          tennantCode: state.customer.tennantCode,
          uuid: state.customer.uuid
        })
          .then(response => {
            if (!response.data) {
              state.activated = true;
              router.push('/activation')
            } else {
              if (response.data.active) {
                state.customer = response.data;
                state.activated = true;
                store.commit('checkInstallation', store);
              } else {
                router.push('/activation');
              }
            }
          });

      }
    },
    checkInstallation(state, store) {
      const api = state.apiUrl + '/customer/is-installed';

      if (state.mode == 'dev') {
        const data = {
          tennantCode: "innovare",
          uuid: "123",
          user_code: 'elton.nicolau'
        };

        window.$http.post(api, data)
          .then(response => {

            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

            if (response.data.installed) {

              state.installed = true;
              store.commit('installed', store);
            } else {
              router.push('/installation');
            }
          });
      } else {
        window.$http.post(api, {
          tennantCode: state.customer.tennantCode,
          uuid: state.customer.uuid,
          user_code: 'elton.nicolau'
        })
          .then(response => {

            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

            if (response.data.installed) {
              // Adding Authentication to URL

              state.installed = true;
              state.activated = true;
              store.commit('installed', store);
            } else {
              router.push('/installation');
            }
          });

      }
    },
    updateContextButtons: (state, buttons) => {
      state.contextButtons = buttons
    },
    updateBreadCrumbs: (state, route) => {
      state.breadcrumb = route;
    },
    installed: (state, store) => {
      state.installed = true;

      window.$http.post(state.apiUrl + '/customer/getUser', {
        user_code: state.user.code
      })
        .then(response => {
          state.user.isRecruiter = response.data.isRecruiter;
          state.user.isManager = response.data.isManager;
          state.user.id = response.data.id;
          state.user.image = response.data.image;
          store.commit('changeLang', response.data.lang_app);

          // não é usuário
          if (state.user.id == '') {
            state.user.isUser = false;
            router.push('/notAllowed');
          } else {
            state.user.isUser = true;
            router.push('/');
            store.commit('activateMenu');
          }
        })
    },
    activateMenu: () => {
      window.setTimeout(function () {
        $('#sidebarnav a').on('click', function () {
          if (!$(this).hasClass("active")) {
            // hide any open menus and remove all other classes
            $("ul", $(this).parents("ul:first")).removeClass("in");
            $("a", $(this).parents("ul:first")).removeClass("active");

            // open our new menu and add the open class
            $(this).next("ul").addClass("in");
            $(this).addClass("active");

          } else if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            $(this).parents("ul:first").removeClass("active");
            $(this).next("ul").removeClass("in");
          }
        })
        $('#sidebarnav >li >a.has-arrow').on('click', function (e) {
          e.preventDefault();
        });
      }, 1500);
    },
    setMode: (state, param) => {

      let mode = param.mode
      let store = param.store

      if (mode == 'dev') {
        state.mode = 'dev';
        state.user = {
          image: '/images/users/elton.jpg',
          name: 'Elton Nicolau',
          code: 'elton.nicolau',
          login: 'elton.nicolau',
          id: '',
          isRecruiter: false,
          isManager: true,
          isUser: true,
          tenantId: 1,
          tenantCode: {
            id: 1
          },
          fluigAdmin: true,
        };
        state.logo = '/images/logo_full.png';
        state.logoLight = '/images/logo.png';
        state.logoFluig = 'http://sandbox.fluigstore.com/portal/resources/images/logo.png';
        state.logoInnovare = '/images/logo-innovare.png';

        store.commit('initilizeApplication', store);
      }
      else if (mode == 'prod') {

        state.mode = 'prod';
        state.apiUrl = env.api;
        state.imagePath = '/humanflowwidget/resources/images/',

          window.$http.get('/api/public/social/user/logged')
            .then(response => {
              let fluigUser = response.data;

              state.user = {
                name: fluigUser.name,
                code: fluigUser.userCode,
                login: fluigUser.userCode,
                email: fluigUser.email,
                id: fluigUser.id,
                isRecruiter: false,
                isManager: false,
                isUser: false,
                tenantId: fluigUser.tenantId,
                tenantCode: 0, //WCMAPI.getTenantCode(),
                image: '/social/api/rest/social/image/profile/' + fluigUser.userCode + '/SMALL_PICTURE',
                fluigAdmin: fluigUser.tenantAdmin,
              };

              state.customer.tenantId = fluigUser.tenantId
              state.customer.tennantCode = fluigUser.tenantId
              state.customer.uuid = fluigUser.tenantUUID
            })
            .then(() => {

              store.commit('initilizeApplication', store);

            })
      } else {

        state.mode = 'web';
        state.apiUrl = env.api;
        state.imagePath = '/images/',
          state.activated = true

        router.push('/login');

      }
    },
    tooggleLoadModal(state) {
      state.loadModal = !state.loadModal;

      $(".nav-item .nav-link").click(function () {
        $(".nav-item .nav-link").each(function () {
          $(this).removeClass("active show");
        });
        $(this).addClass("active show");
      });

      $(".nav-item .nav-link").click(function () {
        $(".nav-item .nav-link").each(function () {
          $(this).removeClass("active show");
        });
        $(this).addClass("active show");
      });



    },
    disableLoadModal(state) {
      state.loadModal = false;
    },
    resetInfoLogged(state) {
      state.user.name = '';
      state.user.code = '';
      state.user.login = '';
      state.user.email = '';
      state.user.id = '';
      state.user.isRecruiter = '';
      state.user.isManager = '';
      state.user.isUser = '';
      state.user.tenantId = '';
      state.user.tenantCode = '';
      state.customer.tenantId = '';
      state.customer.uuid = '';
      state.installed = false;
      state.activated = true;
    },
    setjobsUrl(state, params) {
      state.jobsUrl = params.jobsUrl
    },
    setInfoLogged(state, params) {
      state.user = {
        name: params.user.name,
        code: params.user.code,
        login: params.user.login,
        email: params.user.email,
        id: params.user.id,
        image: params.user.image,
        isRecruiter: params.user.isRecruiter,
        isManager: params.user.isManager,
        isUser: params.user.isUser,
        tenantId: params.user.tenantId,
        tenantCode: params.user.tenantCode,
      }
      store.commit('changeLang', params.user.lang_app);

      state.customer = {
        id: params.tenantId,
        tenantId: params.tenantId,
        uuid: params.tenantUUID
      },
        state.installed = true;
      state.activated = true;
    }
  },
  actions: {
    logout({ commit }, instance) {

      let self = instance

      swal({
        title: self.$t('label.desejaSairHumanflow'),
        icon: "warning",
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t('label.nao'),
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: self.$t('label.sim'),
            value: 'confirm'
          }
        },
      }).then((value) => {

        if (value == 'confirm') {
          localStorage.removeItem("token");
          window.axios.defaults.headers.common['Authorization'] = "";
          commit('resetInfoLogged');
          router.push('/login');
        }
      }).catch(error => {
        self.$errorMessage(error)
      })


    },
    auth({ state, commit, dispatch }, param) {

      commit('setErrorLogin', "");
      dispatch("tooggleLoadModal");
      commit('resetInfoLogged');
      window.$http.post(state.apiUrl + '/login', param)
        .then(response => {
          dispatch("tooggleLoadModal");
          commit('setErrorLogin', "");
          commit('setInfoLogged', response.data);
          commit('setjobsUrl', response.data);

          localStorage.setItem("token", response.data.token);
        })
        .then(() => {
          window.axios.defaults.headers.common['Authorization'] = 'Bearer' + localStorage.getItem('token');
        })
        .then(() => {
          router.push('/');
        })
        .catch(response => {
          dispatch("tooggleLoadModal");
          let error = response.response.data.message;
          commit('setErrorLogin', error);
        });
    },
    setMode({ state, commit }, param) {

      let self = this
      if (param.mode == 'web') {

        state.mode = 'web';
        commit('resetInfoLogged');

        const token = localStorage.getItem("token");

        if (token) {
          window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

          window.$http.post(state.apiUrl + '/me')
            .then(response => {
              if (response.data.status) {
                commit('setInfoLogged', response.data);
                router.push('/');
              }
              else {
                router.push('/login');
              }
            })
            .catch(() => {
              window.axios.defaults.headers.common['Authorization'] = "";
              localStorage.removeItem("token");
              self.$errorMessage({ response: { data: self.$t('label.naoFoiPossivelRealizarLogin') } });
              router.push('/login');
            });
        }
        else {
          if (location.search.split('param=')[1] == undefined) {
            router.push('/login');
          }

        }
      }

    },
    changeLang({ state }, lang) {

      let self = this;

      store.commit('changeLang', lang);

      const api = state.apiUrl + '/user/change_lang';

      if (window.axios.defaults.headers.common['Authorization']) {
        state.activated = false
        window.$http.post(api, {
          lang: lang,
        })
          .then(() => {
            state.activated = true
          })
          .catch(() => {
            if (this.$store.getters.installed == true) {
              self.$message('error', self.$t('label.ocorreuUmErro'), self.$t('label.naoFoiPossivelSalvarOIdioma'));
            }
            state.activated = true
          })
      }
    },
    tooggleLoadModal(context) {
      context.commit("tooggleLoadModal");
    },
    disableLoadModal(context) {
      if ($("#humanflowApp").hasClass('hidden')) {
        $("#pre-loading-init").remove()
        $("#humanflowApp").removeClass('hidden')
      }
      context.commit("disableLoadModal");
    }
  },
  getters: {
    fluigAdmin(state) {
      return state.user.fluigAdmin;
    },
    errorLogin: (state) => {
      return state.errorLogin;
    },
    imagePath: (state) => {
      return state.imagePath;
    },
    apiUrl: (state) => {
      return state.apiUrl;
    },
    customerId: (state) => {
      return state.customer.id;
    },
    logoFull: (state) => {
      return state.imagePath + state.logoFull;
    },
    logo: (state) => {
      return state.imagePath + state.logo;
    },
    imgEs: (state) => {
      return state.imagePath + state.imgEs;
    },
    imgEn: (state) => {
      return state.imagePath + state.imgEn;
    },
    imgBr: (state) => {
      return state.imagePath + state.imgBr;
    },
    logoLight: (state) => {
      return state.imagePath + state.logoLight;
    },
    user: (state) => {
      return state.user;
    },
    logoFluig: (state) => {
      return state.imagePath + state.logoFluig
    },
    mode: (state) => {
      return state.mode
    },
    routes: (state) => {

      if (state.installed && state.user.id != '') {
        return state.applicationRoutes
      }

      return state.installationRoutes
    },
    breadcrumb: (state) => {
      return state.breadcrumb
    },
    contextButtons: (state) => {
      return state.contextButtons
    },
    activated: (state) => {
      return state.activated
    },
    installed: (state) => {
      return state.installed
    },
    customer: (state) => {
      return state.customer
    },
    getLoadModal: state => {
      return state.loadModal;
    },
  },
});

let bus = new Vue();
window.bus = bus;

import messages from './config/translation.js'

const i18n = new VueI18n({
  locale: 'ptBR',
  messages: messages
})


new Vue({
  render: h => h(App),
  i18n,
  router,
  store,
  components: {
    'vueSlider': window['vue-slider-component'],
  },
  data() {
    return {
    }
  },
  computed: {
    routes: function () {
      return this.$store.getters.routes;
    }
  },
  mounted() {

    $('.selectpicker').selectpicker;


    $("#humanflowApp").on('click', '#menuToggle', function () {
      $('body').toggleClass('open');
    });

    $("#humanflowApp").on('click', '.search-trigger', function (event) {
      event.preventDefault();
      event.stopPropagation();
      $('.search-trigger').parent('.header-left').addClass('open');
    });

    $("#humanflowApp").on('click', '.search-close', function (event) {
      event.preventDefault();
      event.stopPropagation();
      $('.search-trigger').parent('.header-left').removeClass('open');
    });


    $("#humanflowApp").on('click', "button[data-toggle='collapse']", function () {
      let elem = $(this).attr('data-target-id')
      $(elem).slideToggle();
    });

    $("#humanflowApp").on('click', ".nav-item .nav-link", function () {
      // $(".nav-item .nav-link").each(function () {
      //   $(this).removeClass("active show");
      // });
      // $(this).addClass("active show");

      // $(".tab-content .tab-pane").each(function () {
      //   $(this).removeClass("active");
      // });

      // $($(this).attr('href')).addClass("active")

    });

  },
  methods: {

  },
  created: function () {
    const self = this;
    //let host = window.location.hostname;

    self.$http.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (parseInt(error.response.status) === 404) {
        self.$router.push({
          path: '/404'
        });
      }
      return Promise.reject(error);
    });

    store.dispatch('setMode', { mode: env.mode, store: store })
    /*
    if (host == '127.0.0.1' || host == 'localhost') {
      store.commit('setMode', { mode: 'dev', store: store });
    }
    else if (host == 'app.humanflow.com.br') {
      store.dispatch('setMode', {
        mode: 'web',
        store: store
      });
    } else {
      store.commit('setMode', { mode: 'prod', store: store });
    }
    */

  }
}).$mount('#humanflowApp')

<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="form-group">
          <div class="user-profile" align="center">
            <img :src="user.image" class="photo" />
          </div>
          <br />
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div></div>
            </div>
          </div>
          <hr />
          <div class="user-profile" align="center">
            <h3 class="user_name_max">{{ user.name }}</h3>
            <p>{{ user.email }}</p>
          </div>
          <div class="col-md-12">
            <hr />
            <div class="col-md-4">
              <label for="password">Foto</label>
              <input type="file" id="image" name="image" class="form-control" />
            </div>
            <div class="col-md-4">
              <label for="password">Nova Senha</label>
              <input type="password" v-model="password" class="form-control" />
            </div>
            <div class="col-md-4">
              <label for="password">Confirmar Senha</label>
              <input type="password" v-model="password_confirm" class="form-control" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div class="row" style="margin-top: 50px; margin-left: 20px;">
          <div class="col-md-12">
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
            <router-link to="/" class="btn btn-info">
              <i class="fa fa-arrow-left"></i> Voltar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      user: {
        name: "",
        email: ""
      },
      password: "",
      password_confirm: "",
      endPoint: "/user/updateProfile/"
    };
  },

  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");

      let fd = new FormData();

      fd.append("password", self.password);
      fd.append("password_confirm", self.password_confirm);

      let logo = document.getElementById("image").files[0];
      if (logo) {
        console.log("wsa");
        fd.append("image", logo);
      }

      console.log(logo);

      self.$http
        .post(self.$store.getters.apiUrl + "/user/updateProfile/", fd)
        .then(function() {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
          document.location.reload(true);
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    }
  },
  mounted() {
    this.user = this.$store.getters.user;
  }
};
</script>

<style scoped>
.photo {
  width: 150px;
}
</style>


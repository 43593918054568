<template>
  <div :key="disableds" class="drag-container flex-one minus-margin-10">
    <ul class="drag-list animated flipInY show">
      <div
        v-for="(stage, index) in stages"
        class="drag-column drag bg-flat-gray-2 min-100-height"
        :key="stage.name"
        :index="index"
      >
        <div class="float-right">
          <button
            class="btn edit-btn"
            type="button"
            data-toggle="modal"
            data-target="#modalStepComments"
            @click="stageComentId = stage.id"
          >
            <i class="fa fa-comments" aria-hidden="true"></i>
          </button>
        </div>
        <!-- <slot name="stage-header" :stage="stage-header"></slot> -->
        <h6 class="float-left mb-2">{{ stage.name }}</h6>
        <slot name="stage" :stage="stage"></slot>
        <div class="clear"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group float-left mb-0">
              <div class="input-group">
                <div class="form-check-inline form-check">
                  <label
                    :for="'marcarTodos' + stage.id"
                    class="form-check-label mb-0"
                  >
                    <input
                      type="checkbox"
                      :disabled="countCandidates(stage.id)"
                      :checked="stage.selectedAll"
                      v-model="stage.selectedAll"
                      @change="setSelectAll(stage)"
                      :id="'marcarTodos' + stage.id"
                      :name="'marcarTodos' + stage.id"
                      value="option1"
                      class="form-check"
                    />
                    <span class="checkmark"></span>
                    {{ $t("label.marcarTodosCandidatos") }}
                  </label>
                </div>
              </div>
            </div>
            <div class="dropdown float-right">
              <button
                class="btn drop-btn dropdown-toggle"
                :disabled="countCandidatesStages(stage.id)"
                type="button"
                data-toggle="modal"
                data-target="#modalStages"
                @click="stageId(stage)"
              >
                {{ $t("label.moverPara") }}
              </button>
            </div>
          </div>
        </div>

        <div class="drag-options"></div>
        <div class="drag-inner-list" ref="list" :data-status="stage.name">
          <div
            class="drag-item no-margin"
            v-for="block in getBlocks(stage.name)"
            :data-block-id="block.id"
            :key="block.id"
          >
            <slot :name="block.id">
              <strong>{{ block.status }}</strong>
              <div>{{ block.id }}</div>
            </slot>
          </div>
        </div>
      </div>
    </ul>

    <div class="modal fade" id="modalStages" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mover Candidato</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <a
              class="dropdown-item font-14"
              href="#"
              v-for="(item, index) in getStages(get_stage_id.id)"
              :key="index"
              @click.prevent="changeStatus(get_stage_id, item)"
              data-dismiss="modal"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de comentarios na Etapa -->
    <div
      class="modal fade"
      id="modalStepComments"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCommentTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCommetTitle">
              {{ $t("label.comentarios") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="comments">
              <form action method="post" class>
                <div class="form-group mt-2 fixed-header">
                  <label for="anotacoes" class="control-label mb-1">{{
                    $t("label.comentarios")
                  }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      v-model="iteractionOnStepComments"
                      name="iteractions"
                      id="iteractions"
                      rows="4 "
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </form>
              <button
                @click="saveIterationOnStep"
                class="btn edit-btn float-right"
                type="button"
              >
                <i class="fa fa-floppy-o"></i>
                {{ $t("label.salvar") }}</button
              ><br />
              <div
                v-if="getComments.job_id != job_id"
                class="alert alert-warning text-center"
              >
                {{ $t("label.nenhumComentarioParaExibir") }}
              </div>
              <div
                class="form-group md-12"
                v-for="comments in getComments"
                :key="comments.id"
              >
                <div class="row" v-if="comments.job_id == job_id">
                  <div class="col-md-12">
                    <div class="float-left col-md-12">
                      <div class="float-right">
                        <span class="badge badge-pill badge-info">{{
                          comments.stage.name
                        }}</span>
                      </div>
                      <p class="text-left mb-0">{{ comments.content }}</p>
                      <span class="small floar-right text-muted"
                        ><b
                          ><i
                            >{{ comments.user.name }} em
                            {{ comments.created_at | toBrazilianDateTime}}</i
                          ></b
                        ></span
                      >
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn delete-btn" data-dismiss="modal">
                {{ $t("label.fechar") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal de comentariosGestaoRS -->
    </div>
  </div>
</template>

<script>
import dragula from "dragula";

export default {
  extends: "kanban-board",
  data: () => {
    return {
      iteractionOnSteps: "/job_stage_iteractions",
      iteractionOnStepComments: "",
      stageComentId: "",
      getComments: "",
      get_stage_id: "",
      job_id: "",
      content: "",
      Job: {
        role_name: "",
        company: {
          name: "",
          photo: "",
        },
        cost_center: {
          cost_center: "",
        },
        recruiter: {
          name: "",
        },
      },
      isMoving: false,
    };
  },
  props: {
    stages: {},
    blocks: {},
    disableMove: {},
  },
  // props: ["stages", "blocks", "onlyMyJobs"],
  computed: {
    localBlocks() {
      return this.blocks;
    },
    disableds() {
      return this.disableMove;
    },
  },
  filters: {
    toBrazilianDateTime: function (date) {
      let dt =
        date.substr(8,2) +
        "/" +
        date.substr(5,2) +
        "/" +
        date.substr(0,4) +
        " " + 
        date.substr(11,2) +
        ":" + 
        date.substr(14,2)
      return dt;
    },
    toBrazilianDate: function (date) {
      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];
      return dt;
    },
  },
  methods: {
    stageId(id) {
      this.get_stage_id = id;
    },
    getBlocks(status) {
      return this.localBlocks.filter((block) => block.status === status);
    },
    countCandidates(status) {
      return this.blocks.filter((candidate) => candidate.status_id == status)
        .length == 0
        ? true
        : false;
    },
    countCandidatesStages(status) {
      return this.blocks.filter(
        (candidate) =>
          candidate.status_id == status && candidate.selected == true
      ).length == 0
        ? true
        : false;
    },
    getStages(stage_id) {
      return this.stages.filter((item) => item.id != stage_id);
    },
    setSelectAll(stage_id) {
      this.$emit("setSelectAll", stage_id);
    },
    changeStatus(origin, final) {
      this.$emit("changeStatus", origin, final);
    },
    loadComments: function () {
      let self = this;
      self.$http
        .get(
          this.$store.getters.apiUrl +
            this.iteractionOnSteps +
            "?paginated=false",
          {
            params: {
              with: ["user", "stage"],
            },
          }
        )
        .then((response) => {
          self.getComments = response.data.data;
        });
    },
    saveIterationOnStep: function () {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .post(this.$store.getters.apiUrl + this.iteractionOnSteps, {
          user_id: self.$store.getters.user.id,
          job_id: self.$route.params.id,
          job_stage_id: self.stageComentId,
          content: self.iteractionOnStepComments,
        })
        .then(() => {
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.comentarioAdicionadoComSucesso")
          );
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.iteractionOnStepComments = "";
          self.loadComments();
        })
        .catch((error) => {
          let errors = error.response
            ? self.$t("label.ocorreuUmErroAoRealizarUmaInteracao")
            : error.response.data;

          self.$store.dispatch("tooggleLoadModal");

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            $.each(errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
  },
  mounted() {
    dragula(this.$refs.list, {
      accepts: function () {
        return true; // elements can be dropped in any of the `containers` by default
      },
      moves: function () {
        return true; // elements can be dropped in any of the `containers` by default
      },
    })
      .on("drag", (el) => {
        el.classList.add("is-moving");
        this.isMoving = true;
      })
      .on("drop", (block, list) => {
        let index = 0;

        if (list != undefined) {
          for (index = 0; index < list.children.length; index += 1) {
            if (list.children[index].classList.contains("is-moving")) break;
          }

          this.$emit(
            "update-block",
            block.dataset.blockId,
            list.dataset.status,
            index
          );
        }
      })
      .on("dragend", (el) => {
        el.classList.remove("is-moving");

        this.isMoving = false;

        window.setTimeout(() => {
          el.classList.add("is-moved");
          window.setTimeout(() => {
            el.classList.remove("is-moved");
          }, 600);
        }, 100);
      });

    this.loadComments();
    this.job_id = this.$route.params.id;
  },
};
</script>

<style>
#fixed-header {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
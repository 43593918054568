<template>
  <div>
    <div class="col-xl-12">
      <div class="col-md-4">
        <div class="card text-white bg-primary mb-3">
          <div class="card-header col-sm-12 text-center">
            <h3>{{ $t("label.licencasContratadas") }}</h3>
          </div>
          <div class="card-body">
            <h5 class="card-title text-center" v-if="cards.total_account">
              {{ cards.total_licenses }}
            </h5>
            <h5 class="card-title text-center" v-else>Sem Resultados</h5>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-black bg-flat-yellow mb-3">
          <div class="card-header col-sm-12 text-center">
            <h3>{{ $t("label.licencasUtilizadas") }}</h3>
          </div>
          <div class="card-body">
            <h5 class="card-title text-center" v-if="cards.total_licenses">
              {{ cards.total_account }}
            </h5>
            <h5 class="card-title text-center" v-else>Sem Resultados</h5>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-white bg-success mb-3">
          <div class="card-header col-sm-12 text-center">
            <h3>{{ $t("label.licencasDisponiveis") }}</h3>
          </div>
          <div class="card-body">
            <h5
              class="card-title text-center"
              v-if="!cards.total_licenses || !cards.total_account"
            >
              Sem Resultados
            </h5>
            <h5
              class="card-title text-center"
              v-if="cards.total_licenses - cards.total_account > 0"
            >
              {{ cards.total_licenses - cards.total_account }}
            </h5>
            <h5 class="card-title text-center" v-else>
              <span class="badge badge-warning">
                <i class="fa fa-exclamation" aria-hidden="true" />
              </span>
              {{ cards.total_licenses - cards.total_account }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t("label.cadastroLicencas") }}</h3>
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <router-link
                class="btn add-btn p-0 mb-3 float-right"
                to="/accounts/add"
                ><i class="fa fa-plus-square"></i>
                {{ $t("label.cadastrar") }}</router-link
              >
            </div>
          </div>
          <v-server-table
            ref="table"
            :name="table"
            :url="url"
            :data="tableData"
            :columns="columns"
            :options="options"
          >
            <div slot="actions" slot-scope="props">
              <button
                type="button"
                class="btn view-btn p-0"
                @click="view(props.row.id)"
                :title="$t('label.visualizar')"
              >
                <i class="fa fa-eye"></i>
              </button>
              <button
                type="button"
                class="btn edit-btn p-0"
                @click="edit(props.row.id)"
                :title="$t('label.editar')"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                class="btn delete-btn p-0"
                @click="destroy(props, props.row, props.index)"
                :title="$t('label.deletar')"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </v-server-table>
          <div style="overflow: visible" ref="selectUserDiv">
            <v-select
              :options="users"
              label="name"
              v-model="selectedUser"
              @input="updateSwal()"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import vSelect from "vue-select";

export default {
  data() {
    return {
      selectedUser: "",
      users: [],
      cards: [],
      message: "",
      columns: ["name", "email", "register", "user_type_id", "actions"],
      tableData: [],
      options: {
        headings: {
          name: this.$t("label.nome"),
          email: this.$t("label.email"),
          register: "Matricula",
          user_type_id: this.$t("label.tipo"),
          actions: this.$t("label.acoes"),
        },
        debounce: 1000,
        texts: {
          count: `${this.$t("label.mostrando")} {from} ${this.$t(
            "label.de"
          )} {to} ${this.$t("label.ate")} {count} ${this.$t(
            "label.deRegistros"
          )}`,
          filter: false,
          filterPlaceholder: this.$t("label.pesquisarAqui"),
          limit: "",
          noResults: this.$t("label.nenhumRegistroEncontrado"),
          page: this.$t("label.pagina") + ":",
        },
        templates: {
          user_type_id: function (h, row) {
            return row.user_type_id == 1
              ? "Requisitante"
              : row.is_manager == 1
              ? "Administrador"
              : "Recrutador";
          },
        },
        requestFunction: function (data) {
          let self = this;
          let requestData = {};
          requestData.params = data;
          requestData.params.with = ["jobsRecruiting"];
          requestData.params.name = requestData.params.query;

          self.$store.dispatch("tooggleLoadModal");
          return this.$http
            .get(this.url, requestData)
            .then((data) => {
              self.$store.dispatch("tooggleLoadModal");
              return data;
            })
            .catch((error) => {
              self.$store.dispatch("tooggleLoadModal");
              self.$errorMessage(error);
            });
        },
        columnsClasses: {
          actions: "column-actions",
        },
      },
      endPoint: "/accounts/",
      enableView: true,
      enableEdit: true,
      enableDelete: true,
      name: "usersTable",
      table: "usersTable",
    };
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
  },
  mounted: function () {
    this.loadCards();
    this.getUsers();
  },
  components: {
    vSelect: vSelect,
  },
  methods: {
    updateSwal() {
      if (this.selectedUser) {
        document.getElementsByClassName(
          "swal2-confirm swal2-styled"
        )[0].style.display = "inline-block";
      } else {
        document.getElementsByClassName(
          "swal2-confirm swal2-styled"
        )[0].style.display = "none";
      }
    },
    getUsers() {
      let self = this;
      self.$http
        .get(this.$store.getters.apiUrl + "/users?paginated=false")
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$errorMessage(error);
        });
    },
    edit(id) {
      this.$router.push({
        path: this.endPoint + "edit/" + id,
      });
    },
    view(id) {
      this.$router.push({
        path: this.endPoint + "view/" + id,
      });
    },
    destroy(props, row) {
      const self = this;

      Swal.fire({
        title: this.$t("label.desejaDeletarRegistro"),
        text: this.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        icon: "warning",
        dangerMode: true,
        confirmButtonText: this.$t("label.sim"),
        denyButtonText: this.$t("label.nao"),
        showDenyButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (row.jobs_recruiting.length > 0) {
            Swal.fire({
              title:
                "Este usuário possui pendências como recrutador, por favor selecione um usuário para receber essas pendências:",
              html: this.$refs.selectUserDiv,
              width: "40%",
              icon: "warning",
              dangerMode: true,
              confirmButtonText: this.$t("label.confirm"),
              denyButtonText: this.$t("label.cancelar"),
              showDenyButton: true,
              reverseButtons: true,
              showConfirmButton: false,
            }).then((result) => {
              if (result.isConfirmed) {
                self.$store.dispatch("tooggleLoadModal");
                this.$http
                  .post(this.url + "userWithPendencies", {
                    id: row.id,
                    pendenciesUser: this.selectedUser.id,
                  })
                  .then(() => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$refs.table.refresh();
                    self.$message(
                      "success",
                      self.$t("label.sucesso"),
                      self.$t("label.registroExcluidoComSucesso")
                    );
                  })
                  .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                  });
              }
            });
          } else {
            self.$store.dispatch("tooggleLoadModal");
            this.$http
              .delete(this.url + row.id, {
                id: row.id,
              })
              .then(() => {
                self.$store.dispatch("tooggleLoadModal");
                self.$refs.table.refresh();
                self.$message(
                  "success",
                  self.$t("label.sucesso"),
                  self.$t("label.registroExcluidoComSucesso")
                );
              })
              .catch((error) => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
              });
          }
        }
      });
    },
    loadCards() {
      const api = this.$store.getters.apiUrl + "/info";

      this.$http
        .get(api)
        .then((response) => {
          this.cards = response.data.data;
        })
        .catch((error) => {
          this.$errorMessage(error);
        });
    },
  },
};
</script>
<style>
.swal2-html-container {
  overflow: visible;
  z-index: 2;
}
</style>
<template>
	<div class="row">
		<div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
					<h3 class="card-title">{{ $t('label.instalacaoDoHumanFlow') }}</h3>

					<div class="row mt-3">

						<div class="col-md-12 text-center" v-if="isInstalled">
							<strong>{{ $t('label.parabens') }}!!!</strong>
							<div class="font-size-20 mb-35-px">
								<p>
									{{ $t('label.aInstalacaoFoiConcluidaComCucesso') }}
									<br>
									{{ $t('label.cliqueNoBotaoAbaixoParaAcessaroHumanFlow') }}
								</p>
								<button class="btn init-btn" @click="accessHumanFlow">
									{{ $t('label.acessarHumanFlow') }}
								</button>
							</div>
							{{ $t('label.emCasoDeDuvidasEnvieUmEmailPara') }} <a href="mailto:fluig@innovareti.com.br">fluig@innovareti.com.br</a>
						</div>	

						<div class="col-md-12" v-if="!isInstalled">
						<form-wizard :title="$t('label.instalacao')" 
							:subtitle="$t('label.sigaoProcessoPassoaPasso')" 
							shape="circle" :back-button-text="$t('label.voltar')" :next-button-text="$t('label.proximo')"
							:finish-button-text="$t('label.finalizar')"
							color="#20a0ff" 
							error-color="#dc3545" 
							@on-change="clearInputSearch"
							@on-error="handleErrorMessage"
							@on-complete="finishInstalation">

							<tab-content :title="$t('label.ativacao')" :before-change="validateActivation" change="prepareRecruiters">

								<div class="text-center">{{message}}</div>
								<hr/>

								<div class="row">
									<div class="col-md-12 form-group">
										<label>{{ $t('label.digiteSeuToken') }}</label>
										<input type="text" class="form-control" v-model="token">
									</div>
									<div class="col-md-12 form-group">
										<div class="alert alert-info no-margin">

											<p>{{ $t('label.aSuaLicencaConfiguradaComBaseNaSuaPropostaComercial') }}</p>
											<p>
												{{ $t('label.emCasoDeDivergenciaOuDuvidasEntreEmContatoAtravesDoEmail') }} <a href="mailto:fluig@innovareti.com.br">fluig@innovareti.com.br</a> 
												{{ $t('label.ouContateSeuExecutivoDeContas') }}.
											</p>
											<p class="no-margin">
												* {{ $t('label.naoSePreocupeCasoNumeroDeLicencasEstejaDivergentePoisTambemePossivelAtualizarRecrutadoresGestoresAposaInstalacao') }}
											</p>  
										</div>
									</div>
								</div>

								<div class="row" v-if="errorMsg">
									<div class="col-md-12 text-center">
										<br>
										<span class="alert alert-danger">{{errorMsg}}</span>
										<br>
									</div>
								</div>
							</tab-content>

							<tab-content :title="$t('label.identificacaoDeRecrutadores')" :before-change="validateRecruiters">
								<div class="row">
									
									<div class="col-md-12 form-group">
										<label>{{ $t('label.selecioneUsuarios') }} <b>{{ $t('label.recrutadores') }}</b></label>		
										<div class="input-group">		
											<div class="input-group-addon"><i class="fa fa-user"></i></div>                                        
											<input type="text" class="form-control" @input="isTyping = true, typingUser = false" v-model="search" :placeholder="$t('label.pesquisarUsuariosPlataforma')">
										</div>
									</div>
									<div class="col-md-12">	

										<div class="alert alert-info">
											{{ $t('label.suaLicencaEstaLimitada') }} <b>{{customer.number_of_recruiters}}</b> {{ $t('label.recrutadorS') }}*.<br>
											{{ $t('label.aPesquisaeLimitadaEmAte20RegistrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado') }}											
										</div>
										
										<hr>

										<div class="row">
											<div class="col-md-8">
												<h5>{{ $t('label.usuariosPesquisados') }}</h5>
												<table class="table table-striped table-bordered ">
													<thead>
													<tr>
														<th>{{ $t('label.nome') }}</th>
														<th>{{ $t('label.matricula') }}</th>
														<th>{{ $t('label.email') }}</th>
														<th class="text-center">*</th>
													</tr>
													</thead>
												
													<tbody v-if="fluigUsers.length > 0">
														<tr v-if="loading">
															<td colspan="4">
																<loading-human-flow :loading='true'></loading-human-flow>
															</td>
														</tr>

														<div v-if="!loading">
															<tr v-for="user in fluigUsers" :key="user.email">
																<td>{{user.fullName}}</td>
																<td>{{user.code}}</td>
																<td>{{user.email}}</td>
																<td class="text-center"><button class="btn add-btn" @click="selectRecruiter(user)">{{ $t('label.selecionar') }}</button></td>
															</tr>
														</div>

													</tbody>

													<tbody v-else>
														<tr class="text-center" v-if="search == ''">
															<td colspan="4">{{ $t('label.pesquiseOsUsuariosNoCampoAcima') }}</td>
														</tr>
														<tr class="text-center" v-if="search != ''">
															<td colspan="4">{{ $t('label.nenhumUsuarioEncontrado') }}</td>
														</tr>
													</tbody>
												</table>    

											</div>

											<div class="col-md-4">
												<h5>{{ $t('label.usuariosRecrutadores') }}</h5>
												<table class="table table-striped table-bordered ">
													<thead>
													<tr>
														<th>{{ $t('label.nome') }}</th>                                                
														<th class="text-center">*</th>
													</tr>
													</thead>
													
													<tbody v-if="recruiters.length > 0">                                              
														<tr v-for="(user, index) in recruiters" :key="index">
															<td width="90%">
															{{user.fullName}}
															<br>
															<small>{{user.email}}</small>
															</td>                                                
															<td class="text-center"> <button class="btn trash-btn" @click="removeRecruiter(index)" > <i class="fa fa-trash"></i> </button> </td>
														</tr>
													</tbody>
													<tbody v-else>
														<tr class="text-center">
															<td colspan="4">{{ $t('label.selecioneOsUsuariosAoLado') }}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>                                                                                           
									</div>
								</div>

								<div class="row" v-if="errorMsg">
									<div class="col-md-12 text-center">
										<br>
										<span class="alert alert-danger">{{errorMsg}}</span>
										<br>
									</div>
								</div>
							</tab-content>
							<tab-content :title="$t('label.identificacaoDeGestoresDeArea')" :before-change="validateUsers">
								
								<div class="row">
									
									<div class="col-md-12 form-group">
										<label>{{ $t('label.selecioneUsuarios') }} <b>{{ $t('label.gestoresDeArea') }}</b> <br><small>{{ $t('label.saoOsUsuariosQueIraoSolicitarVagasOuAprovarRequisicoes') }}</small></label>
										<div class="input-group">
											<div class="input-group-addon"><i class="fa fa-user"></i></div>                                        
											<input type="text" class="form-control" @input="isTyping = true, typingUser = true" v-model="search" :placeholder="$t('label.pesquisarUsuariosPlataforma')">
										</div>
									</div>
									<div class="col-md-12">		
										<div class="alert alert-info">
											{{ $t('label.suaLicencaEstaLimitada') }} <b>{{customer.number_of_users}}</b> {{ $t('label.gestoresDeArea') }}*. <br>
											{{ $t('label.aPesquisaeLimitadaEmAte20registrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado') }}
										</div>
										<hr>

										<div class="row">
											<div class="col-md-8">
												<h5>{{ $t('label.usuariosPesquisados') }}</h5>
												<table class="table table-striped table-bordered ">
													<thead>
													<tr>
														<th>{{ $t('label.nome') }}</th>
														<th>{{ $t('label.matricula') }}</th>
														<th>{{ $t('label.email') }}</th>
														<th class="text-center">*</th>
													</tr>
													</thead>

													<tbody v-if="fluigUsers.length > 0">
														<tr v-if="loading">
															<td colspan="4">
																<loading-human-flow :loading='true'></loading-human-flow>
															</td>
														</tr>

														<div v-if="!loading">
															<tr v-for="user in fluigUsers"  :key="user.email">
																<td>{{user.fullName}}</td>
																<td>{{user.code}}</td>
																<td>{{user.email}}</td>
																<td class="text-center"><button class="btn add-btn" @click="selectUser(user)">{{ $t('label.selecionar') }}</button></td>
															</tr>
														</div>

													</tbody>

													<tbody v-else>
														<tr class="text-center" v-if="search == ''">
															<td colspan="4">{{ $t('label.pesquiseOsUsuariosNoCampoAcima') }}</td>
														</tr>
														<tr class="text-center" v-if="search != ''">
															<td colspan="4">{{ $t('label.nenhumUsuarioEncontrado') }}</td>
														</tr>
													</tbody>

												</table>                                          
											</div>

											<div class="col-md-4">
												<h5>{{ $t('label.usuariosGestores') }}</h5>
												<table class="table table-striped table-bordered ">
													<thead>
														<tr>
															<th>{{ $t('label.nome') }}</th>                                                
															<th class="text-center">*</th>
														</tr>
													</thead>
													<tbody v-if="users.length > 0">                                              
														<tr v-for="(user, index) in users" :key="index">
															<td width="90%">
																{{user.fullName}}
																<br>
																<small>{{user.email}}</small>
															</td>                                                
															<td class="text-center"> <button class="btn trash-btn" @click="removeUser(index)" > <i class="fa fa-trash"></i> </button> </td>
														</tr>
													</tbody>

													<tbody v-else>
														<tr class="text-center">
															<td colspan="4">{{ $t('label.selecioneOsUsuariosAoLado') }}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>			
									</div>
								</div>

								<div class="row" v-if="errorMsg">
									<div class="col-md-12 text-center">
										<br>
										<span class="alert alert-danger">{{errorMsg}}</span>
										<br>
									</div>
								</div>
							</tab-content>

							<tab-content :title="$t('label.instalacao')">
								
								<div v-if="!installingBool">
									
									<div class="row">
										<div class="col-md-12">

											<div class="text-center">{{ $t('label.clickEmFinalizarParaConcluiraInstalacao') }}</div>

										</div>
									</div>

								</div>
								<div class="text-center" v-if="installingBool">
									<p>{{ $t('label.cadastrandoRecrutadores') }} <i class="fa fa-check color-installation"></i></p> 
									<p>{{ $t('label.cadastrandoUsuarios') }} <i class="fa fa-check color-installation"></i></p> 
									<div class="font-size-20">
										<p><strong>{{ $t('label.instalando') }}</strong>
										<loading-human-flow :loading='true'></loading-human-flow>
										</p>
									</div>
								</div>
							</tab-content>
						</form-wizard>						
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import _ from 'lodash'

export default {
	data() {
		return {
			message: '',
			token: '',
			errorMsg: '',
			users: [],
			customer: {},
			recruiters: [],			
			fluigUsers: [],
			installingBool: false,
			folderId: 0,
			createCardIndex: '',
			ecmWorkflow: '',
			ecmRelease: '',
			html: '',
			js1: '',
			js2: '',
			js3: '',
			js4: '',
			process: '',
			formId: 0,
			search: '',
			loading: false,
			isTyping: false,
			typingUser: false,
			isInstalled: false,
		}
	},
	computed: {
		active: function () {
			return this.$store.getters.activated
		}
	},
	created: function () {
		this.$store.dispatch("disableLoadModal");

	},
	mounted: function () {
		if( this.$store.getters.fluigAdmin == false){			
            this.$router.push('/notAdmin');
        }

		// this.$store.dispatch("disableLoadModal");

		this.$message = this.$t('label.bemVindoAoInstaladorDoHumanFlowVoceReceberaoTokenNoEmailInformadoNoCadastro')
	},
	watch: {		
		search: _.debounce(function() {
				this.isTyping = false;
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.getFluigUsers(this.search);
			}
		}
		},
	methods: {
		clearInputSearch(prevIndex, nextIndex) {
			if (nextIndex == 1 || nextIndex == 2) {
				this.search=""
				this.fluigUsers = []
			}
		},
		removeUser(index) {
			this.users.splice(index,1)

			this.getFluigUsers(this.search)
		},
		selectUser(user) {
			let recruiter_found = this.recruiters.filter(function (r) {
                return r.email == user.email
            })

            if (recruiter_found.length > 0) {
				this.swal(this.$t('label.usuarioEstaCadastradoComoRecrutador'), this.$t('label.removaPrimeirooUsuarioDosRecrutadoresCadastrados'), 'error');
                return;
            }

			user.selected = true
			let recruiter = this.users.filter(function(recruiter){
				return recruiter.email == user.email
			})[0]

			if(!recruiter){this.users.push(user)}

			this.getFluigUsers(this.search);
		},
		removeRecruiter(index) {
			this.recruiters.splice(index,1)

			this.getFluigUsers(this.search);
		},
		selectRecruiter(user) {

			let user_found = this.users.filter(function (u) {
                return u.email == user.email
            })

            if (user_found.length > 0) {
				this.swal(this.$t('label.usuarioEstaCadastradoComoGestor'), this.$t('label.removaPrimeirooUsuarioDosGestoresCadastrados'), 'error');
                return;
            }

			user.selected = true
			let recruiter = this.recruiters.filter(function(recruiter){
				return recruiter.email == user.email
			})[0]

			if(!recruiter){this.recruiters.push(user)}
		
			this.getFluigUsers(this.search);
		},
		removeUserSelected: function(data) {
			if (this.typingUser == false) {

				if (this.recruiters.length == 0) {
					return data
				}

				let recruiters = [];
				for(let j in data) {
					let foundRecruiter = this.recruiters.filter(recruiter => recruiter.email == data[j].email)

					if (foundRecruiter.length == 0) {
						recruiters.push(data[j])
					}
				}

				return recruiters
			}
			else {
				if (this.users.length == 0) {
					return data
				}

				let users = [];
				for(let j in data) {
					let foundUser = this.users.filter(user => user.email == data[j].email)

					if (foundUser.length == 0) {
						users.push(data[j])
					}
				}

				return users
			}

		},
		getFluigUsers: function (search) {
		
			let self = this
			this.loading = true 

			if(self.$store.getters.mode == 'dev') {
				
				window.this.$http.get(self.$store.getters.apiUrl + '/api/public/2.0/users/listAll')
                .then(response => {
                    self.fluigUsers = this.removeUserSelected(response.data)
				})
				.then( () => {
					
					this.loading = false
				});

				
			} else {
				this.$http.get('/api/public/2.0/users/listAll',{
					params: {limit: 20, pattern: search}
				})
				.then( response => {
					self.fluigUsers = this.removeUserSelected(response.data.content.filter( user => {
					return user.isActive
					}))
				})
				.then( () => {
					self.loading = false
				})
			}
		},

		handleErrorMessage: function (errorMsg) {
			this.errorMsg = errorMsg
		},
		
		activateInstallation () {
			this.installingBool = true;
			let self = this

			const api      = self.$store.getters.apiUrl + '/customer/installed'
			const uuid     = self.$store.getters.customer.uuid
			const code     = self.$store.getters.customer.tennantCode			

			self.$http.post(api, {
				'token': self.token,
				'uuid': uuid,
				'code': code,
				'users_selected': self.users,
				'recruiters_selected': self.recruiters
			})
			.then(response => {
				if (response.data.installed == undefined) {    
					self.$message('error', self.$t('label.erro'), response.data.message);
					
				} else if (response.data.installed == true) {
					self.customer = response.data
					self.installingBool = false;                            					
					self.isInstalled = true
				} 
			})
		},
		accessHumanFlow: function() {

			this.$store.commit('checkInstallation', this.$store)
		},
		finishInstalation: function () {
			
			this.activateInstallation()			
		},
		validateUsers: function () {
			let self = this
			return new Promise((resolve, reject) => {
				let users_selected = this.users;
				
				// validar quantidade
				if (users_selected.length > self.customer.number_of_users) {
					reject(self.$t('label.foiSelecionadoUmNumeroDeUsuariosMaiorQueoContratado'));
				} else if (users_selected.length == 0) {
					reject(self.$t('label.selecioneAoMenosUmUsuario'));
				} else {
					self.users = users_selected
					self.search = ''
					self.fluigUsers = []
					resolve(true)
				}
			})
		},
		validateRecruiters: function () {
			let self = this
			return new Promise((resolve, reject) => {
				let recruiters_selected = [];
				recruiters_selected = self.recruiters

				// validar quantidade
				if (recruiters_selected.length > self.customer.number_of_recruiters) {
					reject(self.$t('label.foiSelecionadoUmNumeroDeRecrutadoresMaiorQueoContratado﻿'));
				} else if (recruiters_selected.length == 0) {
					reject(self.$t('label.selecioneAoMenosUmRecrutador'));
				} else {
					self.recruiters = recruiters_selected
					self.search = ''
					self.fluigUsers = []
					resolve(true)
				}
			})
		},

		validateActivation: function () {
			let self = this
			return new Promise((resolve, reject) => {

				if (self.token == '') {
					reject(self.$t('label.favorInformaroTokenHumanFlow'));
				} else {
					const api = self.$store.getters.apiUrl + '/customer/checkToken'
					const uuid = self.$store.getters.customer.uuid
					const code = self.$store.getters.customer.tennantCode					

					self.$store.dispatch("tooggleLoadModal");
					self.$http.post(api, {
							'token': self.token,
							'uuid': uuid,
							'code': code,
						})
						.then(response => {
							self.$store.dispatch("tooggleLoadModal");
							if (response.data.token == self.token) {
								self.customer = response.data
								resolve(true)
							
							} else {
								reject(self.$t('label.tokenInvalido'));
							}
						}

					).catch( () => {
						self.$store.dispatch("tooggleLoadModal");
						reject(self.$t('label.tokenInvalido'));
					})
				}
			})
		}
	},
	components: {}
}
</script>
<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.cadastroHorariosDeTrabalho') }}</h3>
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <router-link class="btn add-btn p-0 mb-3 float-right" to="/workload/add">
                <i class="fa fa-plus-square"></i>
                {{ $t('label.cadastrar') }}
              </router-link>
            </div>
          </div>
          <v-server-table
            ref="table"
            :name="table"
            :url="url"
            :data="tableData"
            :columns="columns"
            :options="options"
          >
            <div slot="actions" slot-scope="props">
              <!-- <button
                type="button"
                class="btn view-btn p-0"
                @click="view(props.row.id)"
                :title="$t('label.visualizar')"
              >
                <i class="fa fa-eye"></i>
              </button>
              <button
                type="button"
                class="btn edit-btn p-0"
                @click="edit(props.row.id)"
                :title="$t('label.editar')"
              >
                <i class="fa fa-pencil"></i>
              </button>-->
              <button
                type="button"
                class="btn delete-btn p-0"
                @click="destroy(props, props.row, props.index)"
                :title="$t('label.deletar')"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </v-server-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";

export default {
  data() {
    return {
      message: "",
      columns: ["title", "actions"],
      tableData: [],
      options: {
        headings: {
          title: this.$t("label.titulo"),
          actions: this.$t("label.acoes")
        },
        debounce: 1000,
        texts: {
          count: `${this.$t("label.mostrando")} {from} ${this.$t(
            "label.de"
          )} {to} ${this.$t("label.ate")} {count} ${this.$t(
            "label.deRegistros"
          )}`,
          filter: false,
          filterPlaceholder: this.$t("label.pesquisarAqui"),
          limit: "",
          noResults: this.$t("label.nenhumRegistroEncontrado"),
          page: this.$t("label.pagina") + ":"
        },
        templates: {
          //   approver: function(h, row) {
          //     return row.user ? row.user.name : "";
          //   },
          company: function(h, row) {
            return row.company ? row.company.name : "";
          }
        },
        requestFunction: function(data) {
          let self = this;
          let requestData = {};
          requestData.params = data;
          requestData.params.with = [];

          self.$store.dispatch("tooggleLoadModal");
          return this.$http
            .get(this.url, requestData)
            .then(data => {
              self.$store.dispatch("tooggleLoadModal");
              return data;
            })
            .catch(error => {
              self.$store.dispatch("tooggleLoadModal");
              self.$errorMessage(error);
            });
        },
        columnsClasses: {
          actions: "column-actions"
        }
      },
      endPoint: "/workload/",
      enableView: false,
      enableEdit: false,
      enableDelete: true,
      name: "workloadTable",
      table: "workloadTable"
    };
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    destroy(props, row) {
      const self = this;

      swal({
        title: self.$t("label.desejaDeletarRegistro"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        icon: "warning",
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm"
          }
        }
      })
        .then(value => {
          if (value == "confirm") {
            self.$store.dispatch("tooggleLoadModal");
            this.$http
              .delete(this.url + row.id, {
                id: row.id
              })
              .then(() => {
                self.$store.dispatch("tooggleLoadModal");
                self.$refs.table.refresh();
                self.$message(
                  "success",
                  self.$t("label.sucesso"),
                  self.$t("label.registroExcluidoComSucesso")
                );
              })
              .catch(error => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
              });
          }
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    }
  }
};
</script>

<style>
</style>

<template>
    <div>   
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">        
                    <h3 class="card-title">{{ $t('label.gerenciamentoIntegracao') }}</h3>                    
                    <div class="row">
                        <div class="col-md-10 form-group">

                            <label class="control-label mb-1">{{ $t('label.qualODadoASerImportado') }}</label>
                    
                            <div class="input-group">
                                <select class="form-control" @change="changeEntity" v-model="entity_selected">
                                    <option value="">{{ $t('label.selecione') }}...</option>
                                    <option :key="key" :value="key" v-for="(entity, key) in entities">{{ entity }}</option>
                                </select>                                                             
                            </div>
                        </div>   
                        
                        <div class="col-md-2 form-group">
                            <label class="control-label mb-1">{{ $t('label.habilitarImportacao') }}</label>					
                            <select class="form-control" :disabled="!hasEntitySelected" >
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>                                                              
                        </div>
                        
                        
                        <div class="col-md-12 form-group" v-if="hasEntitySelected">
                            <div class="row">
                                <div class="col-md-2 form-group">                                  
                                    <button class="btn dark-btn btn-lg btn-block mt-4"><i class="fa fa-refresh mr-2"></i> {{ $t('label.atualizarDados') }}</button>
                                </div> 
                            </div>
                            <br>
                            <label for="nonImporteds">Responsáveis</label>
                            <select name="nonImporteds[]" id="nonImporteds" size="6" v-model="importeds" multiple>
                                <option v-for="nonImported in nonImporteds" :value="nonImported.id" :key="nonImported.id">
                                    {{ nonImported.name }}
                                </option>
                            </select>                            
                        </div>
                    </div>    
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <button class="btn include-btn p-1 ml-3 float-right"  @click="$router.go(-1)"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</button>
                        </div>
                    </div>                      
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default {
    data(){
        return {     
            importeds: [],
            nonImporteds: [],
            entities: {
                company: "Empresas",
                branch: "Estabelecimentos",
                center_coast: "Centro de Custos",
                role: "Cargos",
                role_template: "Template de Cargos",
                role_benefit: "Benefícios de Cargos",
            },
            entity_selected: "",
            // integrationForm: {
            //     id: "",    
            //     erp_name: "",
            //     erp_link_ws: "",  
            //     customer_id: "",
            //     company_id: "",
            //     auto_sync: "",
            //     enable_integration: "",
            // },
            // message: '',
            // columns: [ 'erp_name', 'erp_link_ws', 'auto_sync', 'enable_integration', 'actions'],
            // tableData: [],
            // options: {
            //     headings: {
            //         'erp_name': $t('label.nomeErp'),
            //         'erp_link_ws': $t('label.linkWebService'),
            //         'auto_sync': $t('label.sincronizarAutomaticamente'),
            //         'enable_integration': $t('label.integracaoHabilitada'),
            //         'actions': $t('label.acoes')
            //     },
            //     debounce: 1000,
            //     texts: {
            //         count: `${$t('label.mostrando')} {from} ${$t('label.de')} {to} ${$t('label.ate')} {count} ${$t('label.deRegistros')}`,
            //         filter: false,
            //         filterPlaceholder: $t('label.pesquisarAqui'),
            //         limit: '',
            //         noResults: $t('label.nenhumRegistroEncontrado'),
            //         page: $t('label.pagina')+':'
            //     },
            //     columnsClasses: {
            //         actions: 'column-actions',
            //     },   
            //     filterable: false,
            //     templates: {
            //         enable_integration: function(h, row, index) {
            //             return row.enable_integration == 0 ? "Não" : "Sim";
            //         },
            //         auto_sync: function(h, row, index) {
            //             return row.auto_sync == 0 ? "Não" : "Sim";
            //         },
            //     }             
            // },
            // endPoint: '/companies/',
            // endPointPage: "/integrations/",
            // enableView: false,
            // enableEdit: false,
            // enableDelete: false,
            // name: 'configsTable',
            // table: "configsTable",

            // companies: {
            //     id: 0,
            //     name: '',
            //     about: '',                
            // },
        }
    },
    computed: {
        hasEntitySelected() {
            return !this.entity_selected || this.entity_selected == "" ? false : true;
        }
        // url() {
        //     return this.$store.getters.apiUrl + this.endPoint
        // },
        // urlConfig() {
        //     return this.$store.getters.apiUrl + "/company-config-integr/"
        // }
    },
    mounted: function () {

        let nonImporteds = this.$("#nonImporteds");

        nonImporteds.bootstrapDualListbox({
          nonSelectedListLabel: "Não selecionados",
          selectedListLabel: "Selecionados",
          infoTextEmpty: "Lista vazia",
          infoTextFiltered:
            '<span class="label label-warning">Filtrando</span> {0} de {1}',
          infoText: "Mostrando tudo {0}",
          filterTextClear: "Mostrar todos",
          filterPlaceHolder: "Buscar...",
          moveSelectedLabel: "Mover selecionado",
          moveAllLabel: "Mover todos",
          removeSelectedLabel: "Remover selecionado",
          removeAllLabel: "Remover todos"
        });
        nonImporteds.ready(function() {
            nonImporteds.bootstrapDualListbox("refresh", true);
        });
        
    },
    methods: {
        changeEntity() {
            // let self = this;
            // if (!self.entity_selected || self.entity_selected == "") {

            // }
        }
    }
}
</script>

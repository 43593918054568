<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ $t('label.visualizacaoCadastroUsuario') }}</h3>
                    <form>
                        <div class="row" v-if="user.id != 0">
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.nome') }}</label>
                                <input type="text" class="form-control" v-model="user.name" max="191" readonly='true'>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.email') }}</label>                                        
                                <input type="email" class="form-control" v-model="user.email" readonly='true'>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.password') }}</label>                                        
                                <input type="password" class="form-control" v-model="user.password" readonly='true'>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label mb-1">{{ $t('label.tipo') }}</label>                                        
                                <input type="text" class="form-control" v-model="user.user_type.name" readonly='true'>
                            </div>                              
                        </div>
                        
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/users"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>                                    
                            </div>
                        </div>
                    </form>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {
                id: 0,
               },
            user_name: '',
            endPoint: '/users/'
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        }
    },
    mounted: function () {
        this.loadUser();
    },
    methods: {
        loadUser() {
            const self = this;
            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(this.url, {
                params: {
                    with: ["userType"]
                }
            })
            .then((response) => {
                self.$store.dispatch("tooggleLoadModal");
                self.user = response.data[0];

            }).catch((error) => {   
                self.$store.dispatch("tooggleLoadModal"); 
                self.$errorMessage(error);
            })
        }
    }
}
</script>

<style>

</style>
<template>
    <div>
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="text-white">Cadastro de Entrevistas com video</h4>                    
            </div>
            <div class="card-body">                                                        
                <form class="form-material" @submit.prevent>

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Nome</label>
                                <input type="text" class="form-control" v-model="video.name" placeholder="Nome do candidato">
                            </div>
                        </div>                            
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Observação</label>
                                <textarea class="form-control" v-model="video.observation" rows="3" placeholder="Observação"></textarea>
                            </div>
                        </div>
                    </div>
                                            
                    <button @submit.prevent @click="salvar"  class="btn btn-info">Salvar</button>
                    <router-link class="btn btn-warning" to="/video">Voltar</router-link>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {  
            // this.$route.params.id          
            video: {
                id: 0,
                name: '',
                observation: ''
            },
            endPoint: "/video/"            
        }
    },
    computed: {
        url () {            
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    mounted: function() {
        this.loadVideo()
    },
    methods: {
        loadVideo () {
            let self = this
            this.$http.get(this.url + this.$route.params.id)
            .then( (response) => {
                self.video.id = response.data.id
                self.video.name = response.data.name
                self.video.observation = response.data.observation
            })
        },
        salvar () {
            let self = this
            self.$http.put(self.url + this.$route.params.id,{
                'name': self.video.name,
                'observation': self.video.observation
            }).then( () => {
                self.$message('success', 'Sucesso', 'Registro atualizado com sucesso!');
                
            }).catch((error) => {
                
                self.$errorMessage(error)
                
            })
        }
    },
    components: {
        
    }
}
</script>

<style>

</style>


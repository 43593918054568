<template>
  <div class="col-md-4 col-sm-6">
    <define-recruiter
      :job="job"
      :active="defineRecruiterActive"
      @eventListJobs="eventListJobs || null"
    ></define-recruiter>
    <div class="card bg-flat-gray animated flipInY">
      <div :class="classObj">
        <div class="float-left" style="height: 30px;">
          <h4 class="card-title mb-0 limit-text-long-card">{{ job.role_name }}</h4>
        </div>
        <br />
        <!-- <div class="float-left">
          <p class="badge-field text-center">
            <span :class="classbadge">{{ normalizeStatus(job.status) }}</span>
          </p>
        </div>-->
      </div>
      <div :class="classstatus" style="font-size: 12px; text-align: center; font-weight: bold;">
        {{ normalizeStatus(job.status) }}
        <div class="dropdown float-right">
          <button
            class="btn drop-btn dropdown-toggle mar-neg-1"
            :style="engine"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-cog"></i>
          </button>
          <div
            class="dropdown-menu"
            v-if="onlyMyJobss == false"
            aria-labelledby="dropdownMenuButton"
          >
            <button
              class="dropdown-item"
              @click="defineRecuiter"
            >{{ $t('label.definirTransferirRecrutador') }}</button>
            <button
              v-if="!internal && job.status == 'inprogress' "
              class="dropdown-item"
              @click="loadVaga(job.id, 'publication')"
            >{{ $t('label.publicacao') }}</button>
            <button
              v-if="job.status == 'selection' "
              class="dropdown-item"
              @click="loadVaga(job.id, 'inprogress')"
            >{{ $t('label.triagem') }}</button>

            <button
              v-if="job.status_id == '4'"
              class="dropdown-item"
              @click="reopenJob(job.id)"
            >{{ $t('label.reabrirProcessoSeletivo') }}</button>

            <button class="dropdown-item" @click="resumoVaga(job.id)">{{ $t('label.resumoDaVaga') }}</button>
            <button
              class="dropdown-item"
              @click="comments(job.id)"
            >{{ $t('label.comentariosGestaoRS') }}</button>

            <button
              v-if="job.status == 'selection' "
              class="dropdown-item"
              @click="changeVisibility(job.id)"
            >
              <!-- <i class="far fa-eye" v-show="job.jobs_visibility"></i>
              <i class="far fa-eye-slash" v-show="!job.jobs_visibility"></i>-->
              {{ $t('label.alterarVisibilidade') }}
            </button>
            <button
              v-if="['selection', 'publication', 'inprogress', 'jobDescription'].includes(job.status)"
              class="dropdown-item"
              @click="manage(job.id)"
            >{{ $t('label.gestaoDaVaga') }}</button>
            <div class="dropdown-divider" v-if="job.status_id != 5"></div>
            <button
              class="dropdown-item"
              @click="cancelJob"
              v-if="job.status_id != 5"
            >{{ $t('label.cancelarVaga') }}</button>
          </div>

          <div
            class="dropdown-menu"
            v-if="onlyMyJobss == true"
            aria-labelledby="dropdownMenuButton"
          >
            <button
              v-if="job.status == 'selection' "
              class="dropdown-item"
              @click="loadVaga(job.id, 'inprogress')"
            >{{ $t('label.triagem') }}</button>
            <button class="dropdown-item" @click="resumoVaga(job.id)">Resumo da Vaga</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <a @click="loadVaga(job.id,job.status)" class="cursor-pointer">
          <div class="row mb-3">
            <div class="col-md-6">
              <p class="mb-0 text-center">
                <b>{{ $t('label.abertura') }}:</b>
                <br />
                <span>{{ job.initial_date | toBrazilianDate }}</span>
              </p>
            </div>
            <div class="col-md-6">
              <p class="mb-0 text-center">
                <b>{{ $t('label.conclusao') }}:</b>
                <br />
                <span>{{ job.end_date == 'N/A' ? 'Não Definido' : job.end_date }}</span>
              </p>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <p class="mb-0 text-center">
                <b>{{ $t('label.salario') }}</b>
              </p>
              <p class="mb-0 text-center">
                <span
                  class="badge bg-green text-light-gray"
                  style="font-size: 11px; text-align: center; font-weight: bold;"
                  v-if="!internal"
                >{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(job.position_salary) }}</span>
                <span
                  class="badge bg-green text-light-gray"
                  style="font-size: 11px; text-align: center; font-weight: bold;"
                  v-if="internal && job.to_combine"
                >{{ $t('label.acombinar') }}</span>
                <span
                  class="badge bg-green text-light-gray"
                  style="font-size: 11px; text-align: center; font-weight: bold;"
                  v-if="internal && !job.to_combine && job.show_salary != 1"
                >{{ $t('label.naoInformado') }}</span>
                <span
                  class="badge bg-green text-light-gray"
                  style="font-size: 11px; text-align: center; font-weight: bold;"
                  v-if="internal && !job.to_combine && job.show_salary == 1"
                >R$ {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(job.position_salary) }}</span>
              </p>
            </div>
            <div class="col-md-6">
              <p class="mb-0 text-center">
                <b>{{ $t('label.candidatos') }}</b>
              </p>
              <p class="mb-0 text-center">
                <span
                  class="badge bg-blue text-light-gray"
                  style="font-size: 11px; text-align: center; font-weight: bold;"
                >
                  <i class="fa fa-users"></i>
                  {{ job.candidates.length }}
                </span>
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="card-footer bg-pattern">
        <p class="text-left mb-1 text-dark-blue">
          {{ $t('label.solicitante') }}:
          <br />
          <b>
            <span>{{ job.requester }}</span>
          </b>
        </p>
        <p class="text-left mb-0 text-dark-blue">
          {{ $t('label.recrutador') }}:
          <br />
          <b>
            <span>{{ job.recruiter.name }}</span>
          </b>
        </p>
        <p class="text-left mb-0 text-dark-blue">
          {{ $t('label.numeroDaVaga') }}:
          <b>
            <span>{{ job.id }}</span>
          </b>
        </p>
        <p v-if="job.date_sequence != null" class="text-left mb-0 text-dark-blue">
          {{ $t('label.sequencialDaVaga') }}:
          <b>
            <span>{{ job.date_sequence }}-0{{ job.sequence }}</span>
          </b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import DefineRecruiter from "../Recruiter/DefineRecruiter";

export default {
  components: { DefineRecruiter },
  props: ["jobsObj", "eventListJobs", "onlyApprover", "internal", "onlyMyJobs"],
  data: () => {
    return {
      // this.$route.params.id
      job_number: 0,
      jobComments: [{ id: 1, text: "aaa" }],
      jobObj: {},
      endPoint: "/jobRoles/",
      job_endDate: "",
      defineRecruiterActive: false,
      reopen: {
        reason: "",
        datetime: "",
        id: ""
      },
      reopen_id: ""
    };
  },
  computed: {
    hasRecuiter() {
      return this.jobsObj.recruiter.name != "" ? true : false;
    },
    job() {
      return this.jobsObj;
    },
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    Comments: {
      // getter
      get: function() {
        return this.jobComments;
      },
      // setter
      set: function(id) {
        const self = this;
        const api = self.$store.getters.apiUrl + "/jobCommentsRS/?job_id=" + id;

        this.$http
          .get(api)
          .then(response => {
            this.jobComments = response.data.data;
            this.$message(null, this.jobComments, "error");
          })
          .catch(error => {
            self.$message(null, error.response.data, "error");
          });
      }
    },
    onlyMyJobss() {
      return this.onlyMyJobs;
    },
    classObj() {
      switch (this.job.status) {
        case "approval":
          return "card-header bt-red";
        case "disapproval":
          return "card-header bt-purple";
        case "jobDescription":
          return "card-header bt-orange";
        case "publication":
          return "card-header bt-yellow";
        case "inprogress":
          return "card-header bt-blue";
        case "selection":
          return "card-header bt-cian";
        case "finished":
          return "card-header bt-green";
        case "canceled":
          return "card-header bt-flat-gray";
      }

      return "";
    },
    classstatus() {
      switch (this.job.status) {
        case "approval":
          return "bg-red text-light-gray";
        case "disapproval":
          return "bg-purple text-light-gray";
        case "jobDescription":
          return "bg-orange text-dark-blue";
        case "publication":
          return "bg-yellow text-dark-blue";
        case "inprogress":
          return "bg-blue text-light-gray";
        case "selection":
          return "bg-cian text-dark-blue";
        case "finished":
          return "bg-green text-light-gray";
        case "canceled":
          return "bg-flat-gray text-dark-blue";
      }

      return "";
    },
    engine() {
      switch (this.job.status) {
        case "approval":
          return "margin-right: 5px; color: #fff";
        case "disapproval":
          return "margin-right: 5px; color: #fff";
        case "jobDescription":
          return "margin-right: 5px; color: #000";
        case "publication":
          return "margin-right: 5px; color: #000";
        case "inprogress":
          return "margin-right: 5px; color: #fff";
        case "selection":
          return "margin-right: 5px; color: #000";
        case "finished":
          return "margin-right: 5px; color: #fff";
        case "canceled":
          return "margin-right: 5px; color: #000";
      }

      return "";
    },
    classbadge() {
      switch (this.job.status) {
        case "approval":
          return "badge bg-red text-light-gray";
        case "disapproval":
          return "badge bg-purple text-light-gray";
        case "jobDescription":
          return "badge bg-orange text-dark-blue";
        case "publication":
          return "badge bg-yellow text-dark-blue";
        case "inprogress":
          return "badge bg-blue text-light-gray";
        case "selection":
          return "badge bg-cian text-dark-blue";
        case "finished":
          return "badge bg-green text-light-gray";
        case "canceled":
          return "badge bg-flat-gray text-dark-blue";
      }

      return "";
    }
  },
  mounted: function() {
    this.loadJob();

    let self = this;
    $(".defineRecruiterModal").on("hidden.bs.modal", function() {
      self.defineRecruiterActive = false;
    });
  },
  methods: {
    comments(id) {
      const self = this;
      self.$router.push({
        path: "/jobComments/" + id
      });
    },
    defineRecuiter() {
      this.defineRecruiterActive = true;
    },
    openLink() {
      window.open(this.jobObj.public_link);
    },
    cancelJob() {
      let self = this;

      swal({
        title: self.$t("label.temCertezaCancelarVaga"),
        text: self.$t("label.porFavorInformeMotivo"),
        content: "input",
        // showCancelButton: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-secondary"
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm",
            className: "btn-primary"
          }
        }
      }).then(cancelReason => {
        if (!cancelReason) {
          self.$message(
            "error",
            self.$t("label.ocorreuUmErro"),
            self.$t("label.porFavorInformeMotivo")
          );
          throw null;
        }

        if (cancelReason != "cancel") {
          self.$store.dispatch("tooggleLoadModal");
          self.$http
            .post(this.$store.getters.apiUrl + "/job/cancelJob", {
              jobs_id: this.jobObj.id,
              canceler_id: this.$store.getters.user.id,
              cancel_observation: cancelReason
            })
            .then(() => {
              self.$store.dispatch("tooggleLoadModal");
              this.$emit("eventListJobs");
              self.$message(
                "success",
                self.$t("label.sucesso"),
                self.$t("label.vagaCanceladaComSucesso")
              );
            })
            .catch(() => {
              self.$store.dispatch("tooggleLoadModal");
              self.$message(
                "error",
                self.$t("label.ocorreuUmErro"),
                self.$t("label.naoFoiPossivelCancelarVaga")
              );
            });
        }
      });
    },

    reopenJob() {
      let self = this;

      swal({
        title: self.$t("label.temCertezaReabrirVaga"),
        text: self.$t("label.porFavorInformeMotivo"),
        content: "input",
        // showCancelButton: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-secondary"
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm",
            className: "btn-primary"
          }
        }
      }).then(reopenReason => {
        if (!reopenReason) {
          self.$message(
            "error",
            self.$t("label.ocorreuUmErro"),
            self.$t("label.porFavorInformeMotivo")
          );
          throw null;
        }

        if (reopenReason != "cancel") {
          self.$store.dispatch("tooggleLoadModal");
          self.$http
            .post(this.$store.getters.apiUrl + "/job/reopenJob", {
              jobs_id: this.jobObj.id,
              reopener_id: this.$store.getters.user.id,
              reopen_observation: reopenReason
            })
            .then(() => {
              self.$store.dispatch("tooggleLoadModal");
              this.$emit("eventListJobs");
              self.$message(
                "success",
                self.$t("label.sucesso"),
                self.$t("label.vagaReabertaComSucesso")
              );
            })
            .catch(() => {
              self.$store.dispatch("tooggleLoadModal");
              self.$message(
                "error",
                self.$t("label.ocorreuUmErro"),
                self.$t("label.naoFoiPossivelReabrirVaga")
              );
            });
        }
      });
    },

    assign() {},
    loadJob: function() {
      this.jobObj = {};
      this.jobObj = this.job;
      this.job_endDate = this.normalizeEndDate(this.jobObj.end_date);
    },
    isRecruiterOfJob() {
      let self = this;

      if (this.$store.getters.user.isManager == true) {
        return true;
      }

      if (this.jobObj.recruiter_id == null) {
        self.$message(
          "error",
          self.$t("label.erro"),
          self.$t("label.vagaSemRecrutador")
        );

        return false;
      }

      if (
        this.jobObj.recruiter_id != this.$store.getters.user.id &&
        this.jobObj.requester_id != this.$store.getters.user.id
      ) {
        self.$message(
          "error",
          self.$t("label.erro"),
          self.$t("label.voceNaoERecrutadorDessaVaga")
        );

        return false;
      }

      return true;
    },
    manage: function(id) {
      const self = this;
      self.$router.push({
        path: "/jobManagement/" + id
      });
    },
    changeVisibility: function(id) {
      const self = this;
      self.$http
        .get(this.$store.getters.apiUrl + "/job/changeVisibility/" + id)
        .then(() => {
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.visibilidadeAlteradaComSucesso")
          );
          self.loadJob();
        })
        .catch(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "error",
            self.$t("label.ocorreuUmErro"),
            self.$t("label.naoFoiPossivelMudarAVisibilidadeDaVaga")
          );
        });
    },
    resumoVaga: function(id) {
      let path = "/job/" + id;

      this.$router.push({
        path: path
      });
    },
    loadVaga: function(id, status) {
      // if (this.internal)
      //   return;

      if (this.onlyMyJobss == true) {
        if (status == "approval") {
          let path = "/jobRoles/approval/" + id;

          this.$router.push({
            path: path
          });
        } else if (status == "selection") {
          let path = "/selection/view/" + id;

          this.$router.push({
            path: path
          });
        }  else if (status == "inprogress") {
          let path = "/jobRoles/view/role/" + id;
          this.$router.push({
            path: path
          });
      } else {
          let path = "/job/" + id;

          this.$router.push({
            path: path
          });
        }
      } else if (status == "selection") {
        if (this.isRecruiterOfJob()) {
          let path = "/selection/view/" + id;

          this.$router.push({
            path: path
          });
        }
      } else if (status == "approval" || status == "disapproval") {
        let path = "/jobRoles/approval/" + id;

        this.$router.push({
          path: path
        });
      } else if (status == "jobDescription") {
        let path = "/jobDescription/" + id;

        if (this.isRecruiterOfJob()) {
          this.$router.push({
            path: path
          });
        }
      } else if (status == "publication") {
        if (this.isRecruiterOfJob()) {
          let path = "/jobRoles/publication/" + id;
          this.$router.push({
            path: path
          });
        }
      } else if (status == "inprogress") {
        if (this.isRecruiterOfJob()) {
          let path = "/jobRoles/view/role/" + id;
          this.$router.push({
            path: path
          });
        }
      } else if (status == "finished" || status == "canceled") {
        let path = "/job/" + id;

        this.$router.push({
          path: path
        });
      } else {
        let path = "/jobRoles/view/role/" + id;

        this.$router.push({
          path: path
        });
      }
    },

    getDaysInRange: function(firstDate, secondDate) {
      let oneDay = 24 * 60 * 60 * 1000;

      return Math.round(
        Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
      );
    },
    normalizeStatus: function(status) {
      let self = this;
      switch (status) {
        case "finished":
          status = self.$t("label.finalizado");
          break;
        case "inprogress":
          status = self.$t("label.triagem");
          break;
        case "publication":
          status = self.$t("label.publicacao");
          break;
        case "canceled":
          status = self.$t("label.cancelado");
          break;
        case "selection":
          status = self.$t("label.selecao");
          break;
        case "approval":
          status = self.$t("label.aprovacao");
          break;
        case "disapproval":
          status = self.$t("label.reprovado");
          break;
        case "jobDescription":
          status = self.$t("label.jobDescription");
          break;
      }
      return status;
    },
    normalizeEndDate: function(dt) {
      const self = this;
      let today = new Date();

      dt = new Date(dt.split("-")[0], dt.split("-")[1] - 1, dt.split("-")[2]);

      let dias = self.getDaysInRange(today, dt);

      if (dias > 1) {
        return dias + " dias";
      }

      return dias + " dia";
    },
    openJobDetailModal() {
      $("#jobDetailModal").modal("show");
    }
  },
  filters: {
    toBrazilianDate: function(date) {
      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];

      return dt;
    },
    recruiter: function(name) {
      if (name == "") {
        return "label.semRecrutador";
      }

      return name;
    }
  }
};
</script>

<style>
</style>


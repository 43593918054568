<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.cadastroEmailTemplate') }}</h3>
          <form class="form-material" @submit.prevent>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.titulo') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="email_template.title"
                    maxlength="191"
                    autofocus
                  />
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.assunto') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="email_template.subject"
                    maxlength="191"
                    autofocus
                  />
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="budgeted" class="control-label mb-1">{{ $t('label.enviarICS') }}</label>
                  <div class="input-group">
                    <label class="switch switch-default switch-primary mt-2 mb-0">
                      <input type="checkbox" class="switch-input" value="true" v-model="send_ics" />
                      <span class="switch-label border-gray"></span>
                      <span class="switch-handle border-gray"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="send_ics == true" class="col-md-12">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.organizador') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="email_template.ics_organizer"
                    maxlength="191"
                    autofocus
                  />
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>

              <div v-if="send_ics == true" class="col-md-12">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.organizadorEmail') }}*</label>
                  <input
                    type="mail"
                    class="form-control"
                    v-model="email_template.ics_organizer_email"
                    maxlength="191"
                    autofocus
                  />
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>
              <div v-if="send_ics == true" class="col-md-12">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.tituloInvite') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="email_template.ics_title"
                    maxlength="191"
                    autofocus
                  />
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>
              <div v-if="send_ics == true" class="col-md-6">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.localInvite') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="email_template.ics_location"
                    maxlength="191"
                    autofocus
                  />
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>
              <div v-if="send_ics == true" class="col-md-6">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.duracaoInvite') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="email_template.ics_duration"
                    maxlength="191"
                    autofocus
                  />
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>
              <div v-if="send_ics == true" class="col-md-6">
                <div class="form-group">
                  <label for="budgeted" class="control-label mb-1">{{ $t('label.enviarGestor') }}</label>
                  <div class="input-group">
                    <label class="switch switch-default switch-primary mt-2 mb-0">
                      <input
                        type="checkbox"
                        class="switch-input"
                        value="1"
                        v-model="email_template.send_mail_manager"
                      />
                      <span class="switch-label border-gray"></span>
                      <span class="switch-handle border-gray"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="send_ics == true" class="col-md-6">
                <div class="form-group">
                  <label
                    for="logo"
                    class="control-label mb-1"
                  >{{ $t('label.escolhaTemplateGestor') }}*</label>

                  <select class="form-control" v-model="email_template.id_template_manager">
                    <option value selected="selected">{{ $t('label.selecione') }}...</option>
                    <option
                      v-for="email_template in email_templates"
                      :value="email_template.id"
                      :key="email_template.id"
                    >{{email_template.title}}</option>
                  </select>
                  <!-- <input type="text" v-if="this.$route.params.id" class="form-control" v-model="email_template._method" value="put"  maxlength="191" autofocus>                                       -->
                </div>
              </div>

              <div class="col-md-12">
                <label for>{{ $t('label.bodyEmail') }}*</label>
                <ckeditor :editor="editor" v-model="email_template.body" :config="editorConfig"></ckeditor>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <router-link class="btn include-btn p-1 ml-3 float-right" to="/email_templates">
                  <i class="fa fa-reply"></i>
                  {{ $t('label.voltar') }}
                </router-link>
                <button
                  @submit.prevent
                  @click="save"
                  id="save-btn"
                  class="btn include-btn p-1 ml-3 float-right"
                >
                  <i class="fa fa-floppy-o"></i>
                  {{ $t('label.salvar') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      email_template: {
        title: "",
        body: "",
        _method: ""
      },
      endPoint: "/email_templates/",
      send_ics: false,
      email_templates: []
    };
  },
  computed: {
    url() {
      if (this.$route.params.id) {
        return (
          this.$store.getters.apiUrl + this.endPoint + this.$route.params.id
        );
      } else {
        return this.$store.getters.apiUrl + this.endPoint;
      }
    },

    urlEmailTemplate() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    loadTemplates() {
      const self = this;
      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(this.url)
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.email_template = response.data;
          self.email_template._method = "PUT";
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.errorMessage(error);
        });
    },
    loadTemplatesEmail() {
      const self = this;

      self.$http
        .get(self.urlEmailTemplate, {
          params: {}
        })
        .then(response => {
          self.email_templates = response.data.data;
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    },
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .post(self.url, self.email_template)
        .then(function() {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
          self.$router.push({ path: "/email_templates/" });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    }
  },
  mounted() {
    this.loadTemplatesEmail();

    if (this.$route.params.id) {
      this.loadTemplates();
      this.email_template._method = "PUT";
    }
  }
};
</script>

<style scope>
.btn-vinculo {
  margin-top: 100px;
}

.v-select {
  position: relative;
  font-family: inherit;
}

.v-select,
.v-select * {
  box-sizing: border-box;
}

@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

.vs__fade-enter-active,
.vs__fade-leave-active {
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  cursor: not-allowed;
  background-color: #f8f8f8;
}

.v-select[dir="rtl"] .vs__actions {
  padding: 0 3px 0 6px;
}

.v-select[dir="rtl"] .vs__clear {
  margin-left: 6px;
  margin-right: 0;
}

.v-select[dir="rtl"] .vs__deselect {
  margin-left: 0;
  margin-right: 2px;
}

.v-select[dir="rtl"] .vs__dropdown-menu {
  text-align: right;
}

.vs__dropdown-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: 0 0 4px;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
}

.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
}

.vs__actions {
  display: flex;
  align-items: center;
  padding: 4px 6px 0 3px;
}

.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs--open .vs__dropdown-toggle {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
  transform: scale(1);
  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}

.vs--loading .vs__open-indicator {
  opacity: 0;
}

.vs__clear {
  fill: rgba(60, 60, 60, 0.5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
}

.vs__dropdown-menu {
  display: block;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 160px;
  overflow-y: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff;
}

.vs__no-options {
  text-align: center;
}

.vs__dropdown-option {
  line-height: 1.42857143;
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  white-space: nowrap;
}

.vs__dropdown-option:hover {
  cursor: pointer;
}

.vs__dropdown-option--highlight {
  background: #5897fb;
  color: #fff;
}

.vs__dropdown-option--disabled {
  background: inherit;
  color: rgba(60, 60, 60, 0.5);
}

.vs__dropdown-option--disabled:hover {
  cursor: inherit;
}

.vs__selected {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  color: #333;
  line-height: 1.4;
  margin: 4px 2px 0;
  padding: 0 0.25em;
}

.vs__deselect {
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: rgba(60, 60, 60, 0.5);
  text-shadow: 0 1px 0 #fff;
}

.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}

.vs--single.vs--open .vs__selected {
  position: absolute;
  opacity: 0.4;
}

.vs--single.vs--searching .vs__selected {
  display: none;
}

.vs__search::-webkit-search-cancel-button {
  display: none;
}

.vs__search::-ms-clear,
.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration {
  display: none;
}

.vs__search,
.vs__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
}

.vs__search::-webkit-input-placeholder {
  color: inherit;
}

.vs__search::-moz-placeholder {
  color: inherit;
}

.vs__search:-ms-input-placeholder {
  color: inherit;
}

.vs__search::-ms-input-placeholder {
  color: inherit;
}

.vs__search::placeholder {
  color: inherit;
}

.vs--unsearchable .vs__search {
  opacity: 1;
}

.vs--unsearchable .vs__search:hover {
  cursor: pointer;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}

.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border: 0.9em solid hsla(0, 0%, 39.2%, 0.1);
  border-left-color: rgba(60, 60, 60, 0.45);
  transform: translateZ(0);
  -webkit-animation: vSelectSpinner 1.1s linear infinite;
  animation: vSelectSpinner 1.1s linear infinite;
  transition: opacity 0.1s;
}

.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.vs--loading .vs__spinner {
  opacity: 1;
}

/*# sourceMappingURL=vue-select.css.map*/
</style>
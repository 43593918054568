<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">
            {{ $t('label.minhasVagasCadastradas') }}
            <a
              class="btn dark-btn pull-right"
              @click="showModal"
              v-show="!onlyApprover"
              :title=" $t('label.filtros') "
            >
              <i class="fa fa-filter"></i>
            </a>
          </h3>

          <form>
            <div class="row">
              <div class="col-md-8">
                <filter-jobs-text
                  @onToggleFilter="onToggleFilter"
                  @onSearchText="onSearchText"
                  :dateOpenObj="dateOpenObj"
                  :dateExpirationObj="dateExpirationObj"
                  :onlyApprover="onlyApprover"
                ></filter-jobs-text>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="ordenar" class="control-label mb-1">{{ $t('label.ordenar') }}</label>

                  <div class="input-group">
                    <select class="form-control" v-model="orderField" @change="orderJobs()">
                      <option value="created_at">{{ $t('label.dataDeAbertura') }}</option>
                      <option value="recruiter_name">{{ $t('label.recrutador') }}</option>
                      <option value="requester">{{ $t('label.solicitante') }}</option>
                      <option value="department">{{ $t('label.area') }}</option>
                      <option value="end_date">{{ $t('label.dataDeConclusao') }}</option>
                      <option value="role_name">{{ $t('label.nomeDaVaga') }}</option>
                      <option value="position_salary">{{ $t('label.salario') }}</option>
                    </select>

                    <div class="input-group-append clickable" @click="orderJobs()">
                      <span
                        class="input-group-text"
                        v-if="orderDirection"
                        :title="$t('label.ordenarCrescente')"
                      >
                        <i class="fa fa-sort-amount-asc"></i>
                      </span>
                      <span class="input-group-text" v-else :title="$t('label.ordenarDecrescente')">
                        <i class="fa fa-sort-amount-desc"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="row mb-3">
            <div class="col-md-12">
              <ul class="nav nav-pills nav-justified fields" id="pills-tab" role="tablist">
                <li class="nav-item" @click="loadFilteredJobListByTabs()">
                  <a
                    class="nav-link active"
                    id="pills-all-tab"
                    data-toggle="pill"
                    data-filter="all"
                    href="#"
                    role="tab"
                    aria-controls="pills-all"
                    aria-selected="true"
                  >{{ $t('label.todas') }}</a>
                </li>
                <li class="nav-item" @click="loadFilteredJobListByTabs('8')">
                  <a
                    class="nav-link"
                    id="pills-aprovacao-tab"
                    data-toggle="pill"
                    data-filter="aprovacao"
                    href="#"
                    role="tab"
                    aria-controls="pills-aprovacao"
                    aria-selected="false"
                  >
                    {{ $t('label.aprovacao') }}
                  </a>
                </li>
                <li class="nav-item" @click="loadFilteredJobListByTabs('7')">
                  <a
                    class="nav-link"
                    id="pills-jobDescription-tab"
                    data-toggle="pill"
                    data-filter="jobDescription"
                    href="#"
                    role="tab"
                    aria-controls="pills-jobDescription"
                    aria-selected="false"
                  >
                    {{ $t('label.jobDescription') }}
                  </a>
                </li>
                <li class="nav-item" @click="loadFilteredJobListByTabs('2')">
                  <a
                    class="nav-link"
                    id="pills-publicacao-tab"
                    data-toggle="pill"
                    data-filter="publicacao"
                    href="#"
                    role="tab"
                    aria-controls="pills-publicacao"
                    aria-selected="false"
                  >
                    {{ $t('label.publicacao') }}
                  </a>
                </li>
                <li class="nav-item" @click="loadFilteredJobListByTabs('6')">
                  <a
                    class="nav-link"
                    id="pills-triagem-tab"
                    data-toggle="pill"
                    data-filter="triagem"
                    href="#"
                    role="tab"
                    aria-controls="pills-triagem"
                    aria-selected="false"
                  >
                    {{ $t('label.triagem') }}
                  </a>
                </li>
                <li class="nav-item" @click="loadFilteredJobListByTabs('3')">
                  <a
                    class="nav-link"
                    id="pills-selecao-tab"
                    data-toggle="pill"
                    data-filter="selecao"
                    href="#"
                    role="tab"
                    aria-controls="pills-selecao"
                    aria-selected="false"
                  >
                    {{ $t('label.selecao') }}
                  </a>
                </li>
                <li class="nav-item" @click="loadFilteredJobListByTabs('4')">
                  <a
                    class="nav-link"
                    id="pills-concluidas-tab"
                    data-toggle="pill"
                    data-filter="concluidas"
                    href="#"
                    role="tab"
                    aria-controls="pills-concluidas"
                    aria-selected="false"
                  >
                    {{ $t('label.concluidas') }}
                  </a>
                </li>
                <li class="nav-item" @click="loadFilteredJobListByTabs('5')">
                  <a
                    class="nav-link"
                    id="pills-canceladas-tab"
                    data-toggle="pill"
                    data-filter="canceladas"
                    href="#"
                    role="tab"
                    aria-controls="pills-canceladas"
                    aria-selected="false"
                  >
                    {{ $t('label.canceladas') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <loading-human-flow :loading="loading"></loading-human-flow>

          <div class="row" v-if="!loading">
            <template v-if="jobRoles.length == 0">
              <div class="col-md-12" v-if="!loading">
                <div class="text-center">
                  <span>
                    <strong>{{ $t('label.nenhumResultadoComFiltroInformado') }}</strong>
                  </span>
                </div>
              </div>
            </template>
            <template v-else v-for="job in jobRoles">
              <job-roles-list :jobsObj="job" :onlyMyJobs="omj" @eventListJobs="loadJobList" :key="job.id"></job-roles-list>
            </template>
          </div>

          <div class="row" v-show="pages.length > 1">
            <div class="col-md-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination pull-right">
                  <li class="page-item" v-show="currentPage > 1">
                    <button class="page-link" @click="changePage(1)">
                      <i class="fa fa-backward"></i>
                    </button>
                  </li>
                  <li class="page-item" v-show="currentPage > 1" title="Página anterior">
                    <button class="page-link" @click="previousPage">
                      <i class="fa fa-step-backward"></i>
                    </button>
                  </li>
                  <template v-for="page in pages">
                    <li
                      class="page-item"
                      :class=" {'active': currentPage == page.pageNumber} "
                      :key="page.pageNumber"
                    >
                      <button
                        class="page-link"
                        @click="changePage(page.pageNumber)"
                      >{{page.pageNumber}}</button>
                    </li>
                  </template>
                  <li class="page-item" v-show="currentPage < pages.length" title="Próxima página">
                    <button class="page-link" @click="nextPage">
                      <i class="fa fa-step-forward"></i>
                    </button>
                  </li>
                  <li class="page-item" v-show="currentPage < pages.length">
                    <button class="page-link" @click="changePage( numberOfPages - 1 )">
                      <i class="fa fa-forward"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <filter-jobs
      @onToggleFilter="onToggleFilter"
      @onSearchTextRequester="onSearchTextRequester"
      @onSearchTextDepartment="onSearchTextDepartment"
      :onlyApprover="onlyApprover"
      :filterJobsMessage="filterJobsMessage"
    ></filter-jobs>
  </div>
</template>

<script>
import FilterJobsText from '../Filters/FilterJobsText'
import LoadingHumanFlow from '../LoadingHumanFlow'
import FilterJobs from '../Filters/FilterJobs'
import JobRolesList from '../JobRoles/JobRolesList'

export default {
  components: {FilterJobsText, FilterJobs, LoadingHumanFlow, JobRolesList},
  data: () => {    
    return {
      // this.$route.params.id
      loading: false,
      omj: true,
      jobRoles: [],
      jobBenefits: [],
      job: {},
      endPoint: "/job",
      aCheckBoxFilter: [],
      dateOpenFilter: {},
      dateExpFilter: {},
      searchString: "",
      onlyApprover: false,
      filterJobsMessage: "",
      actualStatus: "",
      analytics: {
        completed: 0,
        selection: 0,
        recruitment: 0,
        publication: 0,
        jobDescription: 0,
        approval: 0,
        canceled: 0
      },

      dateOpenObj: {
        date_to: "",
        date_from: ""
      },
      dateExpirationObj: {
        date_to: "",
        date_from: ""
      },
      currentPage: 0,
      numberOfPages: 0,
      orderField: "created_at",
      orderDirection: true
    };
  },

  computed: {
    url: function() {
      return this.$store.getters.apiUrl + this.endPoint + '?requester_id=' + this.$store.getters.user.id;
    },
    title: function() {
      return this.onlyApprover
        ? this.$t("label.vagasPendentesAprovacao")
        : this.$t("label.vagas");
    },
    pages: function() {
      let pages = [];

      if (this.numberOfPages > 5 && this.currentPage > 1) {
        for (let i = this.currentPage - 1; i <= this.currentPage + 4; i++) {
          if (i <= this.numberOfPages) {
            pages.push({
              pageNumber: i
            });
          }
        }
      } else if (this.numberOfPages > 5) {
        for (let i = 0; i < 5; i++) {
          if (i <= this.numberOfPages) {
            pages.push({
              pageNumber: i + 1
            });
          }
        }
      } else {
        for (let i = 0; i < this.numberOfPages; i++) {
          pages.push({
            pageNumber: i + 1
          });
        }
      }

      return pages;
    },
    urlAnalytics() {
      return (
        this.$store.getters.apiUrl +
        "/job/analytics/" +
        this.$store.getters.customerId
      );
    }
  },
  mounted: function() {
    // this.forceTabToogle();
    let self = this
    this.$forceUpdate();

    this.onlyApprover = self.$route.meta.onlyApprover;

    this.loadJobList();
  },
  methods: {
    // forceTabToogle() {
    //   $(".nav-item .nav-link").click(function () {
    //     $(".nav-item .nav-link").each(function () {
    //       $(this).removeClass("active show");
    //     });
    //     $(this).addClass("active show");
    //   });
    // },
    orderJobs: function() {
      this.orderDirection = !this.orderDirection;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        false,
        this.orderField,
        this.orderDirection
      );
    },
    previousPage() {
      this.currentPage = this.currentPage - 1;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        true
      );
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        true
      );
    },
    changePage: function(page) {
      this.currentPage = page;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        true,
        this.orderField,
        this.orderDirection
      );
    },
    showModal: function() {
      this.$("#filterJobsModal").modal("show");
    },
    onToggleFilter(aCheckBoxFilter) {
      this.aCheckBoxFilter = aCheckBoxFilter;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment
      );
    },
    onToggleDateFilter(oDateOpenFilter, oDateExpFilter) {
      this.dateOpenFilter = oDateOpenFilter;
      this.dateExpFilter = oDateExpFilter;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        oDateOpenFilter,
        oDateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment
      );
    },
    onSearchText(textQueryString) {
      this.searchString = textQueryString;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        textQueryString,
        this.textRequester,
        this.textDepartment,
        this.orderField
      );
    },
    onSearchTextRequester(textRequester) {
      this.textRequester = textRequester;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        textRequester,
        this.textDepartment,
        this.orderField
      );
    },
    onSearchTextDepartment(textDepartment) {
      this.textDepartment = textDepartment;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        textDepartment,
        this.orderField
      );
    },
    loadFilteredJobList: function(
      aFilterCheckBox,
      oDateOpenFilter,
      oDateExpFilter,
      cSearchParam,
      cSearchParamRequester,
      cSearchParamDepartment,
      changePage = false,
      orderField,
      orderDirection
    ) {
      const self = this;

      let params = {};

      if (orderDirection) {
        params = {
          status: self.actualStatus,
          role_name: cSearchParam,
          requester: cSearchParamRequester,
          department: cSearchParamDepartment,
          limit: 15,
          with: ["candidates", "recruiter", "cost_center"],
          orWhere: ["status"],
          orderByAsc: orderField
        };
      } else {
        params = {
          status: self.actualStatus,
          role_name: cSearchParam,
          requester: cSearchParamRequester,
          department: cSearchParamDepartment,
          limit: 15,
          with: ["candidates", "recruiter", "cost_center"],
          orWhere: ["status"],
          orderByDesc: orderField
        };
      }

      if (changePage) {
        params.page = this.currentPage;
      }

      if (this.onlyApprover) {
        params.status = 8;
        params.approver_id = self.$store.getters.user.id;
        delete params.recruiter_id;
      }

      try {
        var openedFrom = this.dateOpenObj.date_from
          ? new Date(this.dateOpenObj.date_from).toISOString().split("T")[0]
          : "";

        var openedTo = this.dateOpenObj.date_to
          ? new Date(this.dateOpenObj.date_to).toISOString().split("T")[0]
          : "";
      } catch (error) {
        var converteData = function(string) {
          var aux = string.split("/");
          var date = aux[2] + "-" + aux[1] + "-" + aux[0];

          if (new Date(date) == "Invalid Date") {
            return false;
          } else {
            return date;
          }
        };

        openedFrom = converteData(this.dateOpenObj.date_from);
        openedTo = converteData(this.dateOpenObj.date_to);
      }

      this.filterJobsMessage = "";

      if (openedTo < openedFrom && openedTo != "1970-01-01") {
        this.filterJobsMessage = "label.dataFinalNaoPodeSerMenuDataInicial";

        return;
      }

      // if( openedFrom === false || openedTo === false){
      //   return;
      // }
      if (openedFrom == false) {
        openedFrom = "1900-01-01";
      }

      if (openedTo == false) {
        openedTo = "2900-01-01";
      }

      if (openedFrom !== "" && openedTo !== "") {
        params.initial_date = openedFrom + "," + openedTo;
      }

      self.changeLoading(true);

      self.$http
        .get(self.url, {
          params
        })
        .then(response => {
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1;
          self.numberOfPages = response.data.pages;

          self.changeLoading(false);
        });
    },
    loadJobList: function() {
      const self = this;

      self.changeLoading(true);

      let params = {
        with: ["candidates", "approver", "recruiter", "cost_center"],
        limit: 15,
        orWhere: ["status"],
        orderByDesc: "created_at"
      };

      if (self.onlyApprover) {
        params.status = 8; // pendente de aprovação
        params.approver_id = self.$store.getters.user.id;
        delete params.recruiter_id;
      }

      self.$http
        .get(self.url, {
          params: params
        })
        .then(response => {
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1;
          self.numberOfPages = response.data.pages;

          self.changeLoading(false);
          this.getAnalytics();
        })
        .catch(function() {});
    },
    changeLoading: function(value) {
      this.loading = value;
    },
    loadJob(id) {
      const self = this;
      self.$http.get(this.url + id).then(response => {
        self.job = response.data;
      });
    },
    loadFilteredJobListByTabs: function(iStatus, changePage = false) {
      this.changeLoading(true);
      const self = this;

      let params = {
        status: iStatus,
        limit: 15,
        with: ["candidates", "recruiter", "cost_center"],
        orWhere: ["status"],
        orderByAsc: this.orderField
      };

      if (changePage) {
        params.page = this.currentPage;
      }

      self.$http
        .get(self.url, {
          params
        })
        .then(response => {
          self.actualStatus = iStatus;
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1;
          self.numberOfPages = response.data.pages;

          self.changeLoading(false);
        })
        .catch(function() {
          self.$errorMessage(
            "Não foi possível buscar as informações referente ao Status selecionado"
          );
        });
    },
    getAnalytics: function() {
      this.$http.get(this.urlAnalytics).then(response => {
        this.analytics = response.data;
      });
    }
  }  
};
</script>


<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.cadastroCentroCusto') }}</h3>
          <form class="form-material" @submit.prevent>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.centroCusto') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="cost_centers.cost_center"
                    maxlength="191"
                    autofocus
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="cost_centers.name"
                    maxlength="191"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="logo" class="control-label mb-1">{{ $t('label.departamento') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="cost_centers.department_name"
                    maxlength="191"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="company_id" class="control-label mb-1">{{ $t('label.empresa') }}*</label>
                  <select name="company_id" v-model="cost_centers.company_id" class="form-control">
                    <option value>{{ $t('label.selecione') }}...</option>
                    <option
                      v-for="company in companies"
                      :value="company.id"
                      :key="company.id"
                    >{{company.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <br />
                <h3 class="card-title">{{ $t('label.vinculoAprovadores') }}</h3>
                <br />
                <div class="row">
                  <div class="col-md-10">
                    <div class="form-group">
                      <select v-model="select.approvers" class="form-control">
                        <option v-for="user in users" :value="user" :key="user.id">{{ user.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button
                      class="btn include-btn btn-vinculo btn-block mt-2"
                      @click="vincularUsuario()"
                    >Vincular Usuário</button>
                  </div>
                </div>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Posição</th>
                      <th scope="col">ID</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Aprovador Alternativo</th>										
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(select, index) in selecionados" :key="select.id">
                      <td>{{ index+1 }}</td>
                      <td>{{ select.user_id }}</td>
                      <td>{{ select.user ? select.user.name : '' }}</td>
                      <td>                                                
                        <button v-if="select.is_alternate_approver && index+1 != 1" @click="changeAlternateApprover(select.id)" type="button" class="btn delete-btn" style="background-color: #28a745;" data-tooltip="tooltip" title="DesativarAprovadorAlternativo"><i class="fa fa-check"></i></button>
                        <button v-if="!select.is_alternate_approver && index+1 != 1" @click="changeAlternateApprover(select.id)" type="button" class="btn delete-btn" data-tooltip="tooltip" title="TornarAprovadorAlternativo"><i class="fa fa-times"></i></button>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn delete-btn mt-1 p-0"
                          data-tooltip="tooltip"
                          @click="split(select.id)"
                          title="Excluir"
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <router-link class="btn include-btn p-1 ml-3 float-right" to="/cost-centers">
                  <i class="fa fa-reply"></i>
                  {{ $t('label.voltar') }}
                </router-link>
                <button
                  @submit.prevent
                  @click="save"
                  id="save-btn"
                  class="btn include-btn p-1 ml-3 float-right"
                >
                  <i class="fa fa-floppy-o"></i>
                  {{ $t('label.salvar') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cost_centers: {
        id: 0,
        name: "",
        observation: "",
        approver_id: 0,
        company_id: ""
      },
      users: [],
      select: {
        approvers: []
      },
      endPoint: "/cost-centers/",
      companies: [],
      selecteds: [],
      selecionados: []
    };
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
    }
  },
  mounted: function() {
    this.loadCostCenters();
    this.getCompany();
    this.getApprovers();
  },
  methods: {
    changeAlternateApprover(userId){
      const self = this;
      const api =
        self.$store.getters.apiUrl +
        "/changeAlternateApprover"

      this.$http
        .post(api, {
            id: userId,
        })
        .then(() => {
          self.getApprovers()
        })
        .catch(() => {
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAEmpresa")
          );
        });
    },
    getCompany() {
      const self = this;
      const api = self.$store.getters.apiUrl + "/companies";

      this.$http
        .get(api, {
          params: {
            paginated: false,
            customer_id: self.$store.getters.customerId
          }
        })
        .then(response => {
          self.companies = response.data.data;
        })
        .catch(() => {
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAEmpresa")
          );
        });
    },
    getApprovers() {
      const self = this;
      const api =
        self.$store.getters.apiUrl +
        "/link-cost-centers-users/?center_cost_id=" +
        self.$route.params.id;

      this.$http
        .get(api, {
          params: {
            paginated: false,
            "with[]": "user"
          }
        })
        .then(response => {
          self.selecionados = response.data.data;
        })
        .catch(() => {
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAEmpresa")
          );
        });
    },
    loadCostCenters() {
      const self = this;
      const api =
        self.$store.getters.apiUrl +
        "/customer/" +
        self.$store.getters.customerId;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(this.url)
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.cost_centers = response.data;
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });

      this.$http
        .get(api, {
          params: {
            paginated: false,
            with: ["users"],
            "users[active]": 1
          }
        })
        .then(response => {
          self.users = response.data.users.filter(function(user) {
            return user.active == 1;
          });
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    },
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .put(self.url, self.cost_centers)
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroAtualizadoComSucesso")
          );
          self.$router.push({ path: "/cost-centers/" });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    vincularUsuario() {
      const self = this;

      let fd = new FormData();

      fd.append("user_id", self.select.approvers.id);
      fd.append("center_cost_id", self.cost_centers.id);

      let api = self.$store.getters.apiUrl + "/link-cost-centers-users";

      self.$http
        .post(api, fd)
        .then(() => {
          self.$message("success", `Sucesso`, "Usuário vinculado com sucesso");
          self.getApprovers();
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });
    },
    split(index) {
      const self = this;

      let fd = new FormData();

      fd.append("id", index);

      let api =
        self.$store.getters.apiUrl + "/link-cost-centers-users/" + index;

      self.$http
        .delete(api, fd)
        .then(() => {
          self.$message("success", `Sucesso`, "Usuário removido com sucesso");
          self.getApprovers();
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });
    }
  }
};
</script>

<style>
</style>

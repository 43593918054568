<template>
    <div>
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="text-white">Blacklist</h4>                    
            </div>
            <div class="card-body">                                                        
                <form class="form-material">

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Nome</label>
                                <input readonly='true' type="text" class="form-control" v-model="blacklist.name" placeholder="Nome da pessoa">
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Email</label>
                                <input readonly='true' type="text" class="form-control" v-model="blacklist.email" placeholder="Nome da pessoa">
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="form-group">
                                <label>Motivo</label>
                                <input readonly='true' type="text" class="form-control" v-model="blacklist.motive" placeholder="Nome da pessoa">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Observação</label>
                                <textarea readonly='true' class="form-control" v-model="blacklist.observation" rows="3" placeholder="Observação"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <router-link class="btn btn-warning" to="/blacklist">Voltar</router-link>
                    </div>
                    
                </form>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {            
            blacklist: {
                id: 0,
                name: '',
                observation: '',
                motive: '',
                email: ''
            },
            endPoint: '/blacklist/'
        }
    },
    computed: {
        url () {            
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    mounted: function() {
        this.loadBlacklist()
    },
    methods: {
        loadBlacklist () {
            let self = this
            this.$http.get(this.url + this.$route.params.id)
            .then( (response) => {
                self.blacklist.id = response.data.id
                self.blacklist.name = response.data.name
                self.blacklist.observation = response.data.observation
                self.blacklist.motive = response.data.motive
                self.blacklist.email = response.data.email
            })
        }
    },
    components: {
        
    }
}
</script>


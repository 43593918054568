<template>
    <div>
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="text-white">Cadastro de Etapas</h4>                    
            </div>
            <div class="card-body">
                <form class="form-material">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Nome</label>
                                <input readonly='true' type="text" class="form-control" v-model="stage.name" />
                            </div>
                        </div>    
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Ordem</label>
                                <input readonly='true' class="form-control" v-model="stage.order" rows="3" />
                            </div>
                        </div>                        
                    </div>
                    
                    <button @submit.prevent @click="salvar"  class="btn btn-info">Salvar</button>
                    <router-link class="btn btn-warning" to="/roleStage">Voltar</router-link>
                </form> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {  
            // this.$route.params.id          
            stage: {
                id: 0,
                name: '',
                order: '',
                role_template_id: '',
            },
            endPoint: '/roleStage/'            
        }
    },
    computed: {
        url(){
            return this.$store.getters.apiUrl + this.endPoint;
        }
    },
    mounted: function() {
        this.loadStage()
    },
    methods: {
        loadStage () {
            let self = this
            this.$http.get(this.url + this.$route.params.id)
            .then( (response) => {
                self.stage.id = response.stage.id
                self.stage.name = response.stage.name
                self.stage.order = response.stage.order
                self.stage.role_template_id = response.stage.role_template_id
            })
        },
        salvar () {
            let self = this
            self.$http.put(self.url + this.$route.params.id,{
                'name': self.stage.name,
                'order': self.stage.order
            }).then( () => {
                self.$message('success', 'Sucesso', 'Registro atualizado com sucesso!');                
            }).catch((error) => {
                
                self.$errorMessage(error)
                
            })
        }
    },
    components: {
        
    }
}
</script>

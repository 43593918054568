<template>
    <div class="col-md-3">  
            <div class="card bg-flat-gray-2" v-show="!$store.getters.getLoadModal">
                <div class="card-body">
                    <h4 class="mb-2">{{ $t('label.filtros') }}</h4>
                    <form class="mb-4">                       
                        <div class="form-group">
                            <label for="buscaGeral" class="control-label mb-1">{{ $t('label.buscaGeral') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.pesquisaEmVariasInformacoesDoPerfilDoCandidatoComoResumoeDescricao')"><i class="fa fa-question-circle"></i></a></label>
                            <input type="text" id="buscaGeral" v-model="query" @input="isTyping = true" name="buscaGeral" placeholder="" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="positions" class="control-label mb-1 ">{{ $t('label.cargoPretendido') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeCargosDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="positions" id="positions" class="selectize select-filter-candidates-positions" multiple>
                                <option v-for="option in positions" :value="option" :key="option.id" >{{ option }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="city" class="control-label mb-1">{{ $t('label.cidade') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeCidadesDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="city" id="city" class="selectize" multiple>
                                <option v-for="option in cities" :value="option" :key="option.id" >{{ option }}</option>
                            </select> 
                        </div>
                        <div class="form-group">
                            <label for="state" class="control-label mb-1">{{ $t('label.estado') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeEstadosDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="state" id="state" class="selectize" multiple>
                                <option v-for="option in states" :value="option" :key="option.id" >{{ option }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="gender" class="control-label mb-1">{{ $t('label.genero') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeObjetivosDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="gender" id="gender" class="selectize" multiple>
                                <option value="">{{ $t('label.ambos') }}</option>
                                <option value="1">{{ $t('label.masculino') }}</option>
                                <option value="2">{{ $t('label.feminino') }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="age" class="control-label mb-5">{{ $t('label.idade') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeObjetivosDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <vue-slider v-model="ageRange" :options="sliderOptions" tooltip="always" @change="isChangingAge = true"></vue-slider>
                        </div>
                        
                        <div class="form-group">
                            <label for="habilidades" class="control-label mb-1">{{ $t('label.habilidades') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeHabilidadesDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="habilities" id="habilidades" class="selectize" multiple>
                                <option v-for="option in habilities" :value="option" :key="option.id">{{ option }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="courses" class="control-label mb-1">{{ $t('label.cursos') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeCursosLivresDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="courses" id="courses" class="selectize" multiple>
                                <option v-for="option in courses" :value="option" :key="option.id">{{ option }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="institutions" class="control-label mb-1">{{ $t('label.instituicao') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeInstituicoesDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="institutions" id="institutions" class="selectize" multiple>
                                <option v-for="option in institutions" :value="option" :key="option.id">{{ option }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="graduations" class="control-label mb-1">{{ $t('label.graduacao') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeCursosDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="graduations" id="graduations" class="selectize" multiple>
                                <option v-for="option in graduations" :value="option" :key="option.id" >{{ option }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="languages" class="control-label mb-1">{{ $t('label.idioma') }} <a class="cursor-pointer" data-toggle="tooltip" :title="$t('label.escolhaNaListaDeIdiomasDosCandidatosParaEssaVaga')"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="languages" id="languages" class="selectize" multiple>
                                <option v-for="option in languages" :value="option" :key="option.id" >{{ option }}</option>
                            </select>
                        </div>
                        <hr>
                        <h3 class='mb-2'>{{ $t('label.experienciaProfissional') }}</h3>
                        <div class="form-group">
                            <label for="experienceCompanies" class="control-label mb-1">{{ $t('label.empresa') }} <a class="cursor-pointer" data-toggle="tooltip"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="experienceCompanies" id="experienceCompanies" class="selectize" multiple>
                                <option v-for="option in experienceCompaniesOptions" :value="option" :key="option.id">{{ option }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="experiencePositions" class="control-label mb-1">{{ $t('label.cargo') }} <a class="cursor-pointer" data-toggle="tooltip"><i class="fa fa-question-circle"></i></a></label>                            
                            <select name="experiencePositions" id="experiencePositions" class="selectize" multiple>
                                <option v-for="option in experiencePositionsOptions" :value="option" :key="option.id">{{ option }}</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            
        </div>
</template>

<script>
import _ from 'lodash'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
    components: {
       VueSlider
    },
    data: () => {
        return {
            sliderOptions: {
                min: 0,
                max: 100                
            },
            ageRange: [18, 70],
            loading: true,
            filters: {},
            endPoint: '/filters/',
            distanceRange: "",
            intervalId: null,
            objectivesOptions: [],
            graduationsOptions: [],
            institutionsOptions: [],
            habilitiesOptions: [],
            languagesOptions: [],
            citiesOptions: [],
            statesOptions: [],
            coursesOptions: [],
            positionsOptions: [],
            experienceCompaniesOptions: [],
            experiencePositionsOptions: [],
            typeFilter: '',
            query: '',
            isTyping: false,
            isChangingAge: false,
        }
    },
    computed: {
        url_FILTERS() {
            return this.$store.getters.apiUrl + '/job/' + this.$route.params.id + this.endPoint
         },
        url_FILTERSTALENTS() {            
            return this.$store.getters.apiUrl + '/job/filters_talents'      
        },
        objectives() {
            return this.objectivesOptions;
        },
        graduations() {
            return this.graduationsOptions;
        },
        institutions() {
            return this.institutionsOptions;
        },
        habilities() {
            return this.habilitiesOptions;
        },
        languages() {
            return this.languagesOptions;
        },
        cities() {
            return this.citiesOptions;
        },
        states() {
            return this.statesOptions;
        },
        courses() {
            return this.coursesOptions;
        },
        positions() {
            return this.positionsOptions;
        },
        // experienceCompaniesOptions() {
        //     return this.experienceCompaniesOptions;
        // },
        // experiencePositionsOptions() {
        //     return this.experiencePositionsOptions;
        // },
    },
    watch: {
        query: _.debounce(function () {
            this.isTyping = false;
        }, 800),
        isTyping: function (value) {
            if (!value) {
                this.filter();
            }
        },
        ageRange: _.debounce(function (){
            this.isChangingAge = false;
        }, 800),
        isChangingAge: function (value){
            if(!value){
                this.filter();
            }
        }
    },
    methods: {
        filterAge(){
            this.filter();
        },
        openAdvancedFilter() {
            $('#filtroAvancadoModal').modal('show');
        },
        loadFilters() {
            let self = this;

            // self.loading = true;

            self.job_id = 56;
            self.$store.dispatch("tooggleLoadModal"); 
            self.$http.get(self.url_FILTERSTALENTS)
                .then(response => {
                    self.graduationsOptions  = response.data.graduations;
                    self.institutionsOptions = response.data.institutions;
                    self.habilitiesOptions   = response.data.skills;
                    self.languagesOptions    = response.data.languages;
                    self.experienceCompaniesOptions    = response.data.experience_companies;
                    self.experiencePositionsOptions    = response.data.experience_positions;
                    self.citiesOptions       = response.data.cities;
                    self.statesOptions       = response.data.states;
                    self.objectivesOptions   = response.data.objectives;
                    self.coursesOptions      = response.data.courses;
                    self.positionsOptions    = response.data.positions;
                })
                .then(() => {
                    self.$store.dispatch("tooggleLoadModal"); 
                    $('.selectize').selectize({
                        onChange: function (obj) {
                            let name = this.$input.attr('name');

                            self.filters[name] = obj;
                            self.filter();
                        }
                    });                    
                    // self.loading = false;
                })
                .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal"); 
                    if (!error.response) {
                        self.$message('error', "Erro ao carregar a vaga");
                    } else {
                        let errors = error.response.data;
                        for (error in errors) {
                            self.$message('error', 'Erro ao cadastrar', errors[error]);
                        }
                    }
                });
        },
        filter: function () {
            const self = this;
            
            let params = self.prepareParams();

            self.$emit('filterCandidates', params);            
        },
        prepareParams: function () {

            let self = this
            let params = '';

            params += 'status=1'

            $.each(this.filters, function (column, filter) {

                if (filter != null && filter.length > 0) {

                    // campos diretos

                    // tabelas filhas
                    params += '&with[]=' + column;
                    params += '&orWhere[]=city&orWhere[]=state';

                    let i = 0
                    $.each(filter, function (index, value) {

                        if (column == 'graduations') {
                            params += '&' + column + '[course][' + i + ']=' + value;

                        } else if (column == 'institutions') {

                            params = params.replace('institutions', 'graduations')

                            params += '&graduations[institution][' + i + ']=' + value;

                        } else if (column == 'habilities') {

                            params = params.replace('habilities', 'skills')

                            params += '&skills[description][' + i + ']=' + value;

                        } else if (column == 'city') {

                            params += '&' + 'candidate[city]' + '[' + i + ']=' + value;

                        } else if (column == 'state') {

                            params += '&' + 'candidate[state]' + '[' + i + ']=' + value;

                        } else if (column == 'objective') {

                            params += '&' + 'candidate[objective]' + '[' + i + ']=' + value;

                        } else if (column == 'positions') {

                            params += '&' + 'candidate[position]' + '[' + i + ']=' + value;

                        } else if (column == 'gender') {

                            params += '&' + 'candidate[gender]=' + value;

                        } else {

                            params += '&' + column + '[description][' + i + ']=' + value;
                        }
                        i++;
                    });
                } else {
                    // delete filter
                    filter = undefined
                }

            });

            params += '&' + 'candidate[born_date]=' + self.ageRange[0] + ',' + self.ageRange[1];

            if (this.query.length >= 3) {
                params += '&general_search=' + this.query

                // params += '&mail=' + this.query
            }

            return params;
        }
    },
    mounted: function () {
        this.loadFilters();        
    }
}
</script>

<style>

</style>

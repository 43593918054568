<template>
  <div>
    <div class="card">
      <div class="card-body">
        <section id="wrapper" class="error-page">
          <div class="error-box">
            <div class="error-body text-center">
              <h3 class="text-uppercase">Não foi possível validar sua licença!</h3>

              <p>Entre em contato através do e-mail suporte@innovareti.com.br</p>
              <p>Ou via chat através do nosso canal de atendimento</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  computed: {},
  mounted() {
    this.$store.dispatch("disableLoadModal");
  }
};
</script>

<style>
</style>


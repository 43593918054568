<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">
            {{ $t('label.minhasVagasGerenciadas') }}            
          </h3>
          <br>
          
          <loading-human-flow :loading="loading"></loading-human-flow>

          <div class="row" v-if="!loading">
            <template v-if="jobRoles.length == 0">
              <div class="col-md-12" v-if="!loading">
                <div class="text-center">
                  <span>
                    <strong>{{ $t('label.nenhumResultadoComFiltroInformado') }}</strong>
                  </span>
                </div>
              </div>
            </template>
            <template v-else v-for="job in jobRoles">
              <job-roles-list :jobsObj="job" :onlyMyJobs="omj" @eventListJobs="loadJobList" :key="job.id"></job-roles-list>
            </template>
          </div>          
        </div>
      </div>
    </div>
    <filter-jobs
      @onToggleFilter="onToggleFilter"
      @onSearchTextRequester="onSearchTextRequester"
      @onSearchTextDepartment="onSearchTextDepartment"
      :onlyApprover="onlyApprover"
      :filterJobsMessage="filterJobsMessage"
    ></filter-jobs>
  </div>
</template>

<script>
import LoadingHumanFlow from '../LoadingHumanFlow'
import FilterJobs from '../Filters/FilterJobs'
import JobRolesList from '../JobRoles/JobRolesList'

export default {
  components: {FilterJobs, LoadingHumanFlow, JobRolesList},
  data: () => {    
    return {
      // this.$route.params.id
      loading: false,
      omj: true,
      jobRoles: [],
      jobBenefits: [],
      job: {},
      endPoint: "/my-approval",
      aCheckBoxFilter: [],
      dateOpenFilter: {},
      dateExpFilter: {},
      searchString: "",
      onlyApprover: false,
      filterJobsMessage: "",
      actualStatus: "",
      analytics: {
        completed: 0,
        selection: 0,
        recruitment: 0,
        publication: 0,
        jobDescription: 0,
        approval: 0,
        canceled: 0
      },

      dateOpenObj: {
        date_to: "",
        date_from: ""
      },
      dateExpirationObj: {
        date_to: "",
        date_from: ""
      },
      currentPage: 0,
      numberOfPages: 0,
      orderField: "created_at",
      orderDirection: true
    };
  },

  computed: {
    url: function() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    title: function() {
      return this.onlyApprover
        ? this.$t("label.vagasPendentesAprovacao")
        : this.$t("label.vagas");
    },
    pages: function() {
      let pages = [];

      if (this.numberOfPages > 5 && this.currentPage > 1) {
        for (let i = this.currentPage - 1; i <= this.currentPage + 4; i++) {
          if (i <= this.numberOfPages) {
            pages.push({
              pageNumber: i
            });
          }
        }
      } else if (this.numberOfPages > 5) {
        for (let i = 0; i < 5; i++) {
          if (i <= this.numberOfPages) {
            pages.push({
              pageNumber: i + 1
            });
          }
        }
      } else {
        for (let i = 0; i < this.numberOfPages; i++) {
          pages.push({
            pageNumber: i + 1
          });
        }
      }

      return pages;
    },
    urlAnalytics() {
      return (
        this.$store.getters.apiUrl +
        "/job/analytics/" +
        this.$store.getters.customerId
      );
    }
  },
  mounted: function() {
    // this.forceTabToogle();
    let self = this
    this.$forceUpdate();

    this.onlyApprover = self.$route.meta.onlyApprover;

    this.loadJobList();
  },
  methods: {
    // forceTabToogle() {
    //   $(".nav-item .nav-link").click(function () {
    //     $(".nav-item .nav-link").each(function () {
    //       $(this).removeClass("active show");
    //     });
    //     $(this).addClass("active show");
    //   });
    // },
    orderJobs: function() {
      this.orderDirection = !this.orderDirection;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        false,
        this.orderField,
        this.orderDirection
      );
    },
    previousPage() {
      this.currentPage = this.currentPage - 1;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        true
      );
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        true
      );
    },
    changePage: function(page) {
      this.currentPage = page;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment,
        true
      );
    },
    showModal: function() {
      this.$("#filterJobsModal").modal("show");
    },
    onToggleFilter(aCheckBoxFilter) {
      this.aCheckBoxFilter = aCheckBoxFilter;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment
      );
    },
    onToggleDateFilter(oDateOpenFilter, oDateExpFilter) {
      this.dateOpenFilter = oDateOpenFilter;
      this.dateExpFilter = oDateExpFilter;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        oDateOpenFilter,
        oDateExpFilter,
        this.searchString,
        this.textRequester,
        this.textDepartment
      );
    },
    onSearchText(textQueryString) {
      this.searchString = textQueryString;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        textQueryString,
        this.textRequester,
        this.textDepartment,
        this.orderField
      );
    },
    onSearchTextRequester(textRequester) {
      this.textRequester = textRequester;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        textRequester,
        this.textDepartment,
        this.orderField
      );
    },
    onSearchTextDepartment(textDepartment) {
      this.textDepartment = textDepartment;
      this.loadFilteredJobList(
        this.aCheckBoxFilter,
        this.dateOpenFilter,
        this.dateExpFilter,
        this.searchString,
        this.textRequester,
        textDepartment,
        this.orderField
      );
    },
    loadJobList: function() {
      const self = this;

      self.changeLoading(true);
      
      self.$http
        .get(self.url)
        .then(response => {
          self.jobRoles = response.data.data;
          console.log(self.jobRoles)
          self.currentPage = response.data.page + 1;
          self.numberOfPages = response.data.pages;

          self.changeLoading(false);
          this.getAnalytics();
        })
        .catch(function() {});
    },
    changeLoading: function(value) {
      this.loading = value;
    },
    loadJob(id) {
      const self = this;
      self.$http.get(this.url + id).then(response => {
        self.job = response.data;
      });
    },
    loadFilteredJobListByTabs: function(iStatus, changePage = false) {
      this.changeLoading(true);
      const self = this;

      let params = {
        status: iStatus,
        limit: 15,
        with: ["candidates", "recruiter", "cost_center"],
        orWhere: ["status"],
        orderByAsc: this.orderField
      };

      if (changePage) {
        params.page = this.currentPage;
      }

      self.$http
        .get(self.url, {
          params
        })
        .then(response => {
          self.actualStatus = iStatus;
          self.jobRoles = response.data.data;
          self.currentPage = response.data.page + 1;
          self.numberOfPages = response.data.pages;

          self.changeLoading(false);
        })
        .catch(function() {
          self.$errorMessage(
            "Não foi possível buscar as informações referente ao Status selecionado"
          );
        });
    },
    getAnalytics: function() {
      this.$http.get(this.urlAnalytics).then(response => {
        this.analytics = response.data;
      });
    }
  }  
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title mb-0">
            {{ normalizeStatus(job.status) }} -
            <small>{{job.role_name}}</small>
          </h3>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <div class="logo-image-2 float-left mb-3 mr-4">
                <img :src="job.company.photo" alt="Logo" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="float-left">
                <h3 class="text-left">{{job.role_name}}</h3>
                <p
                  class="small text-muted text-left"
                >{{ job.company.name }} - {{ job.company.city }}, {{ job.company.state }}</p>
                <h5 class="text-left mt-3">{{ quantity(job.quantity) }}</h5>
                <p
                  class="small text-muted text-left"
                >{{ $t('label.anunciadaEm') }} {{ job.initial_date | toBrazilianDateTime }} {{ job.end_date ? '/ Expira em '+job.end_date : '' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5 class="text-justify mb-2">{{ $t('label.comentariosGestaoRS') }}</h5>
              <div id="comments" class="col-md-12">
                <div class="form-group mt-2">
                  <label for="anotacoes" class="control-label mb-1">Comentário</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      name="comments"
                      id="comments"
                      v-model="jobComment"
                      rows="3"
                      placeholder
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
                <button class="btn edit-btn float-right" @click="save" type="button">
                  <i class="fa fa-floppy-o"></i> Salvar
                </button>
                <div class="clear"></div>
                <hr />
                <div class="comments">
                  <div v-for="comment in jobComments" :key="comment.id">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="perfil-image-2 float-left mr-4">
                          <img :src="comment.user.image" alt="Foto Perfil" />
                        </div>
                        <div class="float-left">
                          <p class="text-left text-dark-blue mb-2">
                            {{comment.user.name}}
                            <span
                              class="small text-muted"
                            >em {{comment.created_at}}</span>
                          </p>
                          <p class="text-left mb-0">{{comment.description}}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      // this.$route.params.id
      jobComments: [],
      job: {
        customer: {},
        company: {
          photo: "",
          name: ""
        },
        cost_center: {
          cost_center: ""
        },
        end_date: "",
        status: "",
        approver: {
          name: ""
        },
        approved_at: ""
      },
      endPoint: "/job"
    };
  },
  computed: {
    url: function() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    classObj() {
      return {
        "bg-info": this.job.status == "publication",
        "bg-primary": this.job.status == "inprogress",
        "bg-danger":
          this.job.status == "approval" || this.job.status == "canceled",
        "bg-success":
          this.job.status == "finished" || this.job.status == "selection",
        "bg-warning": this.job.status == "jobDescription"
      };
    },
    aprovedCandidates() {
      let candidates = this.job.candidates.filter(candidate => {
        return candidate.status == 9;
      });
      // console.log(this.job.candidates_exports.filter(exportes => exportes.candidate_id == candidate.candidate_id))

      return candidates.map(candidate => {
        candidate.emailHasBeenSent =
          this.job.candidates_exports.filter(
            exportes =>
              exportes.candidate_id == candidate.candidate_id &&
              exportes.email_sent == 1
          ).length == 0
            ? "Não"
            : "Sim";
        candidate.emailHasBeenAnswered =
          this.job.candidates_exports.filter(
            exportes =>
              exportes.candidate_id == candidate.candidate_id &&
              exportes.email_answered == 1
          ).length == 0
            ? "Não"
            : "Sim";
        candidate.candidateHasBeenExported =
          this.job.candidates_exports.filter(
            exportes =>
              exportes.candidate_id == candidate.candidate_id &&
              exportes.exported_erp == 1
          ).length == 0
            ? "Não"
            : "Sim";
        return candidate;
      });
    }
  },
  mounted: function() {
    this.loadJob();
    this.loadComments(this.$route.params.id);
  },
  methods: {
    save: function() {
      const self = this;

      let fd = self.makeFormData();
      if (!fd) return;

      self.$store.dispatch("tooggleLoadModal");

      self.$http
        .post(self.$store.getters.apiUrl + "/jobCommentsRS/", fd)
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          this.loadComments(this.$route.params.id);
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    makeFormData: function() {
      const self = this;

      let fd = {};

      fd.description = self.jobComment;
      fd.job_id = this.$route.params.id;
      fd.user_id = this.$store.getters.user.id;

      return fd;
    },
    loadComments(id) {
      const self = this;
      const api =
        self.$store.getters.apiUrl + "/jobCommentsRS/?with[]=user&job_id=" + id;

      self.$http
        .get(api)
        .then(response => {
          let jobComments = response.data.data;
          self.jobComments = jobComments;
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });

      return "ok";
    },

    loadJob() {
      let self = this;
      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .get(self.url + "/" + this.$route.params.id, {
          params: {
            with: [
              "company.configintegration",
              "customer.configintegration",
              "branch",
              "cost_center",
              "approver",
              "candidates",
              "candidates.candidate",
              "benefits",
              "candidates_exports",
              "candidatesStages.stage",
              "candidatesStages.candidate.candidate",
              "approvers"
            ]
          }
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          this.job = response.data;
          console.log(this.job);
        })
        .catch(function(error) {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    returnBenefits: function(benefits) {
      let textBenefits = "";
      if (benefits) {
        for (let j = 0; j < benefits.length; j++) {
          textBenefits +=
            benefits[j].description +
            (benefits[j].value ? " - " + benefits[j].value : "");

          if (j < benefits.length - 1) {
            textBenefits += ", ";
          }
        }
      }
      return textBenefits;
    },
    normalizeStatus: function(status) {
      let self = this;
      switch (status) {
        case "approval":
          status = self.$t("label.emAprovacao");
          break;
        case "jobdescription":
          status = self.$t("label.jobDescription");
          break;
        case "publication":
          status = self.$t("label.aguardandoPublicacao");
          break;
        case "selection":
          status = self.$t("label.selecao");
          break;
        case "finished":
          status = self.$t("label.finalizado");
          break;
        case "inedition":
          status = self.$t("label.emEdicao");
          break;
        case "inprogress":
          status = self.$t("label.emRecrutamento");
          break;
        case "canceled":
          status = self.$t("label.cancelado");
          break;
      }
      return status;
    },
    quantity: function(quantity) {
      if (quantity > 1) {
        return quantity + " " + this.$t("label.vagasAPreencher");
      } else {
        return this.$t("label.umaVagaAPreencher");
      }
    }
  },
  filters: {
    toBrazilianDate: function(date) {
      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];
      return dt;
    },
    toBrazilianDateTime: function(dateTime) {
      if (dateTime == null) {
        return "";
      }

      let time = dateTime.substr(11);
      let date = dateTime.substr(0, 10);

      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];
      return dt + " " + time;
    }
  },

  components: {}
};
</script>

<style>
</style>
<template>
  <div>
    <h1>Bem-vindo ao HumanFlow!</h1>
    <div class="alert alert-danger">
      Ele ainda não está instalado na sua plataforma.
      <br />Para iniciar a instalação é necessário ser um usuário adminstrador.
      <br />Favor acessar com um usuário administrador.
      <br />Em caso de dúvidas, envie um e-mail para
      <a href="mailto:fluig@innovareti.com.br">fluig@innovareti.com.br</a> que tentaremos lhe ajudar
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  methods: {},
  components: {}
};
</script>

<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title mb-0">
            {{ normalizeStatus(job.status) }} -
            <small>{{job.role_name}}</small>
          </h3>
        </div>
      </div>
      <div class="card text-dark-blue bg-flat-gray" v-if="job.status == 'canceled'">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5 class="text-justify mb-2">{{ $t('label.motivo') }}</h5>
              <p class="text-justify mb-1">{{ job.cancel_observation }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="logo-image-2 float-left mb-3 mr-4">
                <img :src="job.company.photo" alt="Logo" />
              </div>
              <div class="float-left">
                <h3 class="text-left">{{job.role_name}}</h3>
                <p
                  class="small text-muted text-left"
                >{{ job.company.name }} - {{ job.company.city }}, {{ job.company.state }}</p>
                <h5 class="text-left mt-3">{{ quantity(job.quantity) }}</h5>
                <p
                  class="small text-muted text-left"
                >{{ $t('label.requisitadaEm') }} {{ job.initial_date | toBrazilianDateTime }} {{ job.end_date ? '/ Expira em '+job.end_date : '' }}</p>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row">
                <div class="alert alert-default fade show" role="alert">
                  <span>
                    <strong>{{ $t('label.sequencialDaVaga') }}:</strong>
                    {{job.date_sequence}} - {{job.sequence}}
                  </span>
                </div>
              </div>
              <div v-for="(approver, index) in job.approvers" :key="approver.id">
                <div class="row">
                  <div v-if="job.status_id == 9" class="col-md-12">
                    <div class="alert alert-info">
                      <span class="badge badge-pill badge-info">
                        {{$t('label.vagaReprovadaPor')}} {{ approver.approver.name }} {{$t('label.em')}}
                        {{ new Date(job.approved_at).toLocaleDateString() + " - " + new Date(job.approved_at).toLocaleTimeString() }}
                      </span>
                      {{$t('label.observacao')}}: {{ job.approver_observation }}.
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="name"
                        class="control-label mb-1"
                      >{{$t('label.aprovador')}} {{ index + 1 }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-globe"></i>
                        </div>
                        <input
                          type="text"
                          v-model="approver.user.name"
                          id="name"
                          class="form-control"
                          placeholder
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name" class="control-label mb-1">{{$t('label.status')}}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-globe"></i>
                        </div>
                        <input
                          type="text"
                          v-model="approver.status"
                          id="name"
                          class="form-control"
                          placeholder
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" class="control-label mb-1">{{$t('label.observacao')}}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-globe"></i>
                        </div>
                        <textarea
                          v-model="approver.observation"
                          id="name"
                          class="form-control"
                          placeholder
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray" v-if="job.status == 'finished'">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5 class="text-justify mb-2">
                {{ $t('label.candidatosAprovados') }}
                <button
                  class="btn view-btn p-1 ml-3 float-right"
                  @click="sendEmail"
                  v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                >
                  <i class="fa fa-paper-plane width-23-px"></i>
                  {{ $t('label.enviarEmailRequisitarDados') }}
                </button>
                <button
                  class="btn view-btn p-1 ml-3 float-right"
                  @click="sendCandidateToERP"
                  v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                >
                  <i class="fa fa-cloud-upload width-23-px"></i>
                  {{ $t('label.enviarCandidatoParaOErp') }}
                </button>
              </h5>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t('label.nome') }}</th>
                      <th scope="col">{{ $t('label.email') }}</th>
                      <th scope="col">{{ $t('label.telefone') }}</th>
                      <th scope="col" v-if="job.admission_date">{{ $t('label.dataDeAdmissao') }}</th>
                      <th
                        scope="col"
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ $t('label.emailEnviado') }}</th>
                      <th
                        scope="col"
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ $t('label.emailRespondido') }}</th>
                      <th
                        scope="col"
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ $t('label.candidatoExportado') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="candidate in aprovedCandidates" :key="candidate.id">
                      <th scope="row">{{candidate.candidate.fullName}}</th>
                      <td>{{candidate.candidate.mail}}</td>
                      <td>{{candidate.candidate.phone}}</td>
                      <td
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ candidate.emailHasBeenSent }}</td>
                      <td
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ candidate.emailHasBeenAnswered }}</td>
                      <td
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ candidate.candidateHasBeenExported }}</td>
                      <td v-if="job.admission_date">{{ job.admission_date }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5 class="text-justify mb-2">
                {{ $t('label.candidatosSelecionados') }}
                <button
                  class="btn view-btn p-1 ml-3 float-right"
                  @click="sendEmail"
                  v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                >
                  <i class="fa fa-paper-plane width-23-px"></i>
                  {{ $t('label.enviarEmailRequisitarDados') }}
                </button>
                <button
                  class="btn view-btn p-1 ml-3 float-right"
                  @click="sendCandidateToERP"
                  v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                >
                  <i class="fa fa-cloud-upload width-23-px"></i>
                  {{ $t('label.enviarCandidatoParaOErp') }}
                </button>
              </h5>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t('label.nome') }}</th>
                      <th scope="col">{{ $t('label.email') }}</th>
                      <th scope="col">{{ $t('label.telefone') }}</th>
                      <th scope="col">{{ $t('label.stage') }}</th>
                      <th
                        scope="col"
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ $t('label.emailEnviado') }}</th>
                      <th
                        scope="col"
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ $t('label.emailRespondido') }}</th>
                      <th
                        scope="col"
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ $t('label.candidatoExportado') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="candidate in job.candidates_stages" :key="candidate.id">
                      <th scope="row">{{candidate.candidate.candidate.fullName}}</th>
                      <td>{{candidate.candidate.candidate.mail}}</td>
                      <td>{{candidate.candidate.candidate.phone}}</td>
                      <td>{{candidate.stage.name}}</td>
                      <td
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ candidate.emailHasBeenSent }}</td>
                      <td
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ candidate.emailHasBeenAnswered }}</td>
                      <td
                        v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1"
                      >{{ candidate.candidateHasBeenExported }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label
                  for="horarioTrabalho"
                  class="control-label mb-1"
                >{{ $t('label.horarioTrabalho') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-clock-o"></i>
                  </div>
                  <input
                    type="text"
                    v-model="job.work_schedule"
                    id="horarioTrabalho"
                    class="form-control"
                    placeholder
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="workload" class="control-label mb-1">{{ $t('label.cargaHoraria') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-clock-o"></i>
                  </div>
                  <input
                    type="text"
                    v-model="job.workload"
                    id="workload"
                    class="form-control"
                    placeholder
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="workplace" class="control-label mb-1">{{ $t('label.localTrabalho') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-clock-o"></i>
                  </div>
                  <input
                    type="text"
                    v-model="job.workplace"
                    id="workplace"
                    class="form-control"
                    placeholder
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="workload" class="control-label mb-1">{{ $t('label.motivo') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-clock-o"></i>
                  </div>
                  <input
                    type="text"
                    v-model="job.position_type"
                    id="workload"
                    class="form-control"
                    style="text-transform: uppercase"
                    placeholder
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="workload" class="control-label mb-1">{{ $t('label.estabelecimentos') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-clock-o"></i>
                  </div>
                  <input
                    type="text"
                    v-model="job.establishment"
                    id="workload"
                    class="form-control"
                    style="text-transform: uppercase"
                    placeholder
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5 class="text-justify mb-2">{{ $t('label.justificativa') }}</h5>
              <p class="text-justify mb-1">{{ job.reason_job_observation }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5 class="text-justify mb-2">{{ $t('label.descricaoDaVaga') }}</h5>
              <p class="text-justify">{{ job.role_description }}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.requisitos') }}</h6>
              <p class="text-justify">{{ job.role_requirements }}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.competenciasDesejadas') }}</h6>
              <p class="text-justify">{{ job.differential }}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.competenciasComportamentais') }}</h6>
              <p class="text-justify">{{ job.role_behavioral_skills }}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.informacaoComplementar') }}</h6>
              <p class="text-justify">{{ job.observation }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.departamento') }}</h6>
              <p class="text-justify">{{job.department}}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.centroCusto') }}</h6>
              <p class="text-justify">{{job.cost_center.cost_center}}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.segmentosDeAtuacao') }}</h6>
              <p class="text-justify">{{ job.business_segment}}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.status') }}</h6>
              <p class="text-justify">{{job.status_description}}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.especialidadesDeMercado') }}</h6>
              <p class="text-justify">{{ job.market_info }}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.informacoesAdicionais') }}</h6>
              <p class="text-justify">{{ job.overreach_info || '' }}</p>
              <h6 class="text-justify mb-1 mt-2">{{ $t('label.pontosRelevantes') }}</h6>
              <p class="text-justify">{{ job.profile }}</p>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <h6 class="text-justify mb-2">{{ $t('label.beneficios') }}</h6>
              <p class="text-justify">{{ returnBenefits(job.benefits) }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="text-justify mb-2">{{ $t('label.salario') }}</h6>
              <p class="text-justify">{{ job.position_salary }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="text-justify mb-2">{{ $t('label.regime') }}</h6>
              <p class="text-justify">
                <span class="badge badge-info" v-if="job.clt == 1">{{ $t('label.clt') }}</span>
                <span class="badge badge-info" v-if="job.pj == 1">{{ $t('label.pj') }}</span>
                <span class="badge badge-info" v-if="job.intern == 1">{{ $t('label.interno') }}</span>
                <span
                  class="badge badge-info"
                  v-if="job.temporary == 1"
                >{{ $t('label.temporario') }}</span>
                <span class="badge badge-info" v-if="job.trainee == 1">{{ $t('label.trainee') }}</span>
                <span
                  class="badge badge-info"
                  v-if="job.freelancer == 1"
                >{{ $t('label.freelancer') }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5 class="text-justify mb-2">{{ $t('label.sobreAEmpresa') }}</h5>
              <p class="text-justify mb-1">{{ job.company.about }}</p>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <router-link class="btn view-btn p-1 ml-3 float-right" to="/jobRoles">
                <i class="fa fa-times width-23-px"></i>
                {{ $t('label.voltar') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  data: () => {
    return {
      // this.$route.params.id
      job: {
        customer: {},
        company: {
          photo: "",
          name: ""
        },
        cost_center: {
          cost_center: ""
        },
        end_date: "",
        status: "",
        approver: {
          name: ""
        },
        approved_at: ""
      },
      endPoint: "/job"
    };
  },
  computed: {
    url: function() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    classObj() {
      return {
        "bg-info": this.job.status == "publication",
        "bg-primary": this.job.status == "inprogress",
        "bg-danger":
          this.job.status == "approval" || this.job.status == "canceled",
        "bg-success":
          this.job.status == "finished" || this.job.status == "selection",
        "bg-warning": this.job.status == "jobDescription"
      };
    },
    aprovedCandidates() {
      let candidates = this.job.candidates.filter(candidate => {
        return candidate.status == 9;
      });
      // console.log(this.job.candidates_exports.filter(exportes => exportes.candidate_id == candidate.candidate_id))

      return candidates.map(candidate => {
        candidate.emailHasBeenSent =
          this.job.candidates_exports.filter(
            exportes =>
              exportes.candidate_id == candidate.candidate_id &&
              exportes.email_sent == 1
          ).length == 0
            ? "Não"
            : "Sim";
        candidate.emailHasBeenAnswered =
          this.job.candidates_exports.filter(
            exportes =>
              exportes.candidate_id == candidate.candidate_id &&
              exportes.email_answered == 1
          ).length == 0
            ? "Não"
            : "Sim";
        candidate.candidateHasBeenExported =
          this.job.candidates_exports.filter(
            exportes =>
              exportes.candidate_id == candidate.candidate_id &&
              exportes.exported_erp == 1
          ).length == 0
            ? "Não"
            : "Sim";
        return candidate;
      });
    }
  },
  mounted: function() {
    this.loadJob();
  },
  methods: {
    sendCandidateToERP() {
      this.router.push("/integrationProtheus/" + this.$route.params.id);
    },
    sendEmail() {
      let self = this;

      swal({
        title: self.$t("label.desejaEnviarEmailCandidatosAprovados"),
        text: self.$t("label.oCandidatoIraReceberEmailFormulario"),
        icon: "info",
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: "Sim",
            value: "confirm"
          }
        }
      })
        .then(value => {
          if (value == "confirm") {
            self.$store.dispatch("tooggleLoadModal");
            self.$http
              .post(
                self.$store.getters.apiUrl +
                  "/send-emails-approved/" +
                  self.$route.params.id
              )
              .then(() => {
                self.$store.dispatch("tooggleLoadModal");
              })
              .catch(function(error) {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
              });
          }
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    },
    loadJob() {
      let self = this;
      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .get(self.url + "/" + this.$route.params.id, {
          params: {
            with: [
              "company.configintegration",
              "customer.configintegration",
              "branch",
              "cost_center",
              "approver",
              "candidates",
              "candidates.candidate",
              "benefits",
              "candidates_exports",
              "candidatesStages.stage",
              "candidatesStages.candidate.candidate",
              "approvers"
            ]
          }
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          this.job = response.data;
          console.log(this.job);
        })
        .catch(function(error) {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    returnBenefits: function(benefits) {
      let textBenefits = "";
      if (benefits) {
        for (let j = 0; j < benefits.length; j++) {
          textBenefits +=
            benefits[j].description +
            (benefits[j].value ? " - " + benefits[j].value : "");

          if (j < benefits.length - 1) {
            textBenefits += ", ";
          }
        }
      }
      return textBenefits;
    },
    normalizeStatus: function(status) {
      let self = this;
      switch (status) {
        case "approval":
          status = self.$t("label.emAprovacao");
          break;
        case "jobdescription":
          status = self.$t("label.jobDescription");
          break;
        case "publication":
          status = self.$t("label.aguardandoPublicacao");
          break;
        case "selection":
          status = self.$t("label.selecao");
          break;
        case "finished":
          status = self.$t("label.finalizado");
          break;
        case "inedition":
          status = self.$t("label.emEdicao");
          break;
        case "inprogress":
          status = self.$t("label.emRecrutamento");
          break;
        case "canceled":
          status = self.$t("label.cancelado");
          break;
      }
      return status;
    },
    quantity: function(quantity) {
      if (quantity > 1) {
        return quantity + " " + this.$t("label.vagasAPreencher");
      } else {
        return 1 + " " + this.$t("label.vagaAPreencher");
      }
    }
  },
  filters: {
    toBrazilianDate: function(date) {
      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];
      return dt;
    },
    toBrazilianDateTime: function(dateTime) {
      if (dateTime == null) {
        return "";
      }

      let time = dateTime.substr(11);
      let date = dateTime.substr(0, 10);

      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];
      return dt + " " + time;
    }
  },

  components: {}
};
</script>

<style>
</style>
<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body"> 
                    <h3 class="card-title">{{ $t('label.cadastroDeCargos') }}</h3>
                    <form @submit.prevent>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                        <input type="text" class="form-control" v-model="role.name" placeholder="" autofocus>
                                    </div>                                        
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.salario') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-money"></i></div>
                                        <input type="text" class="form-control salario" v-model="role.salary" placeholder="" v-model.lazy="role.salary" v-money="money"> 
                                    </div>                                        
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="company_id" class="control-label mb-1">{{ $t('label.empresa') }}*</label>                                        
                                    <select name="company_id" v-model="role.company_id" class="form-control">
                                        <option value="">{{ $t('label.selecione') }}...</option>
                                        <option v-for="company in companies" :value="company.id" :key="company.id">{{company.name}}</option>
                                    </select> 
                                </div>
                            </div> 
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.observacao') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-file"></i></div>
                                        <textarea class="form-control" v-model="role.observation" rows="3" placeholder=""></textarea>
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/role"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                                <button @submit.prevent @click="salvar" id="save-btn" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // this.$route.params.id          
            role: {
                id: 0,
                name: '',
                salary: 0,
                observation: '',
                customer_id: this.$store.getters.customerId,
                company_id: '',
            },
            endPoint: "/role/",
            companies: [],
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false /* doesn't work with directive */
            }
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        }
    },
    mounted: function () {
        this.loadRole();        
    },
    methods: {
        getCompany() {
            const self = this;
            const api = self.$store.getters.apiUrl + '/companies';

            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(api, {
                params: {
                    'paginated': false,
                    'customer_id': self.$store.getters.customerId,
                }
            })
            .then((response) => {
                self.$store.dispatch("tooggleLoadModal");
                self.companies = response.data.data
            })
            .catch(() => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(self.$t('label.naoFoiPossivelBuscarInformacoesReferencesAEmpresa'));
            })
        },
        loadRole() {
            const self = this;

            self.getCompany();

            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(this.url)
                .then((response) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.role = response.data;
                }).catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                })
        },
        salvar() {
            const self = this;
            self.$store.dispatch("tooggleLoadModal");
            self.$http.put(self.url, self.role)
            .then(() => {
                self.$store.dispatch("tooggleLoadModal");
                self.$message('success', self.$t('label.sucesso'), self.$t('label.registroAtualizadoComSucesso'));
                this.$router.push({path: "/role/"});
            }).catch((error) => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
            })
        }
    }
}
</script>
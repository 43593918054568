<template>
  <div class="table-responsive">
    <table class="table color-table human-flow-table">
      <thead>
        <tr>
          <td class="pt-0 border-none">
            {{ $t('label.beneficio') }}
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-pencil"></i>
              </div>
              <input type="text" v-model="benefit.name" class="form-control" />
            </div>
          </td>
          <td class="pt-0 border-none">
            {{ $t('label.descricao') }}
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-pencil"></i>
              </div>
              <input type="text" v-model="benefit.value" class="form-control" />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="no-bt">
            <a class="btn include-btn p-0 color-white" @submit.prevent @click="save">
              <i class="fa fa-plus"></i>
              {{ $t('label.incluirBeneficios') }}
            </a>
          </td>
        </tr>
      </thead>
      <slot />
    </table>
  </div>
</template>

<script>
export default {
  props: ["role_template_id", "roleBenefits"],
  data: () => {
    return {
      benefit: {
        id: 0,
        name: "",
        value: "",
        role_template_id: ""
      },
      endPoint: "/roleBenefit/"
    };
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(self.url, {
          name: self.benefit.name,
          value: self.benefit.value,
          role_template_id: self.role_template_id
        })
        .then( () => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message("success", "Sucesso", "Registro salvo com sucesso!");

          self.$emit("updateBenefits", this.benefit);
          self.benefit = {
            id: 0,
            name: "",
            value: "",
            role_template_id: ""
          };
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response.data;

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    }
  },
  components: {}
};
</script>


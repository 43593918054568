<template>
  <div>
    <job-detail :job_id="$route.params.id" :jobObj="Job"></job-detail>
    <div class="col-xl-12" v-if="!loading">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">
            {{ $t('label.jobDescription') }}
            <div class="float-right">
              <button
                class="btn edit-btn"
                type="button"
                @click="openCommentsModal"
              >{{ $t('label.comentariosGestaoRS') }}</button>
            </div>
            <div class="float-right">
              <button
                class="btn edit-btn"
                type="button"
                @click="openJobDetailModal"
              >{{ $t('label.detalhesDaVaga') }}</button>
            </div>
          </h3>
          <div class="row">
            <div class="col-md-3 form-group">
              <label class="control-label mb-1">{{ $t('label.prazo') }}*</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <input type="date" v-model="Job.prazo" :min="value_min" class="form-control" />
              </div>
            </div>
            <div class="col-md-9 form-group">
              <label class="control-label mb-1">{{ $t('label.segmentosDeAtuacao') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <input type="text" v-model="Job.business_segment" class="form-control" />
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{ $t('label.descricao') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <textarea v-model="Job.role_description" class="form-control"></textarea>
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{ $t('label.requisitos') }}*</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <textarea v-model="Job.role_requirements" class="form-control"></textarea>
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label mb-1">{{ $t('label.competenciasDesejadas') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <textarea v-model="Job.differential" class="form-control" rows="2"></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 form-group">
              <label class="control-label mb-1">{{ $t('label.pontosRelevantes') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <input type="text" v-model="Job.profile" class="form-control" />
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label class="control-label mb-1">{{ $t('label.especialidadesDeMercado') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <input type="text" v-model="Job.market_info" class="form-control" />
              </div>
            </div>

            <div class="col-md-12 form-group">
              <label class="control-label mb-1">{{ $t('label.observacoesAdicionais') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <textarea v-model="Job.complements" class="form-control"></textarea>
              </div>
            </div>
            <!-- <div class="col-md-6 form-group">
              <label class="control-label mb-1">{{ $t('label.selecioneQuestionario') }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <select class="form-control" v-model="Job.questionnaire_id">
                  <option>Selecione...</option>
                  <option
                    v-for="questionnaire in questionnaires"
                    :key="questionnaire.id"
                    :value="questionnaire.id"
                  >{{questionnaire.name}}</option>
                </select>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="footer-requisition">
      <div class="background-buttons">
        <button type="button" @click="save" class="btn select-btn mt-0">
          <i class="fa fa-check width-21-px"></i>
          {{$t('label.finalizar')}}
        </button>
        <router-link class="btn delete-btn mt-0" to="/jobRoles">
          <i class="fa fa-close width-23-px"></i>
          {{$t('label.voltar')}}
        </router-link>
      </div>
    </div>

    <!-- Modal de comentariosGestaoRS -->
    <div
      class="modal fade"
      id="modalComments"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{ $t('label.comentariosGestaoRS') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="comments">
              <div v-for="comment in jobComments" :key="comment.id">
                <div class="row">
                  <div class="col-md-12">
                    <div class="perfil-image-2 float-left mr-4">
                      <img :src="comment.user.image" alt="Foto Perfil" />
                    </div>
                    <div class="float-left">
                      <p class="text-left text-dark-blue mb-2">
                        {{comment.user.name}}
                        <span
                          class="small text-muted"
                        >em {{comment.created_at}}</span>
                      </p>
                      <p class="text-left mb-0">{{comment.description}}</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobDetail from "../JobRecruitment/JobDetail";

export default {
  components: { JobDetail },
  data: () => {
    return {
      loading: true,
      jobComments: [],
      questionnaires: [],
      Job: {
        profile: "",
        market_info: "",
        prazo: "",
        desired_age: "",
        desired_sex: "",
        internal_candidate: "",
        initial_date: "",
        business_segment: "",
        complements: "",
        company_name: "",
        company_location: "",
        end_date: "",
        requester: "",
        role_name: "",
        quantity: "",
        role_description: "",
        position_new_renew: "",
        role_requirements: "",
        differential: "",
        observations: "",
        role_benefits: [],
        position_salary: "",
        position_type: "",
        show_salary: "",
        to_define_salary: "",
        clt: "",
        pj: "",
        intern: "",
        temporary: "",
        freelancer: "",
        trainee: "",
        internal_start_date: "",
        internal_end_date: "",

        branch: {
          name: ""
        },
        cost_center: {
          name: ""
        },
        company: {
          photo: ""
        },
        approver: {
          name: ""
        }
      },
      endPoint: "/job/",
      post_EndPoint: "/releaseJob",
      value_min: ""
    };
  },
  computed: {
    url: function() {
      return this.$store.getters.apiUrl + this.endPoint;
    },
    urlPost: function() {
      return this.$store.getters.apiUrl + this.post_EndPoint;
    },
    urlFluig: function() {
      return "/bpm/api/v1/requests/";
    }
  },
  mounted: function() {
    // let elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
    let self = this;

    this.$(".js-switch").each(function() {
      new Switchery(self.$(this)[0], self.$(this).data());
    });

    this.loadJob();
    this.dataRetroactive();
    this.loadComments(this.$route.params.id);
    this.loadQuestionnaires();
  },
  methods: {
    loadComments(id) {
      const self = this;
      const api =
        self.$store.getters.apiUrl + "/jobCommentsRS/?with[]=user&job_id=" + id;

      self.$http
        .get(api)
        .then(response => {
          let jobComments = response.data.data;
          self.jobComments = jobComments;
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });

      return "ok";
    },
    loadQuestionnaires() {
      const self = this;
      const api = self.$store.getters.apiUrl + "/questionnaire";

      self.$http
        .get(api)
        .then(response => {
          let questionnaires = response.data.data;
          self.questionnaires = questionnaires;
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });

      return "ok";
    },
    openJobDetailModal() {
      this.$("#jobDetailModal").modal("show");
    },
    openCommentsModal() {
      this.$("#modalComments").modal("show");
    },
    dataRetroactive() {
      const self = this;

      var d = new Date().toLocaleDateString().split("/");
      var y = d.splice(-1)[0];

      d.reverse();
      d.splice(0, 0, y);

      self.value_min = d.join("-");
    },
    loadJob() {
      const self = this;
      self.loading = true;
      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .get(self.url + this.$route.params.id, {
          params: {
            with: ["company", "approver", "cost_center", "branch", "benefits", "approvers"]
          }
        })
        .then(response => {
          self.Job = response.data;
          self.$store.dispatch("tooggleLoadModal");
          self.loading = false;
        })
        .catch(function(error) {
          self.$errorMessage(error);
        });
    },
    save: function() {
      const self = this;
      // let job_id = self.$route.params.id ? self.$route.params.id : '';
      let Job = self.Job;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(self.urlPost, {
          jobId: Job.id,
          prazo: Job.prazo,
          perfil_candidato: Job.profile,
          informacoes_mercado: Job.market_info,
          candidato: Job.internal_candidate,
          prazoInicial: Job.internal_start_date,
          prazoFinal: Job.internal_end_date,
          segmentos: Job.business_segment,
          observacoes: Job.complements,
          role_description: Job.role_description,
          role_requirements: Job.role_requirements,
          differential: Job.differential,
          questionnaire_id: Job.questionnaire_id
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );

          this.$router.push({
            path: "/jobRoles/"
          });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    delBenefits: function(index) {
      this.Job.role_benefits.splice(index, 1);
    },
    AddBenefits: function() {
      this.Job.role_benefits.push({
        name: "",
        value: ""
      });
    }
  }
};
</script>
import VueRouter from 'vue-router'

import IntegrationProtheus from '../components/Integration/IntegrationProtheus'
import Installation from '../components/Installation/Installation'
import NotAdmin from '../components/Errors/NotAdmin'
import License from '../components/Errors/License'
import Activation from '../components/Installation/Activation'
import DashRecruiter from '../components/DashRecruiter/DashRecruiter'

import Role from '../components/Role/Role'
import RoleAdd from '../components/Role/RoleAdd'
import RoleEdit from '../components/Role/RoleEdit'
import RoleView from '../components/Role/RoleView'
import Companies from '../components/Companies/Companies'
import CompaniesAdd from '../components/Companies/CompaniesAdd'
import CompaniesEdit from '../components/Companies/CompaniesEdit'
import CompaniesView from '../components/Companies/CompaniesView'
import Branchs from '../components/Branchs/Branchs'
import BranchsAdd from '../components/Branchs/BranchsAdd'
import BranchsEdit from '../components/Branchs/BranchsEdit'
import BranchsView from '../components/Branchs/BranchsView'


import Workload from '../components/Workload/Workload'
import WorkloadAdd from '../components/Workload/WorkloadAdd'

import CostCenters from '../components/CostCenters/CostCenters'
import CostCentersAdd from '../components/CostCenters/CostCentersAdd'
import CostCentersEdit from '../components/CostCenters/CostCentersEdit'
import CostCentersView from '../components/CostCenters/CostCentersView'

import EmailTemplates from '../components/EmailTemplates/EmailTemplates'
import EmailTemplatesAdd from '../components/EmailTemplates/EmailTemplatesAdd'

import RoleTemplate from '../components/RoleTemplate/RoleTemplate'
import RoleTemplateAdd from '../components/RoleTemplate/RoleTemplateAdd'
import RoleTemplateEdit from '../components/RoleTemplate/RoleTemplateEdit'
import RoleTemplateView from '../components/RoleTemplate/RoleTemplateView'
import RoleTemplateStages from '../components/RoleTemplate/RoleTemplateStages'
import RoleStage from '../components/RoleStages/RoleStage'

import RoleStageEdit from '../components/RoleStages/RoleStageEdit'
import RoleBenefits from '../components/RoleBenefits/RoleBenefits'
import RoleBenefitsAdd from '../components/RoleBenefits/RoleBenefitsAdd'
import JobView from '../components/JobView/JobView'
import Comments from '../components/JobView/Comments'
import JobRequisitionAdd from '../components/JobRequisition/JobRequisitionAdd'
import JobDescriptionAdd from '../components/JobDescription/JobDescriptionAdd'
import JobRole from '../components/JobRoles/JobRoles'
import JobMyRoles from '../components/JobRoles/JobMyRoles'
import JobMyApproval from '../components/JobRoles/JobMyApproval'
import InternalVacancies from '../components/InternalVacancies/InternalVacancies'
import JobRoleApproval from '../components/JobRoles/JobRolesApproval'
import JobApproval from '../components/JobApproval/JobApproval'
import JobRoleDisapproval from '../components/JobRoles/JobRolesDisapproval'
import CandidatesListPage from '../components/Candidates/CandidatesListPage'
import JobPublication from '../components/JobPostSelection/JobPublication'
import JobRecruitment from '../components/JobRecruitment/JobRecruitment'
import Restricted from '../components/Restricted/Restricted'
import RestrictedAdd from '../components/Restricted/RestrictedAdd'
import RestrictedEdit from '../components/Restricted/RestrictedEdit'
import RestrictedView from '../components/Restricted/RestrictedView'
import Blacklist from '../components/Blacklist/Blacklist'
import BlacklistAdd from '../components/Blacklist/BlacklistAdd'
import BlacklistEdit from '../components/Blacklist/BlacklistEdit'
import BlacklistView from '../components/Blacklist/BlacklistView'
import Questionnaire from '../components/Questionnaire/Questionnaire'
import QuestionnaireAdd from '../components/Questionnaire/QuestionnaireAdd'
import QuestionnaireEdit from '../components/Questionnaire/QuestionnaireEdit'
import QuestionnaireView from '../components/Questionnaire/QuestionnaireView'
import VideoInterview from '../components/Video/VideoInterview'
import VideoInterviewAdd from '../components/Video/VideoInterviewAdd'
import VideoInterviewEdit from '../components/Video/VideoInterviewEdit'
import VideoInterviewView from '../components/Video/VideoInterviewView'
import Error404 from '../components/Errors/404'
import NotAllowed from '../components/Errors/NotAllowed'
import KanbanSelection from '../components/KanbanSelection/KanbanSelection'
import RecruitersManager from '../components/User/RecruitersManager'
import UsersManager from '../components/User/UsersManager'
import Profile from '../components/User/Profile'
import Integrations from '../components/Integrations/Integrations'
import IntegrationsAdd from '../components/Integrations/IntegrationsAdd'
import IntegrationsEdit from '../components/Integrations/IntegrationsEdit'
import IntegrationsConfig from '../components/Integrations/IntegrationsConfig'
import IntegrationsManager from '../components/Integrations/IntegrationsManager'
import Login from '../components/Login/Login'
import Users from '../components/Users/Users'
import UsersAdd from '../components/Users/UsersAdd'
import UsersEdit from '../components/Users/UsersEdit'
import UsersView from '../components/Users/UsersView'
import JobManagement from '../components/JobManagement/JobManagement'
import TalentBank from '../components/TalentBank/Crud'
import CVBank from '../components/CVBank/Crud'
import Accounts from '../components/Account/Crud'
import AccountAdd from '../components/Account/Add'
import AccountEdit from '../components/Account/Edit'
import AccountView from '../components/Account/View'

import Portal from '../components/Portal/Portal'

const routes = [
    {
        path: '/email_templates/',
        component: EmailTemplates,
        name: 'route.email_templates',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/email_templates/add',
        component: EmailTemplatesAdd,
        name: 'route.email_templatesAdd',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/email_templates/edit/:id',
        component: EmailTemplatesAdd,
        name: 'route.email_templatesAdd',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/integrationProtheus/:id',
        component: IntegrationProtheus,
        name: 'route.integracoesProtheus',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: "/installation",
        component: Installation,
        name: 'route.instalacao',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: "/notAdmin",
        component: NotAdmin,
        name: 'Erro Instalação',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: "/license",
        component: License,
        name: 'Licença',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: "/activation",
        component: Activation,
        name: 'route.ativacao',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: "/",
        component: DashRecruiter,
        name: 'route.dashboard',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: "/talent_bank",
        component: TalentBank,
        name: 'route.talent_bank',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: "/cv_bank",
        component: CVBank,
        name: 'route.cv_bank',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/role',
        component: Role,
        name: 'route.cargos',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/role/add',
        component: RoleAdd,
        name: 'route.novoCargo',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/role/edit/:id',
        component: RoleEdit,
        name: 'route.editarCargo',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/role/view/:id',
        component: RoleView,
        name: 'route.consultarCargo',
        meta: {
            recruiter: true,
            user: false
        }
    },

    {
        path: '/portal',
        component: Portal,
        name: 'route.portal',
        meta: {
            recruiter: true,
            user: false
        }
    },

    //customer components
    {
        path: '/companies',
        component: Companies,
        name: 'route.empresas',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/companies/add',
        component: CompaniesAdd,
        name: 'route.novaEmpresa',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/companies/edit/:id',
        component: CompaniesEdit,
        name: 'route.editarEmpresa',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/companies/view/:id',
        component: CompaniesView,
        name: 'route.consultarEmpresa',
        meta: {
            recruiter: true,
            user: false
        }
    },

    //customer estabelecimento
    {
        path: '/branchs',
        component: Branchs,
        name: 'route.estabelecimentos',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/branchs/add',
        component: BranchsAdd,
        name: 'route.novoEstabelecimento',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/branchs/edit/:id',
        component: BranchsEdit,
        name: 'route.editarEstabelecimento',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/branchs/view/:id',
        component: BranchsView,
        name: 'route.consultarEstabelecimento',
        meta: {
            recruiter: true,
            user: false
        }
    },
    //fim branchs

    {
        path: '/workload',
        component: Workload,
        name: 'route.workload',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/workload/add',
        component: WorkloadAdd,
        name: 'route.workload',
        meta: {
            recruiter: true,
            user: false
        }
    },

    //customer cost
    {
        path: '/cost-centers',
        component: CostCenters,
        name: 'route.centroCusto',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/cost-centers/add',
        component: CostCentersAdd,
        name: 'route.novosCentrosCusto',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/cost-centers/edit/:id',
        component: CostCentersEdit,
        name: 'route.editarCentroCusto',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/cost-centers/view/:id',
        component: CostCentersView,
        name: 'route.consultarCentrosCusto',
        meta: {
            recruiter: true,
            user: false
        }
    },
    //fim costs

    // Role
    {
        path: '/roleTemplate',
        component: RoleTemplate,
        name: 'route.templates',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/roleTemplate/add',
        component: RoleTemplateAdd,
        name: 'route.novoTemplate',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/roleTemplate/edit/:id',
        component: RoleTemplateEdit,
        name: 'route.editarTemplate',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/roleTemplate/view/:id',
        component: RoleTemplateView,
        name: 'route.consultarTemplate',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/roleTemplate/stages/:id',
        component: RoleTemplateStages,
        name: 'route.consultarEtapas',
        meta: {
            recruiter: true,
            user: false
        }
    },


    {
        path: '/roleStage',
        component: RoleStage,
        name: 'Etapas do Template',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/roleStage/edit/:id',
        component: RoleStageEdit,
        name: 'Editar Etapa',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/roleBenefits',
        component: RoleBenefits,
        name: 'Beneficios do template',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/roleBenefits/add',
        component: RoleBenefitsAdd,
        name: 'Novo Beneficio',
        meta: {
            recruiter: true,
            user: false
        }
    },

    {
        path: '/job/:id',
        component: JobView,
        name: 'route.visualizarVaga',
        meta: {
            recruiter: true,
            user: true
        }
    },

    {
        path: '/jobComments/:id',
        component: Comments,
        name: 'route.commentsVaga',
        meta: {
            recruiter: true,
            user: true
        }
    },

    {
        path: '/jobManagement/:id',
        component: JobManagement,
        name: 'route.gestaoDaVaga',
        meta: {
            recruiter: true,
            user: false
        }
    },

    {
        path: '/jobRequisition',
        component: JobRequisitionAdd,
        name: 'route.requisitarVaga',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/jobDescription/:id',
        component: JobDescriptionAdd,
        name: 'route.jobDescription',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/jobRoles',
        component: JobRole,
        name: 'route.vagas',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/jobMyRoles',
        component: JobMyRoles,
        name: 'route.minhasVagas',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/jobMyApproval',
        component: JobMyApproval,
        name: 'route.minhasVagas',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/profile',
        component: Profile,
        name: 'route.meuPerfil',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/internalVacancies',
        component: InternalVacancies,
        name: 'route.vagasInternas',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/jobRoles/approval',
        component: JobRoleApproval,
        name: 'route.vagasEmAprovacao',
        meta: {
            recruiter: true,
            user: true,
            onlyApprover: true,
        }
    },
    {
        path: '/jobRoles/approval/:id',
        component: JobApproval,
        name: 'route.aprovarVaga',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/jobRoles/disapproval',
        component: JobRoleDisapproval,
        name: 'route.vagasReprovadas',
        meta: {
            recruiter: true,
            user: true,
            onlyApprover: true,
        }
    },
    {
        path: '/jobRoles/candidates/:id',
        component: CandidatesListPage,
        name: 'Lista de Candidatos',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/jobRoles/publication/:id',
        component: JobPublication,
        name: 'route.publicarVaga',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/jobRoles/view/role/:id',
        component: JobRecruitment,
        name: 'route.triagem',
        meta: {
            recruiter: true,
            user: true,
        }
    },
    {
        path: '/restricted',
        component: Restricted,
        name: 'Empresas Restritas',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/restricted/add',
        component: RestrictedAdd,
        name: 'Nova Empresa Restrita',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/restricted/edit/:id',
        component: RestrictedEdit,
        name: 'Editar Empresa Restrita',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/restricted/view/:id',
        component: RestrictedView,
        name: 'Consultar Empresa Restrita',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/blacklist',
        component: Blacklist,
        name: 'Candidatos Blacklist',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/blacklist/add',
        component: BlacklistAdd,
        name: 'Novo Candidato',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/blacklist/edit/:id',
        component: BlacklistEdit,
        name: 'Editar Candidato',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/blacklist/view/:id',
        component: BlacklistView,
        name: 'Consultar Candidato',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/questionnaire',
        component: Questionnaire,
        name: 'route.questionarios',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/questionnaire/add',
        component: QuestionnaireAdd,
        name: 'route.adicionarQuestionario',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/questionnaire/edit/:id',
        component: QuestionnaireEdit,
        name: 'route.editarQuestionario',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/questionnaire/view/:id',
        component: QuestionnaireView,
        name: 'route.consultarQuestionario',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/video',
        component: VideoInterview,
        name: 'Perguntas em Vídeo',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/video/add',
        component: VideoInterviewAdd,
        name: 'Nova Questão',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/video/edit/:id',
        component: VideoInterviewEdit,
        name: 'Editar Questão',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/video/view/:id',
        component: VideoInterviewView,
        name: 'Consultar Questão',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/404',
        component: Error404,
        name: '404 Not Found',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/notAllowed',
        component: NotAllowed,
        name: 'Não Permitido',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: '/selection/view/:id',
        component: KanbanSelection,
        name: 'route.selecao',
        meta: {
            recruiter: true,
            user: true
        }
    },

    {
        path: '/recruiters-manager',
        component: RecruitersManager,
        name: 'route.gerenciamentoRecrutadores',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/users-manager',
        component: UsersManager,
        name: 'route.gerenciamentoGestoresArea',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/integrations',
        component: Integrations,
        name: 'route.integracoes',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/integrations/add',
        component: IntegrationsAdd,
        name: 'route.novaIntegracao',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/integrations/edit/:id',
        component: IntegrationsEdit,
        name: 'route.editarIntegracao',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/integrations/config/:id',
        component: IntegrationsConfig,
        name: 'route.integracoesConfig',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/integrations/config/:id/manager/:integration_id',
        component: IntegrationsManager,
        name: 'route.integracoesManager',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: "/login",
        component: Login,
        name: 'route.login',
        meta: {
            recruiter: true,
            user: true
        }
    },
    {
        path: "/login-email",
        component: Login,
        name: 'route.login-email',
    },

    //Users
    {
        path: '/users',
        component: Users,
        name: 'route.usuarios',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/users/add',
        component: UsersAdd,
        name: 'route.novosUsuarios',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/users/edit/:id',
        component: UsersEdit,
        name: 'route.editarUsuario',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/users/view/:id',
        component: UsersView,
        name: 'route.consultarUsuario',
        meta: {
            recruiter: true,
            user: false
        }
    },
    //fim users

    //Accounts
    {
        path: '/accounts',
        component: Accounts,
        name: 'route.licencas',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/accounts/add',
        component: AccountAdd,
        name: 'route.novaLicenca',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/accounts/edit/:id',
        component: AccountEdit,
        name: 'route.editarLicenca',
        meta: {
            recruiter: true,
            user: false
        }
    },
    {
        path: '/accounts/view/:id',
        component: AccountView,
        name: 'route.consultarLicenca',
        meta: {
            recruiter: true,
            user: false
        }
    },
    //fim users
];

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: "",
    hashbang: false,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
});

export default router
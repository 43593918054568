<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ $t('label.cadastroEmpresas') }}</h3>

                    <div class="row">
                        <div class="col-md-8">
                            <p>{{ $t('label.mensagemCadastroEmpresa') }}</p>
                        </div>
                        <div class="col-md-4">
                            <router-link class="btn add-btn p-0 mb-3 float-right" to="/companies/add"><i class="fa fa-plus-square"></i> {{ $t('label.cadastrar') }}</router-link>                                
                        </div>
                    </div>

                    <v-server-table ref="table" :name="table" :url="url" :data="tableData" :columns="columns" :options="options">
                        <div slot="photo" slot-scope="props">
                            <img class="slot-photo" :src="changeUrlCache(props.row.photo)" >
                        </div>

                        <div slot="actions" slot-scope="props">
                            <button type="button" class="btn view-btn p-0" @click="view(props.row.id)" :title="$t('label.visualizar')"><i class="fa fa-eye"></i></button>
                            <button type="button" class="btn edit-btn p-0" @click="edit(props.row.id)" :title="$t('label.editar')"><i class="fa fa-pencil"></i></button>
                            <button type="button" class="btn delete-btn p-0" @click="destroy(props, props.row, props.index)" :title="$t('label.deletar')"><i class="fa fa-times"></i></button>
                        </div>
                    </v-server-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert'

export default {
    data() {
        return {
            message: '',
            columns: ['photo', 'name', 'city', 'state', 'actions'],
            tableData: [],
            options: {
                headings: {
                    'photo': this.$t('label.logo'),
                    'name': this.$t('label.nome'),
                    'city': this.$t('label.cidade'),
                    'state': this.$t('label.estado'),
                    'actions': this.$t('label.acoes')
                },
                debounce: 1000,
                texts: {
                    count: `${this.$t('label.mostrando')} {from} ${this.$t('label.de')} {to} ${this.$t('label.ate')} {count} ${this.$t('label.deRegistros')}`,
                    filter: false,
                    filterPlaceholder: this.$t('label.pesquisarAqui'),
                    limit: '',
                    noResults: this.$t('label.nenhumRegistroEncontrado'),
                    page: this.$t('label.pagina')+':'
                },
                columnsClasses: {
                    actions: 'column-actions',
                    photo: 'column-photo',
                },
                requestFunction: function (data) {
                    let self = this;
                    let requestData = {};
                    requestData.params = data;

                    self.$store.dispatch("tooggleLoadModal"); 
                    return this.$http.get(this.url, requestData)
                    .then(data => {                        
                        self.$store.dispatch("tooggleLoadModal"); 
                        return data
                    })
                    .catch((error) => {    
                        self.$store.dispatch("tooggleLoadModal"); 
                        self.$errorMessage(error);
                    })

                },
            },
            endPoint: "/companies/",
            enableView: true,
            enableEdit: true,
            enableDelete: true,
            name: 'companiesTable',
            table: "companiesTable"
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint;
        }
    },
    methods: {
        changeUrlCache(photo) {
            return photo + '?hash=' + Math.random()
        },
        edit(id) {
            this.$router.push({
                path: this.endPoint + 'edit/' + id
            });
        },
        view(id) {
            this.$router.push({
                path: this.endPoint + 'view/' + id
            });
        },
        destroy(props, row) {
            const self = this;

            swal({
                title: self.$t('label.desejaDeletarRegistro'),
                text: self.$t('label.essaOperacaoNaoPodeSerDesfeita'),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    catch: {
                        text: self.$t('label.nao'),
                        value: "cancel",
                        className: "btn-danger"
                    },
                    confirm: {
                        text: self.$t('label.sim'),
                        value: 'confirm'
                    }
                },
            })
                .then((value) => {
                    if (value == 'confirm') {
                        self.$store.dispatch("tooggleLoadModal");                        
                        self.$http
                            .delete(this.url + row.id, {
                                params: {
                                    'id': row.id,
                                    'customer_id': self.$store.getters.customerId
                                }
                            })
                            .then(() => {
                                self.$store.dispatch("tooggleLoadModal");
                                self.$refs.table.refresh();
                                self.$message('success',  self.$t('label.sucesso'),  self.$t('label.registroExcluidoComSucesso'));                                
                            })
                            .catch(error => {  
                                self.$store.dispatch("tooggleLoadModal");                              
                                swal(self.$t('label.ocorreuUmErro'), error.response.data, 'error');
                            })
                    }
                });
        }
    }
}
</script>

<style>

</style>

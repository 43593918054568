<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-4">
        <div class="input-group" v-if="!readonly">
          <input
            type="text"
            id="adicionarCampo"
            name="adicionarCampo"
            :placeholder="$t('label.adicionarCampo')"
            class="form-control"
            v-model="stage.name"
          />
          <div class="input-group-btn">
            <button class="btn form-btn ml-3" @click="stageAdded">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <div class="input-group-btn">
            <button class="btn form-btn ml-3" @click="updateAllStages">
              <i class="fa fa-floppy-o"></i>
              {{ $t('label.salvarOrdenacao') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ul class="sortable">
      <draggable :list="rolesStages">
        <li v-for="(stage,index) in rolesStages" :key="stage.id">
          <i class="fa fa-arrows"></i>

          <span :id="'stage' + stage.id">
            <label
              v-if="!editing_stage || index != editing_index"
            >{{ index + 1 }} - {{ stage.name }}</label>
            <input
              @keyup.enter="update(stage)"
              @keyup.esc="cancelUpdate"
              class="bd-r-3"
              type="text"
              v-if="editing_stage && index == editing_index"
              v-model="stage.name"
            />
          </span>

          <div class="float-right" v-if="!readonly">
            <button class="btn view-btn p-0" @click="loadStage(stage.id)">
              <i class="fa fa-cog"></i>
            </button>

            <button
              class="btn edit-btn p-0"
              v-if="!editing_stage || index != editing_index"
              @click="editing_stage = true; editing_index = index; editing_stage_obj = Object.assign({}, rolesStages[editing_index])"
            >
              <i class="fa fa-pencil"></i>
            </button>

            <button
              class="btn edit-btn p-0"
              v-if="editing_stage && index == editing_index"
              @click="update(stage)"
            >
              <i class="fa fa-check"></i>
            </button>

            <button class="btn delete-btn p-0" @click="remove(stage,index)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </li>
      </draggable>
    </ul>
    <div class="row" v-if="!readonly">
      <div class="col-md-12">
        <p class="small text-muted">
          {{ $t('label.arrasteSolteOrderDesejadaDepoisCliqueBotao') }}
          <b>{{ $t('label.salvarOrdenacao') }}</b>
        </p>
      </div>
    </div>

    <div
      class="modal fade"
      id="typeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('label.configuracaoEtapa') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 form-group">
                <label>{{ $t('label.tipo') }}</label>
                <select name="type" v-model="stageModal.type" class="form-control">
                  <option value="1">{{ $t('label.comum') }}</option>
                  <option value="2">{{ $t('label.teste') }}</option>
                  <option value="3">{{ $t('label.entrevista') }}</option>
                  <option value="4">{{ $t('label.convite_ics') }}</option>
                  <option value="5">{{ $t('label.aprovacao_gestor') }}</option>
                  <option value="6">{{ $t('label.enviarWhatsapp') }}</option>
                </select>
              </div>

              <div class="col-md-12 form-group">
                <label>{{ $t('label.perguntas') }}</label>
                <select
                  v-model="stageModal.questionnaire_id"
                  :disabled="stageModal.type != 2"
                  class="form-control"
                >
                  <option value disabled>{{ $t('label.selecione') }}...</option>
                  <option
                    v-for="question in questions"
                    :value="question.id"
                    :key="question.id"
                  >{{question.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn include-btn p-1 ml-3 float-right" @click="saveStage">
              <i class="fa fa-floppy-o"></i>
              {{ $t('label.salvar') }}
            </button>
            <button
              type="button"
              class="btn include-btn p-1 ml-3 float-right"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
              {{ $t('label.fechar') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  components: { Draggable },
  props: ["role", "roleTemplateId", "readonly"],
  data: () => {
    return {
      editing_stage: false,
      editing_index: "",
      editing_stage_obj: {},
      message: "",
      rolesStages: [],
      draggableList: [],
      templateId: "",
      nextItem: "",
      template_id: "",
      name: "roleStages",
      endPoint: "/roleStage/",
      stageModal: {},
      questions: [],
      stage: {
        name: ""
      }
    };
  },
  mounted: function() {
    this.loadStages();
    this.loadQuestions();
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    loadQuestions() {
      let self = this;
      self.$http
        .get(this.$store.getters.apiUrl + "/questionnaire", {
          params: {
            paginated: false
          }
        })
        .then(response => {
          self.questions = response.data.data;
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    },
    stageAdded() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(self.url, {
          name: self.stage.name,
          order: self.nextItem,
          role_template_id: self.roleTemplateId
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
          self.stage.name = "";
          self.nextItem = response.data.order;
        })
        .then(() => {
          this.loadStages();
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response.data;

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
    generateList: function() {
      const self = this;
      let result = [];

      self.$.each(self.rolesStages, function(index, stage) {
        let elem = {};
        elem.id = stage.id;
        elem.order = index + 1;

        result.push(elem);
      });

      return result;
    },
    loadStage(id) {
      this.stageModal = this.rolesStages.filter(stage => {
        return stage.id == id;
      })[0];
      this.$("#typeModal").modal("toggle");
    },
    loadStages() {
      const self = this;
      self.template_id = self.$route.params.id;

      // self.$store.dispatch("tooggleLoadModal");
      self.$http
        .get(self.url + "?orderByAsc=order", {
          params: {
            role_template_id: self.template_id,
            with: ["questionnaire"],
            paginated: false
          }
        })
        .then(response => {
          // self.$store.dispatch("tooggleLoadModal");
          self.rolesStages = response.data.data;
          self.nextItem = self.rolesStages.length + 1;
        })
        .catch(function() {
          // self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "error",
            null,
            self.$t("label.naoFoiPossivelCarregarAsEtapas")
          );
        });
    },
    update(stage) {
      let self = this;
      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .put(this.url + stage.id, {
          id: stage.id,
          name: stage.name,
          role_template_id: stage.role_template_id
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          // this.rolesStages.splice(index, 1);
          this.editing_stage = false;
          this.editing_index = "";
          this.editing_stage_obj = {};

          this.loadStages();
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroAtualizadoComSucesso")
          );
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    remove(stage, index) {
      let self = this;
      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .delete(this.url + stage.id, {
          id: stage.id
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          this.rolesStages.splice(index, 1);
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroExcluidoComSucesso")
          );
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    updateAllStages() {
      const self = this;
      let stages = self.generateList();

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(self.url + "update/all", {
          role_template_id: self.$route.params.id,
          stages: stages
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
        })
        .then(() => {
          self.loadStages();
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response.data;

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
    cancelUpdate() {
      if (this.rolesStages.length > 0 && this.editing_stage) {
        this.rolesStages[this.editing_index] = this.editing_stage_obj;
      }

      this.editing_stage = false;
      this.editing_index = "";
      this.editing_stage_obj = {};
    },
    saveStage() {
      let self = this;

      if (self.stageModal.type != 2) {
        delete self.stageModal.questionnaire_id;
      }
      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .put(self.url + self.stageModal.id, self.stageModal)
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$("#typeModal").modal("hide");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroAtualizadoComSucesso")
          );
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    }
  }
};
</script>

<template>
    <div class="card">
        <div class="card-header bg-info">
            <h4 class="text-white">Empresas Restritas</h4>
        </div>
        <div class="card-body">
            <form method="post" class="form-material" @submit.prevent>
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="nomeEmpresa"><span class="required">*</span> Nome da empresa</label>
                        <input type="text" class="form-control form-control-lines" v-model="restricted.name">
                    </div>               
                </div>

                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="observações"><span class="required">*</span> Observações</label>
                        <textarea v-model="restricted.observation" class="form-control form-control-lines" rows="5"></textarea>
                    </div>
                </div>   
                
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-5">
                            <button @submit.prevent @click="salvar"  class="btn btn-info">Salvar</button>
                            <router-link class="btn btn-warning" to="/restricted">Voltar</router-link>
                        </div>
                        <div class="col-md-6">
                            <b><span class="required">*</span> Campos Obrigatórios</b>
                        </div>
                        
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            restricted: {
                name: '',
                observation: ''
            },
            endPoint: '/restricted/'
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {
        salvar () {
            
            let self = this;            

            self.$http.post(self.url,{
                'name': self.restricted.name,
                'observation': self.restricted.observation,
                'customer_id': self.$store.getters.customerId
            }).then( () => {
                self.$message('success', 'Sucesso', 'Registro salvo com sucesso!');
                
                this.$router.push({
                    path: "/restricted/"
                });
                
            }).catch((error) => {
                
                self.$errorMessage(error);        
            })
            
        }        
    },
    components: {

    }
}
</script>

<style>

</style>

<template>
    <div class="col-md-3">
        <div class="card bg-flat-gray animated flipInY">
            <div class="card-header">
                <h4 class="card-title text-center m-0">{{ (jobCandidate) ? (jobCandidate.candidate) ? jobCandidate.candidate.fullName : '-' : '-' }}</h4>
            </div>
            <div class="card-body">
                <img :src="((jobCandidate) ? (jobCandidate.candidate) ? jobCandidate.candidate.image : '' : '')" class="profile-avatar">
                <!-- <div class="small text-muted text-center mt-1 mb-1">Via {{ (jobCandidate) ? jobCandidate.platform.name : '-' }}</div> -->
                <div class="form-group mb-0">
                    <div class="input-group">
                        <div class="form-check-inline form-check m-auto">
                            <label :for="'selecionarCand' + jobCandidate.id" class="form-check-label m-0">
                                <input type="checkbox" v-model="selected" :id="'selecionarCand' + jobCandidate.id" :name="'selecionarCand' + jobCandidate.id" value="option1" class="form-check-input" v-bind:checked="selected">
                                <span class="checkmark"></span>{{$t('label.selecionar')}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn edit-btn btn-block mt-1 mb-1" type="button" data-toggle="modal"  @click="showModal"><i class="fa fa-eye"></i> {{$t('label.visualizarPerfil')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['candidate', 'type', 'select'],
    data: () => {
        return {
            selected: false
        };
    },
    watch: {
        selected: function (newState) {
            if (newState) {
                this.onSelected();
            } else {
                this.notSelected();
            }
        },
        select: function (newState) {
            this.selected = newState;
        }
    },
    computed: {
        jobCandidate: function () {
            return this.candidate;
        },
        getType: function () {
            return this.type;
        },
        isSelected: function () {
            return this.select;
        }
    },
    methods: {
        onSelected() {
            this.$emit('selected', this.jobCandidate.id, this.getType);
        },
        notSelected() {
            this.$emit('notSelected', this.jobCandidate.id, this.getType);
        },
        showModal() {
            this.$emit('showModal', this.jobCandidate);
        }
    }
}
</script>

<style>

</style>
<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <img :src="$store.getters.imagePath + 'hf_banner.jpg'" />
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center">{{ $t("label.acesseHumanflow") }}</h4>
          <div class="row">
            <div class="col-md-12 text-center">
              <img
                :src="$store.getters.logoFull"
                alt="Logo"
                class="login-logo"
              />
            </div>
          </div>
          <div class="alert alert-primary" role="alert">
            Agora você pode fazer login utilizando a conta Microsoft da sua
            empresa, para isso, clique em Login com AD. <br />
            Ou continue utilizando seu e-mail e senha normalmente.
          </div>
          <a
            v-if="!authLogin && !loginEmail"
            :href="
              'https://idp.modern.humanflow.com.br/simplesaml/custom.php?redirect_url=' +
              $store.state.frontUrl +
              'login'
            "
            class="btn btn-block init-btn mt-2"
            >Login com AD
          </a>
          <button
            v-if="!authLogin && !loginEmail"
            @click="loginEmail = true"
            class="btn btn-block init-btn"
          >
            Login com E-mail
          </button>
          <form
            method="POST"
            @submit.prevent="auth"
            v-if="!authLogin && loginEmail"
          >
            <div class="row">
              <div class="col-md-12 form-group">
                <label for="email">{{ $t("label.email") }}</label>
                <input
                  class="form-control"
                  v-model="email"
                  name="email"
                  type="email"
                  autofocus
                  required
                />
              </div>
              <div class="col-md-12 form-group">
                <label for="password">{{ $t("label.senha") }}</label>
                <input
                  class="form-control"
                  v-model="password"
                  name="password"
                  type="password"
                  required
                />
              </div>
              <div class="col-md-12 form-group" v-if="error">
                <div class="alert alert-danger">{{ error }}</div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn-block init-btn">
                  {{ $t("label.entrar") }}
                </button>
              </div>
              <div class="col-md-12">
                <a
                  v-if="!authLogin"
                  :href="
                    'https://idp.modern.humanflow.com.br/simplesaml/custom.php?redirect_url=' +
                    $store.state.frontUrl +
                    'login'
                  "
                  class="btn btn-block init-btn2 mt-2"
                  >Login com AD
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      authData: {},
      email: "",
      password: "",
      authLogin: false,
      loginEmail: false,
    };
  },
  computed: {
    error: function () {
      return this.$store.getters.errorLogin;
    },
  },
  mounted() {
    this.$store.dispatch("disableLoadModal");

    if (this.$route.query.param != "" && this.$route.query.param != undefined) {
      this.authLogin = true;

      let data = atob(this.$route.query.param);
      data = JSON.parse(data);
      this.authData = {};
      for (let prop in data.attributes) {
        this.authData[
          prop
            .replace(
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/",
              ""
            )
            .replace(
              "http://schemas.microsoft.com/ws/2008/06/identity/claims/",
              ""
            )
        ] = data.attributes[prop][0];
      }

      this.authData.logoutUrl = data.logoutUrl;

      this.loginAdfs();
    }
  },
  methods: {
    auth() {
      return this.$store.dispatch("auth", {
        email: this.email,
        password: this.password,
      });
    },
    loginAdfs() {
      const self = this;
      const api = this.$store.getters.apiUrl + "/adfsAuth";

      self.$http
        .post(api, {
          register: self.authData.windowsaccountname,
        })
        .then((response) => {
          //   self.$store.dispatch("tooggleLoadModal");
          self.$store.commit("setErrorLogin", "");
          self.$store.commit("setInfoLogged", response.data);
          self.$store.commit("setjobsUrl", response.data);

          localStorage.setItem("token", response.data.token);
        })
        .then(() => {
          window.axios.defaults.headers.common["Authorization"] =
            "Bearer" + localStorage.getItem("token");
        })
        .then(() => {
          self.$router.push({ path: "/" });
        })
        .catch((error) => {
          self.$message("error", "Erro", error.response.data);
          this.authLogin = false;
          self.$router.push({ path: "/login" });
        });
    },
  },
};
</script>

<style>
</style>


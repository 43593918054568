<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body"> 
                    <h3 class="card-title">{{ $t('label.cadastroDeCargos') }}</h3>
                    <form @submit.prevent>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.nome') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                        <input type="text" class="form-control" v-model="role.name" placeholder="" readonly="true">
                                    </div>                                        
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.salario') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-money"></i></div>
                                        <input type="text" class="form-control salario" v-model="role.salary" placeholder="" v-model.lazy="role.salary" v-money="money" readonly='true'> 
                                    </div>                                        
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.empresa') }}*</label>                                        
                                    <input readonly='true' type="text" class="form-control" v-model="role.company.name">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.observacao') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-file"></i></div>
                                        <textarea class="form-control" v-model="role.observation" rows="3" placeholder="" readonly='true'></textarea>
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/role"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            role: {
                id: 0,
                name: '',
                salary: 0,
                observation: '',
                customer_id: this.$store.getters.customerId,
                company: {
                    name: ''
                },
            },
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            endPoint: '/role/'
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        }
    },
    mounted: function () {
        this.loadRole();
    },
    methods: {
        loadRole() {
            const self = this;
            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(this.url, {
                params: {
                    with: ["company"]
                }
            })
                .then((response) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.role = response.data;
                }).catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.errorMessage(error);
                })
        },
    }
}
</script>

<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.gestaoDaVaga') }}</h3>
          <form method="post" class="form-material" @submit.prevent>
            <h3 class="card-title mt-3">{{ $t('label.informacoesCargo') }}</h3>

            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label mb-1">{{ $t('label.exibirNoPortalJobs') }}</label>
                  <div class="input-group">
                    <label class="switch switch-default switch-primary mt-2 mb-0">
                      <input type="checkbox" class="switch-input" v-model="job.jobs_visibility" />
                      <span class="switch-label border-gray"></span>
                      <span class="switch-handle border-gray"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label mb-1">{{ $t('label.prazo') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <input type="date" class="form-control" v-model="job.end_date_raw" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label mb-1">{{ $t('label.horarioTrabalho') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <input type="text" class="form-control" v-model="job.work_schedule" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label mb-1">{{ $t('label.localTrabalho') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <input type="text" class="form-control" v-model="job.workplace" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label mb-1">{{ $t('label.tituloDaVaga') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <input type="text" class="form-control" v-model="job.role_name" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label mb-1">{{ $t('label.numeroDeVagas') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <input type="number" class="form-control" v-model="job.quantity" />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="descricao" class="control-label mb-1">{{ $t('label.descricao') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea class="form-control" v-model="job.role_description" id="description"></textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="requisitos" class="control-label mb-1">{{ $t('label.requisitos') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      class="form-control"
                      v-model="job.role_requirements"
                      id="requirements"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="diferencial"
                    class="control-label mb-1"
                  >{{ $t('label.competenciasDesejadas') }}*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea class="form-control" id="diferencial" v-model="job.differential"></textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="observacoes"
                    class="control-label mb-1"
                  >{{ $t('label.informacaoComplementar') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-file-text-o"></i>
                    </div>
                    <textarea
                      class="form-control"
                      v-model="job.observation"
                      id="observacoesSolicitante"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <h3 class="card-title mt-3">{{ $t('label.complementos') }}</h3>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="salario" class="control-label mb-1">{{ $t('label.salario') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-money"></i>
                    </div>
                    <input
                      class="form-control"
                      type="text"
                      id="salario"
                      v-model="job.position_salary"
                      :disabled="job.to_combine == true"
                      :placeholder="$t('label.digiteAqui')"
                      v-money="money"
                      maxlength="50"
                    />
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exibir" class="control-label mb-1">{{ $t('label.exibirSalario') }}</label>
                  <div class="input-group">
                    <label class="switch switch-default switch-primary mt-2 mb-0">
                      <input
                        type="checkbox"
                        class="switch-input"
                        id="exibir"
                        v-model="job.show_salary"
                      />
                      <span class="switch-label border-gray"></span>
                      <span class="switch-handle border-gray"></span>
                    </label>
                  </div>
                </div>
              </div>-->

              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="combinar" class="control-label mb-1">{{ $t('label.acombinar') }}</label>
                  <div class="input-group">
                    <label class="switch switch-default switch-primary mt-2 mb-0">
                      <input
                        type="checkbox"
                        class="switch-input"
                        id="combinar"
                        v-model="job.to_combine"
                      />
                      <span class="switch-label border-gray"></span>
                      <span class="switch-handle border-gray"></span>
                    </label>
                  </div>
                </div>
              </div>-->
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label
                    for="tipoAnuncio"
                    class="control-label mb-1"
                  >{{ $t('label.tipoDeAnuncio') }}*</label>
                  <div class="input-group">
                    <div class="form-check-inline form-check mt-2">
                      <label for="tipoAnuncio1" class="form-check-label mr-3">
                        <input
                          type="radio"
                          id="tipoAnuncio1"
                          name="tiposAnuncio"
                          value="interno"
                          class="form-check-input"
                          v-model="job.publication_type"
                        />
                        <span class="radiomark"></span>
                        {{ $t('label.interno') }}
                      </label>
                      <label for="tipoAnuncio2" class="form-check-label mr-3">
                        <input
                          type="radio"
                          id="tipoAnuncio2"
                          name="tiposAnuncio"
                          value="externo"
                          class="form-check-input"
                          v-model="job.publication_type"
                        />
                        <span class="radiomark"></span>
                        {{ $t('label.externo') }}
                      </label>
                      <label for="tipoAnuncio3" class="form-check-label">
                        <input
                          type="radio"
                          id="tipoAnuncio3"
                          name="tiposAnuncio"
                          value="ambos"
                          class="form-check-input"
                          v-model="job.publication_type"
                        />
                        <span class="radiomark"></span>
                        {{ $t('label.ambos') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label
                    for="regimeContratacao"
                    class="control-label mb-1"
                  >{{ $t('label.regimeContratacao') }}</label>
                  <div class="input-group">
                    <div class="form-check-inline form-check mt-2">
                      <label for="regimeContratacao1" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao1"
                          name="regimeContratacao1"
                          value="clt"
                          v-model="job.clt"
                          class="form-check-input"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.clt') }}
                      </label>
                      <label for="regimeContratacao2" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao2"
                          name="regimeContratacao2"
                          value="pj"
                          v-model="job.pj"
                          class="form-check-input"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.terceirizado') }}
                      </label>
                      <label for="regimeContratacao4" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao4"
                          name="regimeContratacao4"
                          value="temporary"
                          v-model="job.temporary"
                          class="form-check-input"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.temporario') }}
                      </label>
                      <label for="regimeContratacao6" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao6"
                          name="regimeContratacao6"
                          value="trainee"
                          v-model="job.trainee"
                          class="form-check-input"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.trainee') }}
                      </label>
                      <label for="regimeContratacao3" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao3"
                          name="regimeContratacao3"
                          value="intern"
                          v-model="job.intern"
                          class="form-check-input"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.estagiario') }}
                      </label>
                      <label for="regimeContratacao7" class="form-check-label mr-3">
                        <input
                          type="checkbox"
                          id="regimeContratacao7"
                          name="regimeContratacao7"
                          value="apprentice"
                          v-model="job.apprentice"
                          class="form-check-input"
                        />
                        <span class="checkmark"></span>
                        {{ $t('label.apprentice') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <router-link class="btn include-btn p-1 ml-3 float-right" to="/jobRoles">
                  <i class="fa fa-times width-23-px"></i>
                  {{ $t('label.cancelar') }}
                </router-link>

                <button @click="save" class="btn include-btn p-1 ml-3 float-right">
                  <i class="fa fa-check width-21-px"></i>
                  {{ $t('label.salvar') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      job: {
        role_name: "",
        end_date_raw: "",
        quantity: "",
        role_description: "",
        role_requirements: "",
        differential: "",
        observation: "",
        salario: "",
        to_combine: "",
        show_salary: "",
        publication_type: "",
        clt: "",
        pj: "",
        intern: "",
        temporary: "",
        freelancer: "",
        trainee: "",
        jobs_visibility: "",
        work_schedule: "",
        workplace: ""
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: true /* doesn't work with directive */
      }
    };
  },
  mounted: function() {
    this.getJob();
  },
  methods: {
    getJob() {
      let self = this;

      self.loading = true;
      let id = self.$route.params.id;

      self.$store.dispatch("tooggleLoadModal");

      self.$http
        .get(self.$store.getters.apiUrl + "/job/" + id, {
          params: {
            // 'with': ['roleTemplate.role', 'approver', 'customer', 'branch', 'company']
          }
        })
        .then(response => {
          self.job = response.data;
          self.$store.dispatch("tooggleLoadModal");
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    save: function() {
      const self = this;
      let id = self.$route.params.id;

      let fd = self.makeFormData();
      if (!fd) return;

      self.$store.dispatch("tooggleLoadModal");

      self.$http
        .put(self.$store.getters.apiUrl + "/job/" + id, fd)
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
          self.$router.push({ path: "/jobRoles" });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    makeFormData: function() {
      const self = this;

      let fd = {};

      fd.role_name = self.job.role_name;
      fd.end_date = self.job.end_date_raw;
      fd.quantity = self.job.quantity;
      fd.role_description = self.job.role_description;
      fd.role_requirements = self.job.role_requirements;
      fd.differential = self.job.differential;
      fd.observation = self.job.observation;
      fd.position_salary = self.job.position_salary;
      fd.to_combine = self.job.to_combine;
      fd.show_salary = self.job.show_salary;
      fd.publication_type = self.job.publication_type;
      fd.clt = self.job.clt;
      fd.pj = self.job.pj;
      fd.intern = self.job.intern;
      fd.temporary = self.job.temporary;
      fd.freelancer = self.job.freelancer;
      fd.trainee = self.job.trainee;
      fd.apprentice = self.job.apprentice;
      fd.jobs_visibility = self.job.jobs_visibility;
      fd.work_schedule = self.job.work_schedule;
      fd.workplace = self.job.workplace;

      return fd;
    }
  }
};
</script>
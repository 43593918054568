<template>
  <aside id="left-panel" class="left-panel" v-if="$store.getters.activated">
    <nav class="navbar navbar-expand-sm navbar-default">
      <div class="navbar-header">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target-id="#main-menu"
          aria-controls="main-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fa fa-bars"></i>
        </button>
        <a class="navbar-brand" href="index.html">
          <img :src="$store.getters.logoFull" alt="Logo" />
        </a>
        <a class="navbar-brand hidden" href="index.html">
          <img :src="$store.getters.logo" alt="Logo" />
        </a>
      </div>

      <div id="main-menu" class="main-menu collapse navbar-collapse">
        <ul class="nav navbar-nav">
          <li v-for="(route,index) in routes" :key="route.path + index">
            <router-link
              :to="route.path"
              :key="route.path + $i18n.locale"
              v-if="route.children.length == 0 && route.show && hasPermission(route)"
            >
              <i :class="route.class"></i>
              <span class="hide-menu">{{ $t(route.label) }}</span>
            </router-link>

            <template v-if="route != undefined && route.children.length != 0">
              <li class="linhamenu" v-if="route.name == 'geral' && $store.getters.user.isRecruiter"></li>
              <h3
                class="menu-title"
                v-if="route.name == 'geral' && $store.getters.user.isRecruiter "
              >{{ $t(route.label) }}</h3>

              <li
                class="menu-item-has-children active dropdown"
                v-if="route.children.length != 0 && route.show && hasPermission(route)"
              >
                <a
                  href="javascript:void(0)"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i :class="route.class"></i>
                  {{ $t(route.label) }}
                </a>
                <ul class="sub-menu children dropdown-menu">
                  <li v-for="children in route.children" :key="children.path">
                    <router-link
                      :to="children.path"
                      v-if="children != undefined && !children.onlyWeb"
                      :key="children.path + $i18n.locale"
                    >
                      <i :class="children.class"></i>
                      <span class="hide-menu">{{ $t(children.label) }}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </template>
          </li>

          <li class="linhamenu"></li>

          <h3 class="menu-title">{{ $t('route.conheca') }}</h3>
          <li>
            <a href="http://www.humanflow.com.br/documentacao" target="_blank">
              <i class="menu-icon fa fa-book"></i>
              {{ $t('route.documentacao') }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </aside>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    routes() {
      return this.$store.getters.routes;
    }
  },
  methods: {
    hasPermission(currentRoute) {
      let route = this.$store.getters.routes.filter(route => {
        return route.name == currentRoute.name;
      })[0];

      if (this.$store.getters.user.isManager && route.permission.isManager) {
        return true;
      }
      if (this.$store.getters.user.isRecruiter && route.permission.recruiter) {
        return true;
      } else if (this.$store.getters.user.isUser && route.permission.user) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style>
</style>
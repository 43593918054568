<template>
    <div>
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="text-white">Cadastro de Empresas Restritas</h4>                    
            </div>
            <div class="card-body">
                <router-link class="btn btn-info pull-right" to="/restricted/add">Cadastrar</router-link>
                <v-server-table ref="table" :name="table" :url="url" :data="tableData" :columns="columns" :options="options">
                
                <div slot="actions" slot-scope="props">
                    <button type="button" class="btn btn-info" @click="view(props.row.id)" title="Visualizar"><i class="fa fa-eye"></i></button>
                    <button type="button" class="btn btn-warning text-white" @click="edit(props.row.id)" title="Editar"><i class="fa fa-pencil"></i></button>
                    <button type="button" class="btn btn-danger" @click="destroy(props, props.row, props.index)" title="Deletar"><i class="fa fa-trash-o"></i></button>
                </div>

                </v-server-table>
            </div>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert'
export default {
    data: () => {
        return {
            message: '',
            columns: ['name', 'actions'],
            tableData: [],
            options: {
                headings: {
                    'name': 'Nome',
                    'actions': "Ações",
                },
                debounce: 1000
            },
            endPoint: "/restricted/",
            enableView: true,
            enableEdit: true,
            enableDelete: true,
            name: 'restrictedTable',
            table: "restrictedTable"
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {
        edit(id) {
            this.$router.push({
                path: this.endPoint + 'edit/' + id
            })
        },
        view(id) {
            this.$router.push({
                path: this.endPoint + 'view/' + id
            })
        },
        destroy(props, row, index) {
            const self = this

            swal({
                title: "Deseja deletar o registro?",
                text: "Essa operação não pode ser desfeita",
                type: "warning",
                // showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim, deletar!",
                showLoaderOnConfirm: true,
                dangerMode: true,
                buttons: {
                    catch: {
                        text: "Não",
                        value: "cancel",
                        className: "btn-danger"
                    },
                    confirm: {
                        text: "Sim",
                        value: 'confirm'
                    }
                },
            }).then((value) => {

                if (value == 'confirm') {
                    this.$http.delete(this.url + row.id, {
                        'id': row.id
                    }).then(() => {
                        self.$refs.table.tableData.splice(index - 1, 1);
                        swal('Sucesso', 'Registro excluído com sucesso!', 'success');
                    })
                }
            }).catch(error => {
                self.$errorMessage(error);
            })

            // swal("Erro ao deletar", data.message, "error")

        }
    },
    components: {

    },
    props: {

    }
}
</script>

<style>

</style>

<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ role.name }}</h3>
          <hr />
          <ul
            class="nav nav-pills nav-justified filters mb-3"
            id="pills-tab-role-template"
            role="tablist"
          >
            <li class="nav-item" @click="activeTab = 'template'">
              <a
                class="nav-link active"
                id="pills-template-tab"
                data-toggle="pill"
                href="#template"
                role="tab"
                aria-controls="pills-template"
                aria-selected="true"
              >
                <i class="fa fa-file-text-o"></i>
                {{$t('label.template')}}
              </a>
            </li>
            <li class="nav-item" @click="activeTab = 'etapas'">
              <a
                class="nav-link"
                id="pills-etapas-tab"
                data-toggle="pill"
                href="#etapas"
                role="tab"
                aria-controls="pills-etapas"
                aria-selected="true"
              >
                <i class="fa fa-list-ol"></i>
                {{$t('label.etapas')}}
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div
              :class="['tab-pane fade show', {'active': activeTab == 'template'}]"
              id="template"
              role="tabpanel"
              aria-labelledby="pills-template-tab"
            >
              <form method="post" @submit.prevent>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cep" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-building"></i>
                        </div>
                        <input type="text" class="form-control" v-model="role.name" autofocus />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cep" class="control-label mb-1">{{ $t('label.cargo') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-tag"></i>
                        </div>
                        <select id="role_id" class="form-control" v-model="role.role_id">
                          <option value>{{ $t('label.selecione') }}</option>
                          <option
                            :value="role.id"
                            v-for="role in roles"
                            :key="role.id"
                          >{{role.name}} {{ role.company_id ? ' - ' + role.company.name : '' }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cep"
                        class="control-label mb-1"
                      >{{ $t('label.principaisAtribuicoes') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-file-text-o"></i>
                        </div>
                        <textarea class="form-control" v-model="role.description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cep" class="control-label mb-1">{{ $t('label.requisitos') }}*</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-file-text-o"></i>
                        </div>
                        <textarea class="form-control" v-model="role.requirements"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cep"
                        class="control-label mb-1"
                      >{{ $t('label.competenciasDesejadas') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-star"></i>
                        </div>
                        <textarea type="text" class="form-control" v-model="role.differential"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cep"
                        class="control-label mb-1"
                      >{{ $t('label.competenciasComportamentais') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-star"></i>
                        </div>
                        <textarea type="text" class="form-control" v-model="role.behavioral_skills"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="formacao" class="control-label mb-1">{{ $t('label.formacao') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-university"></i>
                        </div>
                        <textarea
                          type="text"
                          v-model="role.formation"
                          id="formacao"
                          class="form-control"
                          placeholder
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cursosAdicionais"
                        class="control-label mb-1"
                      >{{ $t('label.cursosAdicionais') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-university"></i>
                        </div>
                        <textarea
                          type="text"
                          v-model="role.additional_courses"
                          id="cursosAdicionais"
                          class="form-control"
                          placeholder
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="horarioTrabalho"
                        class="control-label mb-1"
                      >{{ $t('label.horarioTrabalho') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input
                          type="text"
                          v-model="role.work_schedule"
                          id="horarioTrabalho"
                          class="form-control"
                          placeholder
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="cargaHoraria"
                        class="control-label mb-1"
                      >{{ $t('label.cargaHoraria') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input
                          type="text"
                          v-model="role.workload"
                          id="cargaHoraria"
                          class="form-control"
                          placeholder
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        for="workplace"
                        class="control-label mb-1"
                      >{{ $t('label.localTrabalho') }}</label>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="fa fa-map-marker"></i>
                        </div>
                        <input
                          type="text"
                          v-model="role.workplace"
                          id="workplace"
                          class="form-control"
                          placeholder
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h3 class="card-title mt-3">Complementos</h3>
                <div class="row">
                  <div class="col-md-4">
                    <label
                      for="tipoAnuncio"
                      class="control-label mb-1"
                    >{{ $t('label.tipoDeAnuncio') }}*</label>
                    <div class="input-group">
                      <div class="form-check-inline form-check mt-2">
                        <label for="interno" class="form-check-label mr-3">
                          <input
                            type="radio"
                            name="interno"
                            id="interno"
                            value="1"
                            v-model="role.type"
                            class="form-check-input"
                          />
                          <span class="radiomark"></span>
                          {{ $t('label.interno') }}
                        </label>
                        <label for="externo" class="form-check-label mr-3">
                          <input
                            type="radio"
                            id="externo"
                            name="externo"
                            value="2"
                            v-model="role.type"
                            class="form-check-input"
                          />
                          <span class="radiomark"></span>
                          {{ $t('label.externo') }}
                        </label>
                        <label for="ambos" class="form-check-label">
                          <input
                            type="radio"
                            id="ambos"
                            name="ambos"
                            value="3"
                            v-model="role.type"
                            class="form-check-input"
                          />
                          <span class="radiomark"></span>
                          {{ $t('label.ambos') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <label
                      for="regimeContratacao"
                      class="control-label mb-1"
                    >{{ $t('label.regimeContratacao') }}*</label>
                    <div class="input-group">
                      <div class="form-check-inline form-check mt-2">
                        <label for="regimeContratacao1" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao1"
                            name="regimeContratacao1"
                            value="clt"
                            v-model="role.clt"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.clt') }}
                        </label>
                        <label for="regimeContratacao2" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao2"
                            name="regimeContratacao2"
                            value="pj"
                            v-model="role.pj"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.terceirizado') }}
                        </label>
                        <label for="regimeContratacao3" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao3"
                            name="regimeContratacao3"
                            value="intern"
                            v-model="role.intern"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.estagiario') }}
                        </label>
                        <label for="regimeContratacao4" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao4"
                            name="regimeContratacao4"
                            value="temporary"
                            v-model="role.temporary"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.temporario') }}
                        </label>
                        <!-- <label for="regimeContratacao5" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao5"
                            name="regimeContratacao5"
                            value="freelancer"
                            v-model="role.freelancer"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.freelancer') }}
                        </label>-->
                        <label for="regimeContratacao6" class="form-check-label mr-3">
                          <input
                            type="checkbox"
                            id="regimeContratacao6"
                            name="regimeContratacao6"
                            value="trainee"
                            v-model="role.trainee"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.trainee') }}
                        </label>
                        <label for="regimeContratacao7" class="form-check-label">
                          <input
                            type="checkbox"
                            id="regimeContratacao7"
                            name="regimeContratacao7"
                            value="apprentice"
                            v-model="role.apprentice"
                            class="form-check-input"
                          />
                          <span class="checkmark"></span>
                          {{ $t('label.apprentice') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 class="card-title mt-3">{{ $t('label.beneficios') }}</h3>
                <div class="row">
                  <div class="col-md-12">
                    <role-benefit-add
                      @updateBenefits="updateBenefits"
                      :role_template_id="role.id"
                      :roleBenefits="roleBenefits"
                    >
                      <role-benefit
                        :roleBenefits="roleBenefits"
                        :roleTemplateId="role.id"
                        :role="role.name"
                      ></role-benefit>
                    </role-benefit-add>
                  </div>
                </div>
                <h3 class="card-title mt-3">{{ $t('label.extras') }}</h3>
                <div class="row">
                  <div class="col-md-12">
                    <role-extra-add
                      @updateExtras="updateExtras"
                      :role_template_id="role.id"
                      :roleExtras="roleExtras"
                    >
                      <role-extra
                        :roleExtras="roleExtras"
                        :roleTemplateId="role.id"
                        :role="role.name"
                      ></role-extra>
                    </role-extra-add>
                  </div>
                </div>
              </form>
            </div>
            <div
              :class="['tab-pane fade show', {'active': activeTab == 'etapas'}]"
              id="etapas"
              role="tabpanel"
              aria-labelledby="pills-etapas-tab"
            >
              <role-stage :roleTemplateId="role.id" :role="role.name"></role-stage>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <router-link class="btn include-btn p-1 ml-3 float-right" to="/roleTemplate">
                <i class="fa fa-reply"></i>
                {{ $t('label.voltar') }}
              </router-link>
              <button
                @submit.prevent
                @click="save"
                id="save-btn"
                class="btn include-btn p-1 ml-3 float-right"
              >
                <i class="fa fa-floppy-o"></i>
                {{ $t('label.salvar') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoleStage from "../RoleStages/RoleStage";
import RoleBenefitAdd from "../RoleBenefits/RoleBenefitsAdd";
import RoleBenefit from "../RoleBenefits/RoleBenefits";
import RoleExtraAdd from "../RoleExtras/RoleExtrasAdd";
import RoleExtra from "../RoleExtras/RoleExtras";

export default {
  components: {
    RoleStage,
    RoleBenefitAdd,
    RoleBenefit,
    RoleExtra,
    RoleExtraAdd
  },
  data() {
    return {
      activeTab: "template",
      role: {
        id: 0,
        name: "",
        requirements: "",
        differential: "",
        type: "",
        clt: "",
        pj: "",
        intern: "",
        temporary: "",
        freelancer: "",
        trainee: "",
        apprentice: "",
        customer_id: "",
        contract_type: "",
        role_id: "",
        description: "",
        benefits: []
      },
      roles: [],
      rolesStages: [],
      roleBenefits: [],
      roleExtras: [],
      endPoint: "/roleTemplate/"
    };
  },
  computed: {
    customerId() {
      return this.$store.state.customer.id;
    },
    url() {
      return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
    }
  },
  created: function() {
    this.getRole();
    this.getRoles();
  },
  mounted: function() {
    this.loadBenefits();
    this.loadExtras();

    // $(".nav-item .nav-link").click(function () {
    //     $(".tab-content .tab-pane").each(function () {
    //       $(this).removeClass("active");
    //     });

    //     $($(this).attr('href')).addClass("active")
    // });
  },
  methods: {
    getRoles() {
      const self = this;
      const api = self.$store.getters.apiUrl + "/role";

      this.$http
        .get(api, {
          params: {
            with: ["company"],
            paginated: false
          }
        })
        .then(response => {
          this.roles = response.data.data;
        })
        .catch(() => {
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAoCliente")
          );
        });
    },
    getRole() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(self.url, {
          params: {
            paginated: false
          }
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.role = response.data;
        })
        .catch(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAoCliente")
          );
        });
    },
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .put(self.url, self.role)
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroAtualizadoComSucesso")
          );
          this.$router.push({
            path: this.endPoint
          });
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response.data;

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
    loadBenefits() {
      const self = this;
      const api = self.$store.getters.apiUrl + "/roleBenefit";

      this.$http
        .get(api, {
          params: {
            role_template_id: this.$route.params.id,
            paginated: false
          }
        })
        .then(response => {
          this.roleBenefits = response.data.data;
        })
        .catch(error => {
          self.errorMessage(error);
        });
    },
    updateBenefits() {
      this.loadBenefits();
    },
    loadExtras() {
      const self = this;
      const api = self.$store.getters.apiUrl + "/roleExtras";

      this.$http
        .get(api, {
          params: {
            role_template_id: this.$route.params.id,
            paginated: false
          }
        })
        .then(response => {
          this.roleExtras = response.data.data;
        })
        .catch(error => {
          self.errorMessage(error);
        });
    },
    updateExtras() {
      this.loadExtras();
    }
  }
};
</script>
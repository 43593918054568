<template>
    <div>
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="text-white">Cadastro de entrevistas com video</h4>                    
            </div>
            <div class="card-body">                                                        
                <form class="form-material">

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Nome</label>
                                <input readonly='true' type="text" class="form-control" v-model="video.name" placeholder="Nome do Candidato">
                            </div>
                        </div>                            
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Observação</label>
                                <textarea readonly='true' class="form-control" v-model="video.observation" rows="3" placeholder="Observação"></textarea>
                            </div>
                        </div>
                    </div>
                    <router-link class="btn btn-warning" to="/video">Voltar</router-link>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {  
            // this.$route.params.id          
            video: {
                id: 0,
                name: '',
                observation: ''
            },
            endPoint: '/video/'
        }
    },
    computed: {
        url () {            
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    mounted: function() {
        this.loadVideo()
    },
    methods: {
        loadVideo () {
            let self = this
            this.$http.get(this.url + this.$route.params.id)
            .then( (response) => {
                self.video.id = response.data.id
                self.video.name = response.data.name
                self.video.observation = response.data.observation
            })
        }
    },
    components: {
        
    }
}
</script>

<template>
    <div>            
        <div class="form-group">
            <label for="procurarVaga" class="control-label mb-1">{{ $t('label.procurarVaga') }}</label>
            <div class="input-group">          
                <div class="input-group-addon"><i class="fa fa-search"></i></div>          
                <input v-model="txt_search" class="form-control" placeholder="" type="text">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            txt_search: '',
            txt_nomedavaga: '',
            txt_solicitante: '',
            txt_area: '',
            intervalId: ''

        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    props: ['dateOpenObj', 'dateExpirationObj', 'onlyApprover'],
    watch: {
        txt_search: function (newState) {
            if (newState.length > 3 || newState.length == 0) {
                let that = this;
                this.intervalId = setTimeout(function () {
                    that.onType_filter(newState);
                }, 600);
            }
        },
        'dateOpenObj.date_from': function () {
            if (this.dateOpenObj.date_to != null) {
                let that = this;
                
                that.onToggleFilter(that.filterBoxes, that.dateOpenObj, that.dateExpirationObj);
            }
        },
        'dateOpenObj.date_to': function () {
            if (this.dateOpenObj.date_from != null) {
                let that = this;
                
                that.onToggleFilter(that.filterBoxes, that.dateOpenObj, that.dateExpirationObj);
                
            }

        },
        'dateExpirationObj.date_from': function () {
            if (this.dateExpirationObj.date_to != null) {
                let that = this;
                this.intervalId = setTimeout(function () {
                    that.onToggleFilter(that.filterBoxes, that.dateOpenObj, that.dateExpirationObj);
                }, 1000);
            }
        },
        'dateExpirationObj.date_to': function () {
            if (this.dateExpirationObj.date_from != null) {
                let that = this;
                this.intervalId = setTimeout(function () {
                    that.onToggleFilter(that.filterBoxes, that.dateOpenObj, that.dateExpirationObj);
                }, 1000);
            }
        }
    },
    methods: {
        brazilianDate: function (date) {
            let date_br = new Date(date)
            return `${(date_br.getUTCDate()).toString().padStart(2, '0')}/${(date_br.getUTCMonth()+1).toString().padStart(2, '0')}/${date_br.getUTCFullYear()}`
        },
        onToggleFilter: function (aFilter, oDateFilter, oDateExFilter) {
                            
            this.$emit('onToggleFilter', aFilter, oDateFilter, oDateExFilter);            
            clearInterval(this.intervalId);
        },
        onType_filter: function (searchQueryString) {
            this.$emit('onSearchText', searchQueryString);
            clearInterval(this.intervalId);
        }
    },
    mounted: function () {
        this.inProgress = true;
        let self = this

        self.$('#initialDateFrom').on('change', function() {
            self.$('#initialDateTo').attr('min', self.$(this).val());
            self.dateOpenObj.date_from = self.brazilianDate(self.$(this).val());
            self.onToggleFilter(self.filterBoxes, self.dateOpenObj, self.dateExpirationObj);
        })

        self.$('#initialDateTo').on('change', function() {
            self.$('#initialDateFrom').attr('max', self.$(this).val());
            self.dateOpenObj.date_to = self.brazilianDate(self.$(this).val());
            self.onToggleFilter(self.filterBoxes, self.dateOpenObj, self.dateExpirationObj);
        })

        self.$('#endDateFrom').on('change', function() {
            self.dateExpirationObj.date_from = self.brazilianDate(self.$(this).val());
            self.onToggleFilter(self.filterBoxes, self.dateOpenObj, self.dateExpirationObj);
        })        

        self.$('#endDateTo').on('change', function() {
            self.dateExpirationObj.date_to = self.brazilianDate(self.$(this).val());
            self.onToggleFilter(self.filterBoxes, self.dateOpenObj, self.dateExpirationObj);
        })        


    },
    components: {

    }
}
</script>

<style>

</style>


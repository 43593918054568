<script>
import VueChartJs from 'vue-chartjs'
export default {
  extends: VueChartJs.Line,
  data () {
    return {
      data: {
        labels: [],
        datasets: [
          {
            label: this.$t("label.ultimasContratacoes"),
            fill: false,
            backgroundColor: "#01c0c8",
            borderColor: "#35a6a8",
            data: []
          },
          {
            label: this.$t("label.vagasAbertas"),
            fill: false,
            backgroundColor: "#faf316",
            borderColor: "#c7c217",
            data: []
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        ticks: {
          integerSteps: true
        },
        yAxes: [
          {
            ticks: {
              min: 0,
              stepSize: 1
            }
          }
        ]
      }
    };
  },
  props: ["filter"],
  watch: {
    filter: {
      handler: function() {
        // watch it

        // if (
        //   !/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(this.filter.from) ||
        //   !/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(this.filter.to) ||
        //   this.filter.from.trim() == "" ||
        //   this.filter.to.trim() == ""
        // ) {
        //   //Verifying if dates are valid

        //   return;
        // }

        this.loadChart();
      },
      deep: true
    }
  },
  methods: {
    loadChart () {
      let self = this;
      self.$http
        .get(self.$store.getters.apiUrl + "/job/chartFinishedjobs", {
          params: {
            from: self.filter.from,
            to: self.filter.to
          }
        })
        .then(response => {
          self.data.labels = response.data.hirings.labels;
          self.data.datasets[0].data = response.data.hirings.data;
          self.data.datasets[1].data = response.data.jobs.data;

          this.renderChart(this.data, this.options, {
            responsive: true,
            maintainAspectRatio: false
          });
        })
    }
  },
  mounted() {
    this.loadChart();
  }
};
</script>
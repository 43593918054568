<template>
  <div class="modal fade" id="assignRecruiterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"> {{ $t('label.definirRecrutador') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            
              <h5>{{ $t('label.desejaResponsavelVaga') }}</h5>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn include-btn p-1 ml-3 float-right" @click="assignJob"><i
                    class="fa fa-floppy-o"></i> {{ $t('label.sim') }}</button>
                <button type="button" class="btn include-btn p-1 float-right" data-dismiss="modal" 
                    aria-label="Close"><i class="fa fa-times"></i> {{ $t('label.nao') }}</button> 
            </div>
        </div>
    </div>
  </div>  
</template>

<script>
export default {
  data: () => {
    return {
      // objeto vazio
    }
  },
  props: ['job'],
  methods: {
    assignJob: function () {

      let self = this      

      self.$store.dispatch("tooggleLoadModal");
      self.$http.put(self.$store.getters.apiUrl + '/job/assignRecruiter',{
        'recruiter_id': self.$store.getters.user.id,
        'id': self.job.id
      })
      .then( response => {
        self.$store.dispatch("tooggleLoadModal");
        self.$emit('recruiterAssigned', response.data)
      })
      .catch( error => {
        self.$store.dispatch("tooggleLoadModal");
        self.$errorMessage(error)
      })

      self.$('#assignRecruiterModal').modal('toggle');
    }
  },
  components: {

  }
}
</script>

<style>

</style>

<template>
    <div>
        <center v-show="loading">
            <div class="lds-circle"></div>
        </center>
    </div>  
</template>

<script>
export default {
  data: () => {
      return {
      };
    },
    props: [
        'loading'
    ],
    computed: {

    },
    mounted: function () {    	
    },
    watch: {
  
    },
    methods: {
      activate: function () {

      }
    },
    components: {}
}
</script>

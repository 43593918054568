<template>
    <div>
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="text-white">Blacklist</h4>                    
            </div>
            <div class="card-body">                                                        
                <form class="form-material" @submit.prevent>

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label><span class="required">*</span> Nome</label>
                                <input type="text" class="form-control" v-model="blacklist.name" placeholder="Nome da pessoa">
                            </div>
                        </div>                            
                    </div>

                    <div class="row">
                        
                        <div class="form-group col-md-4">
                            <label for="email"><span class="required">*</span> E-mail</label>
                            <input type="email" class="form-control form-control-lines" v-model="blacklist.email">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="motivo"><span class="required">*</span> Motivo</label>
                            <input type="text" class="form-control form-control-lines" v-model="blacklist.motive">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label><span class="required">*</span> Observação</label>
                                <textarea class="form-control" v-model="blacklist.observation" rows="3" placeholder="Observação"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-5">
                                <button @submit.prevent @click="salvar"  class="btn btn-info">Salvar</button>
                                <router-link class="btn btn-warning" to="/blacklist">Voltar</router-link>        
                            </div>
                            <div class="col-md-6">
                                <b><span class="required">*</span> Campos Obrigatórios</b>
                            </div>
                        </div>
                        
                    </div>            
                    
                </form>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {              
            blacklist: {
                id: 0,
                name: '',
                observation: ''
            },
            endPoint: "/blacklist/"            
        }
    },
    computed: {
        url () {            
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    mounted: function() {
        this.loadBlacklist()
    },
    methods: {
        loadBlacklist () {
            let self = this
            this.$http.get(this.url + this.$route.params.id)
            .then( (response) => {
                self.blacklist.id = response.data.id
                self.blacklist.name = response.data.name
                self.blacklist.observation = response.data.observation                
                self.blacklist.motive = response.data.motive
                self.blacklist.email = response.data.email
            })
        },
        salvar () {
            let self = this
            self.$http.put(self.url + this.$route.params.id,{
                'name': self.blacklist.name,
                'observation': self.blacklist.observation,
                'customer_id': self.$store.getters.customerId,
                'motive': self.blacklist.motive,
                'email': self.blacklist.email
            }).then( () => {
                self.$message('success', 'Sucesso', 'Registro atualizado com sucesso!');
                
            }).catch((error) => {
                
                self.$errorMessage(error)
                
            })
        }
    },
    components: {
        
    }
}
</script>
<template>
    <div>   
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                
                    <h3 class="card-title">{{ $t('label.cadastroEstabelecimento') }}</h3>
                    <form class="form-material" @submit.prevent>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="nome" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                                <input type="text" class="form-control" v-model="branchs.name" maxlength="191" autofocus>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label class="control-label mb-1">{{ $t('label.empresa') }}*</label>                                        
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>                                        
                                                <select name="company_id" v-model="branchs.company_id" class="form-control">
                                                    <option value="">{{ $t('label.selecione') }}...</option>
                                                    <option v-for="company in companies" :value="company.id" :key="company.id">{{company.name}}</option>
                                                </select> 
                                            </div>                                                
                                        </div>
                                    </div> 
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="name" class="control-label mb-1">{{ $t('label.codigoERP') }}</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-list-alt"></i></div>                                        
                                                <input type="text" class="form-control" v-model="branchs.erp_code" maxlength="191">                                       
                                            </div>
                                        </div>
                                    </div>      
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="cnpj" class="control-label mb-1">{{ $t('label.cnpj') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                                <input type="text" class="form-control" v-mask="'##.###.###/####-##'" v-model="branchs.cnpj" maxlength="191" autofocus>
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>
                                
                                <h3 class="card-title mt-3">{{ $t('label.informacoesEstabelecimento') }}</h3>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="cep" class="control-label mb-1">{{ $t('label.cep') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                                <input type="tel" id="cep" name="cep" v-mask="'#####-###'" placeholder=""  v-model="branchs.cep" class="form-control" @blur="searchCep()">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="endereco" class="control-label mb-1">{{ $t('label.endereco') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                                <input type="text" class="form-control" v-model="branchs.address" id="endereco" name="endereco" placeholder="">
                                            </div>
                                        </div>
                                    </div>                                
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="complemento" class="control-label mb-1">{{ $t('label.complemento') }}</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                                <input type="text" placeholder="" class="form-control" v-model="branchs.complement">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="numero" class="control-label mb-1">{{ $t('label.numero') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                                <input type="text" class="form-control" v-model="branchs.number" id="numero" name="numero" placeholder="" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="bairro" class="control-label mb-1">{{ $t('label.bairro') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                                <input type="text" class="form-control" v-model="branchs.neighborhood" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="cidade" class="control-label mb-1">{{ $t('label.cidade') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                                <input type="text" class="form-control" v-model="branchs.city" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="estado" class="control-label mb-1">{{ $t('label.estado') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                                <input type="text" class="form-control" v-model="branchs.state" placeholder="">
                                            </div>
                                        </div>
                                    </div>  
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="sobre" class="control-label mb-1">{{ $t('label.observacao') }}</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>                                                    
                                                <textarea class="form-control" v-model="branchs.observation" name="observation" id="observation" rows="5" placeholder=""></textarea>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">

                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/branchs"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                                <button @submit.prevent @click="save" id="save-btn" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>            
    </div>  
</template>

<script>

export default {
    data() {
        return {
            branchs: {
                name: '',
                observation: '',
                erp_code: '',
                company_id: '',
                cnpj: '',
                address: '',
                state: '',
                city: '',
                neighborhood: '',
                complement: '',
                number: '',
                cep: '',
            },
            errorCep: false,
            companies: [],
            endPoint: '/branchs/'
        }
    },
    computed: {
        url () {            
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {

        save() {
            const self = this;
            self.branchs.customer_id = self.$store.getters.customerId;

            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.post(self.url, self.branchs)
            .then(function(){
                self.$store.dispatch("tooggleLoadModal"); 
                self.$message('success', self.$t('label.sucesso'), self.$t('label.registroSalvoComSucesso'));
                self.$router.push({path: "/branchs/"});
            })
            .catch((error) => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.$errorMessage(error);
            });                
        },
        getCompany() {
            const self = this;
            const api = self.$store.getters.apiUrl + '/companies';

            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(api, {
                params: {
                    'paginated': false,
                    'customer_id': self.$store.getters.customerId,
                }
            })
            .then((response) => {
                self.$store.dispatch("tooggleLoadModal");
                self.companies = response.data.data
            })
            .catch(() => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(self.$t('label.naoFoiPossivelBuscarInformacoesReferencesAEmpresa'));
            })
        },
        searchCep() {
            let self = this;
            const cep = this.branchs.cep.toString().replace(/\D/g, '');

            if (cep != "") {
                const validacep = /^[0-9]{8}$/;

                if (validacep.test(cep)) {

                    this.branchs.address = "...";
                    this.branchs.city = "...";
                    this.branchs.neighborhood = "...";
                    this.branchs.state = "...";

                    //Consulta o webservice viacep.com.br/
                    this.$.getJSON("//viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {
                        if (dados.erro) {
                            self.errorCep = true
                        }else {
                            self.errorCep = false
                        }

                        if (!("erro" in dados)) {
                            self.branchs.address = dados.logradouro;
                            self.branchs.city = dados.localidade;
                            self.branchs.neighborhood = dados.bairro;
                            self.branchs.state = dados.uf;
                        }
                    });
                } 
            }
        }
    },
    mounted() {
        this.getCompany();
    }
}
</script>

<style>

</style>
<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.informacoesDoPortalDeCandidatos') }}</h3>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.bannerPrincipal') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file"></i>
                  </div>
                  <input type="file" class="form-control" id="main_banner" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.sobreAEmpresa') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-building"></i>
                  </div>
                  <textarea cols="30" rows="10" class="form-control" v-model="company.about"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.foto1') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file"></i>
                  </div>
                  <input type="file" class="form-control" id="photo1" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.foto2') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file"></i>
                  </div>
                  <input type="file" class="form-control" id="photo2" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.foto3') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file"></i>
                  </div>
                  <input type="file" class="form-control" id="photo3" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <div class="input-group" v-if="company.photo1">
                  <img
                    :src="$store.getters.apiUrl + '/companyInfo/image?path=' + company.photo1"
                    alt
                  />
                  <button type="button" class="btn btn-danger" @click="remove('photo1')">
                    <i class="fa fa-trash">&nbsp; &nbsp; Remover</i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <div class="input-group" v-if="company.photo2">
                  <img
                    :src="$store.getters.apiUrl + '/companyInfo/image?path=' + company.photo2"
                    alt
                  />
                  <button type="button" class="btn btn-danger" @click="remove('photo2')">
                    <i class="fa fa-trash">&nbsp; &nbsp; Remover</i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <div class="input-group" v-if="company.photo3">
                  <img
                    :src="$store.getters.apiUrl + '/companyInfo/image?path=' + company.photo3"
                    alt
                  />
                  <button type="button" class="btn btn-danger" @click="remove('photo3')">
                    <i class="fa fa-trash">&nbsp; &nbsp; Remover</i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.linkVideo') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-youtube"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.video" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.textoDasVagas') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-building"></i>
                  </div>
                  <textarea cols="30" rows="6" class="form-control" v-model="company.jobs_text"></textarea>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h3>{{ $t('label.redesSociais') }}</h3>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.website') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-globe"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.website" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.linkedin') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-linkedin"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.linkedin" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.facebook') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-facebook"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.facebook" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.twitter') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-twitter"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.twitter" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.instagram') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-instagram"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.instagram" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.youtube') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-youtube"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.youtube" />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.emailParaContatoDosCandidatos') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-envelope"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.candidate_contact_email" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.telefoneParaContatoDosCandidatos') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-phone"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.candidate_contact_phone" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.logotipo') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-file-image-o"></i>
                  </div>
                  <input type="file" class="form-control" id="logo" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.firstColor') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-twitter"></i>
                  </div>
                  <input type="color" class="form-control" v-model="company.firstColor" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.secondColor') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-instagram"></i>
                  </div>
                  <input type="color" class="form-control" v-model="company.secondColor" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.thirdColor') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-youtube"></i>
                  </div>
                  <input type="color" class="form-control" v-model="company.thirdColor" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label mb-1">{{ $t('label.url') }}</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-globe"></i>
                  </div>
                  <input type="text" class="form-control" v-model="company.url" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <button @click="save" id="save-btn" class="btn include-btn p-1 ml-3 float-right">
                <i class="fa fa-floppy-o"></i>
                {{ $t('label.salvar') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: {
        about: "",
        video: "",
        website: "",
        linkedin: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        jobs_text: "",
        candidate_contact_email: "",
        candidate_contact_phone: "",
        firstColor: "",
        secondColor: "",
        thirdColor: "",
        logo: "",
        url: ""
      }
    };
  },
  methods: {
    remove(id) {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");

      let fd = new FormData();
      fd.append("photo", id);

      self.$http
        .post(self.$store.getters.apiUrl + "/companyInfo/removePhoto", fd, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function() {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );
          self.getData();
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");

      let fd = new FormData();

      fd.append("about", self.company.about);
      fd.append("video", self.company.video);
      fd.append("website", self.company.website);
      fd.append("linkedin", self.company.linkedin);
      fd.append("facebook", self.company.facebook);
      fd.append("twitter", self.company.twitter);
      fd.append("instagram", self.company.instagram);
      fd.append("youtube", self.company.youtube);
      fd.append("jobs_text", self.company.jobs_text);
      fd.append(
        "candidate_contact_email",
        self.company.candidate_contact_email
      );
      fd.append(
        "candidate_contact_phone",
        self.company.candidate_contact_phone
      );
      fd.append("firstColor", self.company.firstColor);
      fd.append("secondColor", self.company.secondColor);
      fd.append("thirdColor", self.company.thirdColor);
      fd.append("url", self.company.url);

      let logo = document.getElementById("logo").files[0];
      if (logo) {
        fd.append("logo", logo);
      }

      let photo1 = document.getElementById("photo1").files[0];
      if (photo1) {
        fd.append("photo1", photo1);
      }

      let photo2 = document.getElementById("photo2").files[0];
      if (photo2) {
        fd.append("photo2", photo2);
      }

      let photo3 = document.getElementById("photo3").files[0];
      if (photo3) {
        fd.append("photo3", photo3);
      }

      let main_banner = document.getElementById("main_banner").files[0];
      if (main_banner) {
        fd.append("main_banner", main_banner);
      }

      self.$http
        .post(self.$store.getters.apiUrl + "/companyInfo", fd)
        .then(function() {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.registroSalvoComSucesso")
          );

          self.getData();
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    getData() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");

      self.$http
        .get(self.$store.getters.apiUrl + "/companyInfo")
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");

          if (response.data.data) {
            if (response.data.data) {
              self.company = response.data.data;
            }
          }
        })
        .catch(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(
            self.$t("label.naoFoiPossivelBuscarInformacoesReferencesAEmpresa")
          );
        });
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
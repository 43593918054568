<template>
    <div>   
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ $t('label.cadastroEmpresas') }}</h3>
                    <form class="form-material" @submit.prevent>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.logo') }}*</label>
                                    <div class="logo-image mb-3"><img :src="companies.photo" :alt="$t('label.logo')" class="logo-companies"></div>                                        
                                    <input type="file" @change="readImage" name="photo" id="photo" class="form-control-file mb-2">
                                    <span class="help-block small text-muted">{{ $t('label.aImagemDeveTer') }} <u>300px X 300px</u> {{ $t('label.eSerEm') }} <u>.png</u></span>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="nome" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                                <input type="text" class="form-control" v-model="companies.name" maxlength="191" autofocus>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="sobre" class="control-label mb-1">{{ $t('label.sobreAEmpresa') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>                                                    
                                                <textarea class="form-control" v-model="companies.about" name="sobre" id="sobre" rows="5" placeholder=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 class="card-title mt-3">{{ $t('label.informacoesEmpresa') }}</h3>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="cep" class="control-label mb-1">{{ $t('label.cep') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                        <input type="tel" id="cep" name="cep" v-mask="'#####-###'" placeholder=""  v-model="companies.cep" class="form-control" @blur="searchCep()">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="endereco" class="control-label mb-1">{{ $t('label.endereco') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                        <input type="text" class="form-control" v-model="companies.address" id="endereco" name="endereco" placeholder="">
                                    </div>
                                </div>
                            </div>                                
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="complemento" class="control-label mb-1">{{ $t('label.complemento') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                        <input type="text" placeholder="" class="form-control" v-model="companies.complement">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="numero" class="control-label mb-1">{{ $t('label.numero') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                        <input type="text" class="form-control" v-model="companies.number" id="numero" name="numero" placeholder="" >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="bairro" class="control-label mb-1">{{ $t('label.bairro') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                        <input type="text" class="form-control" v-model="companies.neighborhood" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="cidade" class="control-label mb-1">{{ $t('label.cidade') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                            
                                        <input type="text" class="form-control" v-model="companies.city" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="estado" class="control-label mb-1">{{ $t('label.estado') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                        <input type="text" class="form-control" v-model="companies.state" placeholder="">
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="erp_code" class="control-label mb-1">{{ $t('label.codigoERP') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-list-alt"></i></div>
                                        <input type="text" class="form-control" v-model="companies.erp_code" maxlength="191">
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/companies"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>

                                <button @submit.prevent @click="save" id="save-btn" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>            
    </div>
</template>

<script>
export default {
    data() {
        return {
            companies: {
                name: '',
                observation: '',
                about: '',
                photo: '',
                address: '',
                state: '',
                city: '',
                neighborhood: '',
                complement: '',
                number: '',
                cep: '',
                erp_code: '',
            },
            errorCep: false,
            endPoint: '/companies/'
        }
    },
    mounted() {
        this.companies.photo = this.$store.getters.imagePath + 'logo-default.png';
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        }
    },
    methods: {
        readImage(ev) {
            const self = this;
            const file = ev.target.files[0];
            const reader = new FileReader();

            reader.onload = e => self.companies.photo = e.target.result;
            reader.readAsDataURL(file);
        },
        save() {
            const self = this;

            if (this.errorCep) {
                self.$message('error',  self.$t('label.atencao'), self.$t('label.cepInvalido'));
                return;
            }

            let form = new FormData();
            let photo = document.getElementById('photo');

            form.append('name', self.companies.name);
            form.append('about', self.companies.about);
            form.append('customer_id', self.$store.getters.customerId);
            form.append('address', self.companies.address);
            form.append('state', self.companies.state);
            form.append('city', self.companies.city);
            form.append('neighborhood', self.companies.neighborhood);
            form.append('complement', self.companies.complement);
            form.append('number', self.companies.number);
            form.append('cep', self.companies.cep);
            form.append('erp_code', self.companies.erp_code);
            form.append('photo', (photo.files[0]) ? photo.files[0] : '');

            self.$store.dispatch("tooggleLoadModal");
            this.$http.post(self.url, form)
            .then(function(){
                self.$store.dispatch("tooggleLoadModal");
                self.$message('success',  self.$t('label.sucesso'), self.$t('label.registroSalvoComSucesso'));
                self.$router.push({path: "/companies/"});
            })
            .catch((error) => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
            });                
        },

        searchCep() {
            let self = this;
            const cep = this.companies.cep.toString().replace(/\D/g, '');

            if (cep != "") {
                const validacep = /^[0-9]{8}$/;

                if (validacep.test(cep)) {

                    this.companies.address = "...";
                    this.companies.city = "...";
                    this.companies.neighborhood = "...";
                    this.companies.state = "...";

                    //Consulta o webservice viacep.com.br/
                    this.$.getJSON("//viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {
                        if (dados.erro) {
                            self.errorCep = true
                        }else {
                            self.errorCep = false
                        }

                        if (!("erro" in dados)) {
                            self.companies.address = dados.logradouro;
                            self.companies.city = dados.localidade;
                            self.companies.neighborhood = dados.bairro;
                            self.companies.state = dados.uf;
                        }
                    });
                } 
            }
        }
    }
}
</script>

<style>

</style>
<template>
  <div class="table-responsive">
    <table class="table color-table human-flow-table">
      <thead>
        <tr>
          <td class="pt-0 border-none">
            {{ $t('label.nome') }}
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-pencil"></i>
              </div>
              <input type="text" v-model="extra.name" class="form-control" />
            </div>
          </td>
          <td class="pt-0 border-none">
            {{ $t('label.descricao') }}
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-pencil"></i>
              </div>
              <input type="text" v-model="extra.value" class="form-control" />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="no-bt">
            <a class="btn include-btn p-0 color-white" @submit.prevent @click="save">
              <i class="fa fa-plus"></i>
              {{ $t('label.incluirExtras') }}
            </a>
          </td>
        </tr>
      </thead>
      <slot />
    </table>
  </div>
</template>

<script>
export default {
  props: ["role_template_id", "roleExtras"],
  data: () => {
    return {
      extra: {
        id: 0,
        name: "",
        value: "",
        role_template_id: ""
      },
      endPoint: "/roleExtras/"
    };
  },
  computed: {
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    save() {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(self.url, {
          name: self.extra.name,
          value: self.extra.value,
          role_template_id: self.role_template_id
        })
        .then( () => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message("success", "Sucesso", "Registro salvo com sucesso!");

          self.$emit("updateExtras", this.extra);
          self.extra = {
            id: 0,
            name: "",
            value: "",
            role_template_id: ""
          };
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response.data;

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    }
  },
  components: {}
};
</script>


<template>
    <div class="row">
        <div class="col-xl-12">

            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <h5 class="text-justify mb-2">{{ $t('label.integracaoProtheus') }}</h5>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <h5 class="text-justify mb-2">{{ $t('label.candidatosAprovados') }}</h5>
                            <div class="table-responsive">
                                <table class="table table-striped" v-if="job.customer">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ $t('label.nome') }}</th>
                                            <th scope="col">{{ $t('label.email') }}</th>
                                            <th scope="col">{{ $t('label.telefone') }}</th>
                                            <th scope="col" v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1">{{ $t('label.emailEnviado') }}</th>
                                            <th scope="col" v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1">{{ $t('label.emailRespondido') }}</th>
                                            <th scope="col" v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1">{{ $t('label.candidatoExportado') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="candidate in aprovedCandidates" :key="candidate.id">
                                            <th scope="row">{{candidate.candidate.fullName}}</th>
                                            <td>{{candidate.candidate.mail}}</td>
                                            <td>{{candidate.candidate.phone}}</td>
                                            <td v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1">{{ candidate.emailHasBeenSent }}</td>
                                            <td v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1">{{ candidate.emailHasBeenAnswered }}</td>
                                            <td v-if="job.customer.configintegration && job.customer.configintegration.export_new_employee == 1">{{ candidate.candidateHasBeenExported }}</td>                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Categoria E-Social</label>
                            <select class="form-control" v-model="dataSend.categoriaESocial">
                                <option value="">Selecione...</option>
                                <option :value="value.codigo" v-for="value in categoriasESocial" :key="value.codigo">{{ value.descricao }}</option>
                            </select>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Sindicato</label>
                            <select class="form-control" v-model="dataSend.sindicato">
                                <option value="">Selecione...</option>
                                <option :value="value.RCE_CODIGO" v-for="value in sindidatos" :key="value.RCE_CODIGO">{{ value.RCE_DESCRI }}</option>
                            </select>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Processo</label>
                            <select class="form-control" v-model="dataSend.processo">
                                <option value="">Selecione...</option>
                                <option :value="value.RCJ_CODIGO" v-for="value in processos" :key="value.RCJ_CODIGO">{{ value.RCJ_DESCRI }}</option>
                            </select>
                        </div>
                        <div class="col-md-3 form-group">
                            <label>Data de Admissão</label>
                            <input type="date" v-model="dataSend.dataAdmissao" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label>Data optante FGTS</label>
                            <input type="date" v-model="dataSend.dataOptanteFGTS" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label>Conta FGTS</label>
                            <input type="number" v-model="dataSend.contaFGTS" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label>Dígito Conta FGTS</label>
                            <input type="number" v-model="dataSend.digitoContaFGTS" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label>Horas Trabalhadas no mês</label>
                            <input type="number" v-model="dataSend.horasTrabalhadasMes" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label>Horas na semana</label>
                            <input type="number" v-model="dataSend.horasTrabalhadasSemana" class="form-control">
                        </div>   
                        <div class="col-md-3 form-group">
                            <label>Compensa Sábado?</label>
                            <select class="form-control" v-model="dataSend.compensaSabado">
                                <option value="">Selecione...</option>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>   
                        <div class="col-md-3 form-group">
                            <label>Matrícula</label>
                            <input type="text" v-model="dataSend.matriculaFunc" class="form-control">
                        </div>   
                                        
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        <router-link class="btn view-btn  p-1 ml-3 float-right" :to="'/job/'+$route.params.id"> <i class="fa fa-times width-23-px"></i> {{ $t('label.voltar') }}</router-link>                    
                        <button class="btn view-btn  p-1 ml-3  float-right" @click="send"> <i class="fa fa-cloud-upload width-23-px"></i> {{ $t('label.enviar') }}</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>

export default {    

    data: () => {
        return {
           job_id: 0,
           job: {

           },
           endPoint: "/job",

           processos: [],
           sindidatos: [],
           categoriasESocial: [],
           dataSend: {
                categoriaESocial: '',
                sindicato: '',
                processo: '',
                dataAdmissao: '',
                dataOptanteFGTS: '',
                contaFGTS: '',
                digitoContaFGTS: '',
                horasTrabalhadasMes: '',
                horasTrabalhadasSemana: '',
                compensaSabado: '',
                matriculaFunc: '',
           }
        };
    },
    computed: {
        url: function () {
            return this.$store.getters.apiUrl + this.endPoint;
        },
        aprovedCandidates() {
            let candidates = this.job.candidates.filter(candidate => {
                return candidate.status == 9
            })

            return candidates.map(candidate => {
                candidate.emailHasBeenSent = this.job.candidates_exports.filter(exportes => exportes.candidate_id == candidate.candidate_id && exportes.email_sent == 1).length == 0 ? "Não" : "Sim";
                candidate.emailHasBeenAnswered = this.job.candidates_exports.filter(exportes => exportes.candidate_id == candidate.candidate_id && exportes.email_answered == 1).length == 0 ? "Não" : "Sim";
                candidate.candidateHasBeenExported = this.job.candidates_exports.filter(exportes => exportes.candidate_id == candidate.candidate_id && exportes.exported_erp == 1).length == 0 ? "Não" : "Sim";
                return candidate;
            });
        }
    },
    created: function () {
        
    },
    mounted: function () {
        this.job_id = this.$route.params.id

        this.getJobData()

        let self = this

        self.$store.dispatch("tooggleLoadModal");
        Promise.all([
            this.getZoomProtheus('getCategoriaESocial'),
            this.getZoomProtheus('getSindicato'),
            this.getZoomProtheus('getProcesso'),
        ]).then(data => {
            self.$store.dispatch("tooggleLoadModal");
            
            self.categoriasESocial = self.toArray(data[0].data);
            self.sindidatos = self.toArray(data[1].data);
            self.processos = self.toArray(data[2].data);
        })
        .catch(error => {
            self.$store.dispatch("tooggleLoadModal");
            self.$errorMessage(error);
        })
    },
    watch: {},
    methods: {
        toArray(obj){
            let newArray = [];
            for(let key in obj){
                newArray.push(obj[key]);
            }
            return newArray;
        },
        getZoomProtheus(zoom) {
            let self = this;

            return self.$http.get(`${this.$store.getters.apiUrl}/zoom-protheus/${zoom}`)
        },
        send(){

            let self = this
            let api = this.$store.getters.apiUrl + "/send-candidate-to-protheus"
            self.$store.dispatch("tooggleLoadModal");
            self.$http.post(api, {
                'job_id': this.job_id,
                'data': this.dataSend
            })
            .then(response => {
                self.$message('success', self.$t('label.sucesso'), response.data.message);
                self.$store.dispatch("tooggleLoadModal");
            })
            .catch(function (error) {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
            });
        },
        getJobData(){

            let self = this;
            self.$store.dispatch("tooggleLoadModal");
            self.$http
                .get(self.url + "/" + self.$route.params.id, {
                    params: {
                        with: ['customer.configintegration', 'branch', 'cost_center', 'approver', 'candidates', 'candidates.candidate', 'benefits', 'candidates_exports.data']
                    }
                })
                .then(response => {
                    self.$store.dispatch("tooggleLoadModal");
                    this.job = response.data;
                })
                .catch(function (error) {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                });
        }
    },
    components: {},
}
</script>

<template>
  <div>
    <div class="col-xl-12">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t('label.gerenciamentoRecrutadores') }}</h3>
          <form>
            <div class="row">
              <div class="col-md-8">
                <p>
                  {{ $t('label.selecioneUsuarios') }}
                  <b>{{ $t('label.recrutadores') }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="cep" class="control-label mb-1">{{ $t('label.pesquisar') }}</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-user"></i>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      @input="isTyping = true"
                      v-model="search"
                      :placeholder=" $t('label.pesquisarUsuariosPlataforma') "
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-8">
                <span class="alert alert-info">
                  {{ $t('label.suaLicencaEstaLimitada') }}
                  <b>{{customer.number_of_recruiters}}</b>
                  {{ $t('label.recrutadorS') }}.
                </span>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-8">
                <h5>{{ $t('label.usuariosDisponiveis') }}</h5>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>{{ $t('label.nome') }}</th>
                      <th>{{ $t('label.matricula') }}</th>
                      <th>{{ $t('label.email') }}</th>
                      <th class="text-center">*</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loading">
                      <td colspan="4">
                        <loading-human-flow :loading="true"></loading-human-flow>
                      </td>
                    </tr>
                    <div v-if="!loading">
                    <tr
                      v-for="user in fluigUsers"
                      
                      :key="user.email"
                      :class="userTrade && userTrade.code == user.code ? 'background-color-managers' : ''"
                    >
                      <td>{{user.fullName}}</td>
                      <td>{{user.code}}</td>
                      <td>{{user.email}}</td>
                      <td class="vertical-middle">
                        <div
                          v-show="customer && recruiters.length < customer.number_of_recruiters"
                          class="text-center"
                        >
                          <button
                            class="btn add-btn"
                            @click="selectRecruiter(user)"
                          >{{ $t('label.selecionar') }}</button>
                        </div>
                        <div
                          v-show="customer && recruiters.length >= customer.number_of_recruiters"
                          class="text-center"
                        >
                          <button
                            :disabled="tradeMode"
                            class="btn add-btn"
                            @click="selectTradeRecruiter(user)"
                          >{{ $t('label.trocar') }}</button>
                        </div>
                      </td>
                    </tr>
                    </div>
                  </tbody>
                </table>
              </div>
              <div class="col-md-4">
                <h5>{{ $t('label.usuariosCadastrados') }}</h5>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        {{ $t('label.nome') }}
                        <button
                          class="btn default-btn btn-sm"
                          v-show="tradeMode && userTrade"
                          @click="cancelTrade"
                        >{{ $t('label.cancelarTroca') }}</button>
                      </th>
                      <th class="text-center">*</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) in recruiters" :key="index">
                      <td width="90%">
                        <button
                          v-show="tradeMode && userTrade"
                          class="btn select-btn"
                          @click="tradeRecruiter(user)"
                        >
                          <i class="fa fa-check"></i>
                        </button>
                        {{user.fullName || user.name}}
                        <br />
                        <small>{{user.email}}</small>
                      </td>
                      <td class="vertical-middle">
                        <div class="input-group-btn text-center">
                          <a class="btn trash-btn color-white" @click="removeRecruiter(user)">
                            <i class="fa fa-trash-o"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-if="errorMsg">
              <div class="col-md-12 text-center">
                <br />
                <span class="alert alert-danger">{{errorMsg}}</span>
                <br />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <router-link class="btn include-btn p-1 ml-3 float-right" to="/">
                  <i class="fa fa-reply"></i>
                  {{ $t('label.voltar') }}
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import _ from 'lodash'
export default {
  data: () => {
    return {
      customer: {},
      errorMsg: "",
      search: "",
      loading: false,
      fluigUsers: [],
      recruiters: [],
      isTyping: false,
      tradeMode: false,
      userTrade: {}
    };
  },
  watch: {
    search: _.debounce(function() {
      this.isTyping = false;
    }, 1000),
    isTyping: function(value) {
      if (!value) {
        this.getFluigUsers(this.search);
      }
    }
  },
  methods: {
    getFluigUsers: function(search) {
      // '/api/public/2.0/users/listAll'

      let self = this;
      this.loading = true;

      if (self.$store.getters.mode == "dev") {
        self.$http
          .get(self.$store.getters.apiUrl + "/api/public/2.0/users/listAll")
          .then(response => {
            self.fluigUsers = response.data;
          })
          .then(() => {
            this.loading = false;
          });
      } else {
        this.$http
          .get("/api/public/2.0/users/listAll", {
            params: {
              limit: 20,
              pattern: search
            }
          })
          .then(response => {
            self.fluigUsers = response.data.content
              .filter(user => {
                return user.isActive;
              })
              .then(response => {
                self.fluigUsers = response.data.content.filter(user => {
                  return user.isActive;
                });
              })
              .then(() => {
                self.loading = false;
              })
              .catch(error => {
                self.$errorMessage(error);
              });
          });
      }
    },
    selectRecruiter(user) {
      let recruiter = this.recruiters.filter(function(recruiter) {
        return recruiter.email == user.email;
      })[0];

      if (recruiter) {
        this.$message(
          "warning",
          this.$t("label.atencao"),
          (user.fullName || user.name) + " " + this.$t("label.jaAdicionado")
        );
        return;
      }

      if (this.recruiters.length >= this.customer.number_of_recruiters) {


        swal({
          title:
            this.$t("label.suaLicencaEstaLimitada") +
            this.customer.number_of_recruiters +
            " " +
            this.$t("label.usuariosRecrutadores") +
            ". " +
            this.$t("label.desejaRealizarATrocaDeAlgumUsuario"),
          text: this.$t("label.essaOperacaoNaoPodeSerDesfeita"),
          icon: "warning",
          dangerMode: true,
          buttons: {
            catch: {
              text: this.$t("label.nao"),
              value: "cancel",
              className: "btn-danger"
            },
            confirm: {
              text: this.$t("label.sim"),
              value: "confirm",
              className: "btn-primary"
            }
          }
        }).catch(error => {
          this.$errorMessage(error);
        });
      } else {
        let self = this;
        let api = self.$store.getters.apiUrl + "/customer/add-user";

        self.$store.dispatch("tooggleLoadModal");
        self.$http
          .post(api, {
            user: user,
            customer_id: self.$store.getters.customerId,
            type_user_id: 2,
            user_logged: self.$store.getters.user.id
          })
          .then(response => {
            self.$store.dispatch("tooggleLoadModal");
            if (response.data.hasPending == true) {
              swal(response.data.title, response.data.text, "error");
            } else {
              self.recruiters = response.data.data;
              self.$message(
                "success",
                self.$t("label.sucesso"),
                self.$t("label.recrutadorAdicionadoComSucesso")
              );
            }
          })
          .catch( () => {
            self.$store.dispatch("tooggleLoadModal");
            self.$message(
              "error",
              self.$t("label.ocorreuUmErro"),
              self.$t("label.naoFoiPossivelAdicionarRecrutadorSelecionado")
            );
          });
      }
    },
    addUser() {},
    getRecruitersManager() {
      let self = this;
      let api = self.$store.getters.apiUrl + "/customer/getUserRecruiter";

      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(api, {
          customer_id: self.$store.getters.customerId
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          self.recruiters = response.data.data;
        })
        .catch(error => {
          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(error);
        });
    },
    removeRecruiter(user) {
      let self = this;

      if (self.recruiters.length <= 1) {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          self.$t("label.eNecessarioAoMenosUmUsuario")
        );
        return;
      }

      swal({
        title: self.$t("label.desejaDeletarRegistro"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        icon: "warning",
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm"
          }
        }
      }).then(value => {
        if (value == "confirm") {
          let api = self.$store.getters.apiUrl + "/customer/remove-user";
          self.$store.dispatch("tooggleLoadModal");
          self.$http
            .post(api, {
              customer_id: self.$store.getters.customerId,
              user_id: user.id,
              type_user_id: 2,
              user_logged: self.$store.getters.user.id
            })
            .then(response => {
              self.$store.dispatch("tooggleLoadModal");
              if (response.data.hasPending == true) {
                swal(
                  self.$t("label.existemVagasVinculadasAEsseRecrutador"),
                  self.$t(
                    "label.selecioneUmOutroRecrutadorParaTrocarComORecrutadorASerRemovido"
                  ),
                  "error"
                );
              } else {
                self.recruiters = response.data.data;
                self.$message(
                  "success",
                  self.$t("label.sucesso"),
                  self.$t("label.recrutadorRemovidoComSucesso")
                );
              }
            })
            .catch( () => {
              self.$store.dispatch("tooggleLoadModal");
              self.$message(
                "error",
                self.$t("label.ocorreuUmErro"),
                self.$t("label.naoFoiPossivelRemoverRecrutadorSelecionado")
              );
            });
        }
      });
    },
    selectTradeRecruiter(user) {
      let self = this;
      let recruiter = this.recruiters.filter(function(recruiter) {
        return recruiter.email == user.email;
      })[0];

      if (recruiter) {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          (user.fullName || user.name) + " " + self.$t("label.jaAdicionado")
        );
        return;
      }

      self.tradeMode = true;
      self.userTrade = user;
    },
    cancelTrade() {
      this.tradeMode = false;
      this.userTrade = {};
    },
    tradeRecruiter(user) {
      let self = this;
      let user_old = user;
      let user_new = this.userTrade;

      let api = self.$store.getters.apiUrl + "/customer/trade-user";
      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .post(api, {
          type_user_id: 2,
          user_new: user_new,
          user_old: user_old,
          user_logged: self.$store.getters.user.id,
          customer_id: self.$store.getters.customerId
        })
        .then(response => {
          self.$store.dispatch("tooggleLoadModal");
          if (response.data.hasPending == true) {
            swal(response.data.title, response.data.text, "error");
          } else {
            self.recruiters = response.data.data;
            self.$message(
              "success",
              self.$t("label.sucesso"),
              self.$t("label.recrutadorAdicionadoComSucesso")
            );
          }
        })
        .then(() => {
          self.cancelTrade();
        })
        .catch( () => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "error",
            self.$t("label.ocorreuUmErro"),
            self.$t("label.naoFoiPossivelAdicionarRecrutadorSelecionado")
          );
        });
    }
  },
  mounted() {
    this.customer = this.$store.getters.customer;
    this.getRecruitersManager();
  }
};
</script>

<style>
</style>

var RecruitersManager = Vue.component('recruiters-manager', {
    ,
    template: 
    `
        
      `
})

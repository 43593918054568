<template>
    <div>   
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">        
                    <h3 class="card-title">{{ $t('label.cadastroIntegracao') }}</h3>                    
                    <form @submit.prevent>
                        <div class="row">
                            <div class="col-md-4 form-group">
                                <label>{{ $t('label.nomeErp') }}*</label>
                                <select v-model="integration.erp_name" class="form-control" rqeuired>
                                    <option value="">Selecione o ERP</option>
                                    <option value="Datasul">Datasul</option>
                                    <option value="Protheus">Protheus</option>
                                    <option value="RM">RM</option>
                                </select>                                      
                            </div>                                                              
                            <div class="col-md-5 form-group">
                                <label>{{ $t('label.linkWebService') }}*</label>
                                <input type="text" class="form-control" v-model="integration.erp_link_ws">
                            </div> 
                            <div class="col-md-3 form-group">
                                <label>{{ $t('label.integracaoHabilitada') }}</label>
                                <select v-model="integration.enable_integration" class="form-control" >
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>                                  

                            <div class="col-md-3 form-group">
                                <label>{{ $t('label.empresaPrincipal') }}*</label>
                                <input type="text" class="form-control" v-model="integration.company_main" >
                            </div>
                            <div class="col-md-3 form-group">
                                <label>{{ $t('label.filialPrincipal') }}*</label>
                                <input type="text" class="form-control" v-model="integration.branch_main" >
                            </div>
                            <div class="col-md-3 form-group">
                                <label>{{ $t('label.exportarNovosFuncionarios') }}</label>
                                <select v-model="integration.export_new_employee" class="form-control" >
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>   

                            <div class="col-md-3 form-group">
                                <button type="button" @click="checkConnection" class="btn dark-btn btn-lg btn-block btn-no-label"><i class="fa fa-plug mr-2"></i> {{ $t('label.testarConexao') }}</button>
                            </div>                               
                        </div>                
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/integrations"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                                <button @submit.prevent @click="save" id="save-btn" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>                                                                        
                            </div>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
export default {
    data(){
        return {  
            integration: {
                company_id: '',
                erp_name: '',
                erp_link_ws: '',
                company_main: '',
                branch_main: '',
                enable_integration: '',
                auto_sync: '',
                export_new_employee: '',
            },
            endPoint: "/company-config-integr/",
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        },
    },
    methods: {
        save() {
            const self = this;

            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.post(self.url, self.integration)
            .then(function(){
                self.$store.dispatch("tooggleLoadModal"); 
                self.message('success', self.$t('label.sucesso'), self.$t('label.registroSalvoComSucesso'));
                self.$router.push({path: "/integrations/"});
            })
            .catch((error) => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.$errorMessage(error);
            });
        },
        checkConnection() {
            let self = this;

            if (self.integration.erp_name == "") {
                self.$message('error', self.$t('label.ocorreuUmErro'), self.$t('label.selecioneOErp'));
                return;
            }

            if (self.integration.erp_link_ws == "") {
                self.$message('error', self.$t('label.ocorreuUmErro'), self.$t('label.informeOLinkWS'));
                return;
            }    

            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.post(self.$store.getters.apiUrl + '/verify-connection' , {
                erp_name: self.integration.erp_name,
                erp_link_ws: self.integration.erp_link_ws,
                check_connection: true,
            })
            .then(function(){
                self.$store.dispatch("tooggleLoadModal"); 
                self.$message('success', self.$t('label.sucesso'), self.$t('label.webserviceConectadoComSucesso'));
            })
            .catch(() => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.message('error', self.$t('label.ocorreuUmErro'), self.$t('label.naoFoiPossivelConectarNoWebService'));
            });            

        }
    }
}
</script>

<style>

</style>
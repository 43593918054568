<template>
    <div class="modal fade" id="filterJobsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('label.filtros') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col-md-12 form-group">
                            <label for="label-control">
                                <b>{{ $t('label.dataDeAbertura') }}</b>
                            </label>

                            <div class="input-daterange input-group" id="date-range">
                                <input type="date"
                                    class="form-control mydatepicker date-input-daterange" id="initialDateFrom" >
                                <div class="input-group-append">
                                    <span class="input-group-text bg-info b-0 text-white">{{ $t('label.ate') }}</span>
                                </div>
                                <input type="date"
                                    class="form-control date-input-daterange" id="initialDateTo" >
                            </div>
                        </div>

                        <div class="col-md-12 form-group">
                            <label for="label-control">
                                <b>{{ $t('label.solicitante') }}</b>
                            </label>
                            <div class="input-group">
                                <input v-model="txt_solicitante" class="form-control" :placeholder="$t('label.pesquisePorSolicitante')" type="text">
                            </div>
                        </div>

                        <div class="col-md-12 form-group">
                            <label for="label-control">
                                <b>{{ $t('label.recrutador') }}</b>
                            </label>
                            <div class="input-group">
                                <input v-model="txt_recrutador" class="form-control" :placeholder="$t('label.pesquisePorRecrutador')" type="text">
                            </div>
                        </div>

                        <div class="col-md-12 form-group">
                            <label for="label-control">
                                <b>{{ $t('label.area') }}</b>
                            </label>
                            <div class="input-group">
                                <input v-model="txt_area" class="form-control" :placeholder="$t('label.pesquisePorArea')" type="text">
                            </div>
                        </div>

                    </div>

                </div>

                <div class="modal-footer">

                    <div class="col-md-6">
                        <p class="centralizado color-red" >{{ $t(filterJobsMessage) }}</p>
                    </div>

                    <button type="button" class="btn include-btn p-1 ml-3 float-right"  data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> {{ $t('label.fechar') }}</button>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            status: '',
            filterBoxes: [],
            intervalId: '',
            txt_nomedavaga: '',
            txt_solicitante: '',
            txt_recrutador: '',
            txt_area: '',
            dateTo: "",
            dateFrom: ""
        }
    },
    props:[
        'filterJobsMessage', 'onlyApprover'
    ],
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint;
        },
        filteredBoxes() {
            return this.filterBoxes;

        }
    },
    mounted: function () {
        this.inProgress = true;

        // var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
        $('.js-switch').each(function () {
            new Switchery($(this)[0], $(this).data());
        });

    },
    watch: {
        filterBoxes: function (aNewArr) {
            this.onToggle_filter(aNewArr);
        },

        txt_solicitante: function (newState) {
            if (newState.length > 3 || newState.length == 0) {
                let that = this;
                this.intervalId = setTimeout(function () {
                    that.onType_filterRequester(newState);
                }, 600);
            }
        },

        txt_recrutador: function (newState) {
            if (newState.length > 3 || newState.length == 0) {
                let that = this;
                this.intervalId = setTimeout(function () {
                    that.onType_filterRecruiter(newState);
                }, 600);
            }
        },

        txt_area: function (newState) {
            if (newState.length > 3 || newState.length == 0) {
                let that = this;
                this.intervalId = setTimeout(function () {
                    that.onType_filterDepartment(newState);
                }, 600);
            }
        }

    },
    methods: {
        onToggle_filter: function (aFilter) {
            this.$emit('onToggleFilter', aFilter);
            clearInterval(this.intervalId);
        },
        onType_filterRequester: function (searchQueryString) {
            this.$emit('onSearchTextRequester', searchQueryString);
            clearInterval(this.intervalId);
        },
        onType_filterRecruiter: function (searchQueryString) {
            this.$emit('onSearchTextRecruiter', searchQueryString);
            clearInterval(this.intervalId);
        },
        onType_filterDepartment: function (searchQueryString) {
            this.$emit('onSearchTextDepartment', searchQueryString);
            clearInterval(this.intervalId);
        },

    },
    components: {
    }
}
</script>
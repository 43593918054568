<template>
    
<div>
    <div class="col-xl-12">
        <div class="card text-dark-blue bg-flat-gray">
            <div class="card-body">
                <h3 class="card-title">{{ $t('label.integracoes') }}</h3>

                <div class="row">
                    <div class="col-md-8">
                        <p>{{ $t('label.configureGerencieIntegracoesERP') }}</p>
                    </div>    
                    <div class="col-md-4">
                        <router-link class="btn add-btn p-0 mb-3 float-right" to="/integrations/add"><i class="fa fa-plus-square"></i> {{ $t('label.cadastrar') }}</router-link>                                
                    </div>                        
                </div>

                <v-server-table ref="table" :name="table" :url="url" :data="tableData" :columns="columns" :options="options">
                    <div slot="company_photo" slot-scope="props">
                        <img class="slot-photo" :src="changeUrlCache(props.row.company ? props.row.company.photo : '')" >
                    </div>

                    <div slot="actions" slot-scope="props">
                        <button type="button" class="btn edit-btn p-0" @click="edit(props.row.id)" :title="$t('label.editar')"><i class="fa fa-pencil"></i></button>
                        <button type="button" class="btn delete-btn p-0" @click="destroy(props, props.row, props.index)" :title="$t('label.deletar')"><i class="fa fa-times"></i></button>

                        <button type="button" class="btn view-btn p-0" @click="openConfig(props.row.id, props.row.company_id)" :title="$t('label.configurar')"><i class="fa fa-cog"></i></button>
                    </div>
                </v-server-table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import swal from 'sweetalert'

export default {
    data() {
        return {
            message: '',
            columns: [/*'company_photo', 'company_name', */'erp_name', 'auto_sync', 'enable_integration', 'actions'],
            tableData: [],
            options: {
                headings: {
                    // 'company_photo': $t('label.logo'),
                    // 'company_name': $t('label.nome'),
                    'erp_name': this.$t('label.nomeErp'),
                    'auto_sync': this.$t('label.sincronizarAutomaticamente'),
                    'enable_integration': this.$t('label.integracaoHabilitada'),
                    'actions': this.$t('label.acoes')
                },
                debounce: 1000,
                templates: {
                    // company_photo: function(h, row, index) {
                    //     return row.company ? row.company.photo : "";
                    // },
                    // company_name: function(h, row, index) {
                    //     return row.company ? row.company.name : "";
                    // },
                    enable_integration: function(h, row) {
                        return row.enable_integration == 0 ? "Não" : "Sim";
                    },
                    auto_sync: function(h, row) {
                        return row.auto_sync == 0 ? "Não" : "Sim";
                    },
                },
                filterable: false,
                texts: {
                    count: `${this.$t('label.mostrando')} {from} ${this.$t('label.de')} {to} ${this.$t('label.ate')} {count} ${this.$t('label.deRegistros')}`,
                    filter: false,
                    filterPlaceholder: this.$t('label.pesquisarAqui'),
                    limit: '',
                    noResults: this.$t('label.nenhumRegistroEncontrado'),
                    page: this.$t('label.pagina')+':'
                },
                columnsClasses: {
                    actions: 'column-actions',
                    company_photo: 'column-photo',
                },
                requestFunction: function (data) {
                    let self = this;
                    let requestData = {};
                    requestData.params = data;
                    // requestData.params.with = ["company"];

                    self.$store.dispatch("tooggleLoadModal"); 
                    return this.$http.get(this.url, requestData)
                    .then(data => {                        
                        self.$store.dispatch("tooggleLoadModal"); 
                        return data
                    })
                    .catch((error) => {    
                        self.$store.dispatch("tooggleLoadModal"); 
                        self.$errorMessage(error);
                    })

                },                
            },
            endPoint: "/company-config-integr/",
            endPointPage: "/integrations/",
            enableView: false,
            enableEdit: false,
            enableDelete: false,
            name: 'companiesTable',
            table: "companiesTable"
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint;
        }
    },
    methods: {
        changeUrlCache(photo) {
            return photo + '?hash=' + Math.random()
        },
        openConfig(id, company_id) {
            this.$router.push({
                path: this.endPointPage + 'config/' + id,
                query: { company_id: company_id }
            });
        },    
        edit(id) {
            this.$router.push({
                path: this.endPointPage + 'edit/' + id
            })
        }, 
        destroy(props, row) {
            const self = this;

            swal({
                title: self.$t('label.desejaDeletarRegistro'),
                text: self.$t('label.essaOperacaoNaoPodeSerDesfeita'),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    catch: {
                        text: self.$t('label.nao'),
                        value: "cancel",
                        className: "btn-danger"
                    },
                    confirm: {
                        text: self.$t('label.sim'),
                        value: 'confirm'
                    }
                },
            })
                .then((value) => {
                    if (value == 'confirm') {
                        self.$store.dispatch("tooggleLoadModal");                        
                        self.$http
                            .delete(this.url + row.id, {
                                params: {
                                    'id': row.id,
                                    'customer_id': self.$store.getters.customerId
                                }
                            })
                            .then(() => {
                                self.$store.dispatch("tooggleLoadModal");
                                self.$refs.table.refresh();
                                self.$message('success',  self.$t('label.sucesso'),  self.$t('label.registroExcluidoComSucesso'));                                
                            })
                            .catch(error => {  
                                self.$store.dispatch("tooggleLoadModal");                              
                                swal(self.$t('label.ocorreuUmErro'), error.response.data, 'error');
                            })
                    }
                });
        }   
    }
}
</script>

<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ role.name }}</h3>
                    <hr>
                    <ul class="nav nav-pills nav-justified filters mb-3" id="pills-tab-role-template" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-template-tab" data-toggle="pill" href="#template"
                                role="tab" aria-controls="pills-template" aria-selected="true"><i
                                    class="fa fa-file-text-o"></i>
                                    {{$t('label.template')}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-etapas-tab" data-toggle="pill" href="#etapas"
                                role="tab" aria-controls="pills-etapas" aria-selected="true"><i
                                    class="fa fa-list-ol"></i>
                                    {{$t('label.etapas')}}
                            </a>
                        </li>                                                
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="template" role="tabpanel" aria-labelledby="pills-template-tab">                                
                            <form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="cep" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                                <input type="text" class="form-control" v-model="role.name" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="cep" class="control-label mb-1">{{ $t('label.cargo') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-tag"></i></div>
                                                <input type="text" class="form-control" v-model="role_company_name" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="cep" class="control-label mb-1">{{ $t('label.descricao') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>
                                                <textarea class="form-control" v-model="role.description" readonly></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="cep" class="control-label mb-1">{{ $t('label.requisitos') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>
                                                <textarea class="form-control" v-model="role.requirements" readonly></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="cep" class="control-label mb-1">{{ $t('label.competenciasDesejadas') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-star"></i></div>
                                                <input type="text" class="form-control" v-model="role.differential"
                                                    readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 class="card-title mt-3">{{ $t('label.complementos') }}</h3>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="tipoAnuncio" class="control-label mb-1">{{ $t('label.tipoDeAnuncio') }}*</label>
                                        <div class="input-group">
                                            <div class="form-check-inline form-check mt-2">
                                                <label for="interno" class="form-check-label mr-3">
                                                    <input type="radio" name="interno" id="interno" value="1" v-model='role.type'
                                                        class="form-check-input" disabled />
                                                    <span class="radiomark"></span>{{ $t('label.interno') }}
                                                </label>
                                                <label for="externo" class="form-check-label mr-3">
                                                    <input type="radio" id="externo" name="externo" value="2" v-model='role.type'
                                                        class="form-check-input" disabled />
                                                    <span class="radiomark"></span>{{ $t('label.externo') }}
                                                </label>
                                                <label for="ambos" class="form-check-label">
                                                    <input type="radio" id="ambos" name="ambos" value="3" v-model='role.type'
                                                        class="form-check-input" disabled />
                                                    <span class="radiomark"></span>{{ $t('label.ambos') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="regimeContratacao" class="control-label mb-1">{{ $t('label.regimeContratacao') }}*</label>
                                        <div class="input-group">
                                            <div class="form-check-inline form-check mt-2">
                                                <label for="regimeContratacao1" class="form-check-label mr-3">
                                                    <input type="checkbox" id="regimeContratacao1" name="regimeContratacao1"
                                                        value="clt" v-model='role.clt' class="form-check-input"
                                                        disabled>
                                                    <span class="checkmark"></span>{{ $t('label.clt') }}
                                                </label>
                                                <label for="regimeContratacao2" class="form-check-label mr-3">
                                                    <input type="checkbox" id="regimeContratacao2" name="regimeContratacao2"
                                                        value="pj" v-model='role.pj' class="form-check-input" disabled>
                                                    <span class="checkmark"></span>{{ $t('label.pj') }}
                                                </label>
                                                <label for="regimeContratacao3" class="form-check-label mr-3">
                                                    <input type="checkbox" id="regimeContratacao3" name="regimeContratacao3"
                                                        value="intern" v-model='role.intern' class="form-check-input"
                                                        disabled>
                                                    <span class="checkmark"></span>{{ $t('label.estagiario') }}
                                                </label>
                                                <label for="regimeContratacao4" class="form-check-label mr-3">
                                                    <input type="checkbox" id="regimeContratacao4" name="regimeContratacao4"
                                                        value="temporary" v-model='role.temporary' class="form-check-input"
                                                        disabled>
                                                    <span class="checkmark"></span>{{ $t('label.temporario') }}
                                                </label>
                                                <label for="regimeContratacao5" class="form-check-label mr-3">
                                                    <input type="checkbox" id="regimeContratacao5" name="regimeContratacao5"
                                                        value="freelancer" v-model='role.freelancer' class="form-check-input"
                                                        disabled>
                                                    <span class="checkmark"></span>{{ $t('label.freelancer') }}
                                                </label>
                                                <label for="regimeContratacao6" class="form-check-label">
                                                    <input type="checkbox" id="regimeContratacao6" name="regimeContratacao6"
                                                        value="trainee" v-model='role.trainee' class="form-check-input"
                                                        disabled>
                                                    <span class="checkmark"></span>{{ $t('label.trainee') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 class="card-title mt-3">{{ $t('label.beneficios') }}</h3>                                    
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class=" table-responsive">
                                            <table class="table color-table human-flow-table">
                                                <thead v-if="roleBenefits.length > 0">
                                                    <tr>
                                                        <td class="role-benefits-description">
                                                            {{ $t('label.beneficio') }}
                                                        </td>
                                                        <td class="role-benefits-description">
                                                            {{ $t('label.descricao') }}
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(benefit, key) in roleBenefits" :key="key">                                                    
                                                        <td>
                                                            <div class="input-group">
                                                                <div class="input-group-addon">
                                                                    <i class="fa fa-pencil"></i>
                                                                </div>                                                        
                                                                <input type="text" v-model="benefit.name" class="form-control" readonly>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-group">
                                                                <div class="input-group-addon">
                                                                    <i class="fa fa-pencil"></i>
                                                                </div>                                                        
                                                                <input type="text" v-model="benefit.value" class="form-control" readonly>                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>                                            
                                            </table>                                     
                                        </div>
                                    </div>
                                </div>                                  
                            </form>
                        </div>

                        <div class="tab-pane fade show" id="etapas" role="tabpanel" aria-labelledby="pills-etapas-tab">
                            <role-stage :roleTemplateId="role.id" :role="role.name"  readonly="true"></role-stage>
                        </div>                            
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-12">
                            <router-link class="btn include-btn p-1 ml-3 float-right" to="/roleTemplate"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // this.$route.params.id
            rolesStages: [],
            roleBenefits: [],
            role: {
                id: 0,
                role_id: 0,
                name: '',
                description: '',
                requirements: '',
                differential: '',
                type: '',
                clt: '',
                pj: '',
                intern: '',
                temporary: '',
                freelancer: '',
                trainee: '',
                customer_id: '',
                contract_type: '',
                role: {
                    name: ''
                }
            },
            endPoint: "/roleTemplate/"
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        },
        role_company_name() {
            if (this.role.role && this.role.role.company) {
                return  this.role.role.name + (this.role.role.company ? " - " + this.role.role.company.name : "")
            }
            return "";
        }
    },
    created: function () {
        this.loadRole();
    },
    mounted: function () {
        this.loadBenefits();

        // $(".nav-item .nav-link").click(function () {
        //     $(".tab-content .tab-pane").each(function () {
        //       $(this).removeClass("active");
        //     });
        
        //     $($(this).attr('href')).addClass("active")
        // });
    },
    methods: {
        loadBenefits() {
            const self = this;
            const api = self.$store.getters.apiUrl + "/roleBenefit"

            this.$http.get(api, {
                    params: {
                        role_template_id: this.$route.params.id
                    }
                })
                .then(response => {
                    this.roleBenefits = response.data.data;
                })
                .catch(error => {
                    self.$errorMessage(error);
                })
        },
        // updateBenefits(data) {
        //     this.loadBenefits();
        // },
        loadRole() {
            const self = this;

            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.get(self.url, {
                params: {
                    with: ['role.company'],
                    'paginated': false,
                }
            })
            .then((response) => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.role = response.data;
            })
            .catch(() => {
                self.$store.dispatch("tooggleLoadModal"); 
                self.$errorMessage( self.$t('label.naoFoiPossivelBuscarInformacoesReferencesAoCliente'));
            })
        },
        viewStage() {
            this.$router.push({
                path: this.endPoint + this.role.id + '/roleStage/'
            });
        }
    }
}
</script>

<style>

</style>
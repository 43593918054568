<template>
    <tbody>
        <tr v-for="(extra, key) in extrasList" :key="key">                                                    
            <td>
                <div class="input-group">
                    <div class="input-group-addon">
                        <i class="fa fa-pencil"></i>
                    </div>                                                        
                    <input type="text" v-model="extra.name" disabled="true" class="form-control">
                </div>
            </td>
            <td>
                <div class="input-group">
                    <div class="input-group-addon">
                        <i class="fa fa-pencil"></i>
                    </div>                                                        
                    <input type="text" v-model="extra.value" disabled="true" class="form-control">
                    <div class="input-group-btn">
                        <a class="btn trash-btn ml-3 color-white" @click="(event) => { remove(event, extra.id, key) }">
                            <i class="fa  fa-trash-o "></i>
                        </a>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import swal from 'sweetalert'
export default {
  props: ["roleTemplateId", "roleExtras"],
  data: () => {
    return {
      message: "",
      templateId: "",
      nextItem: "",
      endPoint: "/roleExtras/",
      name: "roleExtras"
    };
  },
  computed: {
    extrasList() {
      return this.roleExtras;
    },
    url() {
      return this.$store.getters.apiUrl + this.endPoint;
    }
  },
  methods: {
    remove(ev, id, index) {
      const self = this;

      swal({
        title: self.$t("label.desejaDeletarRegistro"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        icon: "warning",
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm"
          }
        }
      })
        .then(value => {
          if (value === "confirm") {
            self.$store.dispatch("tooggleLoadModal");
            self.$http
              .delete(self.url + id)
              .then( () => {
                self.$store.dispatch("tooggleLoadModal");
                self.$message(
                  "success",
                  self.$t("label.sucesso"),
                  self.$t("label.registroExcluidoComSucesso")
                );
                self.roleExtras.splice(index, 1);
              })
              .catch(error => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
              });
          }
        })
        .catch( () => {
          self.$message(
            "error",
            null,
            "Ocorreu um erro durante o processamento da requisição"
          );
        });
    }
  }
};
</script>

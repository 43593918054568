<template>
    <div>   
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">        
                    <h3 class="card-title">{{ $t('label.configuracaoIntegracao') }}</h3>                    
                    <form>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.logo') }}</label>
                                    <div class="logo-image mb-3">                                        
                                        <img :src="companies.photo" :alt="$t('label.logo')" class="logo-companies">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="nome" class="control-label mb-1">{{ $t('label.nome') }}</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>
                                                <input readonly='true' type="text" class="form-control" v-model="companies.name" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="sobre" class="control-label mb-1">{{ $t('label.sobreAEmpresa') }}</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>                                                
                                                <textarea readonly='true' class="form-control" v-model="companies.about" name="sobre" id="sobre" rows="5" placeholder="" disabled=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>     
                        <h3 class="card-title mt-3">{{ $t('label.integracoes') }}</h3>        
                        <div class="row">

                            <div class="col-md-2 form-group">
                                <label>{{ $t('label.nomeErp') }}</label>
                                <select class="form-control">
                                    <option value="">{{ $t('label.selecione') }}...</option>
                                    <option value="Datasul">Datasul</option>
                                    <option value="Protheus">Protheus</option>
                                    <option value="RM">RM</option>
                                </select>  
                            </div>  
                            <div class="col-md-6 form-group">
                                <label>{{ $t('label.linkWebService') }}</label>
                                <input type="text" class="form-control" required="true">
                            </div> 
                            <div class="col-md-2 form-group">
                                <label>{{ $t('label.empresaPrincipal') }}</label>
                                <input type="text" class="form-control" required="true">
                            </div>
                            <div class="col-md-2 form-group">
                                <label>{{ $t('label.filialPrincipal') }}</label>
                                <input type="text" class="form-control" required="true">
                            </div>

                            <div class="col-md-2 form-group">
                                <label>{{ $t('label.sincronizarAutomaticamente') }}</label>
                                <select v-model="integrationForm.auto_sync" class="form-control" required>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                            <div class="col-md-2 form-group">
                                <label>{{ $t('label.integracaoHabilitada') }}</label>
                                <select v-model="integrationForm.enable_integration" class="form-control" required>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>     
                            
                            <div class="col-md-2 form-group">
                                    <button class="btn dark-btn btn-lg btn-block mt-4"><i class="fa fa-plug mr-2"></i> {{ $t('label.testarConexao') }}</button>
                            </div>

                            <!-- <div class="col-md-12 form-group">
                                <button class="btn include-btn p-0 color-white" @submit.prevent @click="openModalIntegration"  >
                                    <i class="fa fa-plus"></i>
                                    {{ $t('label.incluirIntegracoes') }}
                                </button>
                            </div>
                            <div class="col-md-12">
                                <v-server-table ref="table" :name="table" :url="urlConfig" :data="tableData" :columns="columns" :options="options">
                                    <div slot="actions" slot-scope="props">
                                        <button type="button" class="btn edit-btn p-0" :title="$t('label.editar')" @click="openModalIntegration(props.row)"><i class="fa fa-pencil"></i></button>
                                        <button type="button" class="btn delete-btn p-0" :title="$t('label.deletar')"><i class="fa fa-times"></i></button>
                                        <button type="button" class="btn view-btn p-0" :title="$t('label.gerenciar')" @click="openManagerIntegration(props.row.id)"><i class="fa fa-cog"></i></button>
                                    </div>
                                </v-server-table>
                            </div> -->
                        </div>               
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/integrations"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                            </div>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalIntegrationForm" tabindex="-1" role="dialog"   aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form  @submit.prevent>
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('label.cadastroIntegracoes') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <label>{{ $t('label.nomeErp') }}</label>
                                    <select v-model="integrationForm.erp_name" class="form-control" rqeuired>
                                        <option value="">Selecione o ERP</option>
                                        <option value="Datasul">Datasul</option>
                                        <option value="Protheus">Protheus</option>
                                        <option value="RM">RM</option>
                                    </select>                                      
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>{{ $t('label.linkWebService') }}</label>
                                    <input v-model="integrationForm.erp_link_ws" type="text" class="form-control" required="true">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>{{ $t('label.sincronizarAutomaticamente') }}</label>
                                    <select v-model="integrationForm.auto_sync" class="form-control" required>
                                        <option value="0">Não</option>
                                        <option value="1">Sim</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>{{ $t('label.integracaoHabilitada') }}</label>
                                    <select v-model="integrationForm.enable_integration" class="form-control" required>
                                        <option value="0">Não</option>
                                        <option value="1">Sim</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button @submit.prevent @click="saveModalIntegration" type="submit" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-save"></i> {{ $t('label.salvar') }}</button>
                            <button type="button" class="btn include-btn p-1 float-right"  data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> {{ $t('label.fechar') }}</button> 
                        </div>
                    </form>                                                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {     
            integrationForm: {
                id: "",    
                erp_name: "",
                erp_link_ws: "",  
                customer_id: "",
                company_id: "",
                auto_sync: "",
                enable_integration: "",
            },
            message: '',
            columns: [ 'erp_name', 'erp_link_ws', 'auto_sync', 'enable_integration', 'actions'],
            tableData: [],
            options: {
                headings: {
                    'erp_name': this.$t('label.nomeErp'),
                    'erp_link_ws': this.$t('label.linkWebService'),
                    'auto_sync': this.$t('label.sincronizarAutomaticamente'),
                    'enable_integration': this.$t('label.integracaoHabilitada'),
                    'actions': this.$t('label.acoes')
                },
                debounce: 1000,
                texts: {
                    count: `${this.$t('label.mostrando')} {from} ${this.$t('label.de')} {to} ${this.$t('label.ate')} {count} ${this.$t('label.deRegistros')}`,
                    filter: false,
                    filterPlaceholder: this.$t('label.pesquisarAqui'),
                    limit: '',
                    noResults: this.$t('label.nenhumRegistroEncontrado'),
                    page: this.$t('label.pagina')+':'
                },
                columnsClasses: {
                    actions: 'column-actions',
                },   
                filterable: false,
                templates: {
                    enable_integration: function(h, row) {
                        return row.enable_integration == 0 ? "Não" : "Sim";
                    },
                    auto_sync: function(h, row) {
                        return row.auto_sync == 0 ? "Não" : "Sim";
                    },
                }             
            },
            endPoint: '/companies/',
            endPointPage: "/integrations/",
            enableView: false,
            enableEdit: false,
            enableDelete: false,
            name: 'configsTable',
            table: "configsTable",

            companies: {
                id: 0,
                name: '',
                about: '',                
            },
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        },
        urlConfig() {
            return this.$store.getters.apiUrl + "/company-config-integr/"
        }
    },
    mounted: function () {
        this.loadCompanies();
    },
    methods: {
        clearModal() {
            this.integrationForm = {
                id: "",    
                erp_name: "",
                erp_link_ws: "",  
                customer_id: "",
                company_id: "",
                auto_sync: "",
                enable_integration: "",
            };
        },
        openModalIntegration(data = null) {
            if (data) {
                this.integrationForm = {
                    id: data.id,    
                    erp_name: data.erp_name,
                    erp_link_ws: data.erp_link_ws,  
                    auto_sync: data.auto_sync,
                    enable_integration: data.enable_integration,
                };             
            }
            else {
                this.clearModal();
            }
            this.$("#modalIntegrationForm").modal('show'); 
        },
        saveModalIntegration() {
            const self = this;

            self.$store.dispatch("tooggleLoadModal");

            self.integrationForm.company_id = self.$route.params.id;

            if (self.integrationForm.id == "" || !self.integrationForm.id) {
                this.$http.post(self.urlConfig, self.integrationForm)
                    .then(() => {
                        self.$store.dispatch("tooggleLoadModal");
                        self.message('success', self.$t('label.sucesso'), self.$t('label.registroSalvoComSucesso'));
                        this.clearModal();
                        self.$("#modalIntegrationForm").modal('hide'); 
                        self.$refs.table.refresh();
                    })
                    .catch((error) => {
                        self.$store.dispatch("tooggleLoadModal");
                        self.errorMessage(error);
                    })
            }
            else {
                self.$http.put(self.urlConfig + self.integrationForm.id, self.integrationForm)
                    .then(() => {
                        self.$store.dispatch("tooggleLoadModal");
                        self.message('success', self.$t('label.sucesso'), self.$t('label.registroAtualizadoComSucesso'));
                        this.clearModal();
                        self.$("#modalIntegrationForm").modal('hide'); 
                        self.$refs.table.refresh();
                    })
                    .catch((error) => {
                        self.$store.dispatch("tooggleLoadModal");
                        self.errorMessage(error);
                    })
            }
        },
        openManagerIntegration(id) {
            this.$router.push({
                path: this.endPointPage + 'config/' + this.$route.params.id + '/manager/' + id
            });
        },
         loadCompanies() {
            let self = this;
            
            self.$store.dispatch("tooggleLoadModal"); 
            this.$http.get(this.url + this.$route.params.id)
                .then((response) => {
                    self.$store.dispatch("tooggleLoadModal"); 
                    self.companies = response.data;
                })
                .catch((error) => {    
                    self.$store.dispatch("tooggleLoadModal"); 
                    self.$errorMessage(error);
                })
        }
    }
}
</script>


<template>
  <div>
    <candidate-view-modal
      v-if="openModal"
      :candidate="jobCandidate"
      :jobCandidateID="jobCandidateID"
      :stages="fullStages"
      :job_id="job_id"
      :url_iteration="url_iteration"
      :is_manager="move"
      :id_requester="this.Job.requester_id"
    ></candidate-view-modal>

    <div class="row" v-if="noCandidates">
      <div class="col-md-12">
        <div class="panel panel-primary">
          <div class="panel-body">
            <div class="alert alert-danger">
              Não há candidatos selecionados para esta vaga, volte na triagem e
              adicione ao menos um candidato!
            </div>
          </div>
        </div>
      </div>
    </div>

    <kanban-finalize-modal
      :candidatesInLastStage="candidatesInLastStage"
      :candidatesNotApproved="candidatesNotApproved"
      @finalizeSelection="finalizeSelection"
      @showModal="showModal"
      v-if="!noCandidates"
    ></kanban-finalize-modal>
    <div class="col-xl-12" v-if="!noCandidates">
      <div class="card text-dark-blue bg-flat-gray">
        <div class="card-body">
          <h3 class="card-title">{{ $t("label.processoSeletivo") }}</h3>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="float-right mb-4">
                    <button class="btn edit-btn" @click="loadKanban">
                      <i class="fa fa-refresh"></i>
                    </button>

                    <button
                      v-if="move == true"
                      class="btn edit-btn"
                      type="button"
                      @click="openCommentsModal"
                    >
                      {{ $t("label.comentariosGestaoRS") }}
                    </button>

                    <button
                      class="btn edit-btn"
                      type="button"
                      data-toggle="collapse"
                      data-target-id="#informacoes"
                      aria-expanded="false"
                      aria-controls="informacoes"
                    >
                      Informações
                    </button>

                    <a
                      class="btn select-btn"
                      v-if="move == true"
                      type="button"
                      href="#"
                      @click="finalizeSelectionModal"
                    >
                      {{ $t("label.finalizar") }}
                      <i class="fa fa-forward"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="collapse" id="informacoes">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("label.empresa") }}</th>
                      <th>{{ $t("label.tituloDaVaga") }}</th>
                      <th>{{ $t("label.area") }}</th>
                      <th>{{ $t("label.centroCusto") }}</th>
                      <th>{{ $t("label.dataFinal") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{{ Job.company.name }}</th>
                      <td>{{ Job.role_name }}</td>
                      <td>{{ Job.department }}</td>
                      <td>{{ Job.cost_center.cost_center }}</td>
                      <td>{{ Job.end_date }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mb-3">
                <div class="col-md-12">
                  <custom-kanban
                    v-if="move == true"
                    :stages="stages"
                    :blocks="candidates"
                    :disableMove="disable"
                    @showModal="showModal"
                    @setSelectAll="setSelectAll"
                    @changeStatus="changeStatus"
                    @update-block="updateStatus"
                  >
                    <template
                      slot="stage"
                      slot-scope="currentStage"
                      v-if="move == true"
                    >
                      <button
                        v-if="
                          currentStage.stage.type == 2 && candidates.length > 0
                        "
                        :disabled="hasCandidatesSelected(currentStage.stage)"
                        @click="sendQuestion(currentStage.stage)"
                        class="btn drop-btn float-right"
                        type="button"
                        data-toggle="modal"
                        data-target="#enviarTeste"
                      >
                        <i class="fa fa-list-alt"></i>
                        {{ $t("label.enviarTeste") }}
                      </button>
                      <button
                        v-if="
                          currentStage.stage.type == 4 && candidates.length > 0
                        "
                        @click="sendInvite(currentStage.stage)"
                        class="btn drop-btn float-right"
                        type="button"
                        data-toggle="modal"
                        data-target="#enviarTeste"
                      >
                        <i class="fa fa-envelope"></i>
                        {{ $t("label.enviarConvite") }}
                      </button>
                      <button
                        v-if="
                          currentStage.stage.type == 6 && candidates.length > 0
                        "
                        @click="sendWhatsapp(currentStage.stage)"
                        class="btn drop-btn float-right"
                        type="button"
                        data-toggle="modal"
                        data-target="#enviarTeste"
                      >
                        <i class="fa fa-envelope"></i>
                        {{ $t("label.enviarWhatsapp") }}
                      </button>
                    </template>

                    <div
                      class="card bg-flat-gray mb-0 ribbon-wrapper"
                      v-for="item in candidates"
                      :slot="item.id"
                      :key="item.id"
                    >
                      <div class="card-body ribbon-content">
                        <!-- <template v-show="item.type == 2 || item.has_completed_questionnaire > 0"> -->
                        <img
                          :src="$store.getters.imagePath + 'tag-false.png'"
                          class="tag-image"
                          v-show="item.has_completed_questionnaire == 0"
                        />
                        <img
                          :src="$store.getters.imagePath + 'tag-true.png'"
                          class="tag-image"
                          v-show="item.has_completed_questionnaire > 0"
                        />
                        <!-- </template> -->

                        <img
                          :src="item.candidate.image"
                          class="profile-avatar"
                        />
                        <h6 class="text-center mt-1 mb-2">
                          {{ item.candidate.fullName }}
                        </h6>

                        <h6
                          class="text-center mt-1 mb-2"
                          style="cursor: pointer"
                          @click="talentBank(item.candidate.id)"
                        >
                          <i
                            :class="[
                              item.candidate.talent_bank == 0
                                ? 'fa fa-star-o'
                                : 'fa fa-star',
                            ]"
                          ></i>
                        </h6>
                        <h6
                          class="text-center mt-1 mb-2"
                          v-if="item.approved == 0"
                        >
                          <span class="badge badge-danger">{{
                            $t("label.notApproved")
                          }}</span>
                        </h6>
                        <h6
                          class="text-center mt-1 mb-2"
                          v-if="item.approved == 1"
                        >
                          <span
                            @click="reproveCandidate(item.id)"
                            class="badge badge-success"
                            style="cursor: pointer"
                            >{{ $t("label.approved") }}</span
                          >
                        </h6>
                        <div class="form-group mb-0">
                          <div
                            class="text-center mb-3"
                            v-if="item.has_received_questionnaire > 0"
                          >
                            <span class="badge badge-pill badge-info"
                              >Teste enviado!</span
                            >
                          </div>
                          <div class="input-group">
                            <div class="form-check-inline form-check m-auto">
                              <label
                                :for="'selecionarCand' + item.id"
                                class="form-check-label m-0"
                              >
                                <input
                                  type="checkbox"
                                  @click="verifySelected(item)"
                                  v-model="item.selected"
                                  :checked="item.selected"
                                  :id="'selecionarCand' + item.id"
                                  :name="'selecionarCand' + item.id"
                                  value="option1"
                                  class="form-check-input"
                                />
                                <span class="checkmark"></span>
                                {{ $t("label.selecionar") }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="move-candidate col-md-12">
                          <br />
                          <button
                            class="btn edit-btn btn-primary mt-1 mb-1 col-md-12"
                            type="button"
                            data-toggle="modal"
                            data-target="#MoveCandidateToNewJob"
                            @click="modalMoveCandidate(item.candidate.id)"
                          >
                            <i class="fa fa-exchange" aria-hidden="true"></i>
                            {{ $t("label.apply_to_another_job") }}
                          </button>
                        </div>
                      </div>
                      <div class="card-footer">
                        <button
                          class="btn edit-btn btn-block mt-1 mb-1"
                          type="button"
                          data-toggle="modal"
                          data-target="#vizualizarPerfil"
                          @click="showModal(item)"
                        >
                          <i class="fa fa-eye"></i>
                          {{ $t("label.visualizarPerfil") }}
                        </button>
                        <br
                          v-if="item.approved == 0 && approveButton == true"
                        />
                        <br
                          v-if="item.approved == 0 && approveButton == true"
                        />
                        <button
                          class="btn select-btn btn-block mt-1 mb-1"
                          v-if="item.approved == 0 && approveButton == true"
                          type="button"
                          data-toggle="modal"
                          data-target="#vizualizarPerfil"
                          @click="approveCandidate(item.id)"
                        >
                          <i class="fa fa-check"></i>
                          {{ $t("label.approve") }}
                        </button>
                      </div>
                    </div>
                  </custom-kanban>

                  <custom-kanban-manager
                    v-if="
                      move == false &&
                      this.Job.requester_id != this.Job.recruiter_id
                    "
                    :stages="stages"
                    :blocks="candidates"
                    :disableMove="disable"
                    @showModal="showModal"
                    @setSelectAll="setSelectAll"
                    @changeStatus="changeStatus"
                    @update-block="updateStatus"
                  >
                    <div
                      class="card bg-flat-gray mb-0 ribbon-wrapper"
                      v-for="item in candidates"
                      :slot="item.id"
                      :key="item.id"
                    >
                      <div class="card-body ribbon-content">
                        <!-- <template v-show="item.type == 2 || item.has_completed_questionnaire > 0"> -->
                        <img
                          :src="$store.getters.imagePath + 'tag-false.png'"
                          class="tag-image"
                          v-show="item.has_completed_questionnaire == 0"
                        />
                        <img
                          :src="$store.getters.imagePath + 'tag-true.png'"
                          class="tag-image"
                          v-show="item.has_completed_questionnaire > 0"
                        />
                        <!-- </template> -->

                        <img
                          :src="item.candidate.image"
                          class="profile-avatar"
                        />
                        <h6 class="text-center mt-1 mb-2">
                          {{ item.candidate.fullName }}
                        </h6>
                        <h6
                          class="text-center mt-1 mb-2"
                          v-if="item.approved == 0"
                        >
                          <span class="badge badge-danger">{{
                            $t("label.notApproved")
                          }}</span>
                        </h6>
                        <h6
                          class="text-center mt-1 mb-2"
                          v-if="item.approved == 1"
                        >
                          <span class="badge badge-success">{{
                            $t("label.approved")
                          }}</span>
                        </h6>
                        <div class="form-group mb-0">
                          <div
                            class="text-center mb-3"
                            v-if="item.has_received_questionnaire > 0"
                          >
                            <span class="badge badge-pill badge-info"
                              >Teste enviado!</span
                            >
                          </div>
                          <div class="input-group">
                            <div class="form-check-inline form-check m-auto">
                              <label
                                :for="'selecionarCand' + item.id"
                                class="form-check-label m-0"
                              >
                                <input
                                  type="checkbox"
                                  @click="verifySelected(item)"
                                  v-model="item.selected"
                                  :checked="item.selected"
                                  :id="'selecionarCand' + item.id"
                                  :name="'selecionarCand' + item.id"
                                  value="option1"
                                  class="form-check-input"
                                />
                                <span class="checkmark"></span>
                                {{ $t("label.selecionar") }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <button
                          class="btn edit-btn btn-block mt-1 mb-1"
                          type="button"
                          data-toggle="modal"
                          data-target="#vizualizarPerfil"
                          @click="showModal(item)"
                        >
                          <i class="fa fa-eye"></i>
                          {{ $t("label.visualizarPerfil") }}
                        </button>
                        <br
                          v-if="item.approved == 0 && approveButton == true"
                        />
                        <br
                          v-if="item.approved == 0 && approveButton == true"
                        />
                        <button
                          class="btn select-btn btn-block mt-1 mb-1"
                          v-if="item.approved == 0 && approveButton == true"
                          type="button"
                          data-toggle="modal"
                          data-target="#vizualizarPerfil"
                          @click="approveCandidate(item.id)"
                        >
                          <i class="fa fa-check"></i>
                          {{ $t("label.approve") }}
                        </button>
                      </div>
                    </div>
                  </custom-kanban-manager>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Envio de Email -->

    <div class="modal" id="modalInvite" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("label.desejaEnviarOsInvitesParaOsCandidatos") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="form-group">
                <label for="logo" class="control-label mb-1"
                  >{{ $t("label.escolhaTemplateEmail") }}*</label
                >
                <select class="form-control" v-model="mailInvite.template">
                  <option value selected="selected">
                    {{ $t("label.selecione") }}...
                  </option>
                  <option
                    v-for="email_template in email_templates"
                    :value="email_template.id"
                    :key="email_template.id"
                  >
                    {{ email_template.title }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="logo" class="control-label mb-1"
                  >{{ $t("label.data") }}*</label
                >
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="mailInvite.datetime"
                  maxlength="191"
                  autofocus
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="logo" class="control-label mb-1"
                  >{{ $t("label.duracaoInvite") }}*</label
                >
                <select class="form-control" v-model="mailInvite.duration">
                  <option value="1">1 Hora</option>
                  <option value="2">2 Horas</option>
                  <option value="3">3 Horas</option>
                  <option value="4">4 Horas</option>
                  <option value="5">5 Horas</option>
                  <option value="6">6 Horas</option>
                  <option value="7">7 Horas</option>
                  <option value="8">8 Horas</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="sendInviteMail(StageInvite)"
            >
              {{ $t("label.enviarEmail") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("label.fechar") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Envio de WhatsApp -->

    <div class="modal" id="modalWhatsapp" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("label.desejaEnviarWhatsApp") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="form-group">
                <label for="logo" class="control-label mb-1"
                  >{{ $t("label.escolhaTemplateWhatsapp") }}*</label
                >
                <select class="form-control" v-model="mailInvite.template">
                  <option value selected="selected">
                    {{ $t("label.selecione") }}...
                  </option>
                  <option
                    v-for="email_template in email_templates"
                    :value="email_template.id"
                    :key="email_template.id"
                  >
                    {{ email_template.title }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="logo" class="control-label mb-1"
                  >{{ $t("label.data") }}*</label
                >
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="mailInvite.datetime"
                  maxlength="191"
                  autofocus
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="logo" class="control-label mb-1"
                  >{{ $t("label.duracaoInvite") }}*</label
                >
                <select class="form-control" v-model="mailInvite.duration">
                  <option value="1">1 Hora</option>
                  <option value="2">2 Horas</option>
                  <option value="3">3 Horas</option>
                  <option value="4">4 Horas</option>
                  <option value="5">5 Horas</option>
                  <option value="6">6 Horas</option>
                  <option value="7">7 Horas</option>
                  <option value="8">8 Horas</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="sendInviteWhatsapp(StageInvite)"
            >
              {{ $t("label.enviarWhatsapp") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("label.fechar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de comentariosGestaoRS -->
    <div
      class="modal fade"
      id="modalComments"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ $t("label.comentariosGestaoRS") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="comments">
              <div v-for="comment in jobComments" :key="comment.id">
                <div class="row">
                  <div class="col-md-12">
                    <div class="perfil-image-2 float-left mr-4">
                      <img :src="comment.user.image" alt="Foto Perfil" />
                    </div>
                    <div class="float-left">
                      <p class="text-left text-dark-blue mb-2">
                        {{ comment.user.name }}
                        <span class="small text-muted"
                          >em {{ comment.created_at }}</span
                        >
                      </p>
                      <p class="text-left mb-0">{{ comment.description }}</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Vagas -->

    <div class="modal" id="modalMoveToNewJob" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aplicar candidatos à uma vaga</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="form-group">
                <label for=""
                  >Selecione uma vaga para vincular os candidatos</label
                >
                <select class="form-control" v-model="job_selected">
                  <option value selected="selected">
                    {{ $t("label.selecione") }}...
                  </option>
                  <option
                    v-for="job in jobs_available"
                    :value="job"
                    :key="job.id"
                  >
                    {{ job.role_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for=""
                  >Selecione uma etapa do processo para vincular os
                  candidatos</label
                >
                <select class="form-control" v-model="job_stage">
                  <option value selected="selected">
                    {{ $t("label.selecione") }}...
                  </option>
                  <option
                    v-for="job in job_selected.stages"
                    :value="job.id"
                    :key="job.id"
                  >
                    {{ job.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              v-if="job_selected"
              @click="newApply"
            >
              Aplicar a esta vaga
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("label.fechar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import CandidateViewModal from "../Candidates/CandidateViewModal";
import KanbanFinalizeModal from "../KanbanSelection/KanbanFinalize";
import CustomKanban from "./CustomKanban";
import CustomKanbanManager from "./CustomKanbanManager";

export default {
  components: {
    CandidateViewModal,
    KanbanFinalizeModal,
    CustomKanban,
    CustomKanbanManager,
  },
  data: () => {
    return {
      openModal: false,
      noCandidates: true,
      jobCandidateID: "",
      renderComponent: true,
      jobComments: [],
      loadingJob: false,
      jobRoles: "/job/",
      endPointRoles: "/jobRole",
      endPointCandidates: "/jobCandidateStage/",
      endPointEmailTemplates: "/email_templates/",
      iterationEndPoint: "/jobCandidateStageIteration/",
      jobStageEndPoint: "/jobStage/",
      // iterations: [],
      messageEndPoint: "/message/",
      jobCandidate: {},
      job_id: "",
      stages: [""],
      candidates: [],
      fullStages: [],
      passports: [],
      Job: {
        company: {
          photo: "",
        },
        cost_center: {
          cost_center: "",
          name: "",
        },
        end_date: "",
      },
      StageInvite: "",
      mailInvite: [],
      firstStage: "",
      email_templates: [],
      disable: false,
      loaded: false,
      job_stage: "",
      job_selected: "",
      jobs_available: [],
      candidate_to_new_apply_id: "",
    };
  },

  mounted: function () {
    this.loadKanban();
    this.loadTemplatesEmail();
    this.loadComments(this.$route.params.id);
    this.getAvailable();
  },

  computed: {
    url_JOB() {
      return this.$store.getters.apiUrl + this.endPoint.jobRoles;
    },
    vueInstance() {
      return this;
    },
    url_ROLE() {
      return this.$store.getters.apiUrl + this.endPointRoles;
    },
    urlJobCandidate() {
      return this.$store.getters.apiUrl + this.endPointCandidates;
    },
    urlEmailTemplate() {
      return this.$store.getters.apiUrl + this.endPointEmailTemplates;
    },
    url_iteration() {
      return this.$store.getters.apiUrl + this.iterationEndPoint;
    },
    url_MESSAGE() {
      return this.$store.getters.apiUrl + this.messageEndPoint;
    },
    urlJobStage() {
      return this.$store.getters.apiUrl + this.jobStageEndPoint;
    },
    candidatesInLastStage() {
      let lastStage = this.stages[this.stages.length - 1];

      let aprovedCandidates = this.candidates.filter((candidate) => {
        return candidate.status == lastStage.name;
      });

      // let firstStage = this.stages[0].id;

      return aprovedCandidates;
    },
    candidatesNotApproved() {
      let lastStage = this.stages[this.stages.length - 1];
      let notApprovedCandidates = this.candidates.filter((candidate) => {
        return candidate.status != lastStage.name;
      });

      return notApprovedCandidates;
    },
    urlJob: function () {
      return this.$store.getters.apiUrl + "/job/";
    },
    move() {
      if (this.Job.requester_id == this.$store.getters.user.id) {
        if (this.Job.requester_id == this.Job.recruiter_id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    approveButton() {
      return this.Job.requester_id == this.$store.getters.user.id;
    },
  },
  filters: {
    toBrazilianDate: function (date) {
      let dt =
        date.split("-")[2] +
        "/" +
        date.split("-")[1] +
        "/" +
        date.split("-")[0];
      return dt;
    },
  },
  methods: {
    loadComments(id) {
      const self = this;
      const api =
        self.$store.getters.apiUrl + "/jobCommentsRS/?with[]=user&job_id=" + id;

      self.$http.get(api).then((response) => {
        let jobComments = response.data.data;
        self.jobComments = jobComments;
      });

      return "ok";
    },
    openCommentsModal() {
      this.$("#modalComments").modal("show");
    },
    modalMoveCandidate(id) {
      this.$("#modalMoveToNewJob").modal("show");
      this.candidate_to_new_apply_id = id;
    },
    loadKanban() {
      this.loadStages();
      this.loadCandidates();
      this.loadJob();
    },
    hasCandidatesSelected(stage) {
      let count_candidates = this.candidates.filter(
        (candidate) =>
          candidate.status_id == stage.id && candidate.selected == true
      );

      if (count_candidates.length > 0) {
        return false;
      }
      return true;
    },
    sendQuestion(stage) {
      const self = this;

      let count_candidates = this.candidates.filter(
        (candidate) =>
          candidate.status_id == stage.id && candidate.selected == true
      );

      if (count_candidates.length == 0) {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          self.$t("label.selecionePeloMenos1CandidatoParaEnviaroTeste")
        );
        return;
      }

      swal({
        title: self.$t("label.desejaEnviarOsTestesParaOsCandidatos"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        type: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, enviar!",
        showLoaderOnConfirm: true,
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm",
          },
        },
      })
        .then((value) => {
          if (value == "confirm") {
            let candidatesToSend = this.candidates.filter((candidate) => {
              return (
                candidate.status == stage.name && candidate.selected == true
              );
            });

            if (candidatesToSend.length == 0) {
              self.$message(
                "warning",
                self.$t("label.atencao"),
                self.$t("label.selecionePeloMenos1CandidatoParaEnviaroTeste")
              );
              return;
            }

            candidatesToSend = candidatesToSend.map((candidate) => {
              return candidate.job_candidate_id;
            });

            self.$http
              .post(
                self.$store.getters.apiUrl + "/sendQuestionnaireToCandidates",
                {
                  candidates: candidatesToSend,
                  jobId: self.Job.id,
                  stageId: stage.id,
                }
              )
              .then(() => {
                swal(
                  self.$t("label.sucesso"),
                  self.$t(
                    "label.testeEnviadoComSucessoParaCandidatosSelecionados"
                  ),
                  "success"
                );
              });
          }
        })
        .catch((error) => {
          self.$errorMessage(error);
        });
    },
    sendWhatsapp(stage) {
      const self = this;

      self.StageInvite = stage;

      let count_candidates = this.candidates.filter(
        (candidate) =>
          candidate.status_id == stage.id && candidate.selected == true
      );

      if (count_candidates.length == 0) {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          self.$t("label.selecionePeloMenos1CandidatoParaEnviaroTeste")
        );
        return;
      }

      $("#modalWhatsapp").modal();
    },
    sendInviteWhatsapp(stage) {
      const self = this;

      let candidatesToSend = this.candidates.filter((candidate) => {
        return candidate.status == stage.name && candidate.selected == true;
      });

      if (candidatesToSend.length == 0) {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          self.$t("label.selecionePeloMenos1CandidatoParaEnviaroTeste")
        );
        return;
      }

      candidatesToSend = candidatesToSend.map((candidate) => {
        return candidate.job_candidate_id;
      });

      self.$http
        .post(self.$store.getters.apiUrl + "/sendWhatsappToCandidates", {
          candidates: candidatesToSend,
          jobId: self.Job.id,
          stageId: stage.id,
          template_id: self.mailInvite.template,
          datetime: self.mailInvite.datetime,
          duration: self.mailInvite.duration,
        })
        .then(() => {
          swal(
            self.$t("label.sucesso"),
            self.$t(
              "label.whatsappEnviadoComSucessoParaCandidatosSelecionados"
            ),
            "success"
          );
        });
    },
    sendInvite(stage) {
      const self = this;

      self.StageInvite = stage;

      let count_candidates = this.candidates.filter(
        (candidate) =>
          candidate.status_id == stage.id && candidate.selected == true
      );

      if (count_candidates.length == 0) {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          self.$t("label.selecionePeloMenos1CandidatoParaEnviaroTeste")
        );
        return;
      }

      $("#modalInvite").modal();
    },
    sendInviteMail(stage) {
      const self = this;

      let candidatesToSend = this.candidates.filter((candidate) => {
        return candidate.status == stage.name && candidate.selected == true;
      });

      if (candidatesToSend.length == 0) {
        self.$message(
          "warning",
          self.$t("label.atencao"),
          self.$t("label.selecionePeloMenos1CandidatoParaEnviaroTeste")
        );
        return;
      }

      candidatesToSend = candidatesToSend.map((candidate) => {
        return candidate.job_candidate_id;
      });

      self.$http
        .post(self.$store.getters.apiUrl + "/sendInviteToCandidates", {
          candidates: candidatesToSend,
          jobId: self.Job.id,
          stageId: stage.id,
          template_id: self.mailInvite.template,
          datetime: self.mailInvite.datetime,
          duration: self.mailInvite.duration,
        })
        .then(() => {
          swal(
            self.$t("label.sucesso"),
            self.$t("label.inviteEnviadoComSucessoParaCandidatosSelecionados"),
            "success"
          );
        });
    },
    approveCandidate(candidateId) {
      const self = this;

      swal({
        title: self.$t("label.desejaAprovarEsteCandidato"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, enviar!",
        showLoaderOnConfirm: true,
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm",
          },
        },
      })
        .then((value) => {
          if (value == "confirm") {
            self.$http
              .put(
                self.$store.getters.apiUrl +
                  "/jobCandidateStage/" +
                  candidateId,
                {
                  candidate_id: candidateId,
                  approved: 1,
                }
              )
              .then(() => {
                swal(
                  self.$t("label.sucesso"),
                  self.$t("label.candidatoAprovado"),
                  "success"
                );
                this.loadCandidates();
                this.loadJob();
              });
          }
        })
        .catch((error) => {
          self.$errorMessage(error);
        });
    },
    talentBank(candidateId) {
      const self = this;
      self.loaded = false;

      self.$http
        .post(self.$store.getters.apiUrl + "/jobCandidate/apply_talent_bank/", {
          candidate_id: candidateId,
        })
        .then(() => {
          swal(self.$t("label.sucesso"), "Candidato Atualizado", "success");
          this.loadCandidates();
          this.loadJob();
        });
    },
    reproveCandidate(candidateId) {
      const self = this;

      swal({
        title: self.$t("label.desejaReprovarEsteCandidato"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, enviar!",
        showLoaderOnConfirm: true,
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm",
          },
        },
      })
        .then((value) => {
          if (value == "confirm") {
            self.$http
              .put(
                self.$store.getters.apiUrl +
                  "/jobCandidateStage/" +
                  candidateId,
                {
                  candidate_id: candidateId,
                  approved: 0,
                }
              )
              .then(() => {
                swal(
                  self.$t("label.sucesso"),
                  self.$t("label.candidatoReprovado"),
                  "success"
                );
                this.loadCandidates();
                this.loadJob();
              });
          }
        })
        .catch((error) => {
          self.$errorMessage(error);
        });
    },
    numberOfcandidatesInStage(name) {
      let aprovedCandidates = this.candidates.filter((candidate) => {
        return candidate.status == name;
      });
      return aprovedCandidates.length;
    },
    loadJob() {
      this.loadingJob = true;

      const self = this;
      self.$http
        .get(self.urlJob + this.$route.params.id, {
          params: {
            with: ["company", "cost_center", "branch"],
          },
        })
        .then((response) => {
          self.Job = response.data;
          this.loadingJob = false;
        })
        .catch(function (error) {
          self.$errorMessage(error);
        });
    },
    loadStages() {
      const self = this;
      self.job_id = self.$route.params.id;
      if (self.urlJobStage != undefined) {
        self.$http
          .get(self.urlJobStage, {
            params: {
              job_id: self.job_id,
              sortByAsc: "order",
              paginated: false,
              with: ["roleStage"],
            },
          })
          .then((response) => {
            let dataStages = response.data.data;
            self.stages = dataStages.map(function (s) {
              return {
                name: s.name,
                id: s.id,
                type: s.role_stage ? s.role_stage.type : null,
                selectedAll: false,
              };
            });
            self.fullStages = dataStages;

            if (this.stages.length > 0) {
              this.firstStage = this.stages[0].id;
            }
          })
          .catch((error) => {
            self.$errorMessage(error);
          });
      }
    },
    loadTemplatesEmail() {
      const self = this;
      self.job_id = self.$route.params.id;
      if (self.urlJobStage != undefined) {
        self.$http
          .get(self.urlEmailTemplate, {
            params: {},
          })
          .then((response) => {
            self.email_templates = response.data.data;
          })
          .catch((error) => {
            self.$errorMessage(error);
          });
      }
    },
    loadCandidates() {
      const self = this;
      self.job_id = self.$route.params.id;

      if (this.urlJobCandidate) {
        self.$store.dispatch("tooggleLoadModal");
        self.$http
          .get(this.urlJobCandidate, {
            params: {
              job_id: this.job_id,
              paginated: false,
              with: [
                "stage",
                "candidate",
                "iterations",
                "candidate.candidate",
                "candidate.answers",
                "candidate.answers.question",
                "candidate.answers.question.questionnaire",
                "candidate.answers.answer",
                "candidate.skills",
                "candidate.languages",
                "candidate.experiences",
                "candidate.graduations",
                "candidate.courses",
                "candidate.networks",
                "stage.roleStage",
                "candidate.passports.questionnaire.questions.answers.candidateAnswer.answer",
              ],
            },
          })
          .then((response) => {
            self.$store.dispatch("tooggleLoadModal");
            let data = response.data.data;
            console.log("load candidates", data);

            self.candidates = data.map(function (c) {
              let obj = {};

              obj = c.candidate;
              obj.status = c.stage.name;
              obj.job_candidate_id = c.candidate.id;
              obj.id = c.id;
              obj.status_id = c.stage.id;
              obj.approved = c.approved;
              obj.iterations = c.iterations;
              obj.has_completed_questionnaire = c.has_completed_questionnaire;
              obj.has_received_questionnaire = c.has_received_questionnaire;
              obj.type = c.stage.role_stage.type;
              obj.candidate = c.candidate.candidate;
              obj.selected = false;
              return obj;
            });
          })
          .then(() => {
            if (self.candidates.length > 0) {
              self.loaded = true;
              self.noCandidates = false;
            } else {
              self.loaded = true;
              self.noCandidates = true;
            }
          })
          .catch((error) => {
            self.$store.dispatch("tooggleLoadModal");
            self.$errorMessage(error);
          });
      }
    },
    finalizeSelectionModal: function () {
      this.$("#finalize-modal").modal("show");
    },
    finalizeSelection: function () {},
    showModal: function (candidate) {
      const self = this;
      this.openModal = false;
      this.jobCandidate = {};

      setTimeout(function () {
        self.jobCandidate = candidate;
        self.openModal = true;
      }, 500);

      this.$("#modalTemplate").modal("show");
    },
    changeStatus: function (origin, final) {
      const self = this;

      const candidates_selected = this.candidates.filter(
        (candidate) =>
          candidate.selected == true && candidate.status_id == origin.id
      );

      candidates_selected.map((candidate) => {
        this.updateStatus(candidate.id, final.name, null, () => {
          let candidates = self.candidates;
          for (let j in candidates) {
            candidates[j].selected = false;
          }
          self.candidates = candidates;

          this.stages.map((stage) => {
            stage.selectedAll = false;
            return stage;
          });
        });
      });
    },
    updateStatus(id, status, callbackOnComplete = null) {
      const self = this;

      self.job_id = self.$route.params.id;
      let candidate = self.candidates.filter(function (c) {
        return c.id == id;
      })[0];

      let newStage = self.fullStages.filter(function (s) {
        return s.name == status;
      });

      newStage = newStage[0];
      self.$http
        .put(this.urlJobCandidate + candidate.id, {
          job_stage_id: newStage.id,
          updateStage: true,
        })
        .then((response) => {
          candidate.status = newStage.name;
          candidate.status_id = newStage.id;
          candidate.selected = false;
          candidate.has_completed_questionnaire =
            response.data.has_completed_questionnaire;
          candidate.has_received_questionnaire =
            response.data.has_received_questionnaire;

          this.stages.map((stage) => {
            stage.selectedAll = false;
            return stage;
          });

          if (callbackOnComplete) {
            callbackOnComplete();
          }
        })
        .catch((error) => {
          let errors = error.response
            ? error.response.data
            : self.$t(
                "label.ocorreuUmErroAoAtualizarAsInformacoesDosCandidatos"
              );

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
    setSelectAll(stage_actual) {
      this.stages.map((stage) => {
        if (stage.id != stage_actual.id) {
          stage.selectedAll = false;
        }
        return stage;
      });

      let candidates = this.candidates;
      for (let j in candidates) {
        if (
          candidates[j].status_id == stage_actual.id &&
          stage_actual.selectedAll
        ) {
          candidates[j].selected = true;
        } else {
          candidates[j].selected = false;
        }
      }

      this.candidates = candidates;
    },
    verifySelected(item) {
      let status_id = item.status_id;

      let candidates_total = this.candidates.filter(
        (candidate) => candidate.status_id == status_id
      );
      let candidates_selected = this.candidates.filter(
        (candidate) =>
          candidate.status_id == status_id && candidate.selected == true
      );

      this.stages.map((stage) => {
        stage.selectedAll = false;
        return stage;
      });

      this.stages
        .filter((stage) => stage.id == status_id)
        .forEach((s) => {
          if (candidates_total.length == candidates_selected.length) {
            s.selectedAll = false;
          } else {
            s.selectedAll = true;
          }
        });
    },
    getAvailable() {
      let self = this;

      self.job_id = self.$route.params.id;
      self.$http
        .get(this.$store.getters.apiUrl + "/jobs_available", {
          params: {},
        })
        .then((response) => {
          self.jobs_available = response.data;
        })
        .catch((error) => {
          let errors = error.response
            ? error.response.data
            : self.$t("label.ocorreuUmErroAoCarregarInformacoesVaga");

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            self.$.each(errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
    newApply() {
      const self = this;
      console.log("candidate:" + this.candidate_to_new_apply_id);
      self.$store.dispatch("tooggleLoadModal");
      self.$http
        .put(this.$store.getters.apiUrl + "/job_new-apply", {
          job_id: this.job_id,
          new_job_id: this.job_selected.id,
          candidate_id: this.candidate_to_new_apply_id,
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.$message(
            "success",
            self.$t("label.atualizadoComSucesso"),
            self.$t("label.candidatosVinculadosComSucesso")
          );
        })
        .then(() => {
          this.loadCandidates();
          this.loadJob();
          this.$("#modalMoveToNewJob").modal("close");
        })
        .catch((error) => {
          let errors = error.response
            ? self.$t("label.ocorreuUmErroAoRealizarUmaInteracao")
            : error.response.data;

          self.$store.dispatch("tooggleLoadModal");
          self.$errorMessage(errors);
        });
    },
  },
};
</script>

<style scoped>
.btn {
  margin-right: 5px;
}
</style>
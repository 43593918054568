<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ $t('label.questionario') }}</h3>
                    <form @submit.prevent>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="nome" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>
                                        <input type="text" class="form-control" v-model="questionnaire.name" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card text-dark-blue bg-flat-gray">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <h3 class="card-title">{{ $t('label.perguntas') }}</h3>
                                            </div>
                                            <div class="col-md-4">
                                                <a class="btn add-btn p-0 mb-3 float-right color-white" @click="addQuestion"><i class="fa fa-plus-square"></i> {{ $t('label.cadastrar') }}</a>                                                    
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">                                                        
                                                <v-server-table ref="table" :name="table" :url="questionUrl" :data="tableData" :columns="columns"
                                                    :options="options">
                                                    <div slot="actions" slot-scope="props">
                                                        <button type="button" class="btn view-btn p-0" @click="viewQuestion(props.row.id)" :title="$t('label.visualizar')"><i class="fa fa-eye"></i></button>
                                                        <button type="button" class="btn edit-btn p-0" @click="editQuestion(props.row.id)" :title="$t('label.editar')"><i class="fa fa-pencil"></i></button>
                                                        <button type="button" class="btn delete-btn p-0" @click="destroy(props, props.row, props.index)" :title="$t('label.deletar')"><i class="fa fa-trash-o"></i></button>
                                                    </div>
                                                </v-server-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/questionnaire"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>

                                <button @submit.prevent @click="save" id="save-btn" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <question-modal :questionnaire="questionnaire" :modalMode="modalMode" :modalStatus="modalStatus" :question_id="question_id"
            @changeModal='changeModal' @refreshTable='refreshTable'>
        </question-modal>
    </div>
</template>

<script>
import swal from 'sweetalert'
import QuestionModal from './QuestionModal'

export default {
  
    components: { QuestionModal },

    data() {
        return {
            questionnaire: {
                id: 0,
                name: ''
            },
            questions: [],
            answers: [],
            endPoint: "/questionnaire/",
            columns: ['id', 'description', 'actions'],
            tableData: [],
            options: {
                headings: {
                    'id': 'N°',
                    'description': this.$t('label.pergunta'),
                    'actions': this.$t('label.acoes')
                },
                debounce: 1000,
                requestFunction: function (data) {
                    data.questionnaire_id = this.$route.params.id;

                    return this.$http
                        .get(this.url, {
                            params: data
                        })
                        .catch(function (e) {
                            this.dispatch('error', e);
                        }.bind(this));
                },
                texts: {
                    count: `${this.$t('label.mostrando')} {from} ${this.$t('label.de')} {to} ${this.$t('label.ate')} {count} ${this.$t('label.deRegistros')}`,
                    filter: false,
                    filterPlaceholder: this.$t('label.pesquisarAqui'),
                    limit: '',
                    noResults: this.$t('label.nenhumRegistroEncontrado'),
                    page: this.$t('label.pagina')+':'
                },
            },
            enableView: true,
            enableEdit: true,
            enableDelete: true,
            name: 'questionsTable',
            table: "questionsTable",
            question_id: 0,
            modalMode: '',
            modalStatus: false
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint
        },
        questionUrl() {
            return this.$store.getters.apiUrl + '/question/';
        }
    },
    watch: {
        modalStatus: function () {
            const self = this;
            self.$refs.table.getData();
        }
    },
    mounted: function () {
        this.loadQuestionnaire()
    },
    methods: {
        refreshTable() {
            this.$refs.table.getData();
        },
        loadQuestionnaire() {
            const self = this;
            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(this.url + this.$route.params.id)
            .then((response) => {
                self.$store.dispatch("tooggleLoadModal");
                self.questionnaire = response.data
            })
            .catch((error) => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error)
            })
        },
        save() {
            const self = this;
            self.$store.dispatch("tooggleLoadModal");
            self.$http
                .put(self.url + this.$route.params.id, {
                    'name': self.questionnaire.name,
                })
                .then(() => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.registroAtualizadoComSucesso'));
                    self.$router.push({
                        path: "/questionnaire/"
                    });
                })
                .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                })
        },
        addQuestion() {
            this.modalMode = 'add';
            this.question_id = 0;
            this.modalStatus = true;

            this.$("#modalQuestion").modal('show');
        },
        changeModal(status) {
            this.modalStatus = status;
        },
        viewQuestion(id) {
            this.modalMode = 'view';
            this.question_id = id;
            this.modalStatus = true;

            this.$("#modalQuestion").modal('show');
        },
        editQuestion(id) {
            this.modalMode = 'edit';
            this.question_id = id;
            this.modalStatus = true;

            this.$("#modalQuestion").modal('show');
        },
        destroy(props, row) {
            const self = this;

            swal({
                title: self.$t('label.desejaDeletarRegistro'),
                text: self.$t('label.essaOperacaoNaoPodeSerDesfeita'),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    catch: {
                        text: self.$t('label.nao'),
                        value: "cancel",
                        className: "btn-danger"
                    },
                    confirm: {
                        text: self.$t('label.sim'),
                        value: 'confirm'
                    }
                },
            })
            .then((value) => {
                if (value == 'confirm') {
                    self.$store.dispatch("tooggleLoadModal");
                    this.$http.delete(this.questionUrl + row.id, {
                        'id': row.id
                    })
                    .then(() => {
                        self.$store.dispatch("tooggleLoadModal");
                        self.$refs.table.refresh();
                        self.$message('success',  self.$t('label.sucesso'),  self.$t('label.registroExcluidoComSucesso'));                        
                    })
                    .catch((error) => {   
                        self.$store.dispatch("tooggleLoadModal"); 
                        self.$errorMessage(error);
                    })
                }
            }).catch(error => {
                self.$errorMessage(error);
            }) 
        }
    }
}
</script>

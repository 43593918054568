<template>
    <div>
        <div class="modal fade" id="jobDetailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">

                    <div class="modal-header  bg-info">
                        <h4 class="m-b-0 text-white">Processo Seletivo</h4>

                        <button type="button" class="close color-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="row">

                    <div class="col-md-12">
                        <div class="">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <img v-if="jobObj.company.photo" class="company-photo-jobdetail"
                                            :src="jobObj.company.photo">
                                    </div>
                                    <div class="col-md-9">
                                        <p class="company-role-name">{{jobObj.role_name}}</p>
                                        <p class="company-name-job"> {{jobObj.company.name}} - São Paulo, BR </p>
                                        <b class="text-danger">{{jobObj.quantity | quantity }}</b>
                                        <p class="created-job-detail">Requisitada em {{jobObj.created_at}}</p>
                                        
                                        <!--
                                        <div class="row">
                                            <div class="col-md-3">
                                                <button type="button" class="btn btn-block btn-outline-success">Salvar</button>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="button" class="btn waves-effect waves-light btn-block btn-info">Candidate-se</button>
                                            </div>
                                        </div>
                                        -->

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <div class="card-body">
                                <div class="card-text big-title">
                                    Aprovação/Reprovação
                                </div>

                                <div class="row filter-jobs-home">
                                    <div class="col-md-12" v-if="jobObj.status == 'canceled'">
                                        <div class="alert alert-danger">
                                            Vaga reprovada por {{jobObj.approver.name}} em {{jobObj.approved_at | toBrazilianDateTime }}
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="jobObj.status != 'canceled' && jobObj.status != 'approval'">
                                        <div class="alert alert-success">
                                            <tbody>
                                                <tr v-for="approved in jobObj.approvers" :key="approved.id">
                                                    <span v-if="approved.status == 'Aprovado'" >Vaga aprovada por {{approved.user.name}}  em {{approved.updated_at | toBrazilianDateTime}}</span>
                                                </tr>
                                            </tbody>
                                        </div>                        
                                    </div>

                                    <div class="col-md-12">
                                        <label>Observação</label>
                                        <textarea readonly class="form-control" id="" cols="30" rows="3" v-model="jobObj.approver_observation"></textarea>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="">
                            <div class="card-body">
                                <div class="card-text big-title">
                                    Detalhes da Vaga
                                </div>
                                <div class="row filter-jobs-home">
                                    
                                        <div class="col-md-8">
                                            <p class="filter-jobs-title">Descrição da Vaga:</p>
                                            <p class="font-size-14">
                                                {{ jobObj.role_description }}
                                            </p>

                                            <p class="filter-jobs-title">Buscamos pessoas com:</p>
                                            <p>
                                                <ul class="font-size-14">
                                                    <li class="normal-list"> {{ jobObj.role_requirements }}</li>                                                    
                                                </ul>
                                            </p>

                                            <p class="filter-jobs-title">Diferenciais:</p>
                                            <p>
                                                <ul class="font-size-14">
                                                    <li class="normal-list"> {{ jobObj.differential }}</li>                                                    
                                                </ul>
                                            </p>
                                        </div>
                                    
                                        <div class="col-md-4">
                                            <div class="col-md-12">
                                                <p class="title-column-job">Departamento</p>
                                                <p class="font-size-14">{{jobObj.department}}</p>
                                            </div>
                                            <div class="col-md-12">
                                                <p class="title-column-job">Salário</p>
                                                <p class="font-size-14">R$ {{jobObj.position_salary}}
                                                </p>
                                            </div>
                                            <div class="col-md-12">
                                                <p class="title-column-job">Centro de Custo</p>
                                                <p class="font-size-14">R$ {{jobObj.cost_center.cost_center || 1000}}</p>
                                            </div>
                                            
                                            <div class="col-md-12">
                                                <p class="title-column-job">Segmento</p>
                                                <p class="font-size-14">{{ jobObj.business_segment}}</p>
                                            </div>
                                            

                                            <div class="col-md-12">
                                                <p class="title-column-job">Expira</p>
                                                <p class="font-size-14">{{jobObj.end_date}}</p>
                                            </div>

                                            <div class="col-md-12">
                                                <p class="title-column-job">Status</p>
                                                <p class="font-size-14">{{jobObj.status | normalizeStatus}}</p>
                                            </div>

                                            <div class="col-md-12">
                                                <p class="title-column-job">Quantidade</p>
                                                <p class="font-size-14">{{jobObj.quantity | quantity }}</p>
                                            </div>

                                            <div class="col-md-12">
                                                <p class="title-column-job">Informações de Mercado</p>
                                                <p class="font-size-14">{{ jobObj.market_info }}</p>
                                            </div>
                                            
                                            <div class="col-md-12">
                                                <p class="title-column-job">Observações Adicionais</p>
                                                <p class="font-size-14">{{ jobObj.overreach_info }}</p>
                                            </div>
                                            
                                            <div class="col-md-12">
                                                <p class="title-column-job">Perfil</p>
                                                <p class="font-size-14">{{jobObj.profile}}</p>
                                            </div>

                                        </div>

                                    
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <div class="card-body">
                                <div class="card-text big-title">
                                    Sobre a Empresa
                                </div>
                                <div class="">
                                    
                                    <p class="font-size-14">
                                        {{jobObj.company.about}}
                                    </p>                                   
                                </div>

                            </div>
                        </div>
                        
                    </div>
                   
                </div>


                    <div class="card-body printableArea p-30">
                       

                        <div id="row_dim">
                            <div class="row job-detail-printablearea">
                                <div class="col-md-2 ">

                                    <button type="button" class="btn waves-effect waves-light btn-block btn-info">Selecionados</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 1</p>
                                </div>

                                <div class="col-md-2">
                                    <button type="button" class="btn btn-block btn-outline-info">Teste em Vídeo</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 2</p>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn btn-block btn-outline-info">Dinâmicas</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 3</p>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn btn-block btn-outline-info">Entrevista Online</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 4</p>
                                </div>

                                <div class="col-md-2 ">

                                    <button type="button" class="btn btn-block btn-outline-info">Entrevista Pessoal</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 5</p>
                                </div>

                                <div class="col-md-2 ">

                                    <button type="button" class="btn btn-block btn-outline-info">Exames</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 6</p>
                                </div>

                                <div class="col-md-2 ">

                                    <button type="button" class="btn btn-block btn-outline-info">Teste Prático</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 7</p>

                                </div>
                                <div class="col-md-2 ">

                                    <button type="button" class="btn btn-block btn-outline-info">Teste Cultura Organizacional</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 8</p>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn waves-effect waves-light btn-block btn-success text-center">Finalizado</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 9</p>
                                </div>
                            </div>
                        </div>

                        <div id="row_dim3">
                            <div class="row">
                                <div class="col-md-2 ">

                                    <button type="button" class="btn waves-effect waves-light btn-block btn-info">Selecionados</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 1</p>
                                </div>
                                <div class="col-md-2 ">

                                    <button type="button" class="btn btn-block btn-outline-info">Teste</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 2</p>
                                </div>

                                <div class="col-md-2">
                                    <button type="button" class="btn btn-block btn-outline-info">Entrevistas</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 3</p>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn btn-block btn-outline-info">Dinâmicas</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 4</p>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn btn-block btn-outline-info">Exames</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 5</p>
                                </div>
                                <div class="col-md-2">
                                    <button type="button" class="btn waves-effect waves-light btn-block btn-success text-center">Finalizado</button>
                                    <p class="text-center text-muted mt-6-px">Etapa 6</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>                        
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    data: () => {
        return {}
    },
    props: [
        'jobObj', 'job_id'
    ],
    methods: {},
    filters: {
        quantity: function (quantity) {

            if (quantity > 1) {
                return quantity + ' vagas a preencher'
            } else {
                return '1 vaga a preencher'
            }
        },
        toBrazilianDateTime: function (dateTime) {
            if (!dateTime) {
                return ''
            }
            let time = dateTime.substr(11);
            let date = dateTime.substr(0, 10);

            let dt = date.split('-')[2] + '/' +
                date.split('-')[1] + '/' +
                date.split('-')[0];

            return dt + ' ' + time;
        },
        normalizeStatus: function (status) {
            switch (status) {
                case 'finished':
                    status = 'Finalizado';
                    break;
                case 'inedition':
                    status = ' Em Edição';
                    break;
                case 'inprogress':
                    status = "Em Recrutamento";
                    break;
                case 'canceled':
                    status = 'Cancelado';
                    break;
            }
            return status;
        }
    }
}
</script>

<style scoped>
.normal-list {
    list-style-type: none;
}
</style>
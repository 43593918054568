<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ $t('label.visualizacaoCadastroCentroCusto') }}</h3>
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.centroCusto') }}*</label>
                                    <input type="text" class="form-control" v-model="cost_centers.cost_center" max="191" readonly='true'>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.nome') }}*</label>                                        
                                    <input type="text" class="form-control" v-model="cost_centers.name" readonly='true'>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.departamento') }}*</label>                                        
                                    <input type="text" class="form-control" v-model="cost_centers.department_name" readonly='true'>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.aprovador') }}*</label>                                        
                                    <input readonly='true' type="text" class="form-control" v-model="user_name">
                                </div>
                            </div>   
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.empresa') }}*</label>                                        
                                    <input readonly='true' type="text" class="form-control" v-model="cost_centers.company.name">
                                </div>
                            </div>                          
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/cost-centers"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>                                    
                            </div>
                        </div>
                    </form>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cost_centers: {
                id: 0,
                name: '',
                cost_center: '',
                department_name: '',
                company: {
                    name: ''
                },
            },
            user_name: '',
            endPoint: '/cost-centers/'
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        }
    },
    mounted: function () {
        this.loadCostCenters();
    },
    methods: {
        loadCostCenters() {
            const self = this;
            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(this.url, {
                params: {
                    with: ["user", "company"]
                }
            })
            .then((response) => {
                self.$store.dispatch("tooggleLoadModal");
                self.cost_centers = response.data;
                self.user_name = response.data.user.name;

            }).catch((error) => {   
                self.$store.dispatch("tooggleLoadModal"); 
                self.$errorMessage(error);
            })
        }
    }
}
</script>

<style>

</style>

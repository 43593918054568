<template>
  <div
    class="modal fade"
    id="finalize-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ $t('label.finalizarProcessoSeletivo') }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5>{{ $t('label.candidatosAprovados') }}</h5>
          <div class="row mt-4">
            <div class="col-md-4" v-for="candidate in candidatesInLastStage" :key="candidate.id">
              <div class="card bg-flat-gray animated flipInY">
                <div class="card-header">
                  <h4 class="card-title text-center m-0">{{ candidate.candidate.fullName }}</h4>
                </div>
                <div class="card-body">
                  <img :src="candidate.candidate.image" class="profile-avatar" />
                </div>
                <div class="card-footer">
                  <button
                    class="btn edit-btn btn-block mt-1 mb-1"
                    type="button"
                    data-toggle="modal"
                    @click="showModal(candidate)"
                  >
                    <i class="fa fa-eye"></i>
                    {{$t('label.visualizarPerfil')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-4">
              <label>Data de Admissão dos Candidatos:</label>
              <input type="date" v-model="admission_date" class="form-control" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn include-btn p-1 ml-3 float-left"
            @click="sendNegativeFeedback"
          >
            <i class="fa fa-check"></i>
            {{ $t('label.enviarFeedbackNegativo') }}
          </button>
          <button
            type="button"
            class="btn include-btn p-1 ml-3 float-left"
            @click="sendPositiveFeedback"
          >
            <i class="fa fa-check"></i>
            {{ $t('label.enviarFeedbackPositivo') }}
          </button>
          <button type="button" class="btn include-btn p-1 ml-3 float-right" @click="finish">
            <i class="fa fa-check"></i>
            {{ $t('label.finalizar') }}
          </button>
          <button
            type="button"
            class="btn include-btn p-1 ml-1 float-right"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times"></i>
            {{ $t('label.fechar') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  props: ["candidatesInLastStage", "candidatesNotApproved", "vue"],
  data: () => {
    return {
      admission_date: "",
      mailInvite: [],
      endPointEmailTemplates: "/email_templates/"
    };
  },
  methods: {
    showModal: function(item) {
      this.$emit("showModal", item);
    },
    sendPositiveFeedback() {
      const self = this;

      swal({
        title: self.$t("label.desejaEnviarFeedbackPositivoAosAprovados"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        type: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, enviar!",
        showLoaderOnConfirm: true,
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm"
          }
        }
      })
        .then(value => {
          if (value == "confirm") {
            self.$http
              .post(self.$store.getters.apiUrl + "/sendFeedback", {
                jobId: this.$route.params.id,
                candidates: this.candidatesInLastStage,
                template_id: 4
              })
              .then(() => {
                swal(
                  self.$t("label.sucesso"),
                  self.$t(
                    "label.feedbackEnviadoComSucessoParaCandidatosAprovados"
                  ),
                  "success"
                );
              });
          }
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    },
    sendNegativeFeedback() {
      const self = this;

      swal({
        title: self.$t("label.desejaEnviarFeedbackNegativoAosNãoAprovados"),
        text: self.$t("label.essaOperacaoNaoPodeSerDesfeita"),
        type: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, enviar!",
        showLoaderOnConfirm: true,
        dangerMode: true,
        buttons: {
          catch: {
            text: self.$t("label.nao"),
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: self.$t("label.sim"),
            value: "confirm"
          }
        }
      })
        .then(value => {
          if (value == "confirm") {
            self.$http
              .post(self.$store.getters.apiUrl + "/sendFeedback", {
                jobId: this.$route.params.id,
                candidates: this.candidatesNotApproved,
                template_id: 5
              })
              .then(() => {
                swal(
                  self.$t("label.sucesso"),
                  self.$t(
                    "label.feedbackEnviadoComSucessoParaCandidatosNaoAprovados"
                  ),
                  "success"
                );
              });
          }
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    },
    loadTemplatesEmail() {
      const self = this;
      self.job_id = self.$route.params.id;
      if (self.urlJobStage != undefined) {
        self.$http
          .get(self.urlEmailTemplate, {
            params: {}
          })
          .then(response => {
            self.email_templates = response.data.data;
          })
          .catch(error => {
            self.$errorMessage(error);
          });
      }
    },
    // sendFeedbackMail(stage){
    //     const self = this;

    //     let candidatesToSend = this.candidates.filter((candidate) => {
    //         return candidate.status == stage.name && candidate.selected == true;
    //     });

    //     if (candidatesToSend.lenght == 0) {
    //         self.$message('warning', self.$t('label.atencao'), self.$t('label.selecionePeloMenos1CandidatoParaEnviaroTeste'));
    //         return
    //     }

    //     candidatesToSend = candidatesToSend.map(candidate => {
    //         return candidate.job_candidate_id
    //     });

    //     self.$http
    //         .post(self.$store.getters.apiUrl + '/sendInviteToCandidates', {
    //             candidates: candidatesToSend,
    //             jobId: self.Job.id,
    //             stageId: stage.id,
    //             template_id: self.mailInvite.template,
    //             datetime: self.mailInvite.datetime,
    //             duration: self.mailInvite.duration
    //         })
    //         .then( () => {
    //             swal(self.$t('label.sucesso'), self.$t('label.inviteEnviadoComSucessoParaCandidatosSelecionados'), 'success');
    //         })
    // },
    finish: function() {
      const self = this;

      $("#finalize-modal").modal("toggle");
      self.$store.dispatch("tooggleLoadModal");
      if(self.candidatesInLastStage.length < 1){
        self.$store.dispatch("tooggleLoadModal");
        self.$errorMessage("Error", self.$t("label.nenhumCandidatoEstagioFinal") );
      }
      self.candidatesInLastStage.forEach(candidate => {
        self.$http
          .put(
            self.$store.getters.apiUrl +
              "/jobCandidate/" +
              candidate.job_candidate_id,
            {
              status: 9
            }
          )
          .then(() => {
            self.$http
              .post(
                self.$store.getters.apiUrl +
                  "/finishJob/" +
                  this.$route.params.id,
                {
                  status: 4,
                  admission_date: this.admission_date
                }
              )
              .then(() => {
                self.$store.dispatch("tooggleLoadModal");
                self.$message(
                  "success",
                  self.$t("label.sucesso"),
                  self.$t("label.processoFinalizadoComExito")
                );
                self.$router.push({
                  path: "/jobRoles/"
                });
              })
              .catch(error => {
                self.$store.dispatch("tooggleLoadModal");
                self.$errorMessage(error);
                $("#finalize-modal").modal("toggle");
              });
          })
          .catch(error => {
            self.$store.dispatch("tooggleLoadModal");
            self.$errorMessage(error);
          });
      });
    }
  },
  mounted: function() {
    this.loadTemplatesEmail();
  },
  computed: {
    urlEmailTemplate() {
      return this.$store.getters.apiUrl + this.endPointEmailTemplates;
    }
  }
};
</script>